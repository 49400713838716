import {
  BulkActionResultTabs,
  BulkActionsCloseMethod,
  BulkActionsVariant,
} from "components/BulkActions/types";
import DrawerFooter from "ds/components/Drawer/Footer";
import { BulkActionsApiContext, BulkActionsDataContext } from "components/BulkActions/Context";
import TabPanel from "ds/components/TabPanel";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPage } from "hooks/useAnalytics";
import { type BulkActionItemResults } from "components/BulkActions/helpers";

import { TABS_ORDER } from "../constants";
import { BulkActionResultsStepContinueWith } from "../types";
import { BulkActionsDrawerResultsStepFooterContinueWith } from "./ContinueWith";

type BulkActionsDrawerResultsStepTabsFooterProps = {
  currentTab: BulkActionResultTabs;
  bulkActionItemResults: BulkActionItemResults;
  onContinueWith?: (continueWith: BulkActionResultsStepContinueWith) => void;
  stopExecution: () => void;
  analyticsPage: AnalyticsPage;
  rerunWithFailedOnly?: boolean;
};

const BulkActionsDrawerResultsStepFooter = ({
  currentTab,
  bulkActionItemResults,
  onContinueWith,
  stopExecution,
  analyticsPage,
  rerunWithFailedOnly,
}: BulkActionsDrawerResultsStepTabsFooterProps) => {
  const { isExecutingBulkActions } = useTypedContext(BulkActionsDataContext);
  const { closeBulkActions } = useTypedContext(BulkActionsApiContext);

  return (
    <DrawerFooter>
      {TABS_ORDER.map((tab) => (
        <TabPanel key={tab} id={tab} isActive={currentTab === tab}>
          <Box direction="row" gap="medium">
            <Button
              size="medium"
              variant="secondary"
              onClick={() =>
                closeBulkActions(
                  BulkActionsVariant.Drawer,
                  BulkActionsCloseMethod.ResultsCloseButton
                )
              }
            >
              Close
            </Button>
            {isExecutingBulkActions && (
              <Button
                size="medium"
                variant="secondary"
                disabled={!isExecutingBulkActions}
                onClick={stopExecution}
              >
                Stop all
              </Button>
            )}
            {!isExecutingBulkActions && onContinueWith && (
              <BulkActionsDrawerResultsStepFooterContinueWith
                bulkActionItemResults={bulkActionItemResults}
                rerunWithFailedOnly={rerunWithFailedOnly}
                onContinueWith={onContinueWith}
                analyticsPage={analyticsPage}
              />
            )}
          </Box>
        </TabPanel>
      ))}
    </DrawerFooter>
  );
};

export default BulkActionsDrawerResultsStepFooter;
