import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

const FullScreenModalWrapper = ({
  children,
  direction = "column",
  className,
  ...restProps
}: BoxProps) => {
  return (
    <Box
      // It is needed to detect element when drawer open
      data-fullscreen-modal-wrapper
      direction={direction}
      className={cx(styles.wrapper, className)}
      {...restProps}
    >
      {children}
    </Box>
  );
};

FullScreenModalWrapper.displayName = "DS.FullScreenModal.Wrapper";

export default FullScreenModalWrapper;
