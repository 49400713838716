import StackHooks from "components/DocumentationSnippets/StackHooks";
import { File } from "components/icons/generated";
import Button from "ds/components/Button";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

const StackHooksDocumentationDrawer = createDrawer(() => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Hooks" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h3" variant="p-t6">
          Customizing workflow
        </Typography>

        <StackHooks />
        <Typography tag="h3" variant="p-t6">
          Hooks ordering
        </Typography>
        <Typography tag="div" variant="p-body2">
          Hooks ordering is based on context priority. In the before phase, hook priorities work as
          follows:
          <ol>
            <li>Context hooks (based on set priorities)</li>
            <li>Context auto-attached hooks (reversed alphabetically)</li>
            <li>Stack hooks will be the last ones in order.</li>
          </ol>
          In the after phase, hook priorities work as follows:
          <ol>
            <li>Stack hooks</li>
            <li>Context auto-attached hooks (alphabetically)</li>
            <li>Context hooks (reversed priorities)</li>
          </ol>
        </Typography>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button
              variant="secondary"
              startIcon={File}
              target="_blank"
              rel="noopener noreferrer"
              href={getDocsUrl("/concepts/stack/stack-settings#customizing-workflow")}
            >
              Documentation
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showStackHooksDocumentationDrawer = createDrawerTrigger(StackHooksDocumentationDrawer);
