const addPlus = (num: number) => (Math.floor(num) !== num ? "+" : "");

export const transformDeltaCount = (count: number) => {
  if (count > 9999 && count < 1000000) {
    const countDivided = count / 1000;
    return `${Math.floor(countDivided)}k${addPlus(countDivided)}`;
  }

  if (count > 999999) {
    const countDivided = count / 1000000;
    return `${Math.floor(countDivided)}m${addPlus(countDivided)}`;
  }

  return count;
};
