import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import Box from "ds/components/Box";

import SettingsIntegrationsSlackManageAccessMenuItem from "./ManageAccessMenuItem";
import SettingsIntegrationsSlackConnectButton from "./ConnectButton";

type SettingsIntegrationsSlackTileIndicatorSaaSProps = {
  hasIntegration: boolean;
};

const SettingsIntegrationsSlackTileIndicatorSaaS = ({
  hasIntegration,
}: SettingsIntegrationsSlackTileIndicatorSaaSProps) => {
  return (
    <Box gap="medium">
      {hasIntegration && (
        <DropdownMenuEllipsis tooltip="Slack actions" dotsSize="medium">
          <SettingsIntegrationsSlackManageAccessMenuItem />
          {/*
            FYI: the Disconnect button should be disabled and it doesn't have any actions here
            there is no way to trigger deleting Slack integration from the user's workspace in SaaS
            but we want to remind how to do it and align layouts with the SH version
          */}
          <DropdownMenuItem
            tooltip="You can disconnect integration by removing Spacelift app from your Slack workspace."
            tooltipWidthMode="maxWidthSm"
            isDisabled
            danger
          >
            Disconnect
          </DropdownMenuItem>
        </DropdownMenuEllipsis>
      )}

      {!hasIntegration && <SettingsIntegrationsSlackConnectButton />}
    </Box>
  );
};

export default SettingsIntegrationsSlackTileIndicatorSaaS;
