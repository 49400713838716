import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

type ChartTooltipProps = {
  title: string;
  count: string;
};

const ChartTooltipTotalHeader = ({ title, count }: ChartTooltipProps) => {
  return (
    <Box direction="row" justify="between" align="center" gap="medium">
      <Typography variant="p-t6" tag="span" color="primary">
        {title}
      </Typography>
      <Typography variant="p-t6" tag="span" color="primary">
        {count}
      </Typography>
    </Box>
  );
};

ChartTooltipTotalHeader.displayName = "DS.Charts.Tooltip.TotalHeader";

export default ChartTooltipTotalHeader;
