import Counter from "ds/components/Counter";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { TreeBranch } from "ds/components/Tree";
import { PlanPoliciesOutcome } from "types/generated";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import styles from "./styles.module.css";
import Message from "./Message";

type MessagesProps = {
  outcome: PlanPoliciesOutcome;
};

function Messages({ outcome }: MessagesProps) {
  const [isCollapsed, toggle] = useToggle(false);

  const count = outcome.deny.length + outcome.warn.length;

  return (
    <TreeBranch className={styles.messagesWrapper}>
      <CollapsiblePanel isCollapsed={isCollapsed} withTransition onToggle={toggle}>
        <CollapsiblePanelHeader
          gap="medium"
          align="center"
          padding="medium large"
          className={styles.messagesHeader}
        >
          <CollapsiblePanelToggleTrigger ariaLabel={`Messages. ${count} elements.`} ariaLevel={4} />

          <CollapsiblePanelTitle aria-hidden variant="p-t7">
            Messages
          </CollapsiblePanelTitle>

          <Counter count={count} size="small" />
        </CollapsiblePanelHeader>

        <CollapsiblePanelContent>
          {outcome.deny.map((message, index) => (
            <Message key={index} text={message} type="deny" />
          ))}
          {outcome.warn.map((message, index) => (
            <Message key={index} text={message} type="warn" />
          ))}
        </CollapsiblePanelContent>
      </CollapsiblePanel>
    </TreeBranch>
  );
}

export default Messages;
