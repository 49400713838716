import ReactFlow, { Controls, MiniMap } from "reactflow";
import useLocalStorage from "@rehooks/local-storage";
import { useMemo } from "react";

import Callout from "ds/components/Callout";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import CustomReactFlowBackground from "ds/components/CustomReactFlow/Background";

import styles from "./styles.module.css";
import { getViewOptions, makeEdges, makeNodesList, makeSpacesTree } from "./helpers";
import SpaceNode from "../SpaceNode";
import { SpacesViewActionsContext } from "../ActionsContext";

const NODE_TYPES = { space: SpaceNode };

const SPACES_DIAGRAM_INFO_MESSAGE_KEY = "spacesInfoMessage";

const SpacesDiagram = () => {
  const { spaces } = useTypedContext(SpacesContext);
  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const [isInfoToastVisible] = useLocalStorage<boolean>(SPACES_DIAGRAM_INFO_MESSAGE_KEY, true);

  const handleDrawerClose = () => {
    onCloseDrawer();
  };

  const [nodesList, edges] = useMemo(() => {
    const spacesTree = makeSpacesTree(spaces);

    const nodesList = spacesTree ? makeNodesList(spacesTree) : [];
    const edges = makeEdges(spaces);

    return [nodesList, edges];
  }, [spaces]);

  return (
    <>
      {isInfoToastVisible && (
        <Callout variant="info" storageKey={SPACES_DIAGRAM_INFO_MESSAGE_KEY}>
          A solid arrow indicates resources are inherited from the parent space.
          <br />A dashed arrow indicates no inheritance.
        </Callout>
      )}
      <div className={styles.wrapper}>
        <ReactFlow
          className={styles.reactFlow}
          onInit={(reactFlowInstance) => {
            reactFlowInstance.fitView({ maxZoom: 1 });
          }}
          nodes={nodesList}
          edges={edges}
          nodeTypes={NODE_TYPES}
          fitViewOptions={getViewOptions(nodesList.length)}
          nodesDraggable={false}
          nodesConnectable={false}
          edgesUpdatable={false}
          maxZoom={4}
          minZoom={0.1}
          fitView
          panOnScroll
          zoomOnPinch
          aria-label="Spaces tree graph"
          onClick={handleDrawerClose}
        >
          {nodesList.length > 10 && <MiniMap nodeStrokeWidth={3} />}
          <Controls className={styles.controls} showInteractive={false} />
          <CustomReactFlowBackground />
        </ReactFlow>
      </div>
    </>
  );
};

export default SpacesDiagram;
