import { useLayoutEffect } from "react";
import { Background } from "reactflow";

const CustomReactFlowBackground = () => {
  useLayoutEffect(() => {
    const background = document.querySelector("[data-testid='rf__background']");
    if (background) {
      background.setAttribute("aria-hidden", "true");
    }
  }, []);

  return <Background />;
};

CustomReactFlowBackground.displayName = "DS.CustomReactFlowBackground";

export default CustomReactFlowBackground;
