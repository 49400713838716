import { ReactNode } from "react";

import { Stack } from "types/generated";
import TableDropdownCell from "components/Table/DropdownCell";

import useCellActionsConfig, { StackColumnWithActions } from "./useCellActionsConfig";

type StackListTableDropdownCellProps = {
  id: StackColumnWithActions;
  className?: string;
  stack: Stack;
  children: ReactNode;
};

const StackListTableDropdownCell = ({
  id,
  className,
  stack,
  children,
}: StackListTableDropdownCellProps) => {
  const cellActionsConfig = useCellActionsConfig(stack);
  const dropdownActions = cellActionsConfig[id];

  return (
    <TableDropdownCell id={id} className={className} dropdownActions={dropdownActions}>
      {children}
    </TableDropdownCell>
  );
};

export default StackListTableDropdownCell;
