import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchSuggestionsFieldType } from "types/generated";
import { isHierarchical } from "views/Account/Stacks/ListItem/isHierarchical";

import {
  ActiveFilter,
  ActiveFiltersMap,
  FilterItem,
  FilterItemSettings,
  FiltersDictionary,
  FiltersItemsOptionsMap,
  HierarchyFilterItemOption,
} from "./types";
import { mapDictionaryKey } from "./helpers";

type UseMakeApplyFiltersContextProps = {
  allFilters: FilterItem[];
  setActiveFilter: (filter: ActiveFilter) => void;
  currentFilters: FilterItem[];
  activeFiltersByFilterName: ActiveFiltersMap;
  setGenericFilter: (newFilter: FilterItem) => FilterItem[];
  setFiltersOrder: Dispatch<SetStateAction<FilterItemSettings[]>>;
  filtersItemsOptionsMap: FiltersItemsOptionsMap;
};

const useMakeApplyFiltersContext = <T extends string>({
  allFilters,
  setActiveFilter,
  currentFilters,
  activeFiltersByFilterName,
  setGenericFilter,
  setFiltersOrder,
  filtersItemsOptionsMap,
}: UseMakeApplyFiltersContextProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const addFilter = useCallback(
    (value: string, currentFilter?: FilterItem, filterValues?: string[]) => {
      if (currentFilter) {
        if (!filterValues?.includes(value)) {
          setActiveFilter({
            ...currentFilter,
            values: filterValues ? [...filterValues, value] : [value],
          });
        }

        reportSuccess({ message: "Filter has been applied" });
      } else {
        reportError({ message: "Unable to set filter" });
      }
    },
    [reportSuccess, setActiveFilter]
  );

  const applyFilter = useCallback(
    (filterName: T, searchSuggestionsDictionary: FiltersDictionary) => (value: string) => {
      if (!filtersItemsOptionsMap.has(filterName)) {
        return reportError({ message: "Unable to set filter" });
      }

      setFiltersOrder((filtersOrder) =>
        filtersOrder.map((item) => (item.name === filterName ? { ...item, visible: true } : item))
      );

      const filterKey = mapDictionaryKey(filterName, searchSuggestionsDictionary);
      const currentFilter = allFilters.find(({ key }) => key === filterKey);
      const filterValues = activeFiltersByFilterName.get(filterKey)?.values;

      addFilter(value, currentFilter, filterValues);
    },
    [addFilter, setFiltersOrder, activeFiltersByFilterName, allFilters, filtersItemsOptionsMap]
  );

  const applyLabelFilter = useCallback(
    (filterName: T) => (value: string) => {
      if (!filtersItemsOptionsMap.has(filterName)) {
        return reportError({ message: "Unable to set label filter" });
      }

      setFiltersOrder((filtersOrder) =>
        filtersOrder.map((item) =>
          item.name.startsWith(filterName) ? { ...item, visible: true } : item
        )
      );

      const labelGroupIndex = activeFiltersByFilterName.get(filterName)
        ? Math.max(
            ...currentFilters
              .filter(({ key }) => key.startsWith(filterName))
              .map(({ key }) => Number(key.replace(filterName, "")))
          ) + 1
        : 1;

      const filterKeyNew = `${filterName}${labelGroupIndex}`;

      const newFilters = setGenericFilter({
        type: SearchSuggestionsFieldType.String,
        filterName: filterName,
        key: filterKeyNew,
      });

      const currentFilter = newFilters.find(({ key }) => key === filterKeyNew);
      const filterValues = activeFiltersByFilterName.get(filterKeyNew)?.values;

      addFilter(value, currentFilter, filterValues);
    },
    [
      activeFiltersByFilterName,
      currentFilters,
      setFiltersOrder,
      setGenericFilter,
      addFilter,
      filtersItemsOptionsMap,
    ]
  );

  const applySpaceFilter = useCallback(
    (filterName: T, searchSuggestionsDictionary: FiltersDictionary) => (id: string) => {
      const filterSpace = filtersItemsOptionsMap
        .get(filterName)
        ?.find(
          (space) => isHierarchical(space) && (space.id === id || space.id.endsWith(`/${id}`))
        ) as HierarchyFilterItemOption;

      if (!filterSpace) {
        return reportError({ message: "Unable to set space filter" });
      }

      applyFilter(filterName, searchSuggestionsDictionary)(filterSpace.id);
    },
    [filtersItemsOptionsMap, applyFilter]
  );

  const value = useMemo(
    () => ({
      applyFilter,
      applySpaceFilter,
      applyLabelFilter,
    }),
    [applyFilter, applySpaceFilter, applyLabelFilter]
  );

  return value;
};

export default useMakeApplyFiltersContext;
