import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { type AnalyticsPage } from "hooks/useAnalytics";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

import styles from "./styles.module.css";

type ApiKeysInfoDrawerContentProps = {
  analyticsPage: AnalyticsPage;
};

const ApiKeysInfoDrawerContent = ({ analyticsPage }: ApiKeysInfoDrawerContentProps) => {
  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="API keys" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h3" variant="p-t6">
          API Keys access
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          API keys are unique identifiers used for authentication and authorization to access an
          API. They ensure secure communication and control access to API resources.
          <br />
          <br />
          You can create new API keys and assign access rules to them by selecting the desired role
          access level for the chosen spaces in your account.
          <br />
          <br />
          Please note that once API key access rules are created, they cannot be edited. The only
          option available is to remove the key and create a new one.
        </Typography>
        <DrawerFooter>
          <DocumentationButton
            to={getDocsUrl("/integrations/api#spacelift-api-key-token")}
            analyticsPage={analyticsPage}
          />
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default ApiKeysInfoDrawerContent;
