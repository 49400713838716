import SystemMessage from "components/SystemMessage";
import Banner from "ds/components/Banner";

const CLIAuthFailure = () => {
  return (
    <SystemMessage title="Spacelift CLI" message="Unauthorized">
      <Banner variant="danger" title="The authentication flow did not complete successfully." />
    </SystemMessage>
  );
};

export default CLIAuthFailure;
