/* eslint  react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import cx from "classnames";

import formStyles from "components/FormDefault/styles.module.css";
import useOutsideClick from "hooks/useOutsideClick";
import { Cross } from "components/icons/generated";
import Select from "components/select/Select";
import { withEnterKeyPress } from "utils/browser";
import Icon from "ds/components/Icon";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { translateLabel } from "../helpers";
import { generateFilterButtonText } from "./helpers";

import "./styles.css";

/**
 *
 * @deprecated Do nothing - waiting for the Resources redesign
 */
const ResourceFilters = (props) => {
  const {
    filterField,
    filterValues,
    handleFilterChange,
    handleMultiFilterChange,
    index,
    keyPossibleValues,
  } = props;
  const [isEditing, setIsEditing] = useState(true);
  const wrapperRef = useRef(null);
  const { reportError } = useTypedContext(FlashContext);

  const keyOptions = useMemo(
    () =>
      Object.keys(keyPossibleValues)
        .map((value) => ({
          value,
          label: translateLabel(value),
        }))
        .sort(),
    [keyPossibleValues]
  );

  const filterKeyOptions = useMemo(
    () =>
      filterField[index] === ""
        ? null
        : { value: filterField[index], label: translateLabel(filterField[index]) },
    [filterField, index]
  );

  const filterValuesOptions = useMemo(() => {
    if (filterField[index] === "") {
      return [];
    }

    if (!keyPossibleValues[filterField[index]]) {
      return [];
    }

    return keyPossibleValues[filterField[index]].map((value) => {
      return {
        value,
        label: typeof value === "boolean" ? `${value}` : value,
      };
    });
  }, [filterField, keyPossibleValues, index]);

  const hasValues = filterValues[index].length > 0;
  const buttonText = useMemo(
    () => generateFilterButtonText(filterKeyOptions, filterValues, index),
    [filterKeyOptions, filterValues, index]
  );

  const buttonClass = cx("resources-filters__value", {
    "resources-filters__value--empty": !filterKeyOptions,
  });
  const wrapperClass = cx({
    "resources-filters__wrapper--empty": !filterKeyOptions && index === 0,
  });

  const closeEditable = () => {
    setIsEditing(false);
  };

  const handleClick = () => setIsEditing(true);

  useOutsideClick(wrapperRef, closeEditable);
  useEffect(() => {
    if ((filterKeyOptions && filterValues[index].length >= 0) || filterField.length === 1) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearValues = useCallback(() => {
    handleFilterChange(null, index);
  }, [handleFilterChange, index]);

  return (
    <div ref={wrapperRef} className={wrapperClass}>
      <div
        className={buttonClass}
        onClick={handleClick}
        onKeyDown={withEnterKeyPress(handleClick)}
        role="button"
        tabIndex={0}
      >
        {buttonText}
        {hasValues && (
          <button type="button" className="resources-filters__delete" onClick={clearValues}>
            <Icon src={Cross} />
          </button>
        )}
      </div>
      {isEditing && (
        <div className="resource-filters__form">
          <Select
            className={formStyles.input}
            value={filterKeyOptions}
            options={keyOptions}
            onChange={(option) => {
              handleFilterChange(option, index);
            }}
            placeholder="Filter key"
            isClearable
          />
          <Select
            className={formStyles.input}
            value={filterValues[index] || null}
            options={filterValuesOptions}
            onChange={(newValues) => {
              handleMultiFilterChange(newValues, index);
              if (newValues.length > 0) {
                setIsEditing(false);
              }
            }}
            canCreateOptions={true}
            onCreateOption={(option) => {
              let newValues = filterValues[index] || [];
              try {
                newValues = JSON.parse(JSON.stringify(newValues));
                newValues.push({ label: option, value: option });
              } catch {
                reportError({ message: "Couldn't set new option" });
                return;
              }

              handleMultiFilterChange(newValues, index);
            }}
            placeholder="Filtered values"
            isMulti
          />
        </div>
      )}
    </div>
  );
};

export default ResourceFilters;
