import { MouseEvent, Ref, RefObject, useEffect, useRef, useState } from "react";

import useOutsideClick from "hooks/useOutsideClick";
import DropdownList from "ds/components/Dropdown/List";
import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import Portal from "components/Portal";
import { DropdownProps } from "ds/components/Dropdown/types";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import styles from "./styles.module.css";
import { getFixedStyle } from "./getFixedStyle";
import { getAbsoluteStyle } from "./getAbsoluteStyle";

type DropdownPortalledProps = Omit<DropdownProps, "renderTriggerComponent"> & {
  listClassName?: string;
  portalElementRef?: RefObject<HTMLDivElement>;
  isAbsolute?: boolean;
  renderTriggerComponent: ({
    onClick,
    isVisible,
    ref,
  }: {
    onClick: (e: MouseEvent) => void;
    isVisible: boolean;
    ref?: Ref<HTMLDivElement>;
  }) => React.ReactNode;
};

const DropdownPortalled = ({
  children,
  onVisibilityChange,
  closeDropdownTrigger = 0,
  renderTriggerComponent,
  className,
  listClassName,
  position = "bottomLeft",
  isAbsolute,
  portalElementRef,
}: DropdownPortalledProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const portalContainerRef = useRef<HTMLDivElement>(null);
  const [cords, setCords] = useState<DOMRect>();
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
    if (wrapperRef.current && !isAbsolute) {
      setCords(wrapperRef.current.getBoundingClientRect());
    }
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    onVisibilityChange?.(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (closeDropdownTrigger > 0) {
      closeDropdown();
    }
  }, [closeDropdownTrigger]);

  useOutsideClick(
    isAbsolute && portalElementRef ? portalElementRef : portalContainerRef,
    closeDropdown
  );

  useEscapeKeypress(isVisible, closeDropdown);

  useEffect(() => {
    if (wrapperRef.current && !isAbsolute) {
      setCords(wrapperRef.current.getBoundingClientRect());
    }
  }, [isAbsolute]);

  const portalContainerStyles = isAbsolute
    ? getAbsoluteStyle(wrapperRef, triggerRef)
    : getFixedStyle(position, triggerRef, cords);

  return (
    <DropdownWrapper ref={wrapperRef} className={className}>
      {renderTriggerComponent({ onClick: handleClick, isVisible, ref: triggerRef })}
      <Portal customMountElement={portalElementRef?.current}>
        <div
          ref={portalContainerRef}
          className={isAbsolute ? styles.dropdownPortalledAbsolute : styles.dropdownPortalled}
          style={portalContainerStyles}
        >
          <DropdownList active={isVisible} position={position} className={listClassName}>
            {children({ closeDropdown })}
          </DropdownList>
        </div>
      </Portal>
    </DropdownWrapper>
  );
};

DropdownPortalled.displayName = "DS.Dropdown.Portalled";

export default DropdownPortalled;
