import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import { IMPORT_STATE } from "views/Stack/gql";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import FormField from "ds/components/Form/Field";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import Banner from "ds/components/Banner";
import { GET_STATE_UPLOAD_URL } from "views/Account/NewStack/Vendor/Terraform/gql";
import { uploadFileToS3 } from "views/Account/NewStack/Vendor/Terraform/uploadFileToS3";
import Box from "ds/components/Box";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import { StateUploadUrl } from "types/generated";
import ModalHeader from "ds/components/Modal/Header";
import ModalFooter from "ds/components/Modal/Footer";
import ModalCancelButton from "ds/components/Modal/CancelButton";

import { ImportStateFields } from "./types";

type StackImportStateModalProps = {
  stackId: string;
};

const StackImportStateModal = create(function StackImportStateModal({
  stackId,
}: StackImportStateModalProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const modal = useModal();

  const importStateForm = useForm<ImportStateFields>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { isValid, isSubmitting },
  } = importStateForm;

  const [uploading, setUploading] = useState(false);

  const [importState] = useMutation(IMPORT_STATE, {
    refetchQueries: ["GetStateVersions"],
    awaitRefetchQueries: true,
  });
  const [getStateUploadUrl] = useMutation<{ stateUploadUrl: StateUploadUrl }>(GET_STATE_UPLOAD_URL);

  const uploadState = async (file: File | null) => {
    clearErrors("stateFile");
    if (!file) {
      setValue("stateFile", null);
      setValue("uploadedStateObjectId", "", {
        shouldValidate: true,
      });
      return;
    }

    setValue("stateFile", file);
    setUploading(true);

    const stateUploadUrl = await getStateUploadUrl().then(
      ({ data }): StateUploadUrl | undefined => {
        if (data?.stateUploadUrl) {
          return data?.stateUploadUrl;
        }
        return;
      }
    );

    if (!stateUploadUrl) {
      setUploading(false);
      setError("stateFile", {
        message: "We had a problem processing your file",
      });
      return;
    }

    return uploadFileToS3(file, stateUploadUrl.url)
      .then(() => {
        setValue("uploadedStateObjectId", stateUploadUrl.objectId, {
          shouldValidate: true,
        });
      })
      .catch(() => {
        setValue("uploadedStateObjectId", "", {
          shouldValidate: true,
        });
        setError("stateFile", {
          message: "We had a problem processing your file",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const onSubmit: SubmitHandler<ImportStateFields> = async (formData) => {
    if (formData.uploadedStateObjectId == null) {
      return;
    }
    return importState({ variables: { stackId, state: formData.uploadedStateObjectId } })
      .then(() => {
        reportSuccess({ message: "State successfully imported" });
        modal.hide();
      })
      .catch(onError);
  };

  const disabled = uploading || isSubmitting;

  return (
    <Modal size="large" isOpen={modal.visible} onExit={modal.remove}>
      <ModalHeader title="Import a state" onClose={modal.hide}></ModalHeader>
      <ModalContent gap="large">
        <Box direction="column" gap="medium">
          <Banner variant="warning">
            <Typography tag="span" variant="p-body2">
              Importing a state file will overwrite the latest state with the uploaded one.
              <br />
              You can still rollback later on, but you must{" "}
              <Typography tag="span" variant="p-t6">
                know exactly what you are doing here.
              </Typography>
            </Typography>
          </Banner>
          <Banner variant="info">
            <Typography tag="span" variant="p-body2">
              Maximum file size for an imported state is{" "}
              <Typography tag="span" variant="p-t6">
                100MB.
              </Typography>
              <br />
              The file must be a valid JSON state. It can be dumped using the command
              <br />
              <Typography tag="span" variant="s-t6">
                tofu state pull
              </Typography>
            </Typography>
          </Banner>
        </Box>
        <FormProvider {...importStateForm}>
          <Controller
            name="stateFile"
            control={control}
            rules={{ required: "State file field is required." }}
            render={({ field, fieldState }) => (
              <FormField noMargin={true} error={fieldState.error?.message}>
                <DragDropFileUpload
                  ariaLabel="Upload state file"
                  caption="Drag and drop the .tfstate file here"
                  file={field.value}
                  onChange={uploadState}
                  loading={uploading}
                />
              </FormField>
            )}
          />
        </FormProvider>
      </ModalContent>
      <ModalFooter>
        <ModalCancelButton onClick={modal.hide}></ModalCancelButton>
        <Button
          loading={isSubmitting}
          disabled={disabled || !isValid}
          onClick={handleSubmit(onSubmit)}
          variant="primary"
        >
          Import state
        </Button>
      </ModalFooter>
    </Modal>
  );
});

export const showStackImportStateModal = (props: StackImportStateModalProps) =>
  show(StackImportStateModal, props);
