import { Routes, Route, Navigate } from "react-router-dom";

import useTypedFlags from "hooks/useTypedFlags";
import { ApiKey } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import { isSaasDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import useSelfHostedFlags from "hooks/useSelfHostedFlags";

import SettingsSingleSignOn from "./SingleSignOn";
import ApiKeys from "./ApiKeysNew";
import AuditTrail from "./AuditTrail/Config";
import Billing from "./billing/Billing";
import SecuritySettings from "./SecuritySettings";
import Sessions from "./Sessions";
import Users from "./Users";
import ManageMFA from "./ManageMFA";
import IdpGroupMapping from "./IdpGroupMapping";
import ManagementStrategy from "./ManagementStrategy";
import LoginPolicies from "./LoginPolicies";
import SettingsIntegrations from "./Integrations";
import Usage from "./Usage";
import Limits from "./Limits";
import BillingNew from "./BillingNew";
import { isV4BillingTier } from "./BillingNew/utils";
import { SubscriptionContext } from "../SubscriptionWrapper";
import AuditTrailInfo from "./AuditTrail/Info";
import AISettings from "./AI";

const isSaas = isSaasDistribution();

type SettingsRoutesSelfHostedProps = {
  apiKeys: ApiKey[];
  securityEmail: undefined;
};
type SettingsRoutesSaaSProps = SettingsRoutesSelfHostedProps & {
  securityEmail: string;
};

type SettingsRoutesProps = SettingsRoutesSaaSProps | SettingsRoutesSelfHostedProps;

const SettingsRoutes = ({ apiKeys, securityEmail }: SettingsRoutesProps) => {
  const { billingUsage, useBillingV4, auditTrailLogsFrontend, runSummarizationAi } =
    useTypedFlags();
  const { selfHostedV3InstallationFlow } = useSelfHostedFlags();

  const { tier } = useTypedContext(SubscriptionContext);

  return (
    <Routes>
      <Route index element={<Navigate to="users" replace />} />
      {/*
        TODO: remove the note with the `selfHostedV3InstallationFlow` flag removal
        SSO settings are disabled for self-hosted since they are specified when installing.
        This makes it less likely users accidentally lock themselves out by disabling their
        SSO settings and not configuring new SSO.
      */}
      {(isSaas || (!isSaas && selfHostedV3InstallationFlow)) && (
        <Route path="sso">
          <Route index path="*" element={<SettingsSingleSignOn />} />
        </Route>
      )}

      <Route path="mfa" element={<ManageMFA />} />
      <Route path="slack" element={<Navigate to="/settings/integrations" replace />} />
      <Route path="api-keys" element={<ApiKeys apiKeys={apiKeys} />} />
      <Route path="login-policy" element={<LoginPolicies />} />
      <Route
        path="audit-trail"
        element={auditTrailLogsFrontend ? <AuditTrailInfo /> : <AuditTrail />}
      />
      {isSaas && <Route path="limits" element={<Limits />} />}
      {isSaas && (
        <Route path="billing">
          <Route
            index
            path="*"
            element={useBillingV4 && isV4BillingTier(tier) ? <BillingNew /> : <Billing />}
          />
        </Route>
      )}
      {isSaas && billingUsage && (
        <Route path="usage">
          <Route index path="*" element={<Usage />} />
        </Route>
      )}

      {isSaas && typeof securityEmail !== "undefined" && (
        <Route path="security" element={<SecuritySettings securityEmail={securityEmail} />} />
      )}
      <Route path="sessions" element={<Sessions />} />
      <Route path="users" element={<Users />} />
      <Route path="idp-group-mapping" element={<IdpGroupMapping />} />
      <Route path="management-strategy" element={<ManagementStrategy />} />
      <Route path="integrations" element={<SettingsIntegrations />} />
      {runSummarizationAi && <Route path="ai" element={<AISettings />} />}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default SettingsRoutes;
