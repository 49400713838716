import { Separator } from "react-aria-components";

import styles from "./styles.module.css";

const DropdownMenuDivider = () => {
  return <Separator className={styles.divider} />;
};

DropdownMenuDivider.displayName = "DS.DropdownMenu.Divider";

export default DropdownMenuDivider;
