import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { Input, InputProps } from "react-aria-components";
import cx from "classnames";

import styles from "./styles.module.css";

export type AriaInputProps = {
  color?: "default" | "inversed";
  size?: "small" | "regular";
  error?: boolean;
  active?: boolean;
} & InputProps;

const AriaInput = forwardRef(function AriaInput(
  props: AriaInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    className,
    size = "regular",
    color = "default",
    disabled,
    onChange,
    error = false,
    type = "text",
    active,
    ...restProps
  } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange?.(e);
    }
  };

  return (
    <Input
      ref={ref}
      onChange={handleOnChange}
      type={type}
      className={cx(
        styles.input,
        styles[size],
        styles[color],
        {
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.active]: active,
        },
        className
      )}
      disabled={disabled}
      {...restProps}
    />
  );
});

AriaInput.displayName = "DS.AriaInput";

export default AriaInput;
