/* eslint react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const generateFilterButtonText = (filterKeyOptions, filterValues, index) => {
  const key = filterKeyOptions ? `${filterKeyOptions.label} : ` : "+ Add";
  const value =
    filterValues[index].length > 0
      ? filterValues[index].map((item) => item.label).join(", ")
      : " filter";
  return `${key}${value}`;
};
