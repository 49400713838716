import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type CollapsiblePanelHeaderProps = BoxProps;

function CollapsiblePanelHeader({ children, className, ...rest }: CollapsiblePanelHeaderProps) {
  return (
    <Box fullWidth align="center" className={cx(styles.header, className)} {...rest}>
      {children}
    </Box>
  );
}

export default CollapsiblePanelHeader;
