import { memo, useEffect, useMemo, useRef } from "react";
import { useLazyQuery } from "@apollo/client";

import ViewCustomizationDropdown from "components/ViewCustomization/Dropdown";
import Filters from "components/Filters";
import useURLParams from "hooks/useURLParams";
import {
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import { searchModuleSuggestionsDictionary } from "constants/module";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersPanel from "components/Filters/FiltersPanel";
import SortDropdown from "components/Filters/SortDropdown";
import Box from "ds/components/Box";
import FiltersSplit from "components/Filters/Split";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import Toggle from "ds/components/Toggle";

import { SEARCH_MODULES_SUGGESTIONS } from "../../gql";
import { initialSortDirection, initialSortOption } from "../../constants";
import { POLL_INTERVAL, FILTERS_ORDER_SETTINGS_KEY } from "../../constants";
import { getFilterKey } from "../../helpers";

type ModuleFiltersLayoutProps = {
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  hasItems: boolean;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
};

const ModuleFiltersLayout = ({
  allSelected,
  onSelectAll,
  onResetAll,
  predicates,
  children,
  hasItems,
  currentSavedView,
  setCurrentSavedView,
}: ModuleFiltersLayoutProps) => {
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [loadSearchModulesSuggestions, { data: filteringData, loading, refetch }] = useLazyQuery<{
    searchModulesSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_MODULES_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(filteringData?.searchModulesSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            // TODO: distinguish labels from keys
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, modulesFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        // TODO: handle this on the backend side
        .filter((field) => field.filterable && field.name !== "provider")
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = getFilterKey(key);
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll();
    } else {
      onResetAll();
    }
  };

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadSearchModulesSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;

    refetch({
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={modulesFiltersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      filtersDictionary={searchModuleSuggestionsDictionary}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="modules"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageModule.ModulesList} />

        <FiltersContentWrapper>
          {hasItems && (
            <FiltersPanel>
              <Box direction="row">
                <Toggle
                  variant="checkbox"
                  isSelected={allSelected}
                  onChange={handleSelectAllToggle}
                  aria-label={allSelected ? "Unselect all" : "Select all"}
                >
                  {allSelected ? "Unselect all" : "Select all"}
                </Toggle>
              </Box>

              <Box align="center" gap="medium">
                <SortDropdown />
                <ViewCustomizationDropdown analyticsPage={AnalyticsPageModule.ModulesList} />
              </Box>
            </FiltersPanel>
          )}
          {children}
        </FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(ModuleFiltersLayout);
