import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ToastActionsProps = {
  children: ReactNode;
};

const ToastActions = ({ children }: ToastActionsProps) => {
  return <Box className={styles.actions}>{children}</Box>;
};

ToastActions.displayName = "DS.Toast.Actions";

export default ToastActions;
