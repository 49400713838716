import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItem from "components/ListEntitiesItem";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import GpgKeyListItemDropdown from "../GpgKeyListItemDropdown";
import { GpgKeyListItemProps } from "./types";
import { COLUMN_ORDER } from "../constants";

function GpgKeyListItem({ item }: GpgKeyListItemProps) {
  const isActive = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      grid
      gridTemplate={COLUMN_ORDER}
      gap="0 large"
      key={item.id}
      isActive={isActive}
      role="row"
    >
      <Box role="rowheader" direction="column" justify="center" __deprecatedGap="0.2rem">
        <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} variant="p-body2" tag="p">
              {item.name}
            </Typography>
          )}
        </TextEllipsis>

        {item.description && (
          <ListEntitiesItemDescription id={item.id} description={item.description} />
        )}
      </Box>

      <Box role="cell" align="center">
        <Typography tag="p" variant="p-body2">
          {item.id}
        </Typography>
      </Box>

      <Box role="cell" align="center">
        <TextEllipsis tooltip={item.revokedBy || item.createdBy} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="p" variant="p-body2">
              {item.revokedBy || item.createdBy}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box role="cell" align="center">
        <Typography tag="p" variant="p-body2">
          <Timestamp timestamp={item.revokedAt || item.createdAt} />
        </Typography>
      </Box>

      <Box role="cell" align="center">
        <GpgKeyListItemDropdown item={item} />
      </Box>
    </ListEntitiesItem>
  );
}

export default GpgKeyListItem;
