import { useMutation } from "@apollo/client";
import { create, show, useModal } from "@ebay/nice-modal-react";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { ChevronDown } from "components/icons/generated";
import Markdown from "components/markdown/Markdown";
import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";
import ConfirmationModal from "ds/components/ConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import { ACCEPT_AI_TERMS } from "./gql";
import styles from "./styles.module.css";

type AcceptToSModalProps = {
  version: number;
  content: string;
};

const AcceptToSModal = create(function AcceptToSModal({ version, content }: AcceptToSModalProps) {
  const modal = useModal();
  const { isDarkMode } = useTypedContext(ThemeContext);
  const ref = useRef<HTMLDivElement>(null);
  const [showScrollForMore, setShowScrollForMore] = useState(true);

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [acceptAiTerms, { loading }] = useMutation(ACCEPT_AI_TERMS, {
    refetchQueries: ["GetAISettings"],
    awaitRefetchQueries: true,
    variables: { version },
    onError,
  });

  const handleAcceptTermsOfService = () => {
    acceptAiTerms()
      .then(() => {
        modal.resolve(true);
        modal.hide();
        reportSuccess({ message: "Terms of Service accepted" });
      })
      .catch(onError);
  };

  const onClose = () => {
    modal.resolve(false);
    modal.hide();
  };

  useEffect(() => {
    if (!modal.visible || !ref.current) return;
    const el = ref.current;

    const handleScroll = () => {
      if (el.scrollTop === 0) {
        setShowScrollForMore(true);
      } else {
        setShowScrollForMore(false);
      }
    };

    el.addEventListener("scroll", handleScroll);

    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, [modal.visible]);

  const handleScrollToBottom = () =>
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" });

  return (
    <ConfirmationModal
      title="Terms of use of AI services"
      size="x-large"
      confirmationButtonLabel="Accept"
      confirmationButtonVariant="primary"
      onConfirm={handleAcceptTermsOfService}
      onClose={onClose}
      isLoading={loading}
      isDismissable={!loading}
      contentWrapperClassName={styles.noPadding}
    >
      <Box ref={ref} scrollable className={styles.wrapper}>
        <Markdown className={styles.legalText} markup={content} />
        <Box
          className={cx(styles.scrollForMore, {
            [styles.show]: showScrollForMore,
            [styles.darkMode]: isDarkMode,
          })}
          justify="center"
          align="center"
          fullWidth
        >
          <ButtonNew variant="secondary" onPress={handleScrollToBottom} startIcon={ChevronDown}>
            Scroll to read more
          </ButtonNew>
        </Box>
      </Box>
    </ConfirmationModal>
  );
});

export const showAcceptToSModal = (props: AcceptToSModalProps) => show(AcceptToSModal, props);
