import { useId } from "react";

import { useToggle } from "hooks/useToggle";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";

import { content } from "./content";
import LaunchPadVideosItem from "./Item";

const LaunchPadVideos = () => {
  const [isCollapsed, handleToggle] = useToggle(false);

  const titleId = useId();

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader padding="x-large" align="center" gap="medium">
        <CollapsiblePanelToggleTrigger ariaLabelledby={titleId} ariaLevel={2} />
        <CollapsiblePanelTitle id={titleId} variant="p-t5">
          Watch videos
        </CollapsiblePanelTitle>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent
        padding="0 x-large x-large x-large"
        gap="large"
        grid
        gridTemplate="1fr 1fr 1fr 1fr"
      >
        {content.map(({ title, videoId, backgroundClass }) => (
          <LaunchPadVideosItem
            key={videoId}
            title={title}
            videoId={videoId}
            backgroundClass={backgroundClass}
          />
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadVideos;
