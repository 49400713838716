import { ReactNode, useRef } from "react";
import { ComboBox as AriaComboBox, ListBox, Popover } from "react-aria-components";

import { AnalyticsCommonProps } from "hooks/useAnalytics";
import useEnsureId from "hooks/useEnsureId";

import FormField from "../Form/Field";
import ComboBoxEmptyCollection from "./EmptyCollection";
import ComboBoxInput, { ComboBoxInputProps } from "./Input";
import styles from "./styles.module.css";

type ComboBoxProps<Collection extends object, Key extends string> = {
  /**
   * label is optional, but if it's not provided you need to connect the label via ariaLabelledBy attribute
   */
  id?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: string;
  value?: string | null;
  items: Collection[];
  onChange: (value: Key | null) => void;
  inputValue?: string;
  renderInput?: (input: ComboBoxInputProps) => ReactNode;
  onInputChange?: (value: string) => void;
  children: (Collection: Collection) => JSX.Element;
  tooltipInfo?: ReactNode;
  tooltipAnalyticsPage?: AnalyticsCommonProps["analyticsPage"];
  tooltipAnalyticsTitle?: AnalyticsCommonProps["analyticsTitle"];
  tooltipAnalyticsProps?: AnalyticsCommonProps["analyticsProps"];
  isDisabled?: boolean;
  isLoading?: boolean;
};

const ComboBox = <Collection extends object, Key extends string>({
  id: propsId,
  children,
  label,
  helperText,
  placeholder = "Type in or select from the list",
  error,
  value,
  renderInput = (props) => <ComboBoxInput {...props} />,
  onChange,
  inputValue,
  onInputChange,
  items,
  tooltipInfo,
  tooltipAnalyticsPage,
  tooltipAnalyticsTitle,
  tooltipAnalyticsProps,
  isDisabled,
  isLoading,
}: ComboBoxProps<Collection, Key>) => {
  const id = useEnsureId(propsId);
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <AriaComboBox
      className={styles.comboBox}
      menuTrigger="focus"
      selectedKey={value}
      onSelectionChange={(key) => onChange(key !== null ? (String(key) as Key) : null)}
      inputValue={inputValue}
      onInputChange={onInputChange}
      isInvalid={!!error}
      isDisabled={isDisabled}
      allowsEmptyCollection
    >
      <FormField
        id={id}
        label={label}
        helperText={helperText}
        error={error}
        tooltipInfoVariant="modal"
        tooltipInfo={tooltipInfo}
        tooltipAnalyticsPage={tooltipAnalyticsPage}
        tooltipAnalyticsTitle={tooltipAnalyticsTitle}
        tooltipAnalyticsProps={tooltipAnalyticsProps}
        noMargin
      >
        {renderInput({
          id,
          placeholder,
          isError: !!error,
          isDisabled,
          isLoading,
        })}
      </FormField>
      <Popover className={styles.popover} maxHeight={300} scrollRef={scrollRef}>
        <div
          /** react-aria calculates the flip position including the scroll container. It doesn't make sense when we use the maxHeight since that's the maximum height we want it to flip. This way we prevent aria from flipping the popover prematurely. */
          ref={scrollRef}
        >
          <ListBox<Collection>
            className={styles.listBox}
            items={items}
            renderEmptyState={() => <ComboBoxEmptyCollection isLoading={isLoading} />}
          >
            {children}
          </ListBox>
        </div>
      </Popover>
    </AriaComboBox>
  );
};

ComboBox.displayName = "DS.ComboBox";

export default ComboBox;
