import { NetworkStatus, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Datum } from "ds/components/Charts/PieChart/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import MissingDataBanner from "components/MissingDataBanner";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import DashboardResourcesChart from "../../ResourcesChart";
import { vendors } from "./constants";
import { GetResourcesCountByDrift } from "./types";
import { GET_RESOURCES_COUNT_BY_DRIFT } from "./gql";
import DashboardResourcesChartCentralInfo from "../../ResourcesChart/CentralInfo";
import DashboardWidgetsResourcesHealthDriftDisabledCentralInfo from "./DriftDisabledCentralInfo";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

const DEFAULT_EMPTY_VALUES = [0, 0];

const DashboardWidgetsResourcesHealth = () => {
  const { reportError } = useTypedContext(FlashContext);
  const pollInterval = usePollInterval();
  const { data, loading, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetResourcesCountByDrift>(GET_RESOURCES_COUNT_BY_DRIFT, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const navigate = useNavigate();

  const isLoading = loading && !data?.metrics?.resourcesCountByDrift;

  const [driftedValue, healthyValue] = showErrorBanner
    ? DEFAULT_EMPTY_VALUES
    : data?.metrics?.resourcesCountByDrift?.reduce((acc, next) => {
        if (next.labels[0] === "drifted") {
          return [next.value, acc[1]];
        }

        if (next.labels[0] === "healthy") {
          return [acc[0], next.value];
        }

        return acc;
      }, DEFAULT_EMPTY_VALUES) || DEFAULT_EMPTY_VALUES;

  const pieData = useMemo(() => {
    if (showErrorBanner) {
      return [];
    }

    return [
      {
        id: "healthy",
        label: "Healthy resources",
        value: healthyValue,
        color: vendors["healthy"].color,
        activeColor: vendors["healthy"].activeColor,
        inactiveColor: vendors["healthy"].inactiveColor,
      },
      {
        id: "drifted",
        label: "Drifted resources",
        value: driftedValue,
        color: vendors["drifted"].color,
        activeColor: vendors["drifted"].activeColor,
        inactiveColor: vendors["drifted"].inactiveColor,
      },
    ];
  }, [driftedValue, healthyValue, showErrorBanner]);

  const hasNoStacks = !data?.metrics?.hasStacks;
  const hasNoResources = !driftedValue && !healthyValue;
  const isDriftDetectionDisabled = !data?.metrics?.driftDetectionCoverage?.[0]?.value;

  const navigateToResource = useCallback(
    (data: Datum) => {
      const driftedValue = data.id === "drifted" ? "true" : "false";
      trackSegmentAnalyticsEvent("Resource Health Widget - Redirect Clicked", {
        drifted: data.id === "drifted",
      });
      try {
        navigate(
          `/resources/iac-management?filterValues=${btoa(
            encodeURIComponent(JSON.stringify([[{ value: driftedValue, label: driftedValue }]]))
          )}&filterFields=${btoa(JSON.stringify(["driftedBoolean"]))}`
        );
      } catch {
        reportError({ message: "Seomething went wrong and we couldn't navigate you to resources" });
      }
    },
    [navigate, trackSegmentAnalyticsEvent, reportError]
  );

  let errorBanner;

  if (showErrorBanner) {
    errorBanner = (
      <MissingDataBanner
        text="Couldn’t load resources health data. Please try to refresh or come back later."
        refreshHandler={refetch}
        refreshLoading={loading}
        fullWidth
      />
    );
  }

  return (
    <DashboardResourcesChart
      logos={vendors}
      data={pieData}
      align="center"
      renderCentralInfo={(data, hasSmallContainer) => (
        <DashboardResourcesChartCentralInfo
          count={data ? data.value : driftedValue + healthyValue}
          title={
            isDriftDetectionDisabled && !hasNoResources && !hasNoStacks ? (
              <DashboardWidgetsResourcesHealthDriftDisabledCentralInfo
                hasSmallContainer={hasSmallContainer}
              />
            ) : (
              data?.label.toUpperCase()
            )
          }
        />
      )}
      hasDriftedDisabled={isDriftDetectionDisabled}
      hasNoStacks={hasNoStacks}
      hasNoResources={hasNoResources}
      isLoading={isLoading}
      navigateToResource={navigateToResource}
      widget={Widget.ResourcesHealth}
      errorBanner={errorBanner}
    />
  );
};

export default DashboardWidgetsResourcesHealth;
