import cx from "classnames";
import { datadogRum } from "@datadog/browser-rum";
import { writeStorage } from "@rehooks/local-storage";

import { FiltersContext } from "components/Filters";
import { AccountContext } from "views/AccountWrapper";
import { User } from "components/icons/generated";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import { useDefaultView } from "components/Filters/useDefaultView";
import { DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME } from "components/Filters/constants";
import TextEllipsis from "ds/components/TextEllipsis";
import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { FiltersSavedViewsListItemProps } from "./types";
import styles from "./styles.module.css";
import useChangeViewPublicVisibility from "./useChangeViewPublicVisibility";
import { showDeleteConfirmation } from "../DeleteConfirmation";

const FiltersSavedViewsListItem = ({ applyView, item }: FiltersSavedViewsListItemProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { currentView, defaultEmptyFilterView, filterType, filtersOrder } =
    useTypedContext(FiltersContext);
  const isViewCreator = item.createdBy === viewer.id;

  const defaultView = useDefaultView(filtersOrder, filterType, defaultEmptyFilterView);

  const changeViewPublicVisibility = useChangeViewPublicVisibility(item);

  const handleApply = () => {
    datadogRum.addAction("[Saved Views] Click View Item");
    applyView(item.data, item.name, item.id, item.isPublic, isViewCreator);
  };

  const resetView = () => {
    if (item.id === currentView?.id) {
      applyView(
        defaultView.rawData,
        defaultView.data?.name || DEFAULT_VIEW_NAME,
        defaultView.data?.id || DEFAULT_VIEW_ID,
        false,
        true
      );
    }
  };

  const clickDeleteHandler = () => {
    datadogRum.addAction("[Saved Views] Click Delete");
    showDeleteConfirmation({
      id: item.id,
      name: item.name,
    }).then(() => {
      resetView();
    });
  };

  const handleSetDefaultView = () => {
    datadogRum.addAction("[Saved Views] Click Use as your default view");

    writeStorage(getDefaultViewStorageKey(filterType), item.id);

    if (currentView?.id === DEFAULT_VIEW_ID) {
      applyView(item.data, item.name, item.id, item.isPublic, isViewCreator);
    }
  };

  return (
    <li className={styles.listItem}>
      <Box
        direction="column"
        onClick={handleApply}
        className={cx(styles.listButton, {
          [styles.active]: currentView && item.id === currentView.id,
        })}
        role="button"
        tabIndex={0}
      >
        <Box align="center" gap="small">
          <TextEllipsis tooltip={item.name || "Filters"} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {item.name || "Filters"}
              </Typography>
            )}
          </TextEllipsis>
          <DropdownMenuEllipsis tooltip="Saved view actions" className={styles.dropdown}>
            <CopyFieldDropdownMenuItem title="Copy ID" value={item.id} />
            <DropdownMenuItem onAction={handleSetDefaultView}>
              Use as your default view
            </DropdownMenuItem>

            {isViewCreator && viewer.admin && (
              <DropdownMenuItem onAction={changeViewPublicVisibility}>
                {item.isPublic ? "Make it private" : "Share within the account"}
              </DropdownMenuItem>
            )}
            {(isViewCreator || viewer.admin) && (
              <DropdownMenuItem onAction={clickDeleteHandler}>Delete view</DropdownMenuItem>
            )}
          </DropdownMenuEllipsis>
        </Box>

        <MetaInfoList className={styles.metaList}>
          <MetaInfoListItem className={styles.metaListItem}>
            {item.isPublic ? "Shared" : "Private"}
          </MetaInfoListItem>

          {!item.isPublic && (
            <MetaInfoListItem icon={User} className={cx(styles.metaListItem, styles.user)}>
              {item.createdBy}
            </MetaInfoListItem>
          )}
        </MetaInfoList>
      </Box>
    </li>
  );
};

export default FiltersSavedViewsListItem;
