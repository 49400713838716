import useLocalStorage, { deleteFromStorage } from "@rehooks/local-storage";
import { useQuery } from "@apollo/client";

import { SavedFilter } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { SAVED_VIEW } from "./gql";
import { DEFAULT_VIEW_ID } from "./constants";
import { DefaultView, FilterItemSettings, SavedFilterView } from "./types";
import { getFiltersFromSavedData } from "./SavedViews/helpers";
import { getDefaultViewStorageKey } from "./helpers";

export const useDefaultView = (
  filtersOrder: FilterItemSettings[],
  filtersType: string,
  defaultEmptyFilterView: SavedFilterView
): DefaultView => {
  const { onError } = useTypedContext(FlashContext);

  const { viewer } = useTypedContext(AccountContext);

  const defaultViewIdStorageKey = getDefaultViewStorageKey(filtersType);

  const [defaultViewId] = useLocalStorage<string>(defaultViewIdStorageKey);

  const { loading: defaultFilterLoading, data: defaultFilterData } = useQuery<{
    savedFilter: SavedFilter;
  }>(SAVED_VIEW, {
    onError,
    variables: {
      id: defaultViewId,
    },
    skip: !defaultViewId || defaultViewId === DEFAULT_VIEW_ID,
    // APOLLO CLIENT UPDATE notes
  });

  const removeFromStorage = () => {
    deleteFromStorage(defaultViewIdStorageKey);
  };

  if (defaultFilterLoading || !filtersOrder.length) {
    return {
      loading: defaultFilterLoading,
      data: undefined,
      rawData: "",
      removeFromStorage,
    };
  }

  if (!defaultViewId || !defaultFilterData || !defaultFilterData.savedFilter) {
    const defaultValues = {
      loading: defaultFilterLoading,
      data: defaultEmptyFilterView,
      removeFromStorage,
    };

    try {
      const rawData = JSON.stringify({
        filters: [],
        order: defaultEmptyFilterView.order,
        text: "",
        sort: {},
      });

      return {
        ...defaultValues,
        rawData,
      };
    } catch {
      return {
        ...defaultValues,
        rawData: "",
      };
    }
  }

  return {
    loading: defaultFilterLoading,
    data: {
      ...getFiltersFromSavedData(defaultFilterData.savedFilter.data, defaultEmptyFilterView),
      id: defaultFilterData.savedFilter.id,
      name: defaultFilterData.savedFilter.name,
      isPublic: defaultFilterData.savedFilter.isPublic,
      isMine: defaultFilterData.savedFilter.createdBy === viewer.id,
    },
    rawData: defaultFilterData.savedFilter.data,
    removeFromStorage,
  };
};
