import { useContext } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import styles from "./styles.module.css";
import Typography from "../Typography";
import TextEllipsis from "../TextEllipsis";

type ComboBoxHighlightTextProps = {
  text: string;
};
const ComboBoxHighlightText = ({ text }: ComboBoxHighlightTextProps) => {
  const state = useContext(ComboBoxStateContext);
  const highlight = state?.inputValue;
  if (!highlight.trim()) {
    return (
      <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography tag="span" variant="p-body3" color="primary" {...props}>
            {text}
          </Typography>
        )}
      </TextEllipsis>
    );
  }

  const index = text.toLowerCase().indexOf(highlight.toLowerCase());
  if (index === -1) {
    return (
      <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography tag="span" variant="p-body3" color="primary" {...props}>
            {text}
          </Typography>
        )}
      </TextEllipsis>
    );
  }

  const before = text.slice(0, index);
  const match = text.slice(index, index + highlight.length);
  const after = text.slice(index + highlight.length);

  return (
    <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm">
      {(props) => (
        <Typography tag="span" variant="p-body3" color="primary" {...props} aria-label={text}>
          {before}
          <Typography
            variant="p-t7"
            color="primary"
            tag="mark"
            className={styles.mark}
            aria-label={match}
          >
            {match}
          </Typography>
          {after}
        </Typography>
      )}
    </TextEllipsis>
  );
};

ComboBoxHighlightText.displayName = "DS.ComboBox.HighlightText";

export default ComboBoxHighlightText;
