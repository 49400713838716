import { useCallback } from "react";
import { useModal } from "@ebay/nice-modal-react";

import { showFullDescription } from ".";

/**
 * Use inside of a drawer to show a full description with a back arrow.
 * This hook will automatically reopen current drawer with the same arguments when the back arrow is clicked.
 */
export function useShowFullDescriptionWithBackArrow(
  description: string | null,
  id?: string,
  onClose?: () => void
) {
  const drawer = useModal();

  const handleShowFullDescription = useCallback(
    (descriptionArg?: string) => {
      showFullDescription({
        showBackButton: true,
        description: description || descriptionArg,
        onClose,
        id,
      }).then(() => {
        drawer.show(drawer.args);
      });
    },
    [drawer, description, onClose, id]
  );

  return handleShowFullDescription;
}
