import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import TileTitle from "ds/components/Tile/Title";
import Tile from "ds/components/Tile";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Toggle from "ds/components/Toggle";

import {
  GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS,
  SET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS,
} from "./gql";

const NonRootApiKeysToggle = () => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading: isLoading } = useQuery(GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS, {});

  const [mutate, { loading: isSaving }] = useMutation(SET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS, {
    onError,
  });

  const toggle = useCallback(
    (isSelected: boolean) =>
      mutate({
        variables: { enable: isSelected },
        refetchQueries: [{ query: GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS }],
        awaitRefetchQueries: true,
      }),
    [mutate]
  );

  return (
    <Tile
      title={<TileTitle variant="p-t5">API keys management</TileTitle>}
      description="Allow API keys management to Space level admins."
      indicator={
        <Toggle
          variant="switch"
          isSelected={data?.apiKeysManagedByNonRootAdmins || false}
          onChange={toggle}
          isDisabled={isLoading || isSaving}
          aria-label="API keys management"
        />
      }
    />
  );
};

export default NonRootApiKeysToggle;
