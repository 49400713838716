import Box from "ds/components/Box";
import StackStateBadge from "components/StackStateBadge";

import { CellComponentProps } from "./types";

type StackListItemStateCellProps = CellComponentProps;

const StackListItemStateCell = ({ stack }: StackListItemStateCellProps) => {
  return (
    <Box align="center">
      <StackStateBadge state={stack.state} />
    </Box>
  );
};

export default StackListItemStateCell;
