import { ReactNode, useEffect } from "react";
import { announce as reactAriaAnnounce } from "@react-aria/live-announcer";
import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

export type EmptyStateProps = Omit<
  BoxProps,
  "children" | "direction" | "justify" | "align" | "grow"
> & {
  title?: string;
  icon?: IconComponent;
  children?: ReactNode;
  caption?: ReactNode;
  fullHeight?: boolean;
  ariaInTable?: boolean;
  announce?: boolean;
};

const EmptyState = ({
  children,
  caption,
  title,
  icon: Icon,
  className,
  fullHeight,
  ariaInTable,
  announce,
  ...rest
}: EmptyStateProps) => {
  useEffect(() => {
    if (!announce) {
      return;
    }

    const liveMessage = `${title ?? ""} ${typeof caption === "string" ? caption : ""}`.trim();

    if (!liveMessage) {
      return;
    }

    reactAriaAnnounce(liveMessage, "polite");
  }, [announce, title, caption]);

  return (
    <Box
      direction="column"
      justify="center"
      align="center"
      grow="1"
      className={cx(styles.wrapper, fullHeight && styles.fullHeight, className)}
      role={ariaInTable ? "row" : undefined}
      {...rest}
    >
      {Icon && <Icon className={styles.icon} aria-hidden />}

      <Box
        gap="small"
        margin={children ? "0 0 large" : undefined}
        direction="column"
        align="center"
        role={ariaInTable ? "cell" : undefined}
      >
        {title && (
          <Typography tag="p" variant="p-t6" className={styles.title}>
            {title}
          </Typography>
        )}
        {caption && (
          <Typography className={styles.caption} tag="p" variant="p-body3" align="center">
            {caption}
          </Typography>
        )}
      </Box>

      {children}
    </Box>
  );
};

EmptyState.displayName = "DS.EmptyState";
export default EmptyState;
