import cx from "classnames";
import { Cell } from "react-aria-components";
import { useShallow } from "zustand/react/shallow";
import { memo } from "react";

import Toggle from "ds/components/Toggle";

import useSelectionStore from "./useSelectionStore";
import styles from "./styles.module.css";

type TableRowCheckboxProps = {
  id: string;
  name: string;
};

const TableRowCheckbox = ({ id, name }: TableRowCheckboxProps) => {
  const [selected, toggle, isSelectionOn] = useSelectionStore(
    useShallow(({ selected, toggle }) => [selected.has(id), toggle, selected.size > 0])
  );

  return (
    <Cell
      className={cx(
        styles.tableCell,
        styles.checkboxCell,
        isSelectionOn && styles.checkboxCellSticky
      )}
    >
      <Toggle
        aria-label={`Select ${name}`}
        slot={null}
        variant="checkbox"
        isSelected={selected}
        onChange={() => toggle(id)}
      />
    </Cell>
  );
};

export default memo(TableRowCheckbox);
