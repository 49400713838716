import { PressEvent } from "react-aria";

import { Cross } from "components/icons/generated";
import ButtonNew from "ds/components/Button/New";

import styles from "./styles.module.css";

type FilterSectionContentActionsProps = {
  selectedSize: number;
  handleResetAll: (e: PressEvent) => void;
};

const FilterSectionContentActions = ({
  selectedSize,
  handleResetAll,
}: FilterSectionContentActionsProps) => {
  return (
    <span className={styles.listActions}>
      {/* // TODO: uncomment when backend optimize "select all" behavior */}
      {/* {!allSelected && (
          <LinkNew className={styles.action} onPress={handleSelectAll}>
            Select all
          </LinkNew>
        )}{" "} */}
      {/* {!allSelected && selectedValues.size > 0 && "or"}{" "} */}
      {selectedSize > 0 && (
        <ButtonNew startIcon={Cross} variant="ghost" size="small" onPress={handleResetAll}>
          Clear all
        </ButtonNew>
      )}
    </span>
  );
};

export default FilterSectionContentActions;
