/* eslint react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { getKeyValue } from "../helpers";

// getRectPosition calculates the [x,y] offset of a rectangle in a grid.
export const getRectPosition = (i, rectWidth, rectHeight, lineWidth) => {
  const lineRects = Math.floor(lineWidth / rectWidth);
  const line = Math.floor(i / lineRects);
  const x = (i % lineRects) * rectWidth;
  const y = line * rectHeight;
  return [x, y];
};

// groupBy creates a mapping {x -> [element | element.key == x]}.
export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    const keyValue = getKeyValue(key, x);

    if ((key === "stack.id" || key === "module.id") && keyValue === "none") return rv;

    (rv[keyValue] = rv[keyValue] || []).push(x);
    return rv;
  }, {});
};

// getIdText creates an html id tag value based on the entity's ID.
export const getIdText = function (s) {
  return `entity-${s.replace(/[^a-zA-Z0-9]+/g, "-")}`;
};

// https://gist.github.com/shinout/1232505
/**
 * @deprecated use new topologicalSort instead
 */
export const legacyTopologicalSort = (edges) => {
  const nodes = {}, // hash: stringified id of the node => { id: id, afters: list of ids }
    sorted = [], // sorted list of IDs ( returned value )
    visited = {}; // hash: id of already visited node => true

  const Node = function (id) {
    this.id = id;
    this.afters = [];
  };

  // 1. build data structures
  edges.forEach(function (v) {
    const from = v[0],
      to = v[1];
    if (!nodes[from]) nodes[from] = new Node(from);
    if (!nodes[to]) nodes[to] = new Node(to);
    nodes[from].afters.push(to);
  });

  // 2. topological sort
  Object.keys(nodes).forEach(function visit(idstr, ancestors) {
    const node = nodes[idstr],
      id = node.id;

    // if already exists, do nothing
    if (visited[idstr]) return;

    if (!Array.isArray(ancestors)) ancestors = [];

    ancestors.push(id);

    visited[idstr] = true;

    node.afters.forEach(function (afterID) {
      if (ancestors.indexOf(afterID) >= 0)
        // if already in ancestors, a closed chain exists.
        throw new Error("closed chain : " + afterID + " is in " + id);

      visit(
        afterID.toString(),
        ancestors.map(function (v) {
          return v;
        })
      );
    });

    sorted.unshift(id);
  });

  return sorted;
};

export const bidirectionalDfs = (edgesList, start, visit) => {
  const edges = {};
  const nodes = {};
  for (const edge of edgesList) {
    if (edges[edge[0]]) {
      edges[edge[0]].push(edge[1]);
    } else {
      edges[edge[0]] = [edge[1]];
    }
    if (edges[edge[1]]) {
      edges[edge[1]].push(edge[0]);
    } else {
      edges[edge[1]] = [edge[0]];
    }
    nodes[edge[0]] = { visited: false };
    nodes[edge[1]] = { visited: false };
  }

  if (Object.keys(nodes).length === 0) return;

  const listToExplore = [start];
  if (nodes[start]) {
    nodes[start].visited = true;
  }

  while (listToExplore.length) {
    const nodeId = listToExplore.pop();
    visit(nodeId);

    if (!edges[nodeId]) return;
    edges[nodeId].forEach((childId) => {
      if (!nodes[childId].visited) listToExplore.push(childId);
      nodes[childId].visited = true;
    });
  }
};

export const getEntitySidebarDetails = (entity) => {
  switch (entity.type) {
    case "commit":
      return entity.commit;
    case "user":
      return { user: entity.name };
    case "run":
      return entity.run;
    default:
      return entity.run;
  }
};
