import { SavedFilter } from "types/generated";
import { ActiveFiltersMap, SavedFilterView } from "components/Filters/types";

import { SortOptions } from "./types";

export const getFiltersFromSavedData = (
  filter: SavedFilter["data"],
  defaultEmptyFilterView: SavedFilterView
) => {
  try {
    const parsedFilter = JSON.parse(filter);
    const parsedFilterValue = JSON.parse(parsedFilter.value);

    return {
      sortOption: parsedFilterValue.sort.option,
      sortDirection: parsedFilterValue.sort.direction,
      filters: new Map(parsedFilterValue.filters) as ActiveFiltersMap,
      search: parsedFilterValue.text && decodeURI(parsedFilterValue.text),
      order: parsedFilterValue.order,
    };
  } catch {
    return defaultEmptyFilterView;
  }
};

export const getSavedViewData = (activeFilters: ActiveFiltersMap, sortOptions: SortOptions) => {
  try {
    return {
      data: JSON.stringify({
        key: "activeFilters",
        value: JSON.stringify({
          filters: [...activeFilters],
          ...sortOptions,
        }),
      }),
      error: null,
    };
  } catch {
    return { error: new Error("View couldn't be saved"), data: null };
  }
};
