import cx from "classnames";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { useReducedMotion } from "hooks";
import FormToggleField from "ds/components/Form/ToggleField";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import styles from "./styles.module.css";

const AccessibilitySettings = () => {
  const [reducedMotion, { configValue, setReducedMotion, useSystemValue }] = useReducedMotion();
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Personal Settings · Accessibility · ${accountName}`);

  const toggleSystemMode = (isSelected: boolean) => {
    if (isSelected) {
      setReducedMotion("system");
    } else {
      setReducedMotion(configValue);
    }
  };

  const toggleConfigSetting = (isSelected: boolean) => {
    setReducedMotion(isSelected);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Accessibility</ViewHeaderTitle>
      </ViewHeader>

      <Box
        direction="column"
        grow="1"
        className={styles.wrapper}
        padding="x-large"
        limitWidth="medium"
      >
        <Box direction="column" gap="x-large" fullWidth>
          <Typography variant="p-t5" tag="h3" margin="0 0 small 0">
            Prefer reduced motion
          </Typography>

          <Typography variant="p-body2" tag="p" color="secondary">
            When enabled, the animations will minimize the amount of non-essential motion.
          </Typography>

          <FormToggleField
            variant="switch"
            onChange={toggleSystemMode}
            checked={useSystemValue}
            title="Sync with system"
            description="Follow system preferences for reduced motion."
          />
          {!useSystemValue && (
            <FormToggleField
              variant="switch"
              onChange={toggleConfigSetting}
              checked={configValue}
              title="Enable reduced motion"
              description="If checked, the user interface animations (such as popup transitions) will be disabled."
            />
          )}
          <div className={styles.reducedMotionExample}>
            <div className={cx(styles.reducedMotionObject, !reducedMotion && styles.animate)} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AccessibilitySettings;
