import { useMemo } from "react";

import PageInfo from "components/PageWrapper/Info";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import SearchInput from "components/SearchInput";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import RequiredInputs from "./components/RequiredInputs";
import { OptionalInput, RequiredInput } from "./types";
import OptionalInputs from "./components/OptionalInputs";
import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import { FILTERS_ORDER_SETTINGS_KEY_VERSION_INPUTS } from "./constants";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionInputsProps = {
  inputs: Array<{
    name: string;
    description: string;
    required: boolean;
    type: string;
    default: string;
  }>;
};

const ModuleVersionInputs = ({ inputs }: ModuleVersionInputsProps) => {
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const filteredInputs = useMemo(() => {
    const results = inputs.map((input) => ({
      ...input,
      id: input.name,
    }));

    if (searchQuery) {
      return fuzzySearch(results, searchQuery.trim(), {
        keys: ["description", "name"],
        scoreThreshold: -1000,
      });
    }

    return results;
  }, [inputs, searchQuery]);

  const [requiredInputs, optionalInputs] = useMemo(
    () =>
      filteredInputs.reduce<[Array<RequiredInput>, Array<OptionalInput>]>(
        (acc, input) => {
          if (input.required) {
            acc[0].push({
              id: input.id,
              description: input.description,
              type: input.type,
            });
          } else {
            acc[1].push({
              id: input.id,
              description: input.description,
              type: input.type,
              default: input.default,
            });
          }
          return acc;
        },
        [[], []]
      ),
    [filteredInputs]
  );

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Inputs"
            tooltip="Required and optional inputs, their type, and default values."
          />
        }
      >
        <SearchInput
          placeholder="Search by name and description..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_VERSION_INPUTS}
        />
      </PageInfo>
      <ModuleVersionScrollableWrapper gap="x-large">
        {requiredInputs.length === 0 && optionalInputs.length === 0 && (
          <EmptyState
            fullHeight
            icon={EmptystateSearchNoResultsColored}
            caption={
              searchQuery
                ? `No inputs match the search query`
                : `This module has no inputs variables`
            }
            announce={!!searchQuery}
          />
        )}
        {requiredInputs.length > 0 && <RequiredInputs inputs={requiredInputs} />}
        {optionalInputs.length > 0 && <OptionalInputs inputs={optionalInputs} />}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionInputs;
