import { ReactNode } from "react";
import { FocusScope } from "react-aria";

import useTitle from "hooks/useTitle";

import FullScreenModalWrapper from "./Wrapper";
import FullScreenModalHeader from "./Header";

type FullScreenModalProps = {
  title: string;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  id?: string;
};

const FullScreenModal = ({ title, onClose, children, className }: FullScreenModalProps) => {
  useTitle(title);

  return (
    <FocusScope contain>
      <FullScreenModalWrapper tabIndex={-1} className={className}>
        <FullScreenModalHeader onClose={onClose}>{title}</FullScreenModalHeader>
        {children}
      </FullScreenModalWrapper>
    </FocusScope>
  );
};

FullScreenModal.displayName = "DS.FullScreenModal";

export default FullScreenModal;
