import isEqual from "lodash-es/isEqual";
import { FormProvider, useForm } from "react-hook-form";

import TextLink from "components/DocumentationSnippets/TextLink";
import FormFieldSpace from "components/FormFields/Space";
import FormSourceCode from "components/Forms/SourceCode";
import { SourceCodeProjects } from "components/Forms/SourceCode/types";
import Box from "ds/components/Box";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import Typography from "ds/components/Typography";
import { useFormValidations } from "hooks";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";
import { AccountContext } from "views/AccountWrapper";

import { ModuleFormContext } from "../context";
import NewModuleFooter from "../Footer";
import { ModuleCreationWizardStep, ModuleVcsFormFields } from "../types";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";
import { getTooltipAnalyticsProps } from "../utils";
import Documentation from "./Documentation";

const processValuesBeforeSubmit = (values: ModuleVcsFormFields): ModuleVcsFormFields => {
  const projectRoot = values.projectRoot?.trim();

  return {
    ...values,
    projectRoot,
  };
};

const NewModuleVcs = () => {
  const { viewer } = useTypedContext(AccountContext);
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const {
    currentStep,
    updateStepData,
    formData,
    setInternalFormData,
    setAccountOrAuthErrorContent,
  } = useTypedContext(ModuleFormContext);
  const stepData = formData[ModuleCreationWizardStep.Vcs];

  const builderForm = useForm<ModuleVcsFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const { watch, getValues, reset } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const newStepData = watch();
  const spaceData = getValues("space");

  const processStepData = () => {
    const preparedData = processValuesBeforeSubmit(newStepData);
    return updateStepData(currentStep, preparedData);
  };

  const resetFormFields = (type: VcsProvider) => {
    reset({
      repository: "",
      branch: "",
      projectRoot: "",
      repositoryURL: undefined,
      provider: type,
      vcsIntegrationId: undefined,
      space: spaceData,
    });
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Connect to source code
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          {viewer?.admin && (
            <>
              Spacelift supports multiple source code providers. You can connect one on the{" "}
              <TextLink
                analyticsPage={AnalyticsPageModule.ModuleNew}
                analyticsTitle="Source code link click"
                href={"/vcs/integrations"}
              >
                Source Code Integrations
              </TextLink>{" "}
              page.
            </>
          )}
          {!viewer?.admin && (
            <>
              Spacelift supports multiple source code providers. <br />
              Please reach out to one of your friendly account admins to set up one.
            </>
          )}
        </Typography>
        <FormProvider {...builderForm}>
          <Box direction="column" gap="x-large">
            <FormFieldSpace {...getTooltipAnalyticsProps("Details", "Space")} />

            <FormSourceCode
              spaceId={spaceData}
              setInternalFormData={setInternalFormData}
              trackSegmentEvent={trackSegmentEvent}
              provider={newStepData.provider}
              vcsIntegrationId={newStepData.vcsIntegrationId}
              repository={newStepData.repository}
              setAccountOrAuthErrorContent={setAccountOrAuthErrorContent}
              resetFormFields={resetFormFields}
              projectType={SourceCodeProjects.Module}
            />
          </Box>
        </FormProvider>
      </FullScreenModalBody>

      <NewModuleFooter
        isDataChanged={isDataChanged}
        processStepData={processStepData}
        // TODO module-redesign update with proper docs link
        documentationLink={getDocsUrl("/vendors/terraform/module-registry#spacelift-setup")}
        documentationTitle="Integrate source code"
        documentationBody={<Documentation />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewModuleVcs;
