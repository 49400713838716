import { ReactNode } from "react";

import Typography from "ds/components/Typography";

import { FormFieldColor } from "./types";
import { getColors } from "./utils";

type FormFieldLabelProps = {
  label?: string | ReactNode;
  isOptional?: boolean;
  htmlFor?: string; // TODO this should be required
  color?: FormFieldColor;
};

const FormFieldLabel = ({ label, isOptional, htmlFor, color }: FormFieldLabelProps) => {
  const { primaryTextColor, secondaryColor } = getColors(color);

  return (
    <Typography htmlFor={htmlFor} tag="label" variant="p-t7" color={primaryTextColor}>
      {label}
      {isOptional && (
        <>
          {" "}
          <Typography tag="span" variant="p-body3" color={secondaryColor}>
            (Optional)
          </Typography>
        </>
      )}
    </Typography>
  );
};

FormFieldLabel.displayName = "DS.Form.Field.Label";

export default FormFieldLabel;
