import { VcsProvider } from "types/generated";

import {
  LogoAzure,
  LogoAzureColored,
  LogoBitbucket,
  LogoBitbucketColored,
  LogoRawGit,
  LogoGithub,
  LogoGitlab,
  LogoGitlabColored,
  LogoShowcase,
} from "../components/icons/generated";

export const VCS_PROVIDERS_ICONS = {
  [VcsProvider.AzureDevops as string]: LogoAzure,
  [VcsProvider.BitbucketCloud as string]: LogoBitbucket,
  [VcsProvider.BitbucketDatacenter as string]: LogoBitbucket,
  [VcsProvider.Github as string]: LogoGithub,
  [VcsProvider.GithubEnterprise as string]: LogoGithub,
  [VcsProvider.Gitlab as string]: LogoGitlab,
  [VcsProvider.Showcase as string]: LogoShowcase,
  [VcsProvider.Git as string]: LogoRawGit,
};

export const VCS_PROVIDERS_COLORED_ICONS = {
  [VcsProvider.AzureDevops as string]: LogoAzureColored,
  [VcsProvider.BitbucketCloud as string]: LogoBitbucketColored,
  [VcsProvider.BitbucketDatacenter as string]: LogoBitbucketColored,
  [VcsProvider.Github as string]: LogoGithub,
  [VcsProvider.GithubEnterprise as string]: LogoGithub,
  [VcsProvider.Gitlab as string]: LogoGitlabColored,
  [VcsProvider.Showcase as string]: LogoShowcase,
};
