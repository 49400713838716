import Box from "../Box";
import LoadingIndicator from "../LoadingIndicator";
import Typography from "../Typography";

type ComboBoxEmptyCollectionProps = {
  isLoading?: boolean;
};

const ComboBoxEmptyCollection = ({ isLoading }: ComboBoxEmptyCollectionProps) => (
  <Box padding="small">
    {isLoading ? (
      <Box fullWidth justify="center">
        <LoadingIndicator size="large" loading />
      </Box>
    ) : (
      <Typography tag="span" variant="p-body3">
        No options found.
      </Typography>
    )}
  </Box>
);

ComboBoxEmptyCollection.displayName = "DS.ComboBox.EmptyCollection";

export default ComboBoxEmptyCollection;
