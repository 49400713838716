import Button from "ds/components/Button";

import DropdownSelectClearAll from "../SelectClearAll";
import DropdownFooter from ".";

type DropdownFooterSelectionProps = {
  selectedLength: number;
  onResetAll: () => void;
  onSelectAll?: () => void;
  allSelected?: boolean;
  onAdd?: () => void;
};

const DropdownFooterSelection = ({
  selectedLength,
  onResetAll,
  onAdd,
  onSelectAll,
  allSelected,
}: DropdownFooterSelectionProps) => {
  return (
    <DropdownFooter>
      <DropdownSelectClearAll
        allSelected={allSelected}
        selectedLength={selectedLength}
        onResetAll={onResetAll}
        onSelectAll={onSelectAll}
      />
      {onAdd && (
        <Button variant="primary" size="small" onClick={onAdd} disabled={selectedLength === 0}>
          Add
        </Button>
      )}
    </DropdownFooter>
  );
};

DropdownFooterSelection.displayName = "DS.Dropdown.Footer.Selection";

export default DropdownFooterSelection;
