import cx from "classnames";
import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type DropdownFooterProps = {
  className?: string;
  children: ReactNode;
};

const DropdownFooter = ({ className, children }: DropdownFooterProps) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      fullWidth
      className={cx(styles.footer, className)}
    >
      {children}
    </Box>
  );
};

DropdownFooter.displayName = "DS.Dropdown.Footer";

export default DropdownFooter;
