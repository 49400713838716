import { useQuery } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import { datadogRum } from "@datadog/browser-rum";
import { LDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";
import React, { createContext, useEffect } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountData } from "components/form/graphql/types";
import SpaceliftLoader from "components/loading/SpaceliftLoader";
import NotifySessionHasExpired from "components/NotifySessionHasExpired";
import RedirectToLogin from "components/redirect/RedirectToLogin";
import useErrorHandle from "hooks/useErrorHandle";
import useSpaceliftLoginsCookie from "hooks/useSpaceliftLoginsCookie";
import useTypedContext from "hooks/useTypedContext";
import {
  createUserPropertyDataLayer,
  identifyHubspotUser,
  identifySegmentUser,
  identifyUserflowUser,
} from "shared/Analytics";

import { GET_AI_SETTINGS } from "./Account/Settings/AI/gql";
import { flags } from "./flags";
import { GET_ACCOUNT_DATA } from "./gql";
import { ThemeContext } from "./Theme";

const LAUNCHDARKLY_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;

type AccountWrapperProps = {
  storage?: Storage;
  children: React.ReactNode;
};

export const AccountContext = createContext<AccountData | undefined>(undefined);
AccountContext.displayName = "AccountContext";

const AccountWrapper = ({ children, storage = localStorage }: AccountWrapperProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { currentTheme, syncThemeWithSystem } = useTypedContext(ThemeContext);
  const { data, error } = useQuery(GET_ACCOUNT_DATA, {
    onError,
  });
  const { data: AIData } = useQuery(GET_AI_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  useSpaceliftLoginsCookie(!!data?.viewer?.id);

  useEffect(() => {
    if (data?.viewer?.email) {
      identifyHubspotUser({
        accountId: data.accountId,
        email: data.viewer.email,
        id: data.viewer.id,
        provider: data.viewer.identityProvider,
        name: data.viewer.name,
      });
    }

    if (data?.viewer?.id) {
      createUserPropertyDataLayer(data.viewer.id, data.viewer.admin, data.viewer.identityProvider);
      identifySegmentUser({
        theme: currentTheme,
        usesSystemTheme: syncThemeWithSystem,
        hasAIEnabled: AIData?.hasAIEnabled,
      });
      identifyUserflowUser({
        admin: data.viewer.admin,
        id: data.viewer.id,
        email: data.viewer.email,
        name: data.viewer.name,
        since: data.viewer.since || undefined,
        usesSystemTheme: syncThemeWithSystem,
        theme: currentTheme,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.accountId, data?.viewer, AIData]);

  useEffect(() => {
    if (
      data?.accountName &&
      data?.accountType &&
      data?.viewer?.id &&
      data?.viewer?.name &&
      data?.viewer?.admin
    ) {
      datadogRum.setUser({
        id: data.viewer.id,
        name: data.viewer.name,
        account: data.accountName,
        accountType: data.accountType,
        admin: data.viewer.admin,
      });
      datadogRum.startSessionReplayRecording();
    }
  }, [data?.accountName, data?.accountType, data?.viewer]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (data && data.viewer === null) {
    return <RedirectToLogin setItem={(key, value) => storage.setItem(key, value)} />;
  }

  if (data) {
    const accountContext = {
      accountId: data.accountId,
      accountName: data.accountName,
      accountType: data.accountType,
      installationId: data.installationId,
      viewer: data.viewer,
      genericFormsList: data.genericFormsList,
      acceptedTermsAndConditionsAI: AIData?.acceptedTermsAndConditionsAI ?? false,
    };

    const component = (
      <AccountContext.Provider value={accountContext}>
        <NotifySessionHasExpired validUntil={data.viewer.validUntil} />
        {children}
      </AccountContext.Provider>
    );

    if (LAUNCHDARKLY_CLIENT_SIDE_ID) {
      const ldConfig: ProviderConfig = {
        options: {
          inspectors: [
            {
              type: "flag-used",
              name: "ff-inspector",
              method: (key, detail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);

                Bugsnag.addFeatureFlag(key, detail.value);
              },
            },
          ],
        },
        clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID,
        context: {
          kind: "user",
          admin: data.viewer.admin,
          key: data.viewer.id,
          email: data.viewer.email,
          name: data.viewer.name,
          account_name: data.accountName,
          account_type: data.accountType,
          identity_provider: data.viewer.identityProvider,
          pr: process.env.REACT_APP_PR_ID,
        },
        flags,
      };

      return <LDProvider {...ldConfig}>{component}</LDProvider>;
    }

    return component;
  }

  return <SpaceliftLoader />;
};

export default AccountWrapper;
