import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import CardWrapper from "components/CardWrapper";
import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import { SearchModulesOutput, SearchStacksOutput } from "types/generated";
import { useDebounce } from "hooks/useDebounce";
import FlashContext from "components/FlashMessages/FlashContext";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { SpacesContext } from "views/Account/SpacesProvider";
import ComboBox from "ds/components/ComboBox";
import ComboBoxSection from "ds/components/ComboBox/Group";
import ComboBoxItem from "ds/components/ComboBox/Item";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

import NewContextAutoAttachedProjects from "../AutoAttachedProjects";
import NewContextFooter from "../Footer";
import { SEARCH_STACKS_AND_MODULES } from "./gql";
import NewContextManuallyAttachedProjects from "../ManuallyAttachedProjects";
import { AttachableProject, AttachableProjectType } from "./types";
import { AttachedProject } from "../AttachedProject/types";
import { useAutoAttachmentLabels } from "../hooks/useAutoAttachmentLabels";
import { useSaveContext } from "../hooks/useSaveContext";
import { ContextFormFields } from "../types";
import { CONTEXT_ANALYTICS_VERSION, ContextCreationWizardStepName } from "../constants";
import { ContextFormContext } from "../context";
import NewContextAttachContextProjectComboBoxItemBadge from "./ProjectComboBoxItemBadge";

const NewAttachContext = () => {
  const { onError } = useTypedContext(FlashContext);
  const { spaceHierarchy } = useTypedContext(SpacesContext);

  const [project, setProject] = useState<string | null>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [autoAttachedProjectCount, setAutoAttachedProjectCount] = useState(0);
  const debouncedSearchQuery = useDebounce(searchQuery);
  const { handleSubmit, getValues, setValue, watch } = useFormContext<ContextFormFields>();
  const { setAttachedProjects } = useTypedContext(ContextFormContext);

  const space = getValues("space");
  const attachedProjectIds = watch("attachedProjectIds");

  useObserveForWarning(
    !!project,
    <>
      You have not attached the <strong>selected project</strong>. Do you want to continue without
      attaching it?
    </>
  );

  // TODO: Remove this once backend returns space-hierarchy
  const spacePath = spaceHierarchy[space];

  const trackAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.ContextNew,
    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const { data: searchData, loading: searching } = useQuery<{
    searchStacks: SearchStacksOutput;
    searchModules: SearchModulesOutput;
  }>(SEARCH_STACKS_AND_MODULES, {
    onError,
    variables: {
      input: {
        first: 50,
        fullTextSearch: debouncedSearchQuery,
        predicates: [
          {
            constraint: {
              hierarchyNodeValueEquals: [spacePath],
            },
            field: "space",
          },
        ],
      },
    },
    skip: !spacePath,
  });

  const [attachableProjects, attachedProjects] = useMemo(() => {
    const searchResults = [
      ...(searchData?.searchStacks?.edges || []),
      ...(searchData?.searchModules?.edges || []),
    ];

    const attachableProjectResults: { label: string; items: AttachableProject[] }[] = [
      { label: AttachableProjectType.STACK, items: [] },
      { label: AttachableProjectType.MODULE, items: [] },
    ];
    const attachedProjectResults: AttachedProject[] = [];

    for (const edge of searchResults) {
      const { id, name, spaceDetails, __typename } = edge.node;
      const isModule = __typename === "Module";

      if (attachedProjectIds.includes(edge.node.id)) {
        attachedProjectResults.push({
          id,
          name,
          spaceDetails,
          isModule,
        });
      } else {
        const project = {
          value: id,
          label: name,
          spaceDetails,
          type: isModule ? AttachableProjectType.MODULE : AttachableProjectType.STACK,
        };

        if (isModule) {
          attachableProjectResults[1].items.push(project);
        } else {
          attachableProjectResults[0].items.push(project);
        }
      }
    }

    return [
      attachableProjectResults.filter((section) => section.items.length > 0),
      attachedProjectResults,
    ];
  }, [searchData, attachedProjectIds]);

  useEffect(() => {
    setAttachedProjects(attachedProjects);
  }, [attachedProjects, setAttachedProjects]);

  const labelsValue = useAutoAttachmentLabels(getValues("labels"));

  const handleAttach = () => {
    if (!project) {
      return;
    }
    setValue("attachedProjectIds", attachedProjectIds.concat(project));
    trackAnalyticsEvent("Attached", { stack: project });
    setProject("");
  };

  const handleDetach = (id: string) => {
    setValue(
      "attachedProjectIds",
      attachedProjectIds.filter((projectId) => projectId !== id)
    );
  };

  const [saveContext, isSavingContext] = useSaveContext(ContextCreationWizardStepName.Attachment);

  const analyticsData = {
    location: ContextCreationWizardStepName.Attachment,
    "manually-attached": attachedProjectIds.length,
    "auto-attached": autoAttachedProjectCount,
    attached: attachedProjectIds.length > 0 || autoAttachedProjectCount > 0,
  };

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Attach context (optional)
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary">
          If you can’t see the stack or module that you wanted the context to attach to on the
          Auto-attached list you can add it manually here.
        </Typography>

        <CardWrapper variant="filled" direction="column" margin="x-large 0" gap="large">
          <Typography tag="h3" variant="p-t5">
            Attach to projects
          </Typography>
          <ComboBox
            label="Project"
            tooltipInfo={
              <>
                <TooltipModalTitle>Project</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <Typography tag="p" variant="p-body3">
                    Projects are all your stacks and modules.
                  </Typography>
                </TooltipModalBody>
              </>
            }
            isLoading={searching}
            items={attachableProjects}
            value={project}
            onChange={setProject}
            onInputChange={setSearchQuery}
          >
            {(item) => (
              <ComboBoxSection id={item.label} label={item.label} items={item.items}>
                {(item) => (
                  <ComboBoxItem
                    id={item.value}
                    label={item.label}
                    suffix={<NewContextAttachContextProjectComboBoxItemBadge {...item} />}
                  />
                )}
              </ComboBoxSection>
            )}
          </ComboBox>
          <Box align="end" direction="column" fullWidth>
            <Button
              variant="contrast"
              onClick={handleAttach}
              disabled={attachableProjects.length === 0 || !project}
            >
              Attach
            </Button>
          </Box>
        </CardWrapper>

        <Box gap="x-large" direction="column">
          <NewContextManuallyAttachedProjects items={attachedProjects} onDetach={handleDetach} />
          <NewContextAutoAttachedProjects
            space={space}
            labels={labelsValue}
            setAutoAttachedProjectCount={setAutoAttachedProjectCount}
            setAutoAttachProject={setAttachedProjects}
          />
        </Box>
      </FullScreenModalBody>

      <NewContextFooter
        isCreatingContext={isSavingContext}
        onCreateContextClick={handleSubmit(saveContext)}
        continueClickAnalyticsProps={analyticsData}
      />
    </>
  );
};

export default NewAttachContext;
