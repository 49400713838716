import React, { useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";
import { FocusScope } from "react-aria";

import Box from "components/box/Box";

import styles from "./styles.module.css";
import { DropdownPosition } from "../types";

const SPACE_BETWEEN_TRIGGER_AND_LIST = 4;

type DropdownListProps = {
  active?: boolean;
  position: DropdownPosition;
  className?: string;
  children: React.ReactNode;
  wrapperRef?: React.RefObject<HTMLDivElement>;
  id?: string;
};

const DropdownList = ({
  children,
  active,
  position,
  className,
  wrapperRef,
  id,
}: DropdownListProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [recalculatedPosition, setRecalculatedPosition] = useState(position);

  useLayoutEffect(() => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    if (listRef.current && wrapperRef?.current) {
      const listPosition = listRef.current.getBoundingClientRect();
      const triggerPosition = wrapperRef.current.getBoundingClientRect();
      const shouldBeRecalculated =
        listPosition.top < 0 ||
        listPosition.bottom > windowHeight ||
        listPosition.right > windowWidth ||
        listPosition.left < 0;

      if (shouldBeRecalculated) {
        let newPosition = "";

        if (
          triggerPosition.bottom + SPACE_BETWEEN_TRIGGER_AND_LIST + listPosition.height <
          windowHeight
        ) {
          newPosition = "bottom";
        } else {
          newPosition = "top";
        }

        if (triggerPosition.left + listPosition.width < windowWidth) {
          newPosition = `${newPosition}Right`;
        } else {
          newPosition = `${newPosition}Left`;
        }
        setRecalculatedPosition(newPosition as DropdownPosition);
      }
    }
  }, [active, wrapperRef]);

  return (
    <Box
      id={id}
      role="menu"
      innerRef={listRef}
      className={cx(
        styles.dropdownList,
        {
          [styles.active]: active,
          [styles[`${recalculatedPosition}Position`]]: true,
        },
        className
      )}
    >
      <FocusScope contain={active}>{children}</FocusScope>
    </Box>
  );
};

DropdownList.displayName = "DS.Dropdown.List";

export default DropdownList;
