import { useCallback, useEffect, useRef } from "react";
import { Dialog, Modal } from "react-aria-components";
import camelCase from "lodash-es/camelCase";
import cx from "classnames";

import { withTestId } from "utils/withTestId";
import useAriaModalAttribute from "hooks/useAriaModalAttribute";

import styles from "./styles.module.css";
import { DrawerProps } from "./types";

type DrawerInnerProps = Pick<DrawerProps, "onExit" | "children"> & {
  isExiting: boolean;
};

const DrawerInner = ({ children, isExiting, onExit }: DrawerInnerProps) => {
  const onExitRef = useRef(onExit);

  useEffect(() => {
    // Store the latest onExit callback in a ref to avoid specifying it as a dependency of the effect below.
    // Otherwise the effect would be called every time the onExit callback changes.
    onExitRef.current = onExit;
  }, [onExit]);

  useEffect(() => {
    return () => {
      if (isExiting) {
        onExitRef.current?.();
      }
    };
  }, [isExiting]);

  const dialogRef = useAriaModalAttribute();

  return (
    <Dialog ref={dialogRef} className={styles.drawerInner}>
      {children}
    </Dialog>
  );
};

DrawerInner.displayName = "DS.DrawerInner";

const DrawerNew = ({
  children,
  isOpen,
  onOpen,
  onClose,
  onExit,
  isDismissable = true,
  variant = "normal",
  dataTestId,
  shouldCloseOnInteractOutside,
}: DrawerProps) => {
  const handleOnOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [onOpen, onClose]
  );

  return (
    <Modal
      {...withTestId(dataTestId)}
      className={cx(styles.drawer, styles[camelCase(`variant-${variant}`)])}
      isOpen={isOpen}
      onOpenChange={handleOnOpenChange}
      isDismissable={isDismissable}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
    >
      {({ isExiting }) => (
        <DrawerInner isExiting={isExiting} onExit={onExit}>
          {children}
        </DrawerInner>
      )}
    </Modal>
  );
};

DrawerNew.displayName = "DS.DrawerNew";

export default DrawerNew;
