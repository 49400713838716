import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Callout from "ds/components/Callout";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const ACCESS_POLICY_WARNING_KEY = "accessPolicyWarningClosed";

const AccessPolicyWarning = () => {
  return (
    <Callout variant="danger" className={styles.wrapper} storageKey={ACCESS_POLICY_WARNING_KEY}>
      A login policy has been defined for your account, but we were unable to evaluate it. Login
      policy only applies to paid accounts. Learn more about it {` `}
      <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy/stack-access-policy")} title="here" />
      .
    </Callout>
  );
};

export default AccessPolicyWarning;
