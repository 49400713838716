import cronsTrue from "cronstrue";
import { useCallback } from "react";

import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { Cross, Tick } from "components/icons/generated";
import { DriftDetectionIntegration, StackState } from "types/generated";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { getNextSchedule } from "../helpers";
import { makeDriftDetectionRunsLink } from "./helpers";
import useDeleteDriftDetection from "./useDeleteDriftDetection";
import { StackSchedulingContextApi } from "../Context";
import { showManageDriftDetectionDeleteConfirmationModal } from "./DeleteConfirmationModal";

type StackManageDriftDetectionListItemProps = {
  integration: DriftDetectionIntegration;
  stackId: string;
  hasPrivateWorkerPool: boolean;
  stackState?: StackState;
};

const StackManageDriftDetectionListItem = ({
  integration,
  stackId,
  hasPrivateWorkerPool,
  stackState,
}: StackManageDriftDetectionListItemProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const { deleteIntegration, loading: deletionLoading } = useDeleteDriftDetection(stackId);

  const handleDelete = useCallback(() => {
    deleteIntegration(() => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.DRIFT_DETECTION });
    });
  }, [deleteIntegration, trackSegmentEvent]);

  const handleDeleteConfirmation = useCallback(() => {
    showManageDriftDetectionDeleteConfirmationModal({
      onConfirm: handleDelete,
    });
  }, [handleDelete]);

  const handleEdit = useCallback(() => {
    onEdit(integration);
  }, [integration, onEdit]);

  const isBlocked =
    !hasPrivateWorkerPool || (stackState !== StackState.Finished && !integration.ignoreState);

  const driftDetectionRunsUrl = makeDriftDetectionRunsLink(stackId);

  return (
    <CollapsibleList
      ariaLevel={3}
      title="Drift Detection"
      initialIsCollapsed={false}
      action={
        <DropdownMenuEllipsis tooltip="Drift detection actions" buttonVariant="ghost">
          {driftDetectionRunsUrl && (
            <DropdownMenuItem href={driftDetectionRunsUrl}>
              Show drift detection runs
            </DropdownMenuItem>
          )}
          {canManageStackAndRuns && (
            <>
              <DropdownMenuItem onAction={handleEdit}>Edit</DropdownMenuItem>
              <DropdownMenuItem
                loading={deletionLoading}
                danger
                onAction={handleDeleteConfirmation}
                analyticsPage={AnalyticsPageStack.StackScheduling}
                analyticsTitle="Schedule Delete Clicked"
                analyticsProps={{ type: SCHEDULING_TYPE.DRIFT_DETECTION }}
              >
                Delete
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuEllipsis>
      }
      alwaysVisibleContent={
        isBlocked && (
          <Banner
            variant="danger"
            title="Drift Detection jobs for a given stack are not scheduling:"
            fullWidth
          >
            {!hasPrivateWorkerPool && (
              <>
                The stack uses an unsupported public worker pool.
                <br />
              </>
            )}
            {stackState !== StackState.Finished && !integration.ignoreState && (
              <>
                The stack is currently not in the stable (FINISHED) state. You can skip this check
                by changing drift detection to ignore stack state.
                <br />
              </>
            )}

            <FeedbackActions>
              <Button
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/drift-detection")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </Button>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        {!isBlocked && (
          <FormSummaryKeyValueElement name="Starts in">
            {getNextSchedule(integration.nextSchedule || undefined)}
          </FormSummaryKeyValueElement>
        )}

        <FormSummaryKeyValueElement name="Scheduled">
          <Box direction="column">
            {integration.schedule.map((item, i, { length }) => (
              <Typography key={`${item}.${i}`} variant="p-body2" tag="span">
                {length > 1 && " •"} {cronsTrue.toString(item.trim())}
              </Typography>
            ))}
          </Box>
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Timezone">
          {integration.timezone}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Reconcile">
          {integration.reconcile ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={Cross} color="danger" />
          )}
        </FormSummaryKeyValueElement>

        <FormSummaryKeyValueElement name="Ignore State">
          {integration.ignoreState ? (
            <Icon src={Tick} color="success" />
          ) : (
            <Icon src={Cross} color="danger" />
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </CollapsibleList>
  );
};

export default StackManageDriftDetectionListItem;
