import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import MeterChart from "ds/components/Charts/MeterChart";
import Typography from "ds/components/Typography";
import { pluralize } from "shared/Pluralize";
import MissingDataBanner from "components/MissingDataBanner";
import Box from "ds/components/Box";

import { GetDriftDetectionCoverage } from "./types";
import { GET_DRIFT_DETECTION_COVERAGE } from "./gql";
import usePollInterval from "../../usePollInterval";

const EMPTY_TOOLTIP = (
  <Typography tag="span" variant="p-body3" color="primary">
    Enabled on:{" "}
    <Typography tag="span" variant="p-t7">
      no stacks created
    </Typography>
  </Typography>
);

const EMPTY_TOOLTIP_NO_DATA = (
  <Typography tag="span" variant="p-body3" color="primary">
    Enabled on:{" "}
    <Typography tag="span" variant="p-t7">
      no data
    </Typography>
  </Typography>
);

const DashboardWidgetsDriftDetectionCoverage = () => {
  const pollInterval = usePollInterval();
  const { data, loading, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetDriftDetectionCoverage>(GET_DRIFT_DETECTION_COVERAGE, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metrics?.driftDetectionCoverage;

  const [enabled, disabled] = showErrorBanner ? [] : data?.metrics?.driftDetectionCoverage || [];

  const enabledValue = enabled?.value || 0;
  const disabledValue = disabled?.value || 0;

  const chartData = useMemo(
    () => [
      {
        value: enabledValue,
        color: "var(--semantic-color-background-interactive-data-non-semantic-1-rest)",
        renderLegendLabel: (value: number, total: number) => (
          <>
            Drift detection enabled: <b>{total > 0 ? Math.round((value * 100) / total) : 0}%</b>
          </>
        ),
        renderTooltipContent: (value: number) => (
          <Typography tag="span" variant="p-body3" color="primary">
            Enabled on:{" "}
            <Typography tag="span" variant="p-t7">
              {value} {pluralize("stack", value)}
            </Typography>
          </Typography>
        ),
      },
      {
        value: disabledValue,
        color: "var(--semantic-color-background-secondary)",
        renderLegendLabel: (value: number, total: number) => (
          <>
            Drift detection disabled: <b>{total > 0 ? Math.round((value * 100) / total) : 0}%</b>
          </>
        ),
        renderTooltipContent: (value: number) => (
          <Typography tag="span" variant="p-body3" color="primary">
            Disabled on:{" "}
            <Typography tag="span" variant="p-t7">
              {value} {pluralize("stack", value)}
            </Typography>
          </Typography>
        ),
      },
    ],
    [disabledValue, enabledValue]
  );

  return (
    <Box fullWidth direction="column" gap="x-large">
      {showErrorBanner && (
        <MissingDataBanner refreshHandler={refetch} refreshLoading={loading} fullWidth />
      )}
      <MeterChart
        data={chartData}
        isLoading={isLoading}
        emptyTooltipContent={showErrorBanner ? EMPTY_TOOLTIP_NO_DATA : EMPTY_TOOLTIP}
      />
    </Box>
  );
};

export default DashboardWidgetsDriftDetectionCoverage;
