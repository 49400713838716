import { Pages } from "./types";

export const enum AnalyticsPageOrganization {
  Organization = "Organization",
  OrganizationApiKeys = "OrganizationApiKeys",
  OrganizationAuditTrail = "OrganizationAuditTrail",
  OrganizationBilling = "OrganizationBilling",
  OrganizationBillingInvoiceForm = "OrganizationBillingInvoiceForm",
  OrganizationBillingUpgradeForm = "OrganizationBillingUpgradeForm",
  OrganizationIdpGroupMapping = "OrganizationIdpGroupMapping",
  OrganizationIntegrations = "OrganizationIntegrations",
  OrganizationLoginPolicies = "OrganizationLoginPolicies",
  OrganizationLoginPoliciesList = "OrganizationLoginPoliciesList",
  OrganizationLoginPolicyCreate = "OrganizationLoginPolicyCreate",
  OrganizationLoginPolicyDetails = "OrganizationLoginPolicyDetails",
  OrganizationLimits = "OrganizationLimits",
  OrganizationManagementStrategy = "OrganizationManagementStrategy",
  OrganizationOIDC = "OrganizationOIDC",
  OrganizationSlackAccess = "OrganizationSlackAccess",
  OrganizationSAML = "OrganizationSAML",
  OrganizationSecurityEmail = "OrganizationSecurityEmail",
  OrganizationSessions = "OrganizationSessions",
  OrganizationSSO = "OrganizationSSO",
  OrganizationUsage = "OrganizationUsage",
  OrganizationUsageChart = "OrganizationUsageChart",
  OrganizationUsers = "OrganizationUsers",
  OrganizationAi = "OrganizationAi",
}

const organizationSettings = "Organization settings";
const sso = "SSO";
const billing = "Billing";
const usage = "Usage";

export const pagesOrganization: Pages<AnalyticsPageOrganization> = {
  [AnalyticsPageOrganization.OrganizationAuditTrail]: [organizationSettings, "Audit Trail"],
  [AnalyticsPageOrganization.Organization]: [organizationSettings],
  [AnalyticsPageOrganization.OrganizationIdpGroupMapping]: [
    organizationSettings,
    "IdP group mapping",
  ],
  [AnalyticsPageOrganization.OrganizationIntegrations]: [organizationSettings, "Integrations"],
  [AnalyticsPageOrganization.OrganizationLoginPolicies]: [
    organizationSettings,
    "Login Policies List",
  ],
  [AnalyticsPageOrganization.OrganizationManagementStrategy]: [
    organizationSettings,
    "Management strategy",
  ],
  [AnalyticsPageOrganization.OrganizationSlackAccess]: [
    organizationSettings,
    "Integrations",
    "Manage Slack Access",
  ],
  [AnalyticsPageOrganization.OrganizationUsage]: [organizationSettings, usage],
  [AnalyticsPageOrganization.OrganizationUsageChart]: [organizationSettings, usage, "Usage chart"],
  [AnalyticsPageOrganization.OrganizationUsers]: [organizationSettings, "Users"],
  [AnalyticsPageOrganization.OrganizationBilling]: [organizationSettings, billing],
  [AnalyticsPageOrganization.OrganizationBillingInvoiceForm]: [
    organizationSettings,
    billing,
    "Invoice Form",
  ],
  [AnalyticsPageOrganization.OrganizationBillingUpgradeForm]: [
    organizationSettings,
    billing,
    "Upgrade Form",
  ],
  [AnalyticsPageOrganization.OrganizationOIDC]: [organizationSettings, sso, "OIDC"],
  [AnalyticsPageOrganization.OrganizationSSO]: [organizationSettings, sso],
  [AnalyticsPageOrganization.OrganizationSAML]: [organizationSettings, sso, "SAML"],
  [AnalyticsPageOrganization.OrganizationSecurityEmail]: [organizationSettings, "Security email"],
  [AnalyticsPageOrganization.OrganizationSessions]: [organizationSettings, "Sessions"],
  [AnalyticsPageOrganization.OrganizationApiKeys]: [organizationSettings, "API keys"],
  [AnalyticsPageOrganization.OrganizationLimits]: [organizationSettings, "Limits"],
  [AnalyticsPageOrganization.OrganizationLoginPolicyDetails]: [
    organizationSettings,
    "Login Policy Details",
  ],
  [AnalyticsPageOrganization.OrganizationLoginPolicyCreate]: [
    organizationSettings,
    "New Login Policy",
  ],
  [AnalyticsPageOrganization.OrganizationLoginPoliciesList]: [
    organizationSettings,
    "Login Policies List",
  ],
  [AnalyticsPageOrganization.OrganizationAi]: [organizationSettings, "AI"],
};
