import { Plus } from "components/icons/generated";

import Link, { type LinkProps } from ".";
import Icon from "../Icon";

type LinkAddProps = Partial<Pick<LinkProps, "size" | "disabled">> & {
  onClick?: () => void;
  to?: string;
  text: Required<string>;
};

const LinkAdd = ({ text, size = "medium", ...restProps }: LinkAddProps) => {
  return (
    <Link gap={size} size={size} {...restProps}>
      <Icon src={Plus} />
      {text}
    </Link>
  );
};

LinkAdd.displayName = "DS.Link.Add";

export default LinkAdd;
