import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";

import styles from "./styles.module.css";

const IdpGroupMappingInfoDrawer = createDrawer(() => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="IdP Groups" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h3" variant="p-t6">
          Group access
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          You can think of groups as teams that you have defined in your identity provider.
          <br />
          <br />
          In Spacelift, you can use these groups to assign access to a team of users. Please note
          that when defining access rules, the group name provided must exactly match the one
          defined in your IdP. Otherwise, the access rules will not work.
        </Typography>
        <DrawerFooter>
          {/* TODO: update link when documentation created */}
          <DocumentationButton
            to={getDocsUrl("/concepts/user-management/admin.html#idp-group-mapping")}
            analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
          />
        </DrawerFooter>
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showIdpGroupMappingInfoDrawer = createDrawerTrigger(IdpGroupMappingInfoDrawer);
