import { forwardRef } from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import TextEllipsis from "../TextEllipsis";
import styles from "./styles.module.css";
import Icon, { IconProps } from "../Icon";
import BaseActionButton, { BaseActionButtonProps } from "../BaseAction/Button";

export type ButtonProps = BaseActionButtonProps & {
  variant:
    | "primary"
    | "secondary"
    | "contrast"
    | "dangerPrimary"
    | "dangerSecondary"
    | "ghost"
    | "ghostInversed"
    | "ghostDanger"
    | "outlineOnDark";
  size?: "small" | "medium";
  startIcon?: IconComponent;
  startIconRotate?: IconProps["rotate"];
  endIcon?: IconComponent;
  endIconRotate?: IconProps["rotate"];
  iconColor?: IconProps["color"];
  active?: boolean;
  disabled?: boolean;
  withTextEllipsis?: boolean;
  children: string;
} & AnalyticsCommonProps;

const ButtonNew = forwardRef(function Button(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    size = "medium",
    variant,
    onPress,
    children,
    className,
    startIcon,
    startIconRotate,
    endIcon,
    endIconRotate,
    active,
    withTextEllipsis,
    iconColor,
    ...restProps
  } = props;

  return (
    <BaseActionButton
      ref={ref}
      className={cx(
        styles.button,
        styles[size],
        styles[variant],
        {
          [styles.active]: active,
          [styles.disabled]: restProps.disabled,
          [styles.textEllipsis]: withTextEllipsis,
        },
        className
      )}
      onPress={onPress}
      {...restProps}
    >
      {startIcon && (
        <Icon color={iconColor} noShrink src={startIcon} aria-hidden rotate={startIconRotate} />
      )}
      {withTextEllipsis ? (
        <TextEllipsis tooltip={children} tooltipWidthMode="maxWidthSm" tooltipPlacement="bottom">
          {(props) => <span {...props}>{children}</span>}
        </TextEllipsis>
      ) : (
        children
      )}
      {endIcon && (
        <Icon color={iconColor} noShrink src={endIcon} aria-hidden rotate={endIconRotate} />
      )}
    </BaseActionButton>
  );
});

ButtonNew.displayName = "DS.Button.New";

export default ButtonNew;
