import { FunctionComponent, SVGProps } from "react";

import { ArrowsLeftRight, Pulse } from "components/icons/generated";

// TODO: [dashboard] those colors will be changed because of accesibility issues
export const vendors: Record<
  string,
  {
    logo: FunctionComponent<SVGProps<SVGSVGElement>>;
    color: string;
    inactiveColor: string;
    activeColor: string;
  }
> = {
  drifted: {
    logo: ArrowsLeftRight,
    color: "var(--semantic-color-background-interactive-data-semantic-negative-rest)",
    activeColor: "var(--semantic-color-background-interactive-data-semantic-negative-hovered)",
    inactiveColor: "var(--semantic-color-background-interactive-data-semantic-negative-inactive)",
  },
  healthy: {
    logo: Pulse,
    color: "var(--semantic-color-background-interactive-data-semantic-positive-rest)",
    activeColor: "var(--semantic-color-background-interactive-data-semantic-positive-hovered)",
    inactiveColor: "var(--semantic-color-background-interactive-data-semantic-positive-inactive)",
  },
};
