import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { Gear, File, Magnify, Play } from "components/icons/generated";
import { withEnterKeyPress } from "utils/browser";
import { Stack } from "types/generated";
import Icon from "ds/components/Icon";

import { generateEntityLabel, translateEntity } from "./helpers";

import "./styles.css";

const filtersKeys = ["creator", "name", "parent", "provider", "type", "stack", "updater"];

type ResourcesSideBarEntityProps = {
  item: {
    key: string;
    entity: string | { id: string };
    sortKey: string;
  };
  stackId: Pick<Stack, "id"> | string;
  handleFilterNewChange: (key: string, value: string) => void;
};

const ResourcesSideBarEntity = ({
  item,
  stackId,
  handleFilterNewChange,
}: ResourcesSideBarEntityProps) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const isCreatorOrUpdater = item.key === "creator" || item.key === "updater";
  const dropdownClass = cx("resources-dropdown__menu", {
    "resources-dropdown__menu--visible": isDropdownVisible,
    "resources-dropdown__menu--top": item.key === "vendor",
  });
  const translatedValue = useMemo(
    () => translateEntity(item.entity, item.key),
    [item.entity, item.key]
  );
  const labelText = useMemo(
    () => generateEntityLabel(item.entity, item.key),
    [item.entity, item.key]
  );

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (node?.current?.contains(e.target as Node)) {
        // inside click
        return;
      }
      // outside click
      setDropdownVisible(false);
    },
    [setDropdownVisible]
  );

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible, handleClickOutside]);

  const setFilter = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, key: string, value?: { id: string } | string) => {
      e.preventDefault();
      const filterValue: string = isCreatorOrUpdater
        ? (typeof value !== "string" && value?.id) || "none"
        : (typeof value === "string" && value) || "";
      const filterKey = isCreatorOrUpdater ? `${key}.id` : key;

      handleFilterNewChange(filterKey, filterValue);
    },
    [isCreatorOrUpdater, handleFilterNewChange]
  );

  const handleCopy: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (window.isSecureContext && typeof translatedValue === "string") {
        navigator.clipboard.writeText(translatedValue);
      }
    },
    [translatedValue]
  );

  const handleClick = () => setDropdownVisible(!isDropdownVisible);

  return (
    <div className="resources-sidebar__entry">
      <label className="resources-sidebar__entry-label">{labelText}</label>
      <div
        className="resources-sidebar__entry-value"
        ref={node}
        onClick={handleClick}
        onKeyDown={withEnterKeyPress(handleClick)}
        role="button"
        tabIndex={0}
      >
        <div className="resources-sidebar__entry-wrapper">
          <Icon src={Gear} className="resources-sidebar__entry-icon" />
          <pre>
            <code>{translatedValue}</code>
          </pre>
        </div>
        {isDropdownVisible && (
          <ul className={dropdownClass}>
            {filtersKeys.includes(item.key) && (
              <li>
                <button
                  type="button"
                  className="resources-dropdown__button"
                  onClick={(e) => setFilter(e, item.key, item.entity)}
                >
                  <Icon src={Magnify} className="resources-dropdown__icon" />
                  Set as filter
                </button>
              </li>
            )}
            {isCreatorOrUpdater && typeof item.entity !== "string" && item.entity?.id && (
              <li>
                <Link
                  className="resources-dropdown__button"
                  to={`/stack/${stackId}/run/${item.entity.id}`}
                >
                  <Icon src={Play} className="resources-dropdown__icon" />
                  Go to Run
                </Link>
              </li>
            )}
            {item.key === "stack" && (
              <>
                <li>
                  <Link className="resources-dropdown__button" to={`/stack/${stackId}`}>
                    <Icon src={Play} className="resources-dropdown__icon" />
                    Go to Stack
                  </Link>
                </li>
                <li>
                  <Link className="resources-dropdown__button" to={`/stack/${stackId}/resources`}>
                    <Icon src={Play} className="resources-dropdown__icon" />
                    Go to Stack Resources
                  </Link>
                </li>
              </>
            )}
            <li>
              <button type="button" className="resources-dropdown__button" onClick={handleCopy}>
                <Icon src={File} className="resources-dropdown__icon" />
                Copy
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default ResourcesSideBarEntity;
