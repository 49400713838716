import { useMutation } from "@apollo/client";

import { Edit } from "components/icons/generated";
import Box from "ds/components/Box";
import { SpaceAccessLevel, TerraformProviderVersionStatus } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Markdown from "components/markdown/Markdown";
import { AccountContext } from "views/AccountWrapper";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { useToggle } from "hooks/useToggle";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import ButtonNew from "ds/components/Button/New";

import styles from "./styles.module.css";
import { DELETE_PROVIDER_VERSION, PUBLISH_PROVIDER_VERSION, REVOKE_PROVIDER_VERSION } from "../gql";
import {
  DeleteProviderVersionGql,
  ProviderVersionProps,
  PublishProviderVersionGql,
  RevokeProviderVersionGql,
} from "./types";
import ProviderVersionArtifacts from "../ProviderVersionArtifacts";
import TerraformProviderVersionStatusBadge from "../ProvidersVersionStatusBadge";
import { showProviderVersionDeleteConfirmation } from "./DeleteConfirmation";
import { showProviderVersionEditDrawer } from "../ProviderVersionEditDrawer";
import { showProviderVersionDrawer } from "../ProviderVersionDrawer";

function ProviderVersion({ provider, version }: ProviderVersionProps) {
  const [isCollapsed, toggle] = useToggle(true);
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const canManageProvider =
    viewer.admin || provider.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  const [publishVersion, publishMutation] = useMutation<PublishProviderVersionGql>(
    PUBLISH_PROVIDER_VERSION,
    {
      refetchQueries: ["GetProviderWithVersions"],
    }
  );

  const [revokeVersion] = useMutation<RevokeProviderVersionGql>(REVOKE_PROVIDER_VERSION, {
    refetchQueries: ["GetProviderWithVersions"],
  });

  const [deleteVersion] = useMutation<DeleteProviderVersionGql>(DELETE_PROVIDER_VERSION, {
    refetchQueries: ["GetProviderWithVersions"],
  });

  const handlePublishProviderVersion = () => {
    publishVersion({
      variables: {
        version: version.id,
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderVersionPublish?.number) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionPublish.number}" was successfully published`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while publishing Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const handleDeleteProviderVersion = () => {
    deleteVersion({ variables: { version: version.id } })
      .then(({ data }) => {
        if (data?.terraformProviderVersionDelete?.deleted) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionDelete.number}" was successfully deleted`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while deleting Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const handleRevokeProviderVersion = () => {
    revokeVersion({ variables: { version: version.id } })
      .then(({ data }) => {
        if (
          data?.terraformProviderVersionRevoke?.status === TerraformProviderVersionStatus.Revoked
        ) {
          reportSuccess({
            message: `Terraform provider version "${data.terraformProviderVersionRevoke.number}" was successfully revoked`,
          });
        } else {
          reportError({
            message:
              "Something went wrong while revoking Terraform provider version, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const isDraft = version.status === TerraformProviderVersionStatus.Draft;
  const isPublished = version.status === TerraformProviderVersionStatus.Published;
  const isRevoked = version.status === TerraformProviderVersionStatus.Revoked;

  const confirmationModalShow = () => {
    showProviderVersionDeleteConfirmation({
      onConfirm: isPublished ? handleRevokeProviderVersion : handleDeleteProviderVersion,
      isPublished,
      versionNumber: version.number,
    });
  };

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle} withTransition role="row">
      <CollapsiblePanelHeader
        padding="medium large"
        gap="medium"
        grid
        gridTemplate="auto 1fr auto"
        gridAreas={`"indicator content actions" ${
          version.description ? `". description description"` : ""
        }`}
      >
        <Box align="center" gridArea="indicator" gap="medium">
          <CollapsiblePanelToggleTrigger ariaLevel={3} ariaLabel={version.number} />
          <CollapsiblePanelTitle role="rowheader">{version.number}</CollapsiblePanelTitle>
          <TerraformProviderVersionStatusBadge status={version.status} />
        </Box>
        <Box align="center" gridArea="actions" gap="medium">
          {canManageProvider && isDraft && (
            <ButtonNew
              size="small"
              variant="primary"
              onPress={handlePublishProviderVersion}
              disabled={publishMutation.loading}
            >
              Publish
            </ButtonNew>
          )}
          {canManageProvider && isRevoked && (
            <ButtonIconNew
              variant="secondary"
              size="small"
              icon={Edit}
              onPress={() =>
                showProviderVersionEditDrawer({
                  providerVersion: version,
                })
              }
              tooltipPlacement="left"
            >
              Edit provider version
            </ButtonIconNew>
          )}

          {(isPublished || isDraft) && (
            <DropdownMenuEllipsis tooltip="Version actions">
              {canManageProvider && (
                <DropdownMenuItem
                  onAction={() => {
                    showProviderVersionEditDrawer({
                      providerVersion: version,
                    });
                  }}
                >
                  Edit
                </DropdownMenuItem>
              )}

              <DropdownMenuItem
                onAction={() => {
                  showProviderVersionDrawer({
                    providerVersion: version,
                    provider,
                  });
                }}
              >
                View instructions
              </DropdownMenuItem>

              {canManageProvider && (
                <DropdownMenuItem onAction={confirmationModalShow} danger>
                  {isPublished ? "Revoke" : "Delete"}
                </DropdownMenuItem>
              )}
            </DropdownMenuEllipsis>
          )}
        </Box>

        {version.description && (
          <Box gridArea="description">
            <Markdown markup={version.description} className={styles.description} />
          </Box>
        )}
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent padding="medium large large">
        <ProviderVersionArtifacts version={version} provider={provider} />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
}

export default ProviderVersion;
