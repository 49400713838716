import {
  Popover,
  PopoverProps,
  MenuTrigger,
  Menu,
  MenuTriggerProps,
  MenuProps,
} from "react-aria-components";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";

export type DropdownMenuProps<T> = {
  children: ReactNode;
  triggerComponent: ReactNode;
  placement?: PopoverProps["placement"];
  isOpen?: boolean;
  onOpenChange?: MenuTriggerProps["onOpenChange"];
  className?: string;
} & MenuProps<T>;

const DropdownMenu = <T extends object>({
  children,
  triggerComponent,
  placement = "bottom left",
  isOpen,
  onOpenChange,
  className,
  ...menuProps
}: DropdownMenuProps<T>) => {
  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
      {triggerComponent}
      <Popover placement={placement}>
        <Menu {...menuProps} className={cx(styles.menu, className)}>
          {children}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

DropdownMenu.displayName = "DS.DropdownMenu";

export default DropdownMenu;
