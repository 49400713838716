import { useQuery } from "@apollo/client";
import { Route, Routes, useParams } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";
import { Module } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import { hasSpaceManageAccess } from "utils/user";

import { GET_MODULE_SETTINGS } from "./gql";
import ModuleSettingsDeletion from "./Deletion";
import ModuleSettingsDetails from "./Details";
import ModuleSettingsSourceCode from "./SourceCode";
import ModuleSettingsBehavior from "./Behavior";
import ModuleSettingsIntegrations from "./Integrations";
import ModuleSettingsSharing from "./Sharing";
import { ModuleSettingsContext } from "./Context";
import ModuleSettingsRouteLayout from "./components/RouteLayout";
import ModuleSettingsIntegrationsWebhook from "./Integrations/Webhook";
import ModuleSettingsIntegrationsWebhookDelivery from "./Integrations/WebhookDelivery";

const ModuleSettings = () => {
  const { onError } = useTypedContext(FlashContext);
  const { moduleId } = useParams<{ moduleId: string }>();

  const { data, error, loading } = useQuery<{ module: Module }>(GET_MODULE_SETTINGS, {
    onError,
    variables: {
      id: moduleId,
    },
    fetchPolicy: "no-cache",
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module) {
    return <NotFoundPage />;
  }

  const canManageModule = hasSpaceManageAccess(data.module.spaceDetails.accessLevel);

  return (
    <ModuleSettingsContext.Provider value={{ module: data.module, canManageModule }}>
      <Routes>
        <Route path="integrations/webhook/:webhookId/delivery/:deliveryId">
          <Route index path="*" element={<ModuleSettingsIntegrationsWebhookDelivery />} />
        </Route>
        <Route
          path="integrations/webhook/:webhookId"
          element={<ModuleSettingsIntegrationsWebhook />}
        />
        <Route element={<ModuleSettingsRouteLayout />}>
          <Route index element={<ModuleSettingsDetails />} />
          <Route path="source-code" element={<ModuleSettingsSourceCode />} />
          <Route path="behavior" element={<ModuleSettingsBehavior />} />
          <Route path="integrations">
            <Route index path="*" element={<ModuleSettingsIntegrations />} />
          </Route>
          <Route path="sharing" element={<ModuleSettingsSharing />} />
          {canManageModule && <Route path="deletion" element={<ModuleSettingsDeletion />} />}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ModuleSettingsContext.Provider>
  );
};

export default ModuleSettings;
