import { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import useTypedContext from "hooks/useTypedContext";
import { Edit } from "components/icons/generated";
import CollapsibleList from "components/CollapsibleList";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { ModuleFormContext } from "../context";
import { ModuleCreationWizardStep } from "../types";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

type NewModuleSummaryPanelProps = {
  name: string;
  step: ModuleCreationWizardStep;
  children: ReactNode;
  goToStepDisabled?: boolean;
  count?: number;
};

const NewModuleSummaryPanel = ({
  name,
  step,
  children,
  count,
  goToStepDisabled,
}: NewModuleSummaryPanelProps) => {
  const { setCurrentStep } = useTypedContext(ModuleFormContext);
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();
  const goToStep = () => {
    trackSegmentEvent("Summary Edit Click", {
      step: name,
    });
    setCurrentStep(step);
  };

  return (
    <CollapsibleList
      ariaLevel={3}
      title={name}
      count={count}
      initialIsCollapsed={false}
      analyticsPage={AnalyticsPageModule.ModuleNew}
      analyticsProps={{ step: name }}
      analyticsTitle="Summary section toggle"
      action={
        <ButtonIcon disabled={goToStepDisabled} icon={Edit} onClick={goToStep} variant="ghost">
          Edit step
        </ButtonIcon>
      }
    >
      {children}
    </CollapsibleList>
  );
};

export default NewModuleSummaryPanel;
