import { ReactNode } from "react";
import cx from "classnames";

import Box from "../Box";
import styles from "./styles.module.css";

type FeedbackActionsProps = {
  children?: ReactNode;
  className?: string;
};

const FeedbackActions = ({ className, children }: FeedbackActionsProps) => {
  return <Box className={cx(styles.actions, className)}>{children}</Box>;
};

FeedbackActions.displayName = "DS.Feedback.Actions";

export default FeedbackActions;
