import { Group } from "@visx/group";
import { Line } from "@visx/shape";

import Typography from "ds/components/Typography";

import {
  PERCENTILE_BADGE_HEIGHT,
  PERCENTILE_BADGE_HOVER_PADDING,
  PERCENTILE_BADGE_WIDTH,
  PERCENTILE_BADGE_X_OFFSET,
} from "./constants";

type PercentileLineProps = {
  name: string;
  xMax: number;
  y: number;
  rectY: number;
  lineColor: string;
  backgroundColor: string;
  badgeClassName?: string;
  badgeStyle?: React.CSSProperties;
};

const PercentileLine = ({
  xMax,
  y,
  rectY,
  name,
  lineColor,
  backgroundColor,
  badgeClassName,
  badgeStyle,
}: PercentileLineProps) => (
  <Group key={name}>
    <Group className={badgeClassName} style={badgeStyle}>
      <rect
        x={-PERCENTILE_BADGE_WIDTH - PERCENTILE_BADGE_X_OFFSET - PERCENTILE_BADGE_HOVER_PADDING}
        y={rectY - PERCENTILE_BADGE_HEIGHT / 2 - PERCENTILE_BADGE_HOVER_PADDING}
        width={PERCENTILE_BADGE_WIDTH + PERCENTILE_BADGE_HOVER_PADDING * 2}
        height={PERCENTILE_BADGE_HEIGHT + PERCENTILE_BADGE_HOVER_PADDING * 2}
        fill="transparent"
      />
      <rect
        x={-PERCENTILE_BADGE_WIDTH - PERCENTILE_BADGE_X_OFFSET}
        y={rectY - PERCENTILE_BADGE_HEIGHT / 2}
        width={PERCENTILE_BADGE_WIDTH}
        height={PERCENTILE_BADGE_HEIGHT}
        rx={4}
        ry={4}
        fill={backgroundColor}
        stroke={lineColor}
      />
      <text
        fill="currentColor"
        textAnchor="middle"
        x={-PERCENTILE_BADGE_WIDTH / 2 - PERCENTILE_BADGE_X_OFFSET}
        y={rectY + 3}
      >
        <Typography tag="tspan" variant="p-body4" color="primary">
          {name}
        </Typography>
      </text>
    </Group>

    <Line
      from={{ x: -PERCENTILE_BADGE_X_OFFSET, y }}
      to={{ x: xMax, y }}
      stroke={lineColor}
      strokeWidth={1}
      pointerEvents="none"
    />
  </Group>
);

PercentileLine.displayName = "DS.Charts.PercentileLine";

export default PercentileLine;
