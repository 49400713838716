import { Fragment, memo, useCallback, useMemo } from "react";
import { BarStack, Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, StringLike, NumberLike } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { Point } from "@visx/point";
import { ScaleOrdinal } from "d3";

import useContainerSize from "hooks/useContainerSize";
import Box from "ds/components/Box";

import styles from "./styles.module.css";
import ChartTooltip from "../components/Tooltip";
import ChartTooltipTotalHeader from "../components/Tooltip/TotalHeader";
import ChartTooltipDivider from "../components/Tooltip/Divider";
import ChartTooltipItemValue from "../components/Tooltip/ItemValue";
import { tooltipContainerStyles } from "../components/Tooltip/helpers";
import AxisLabel from "../components/AxisLabel";
import { Percentile } from "../types";
import { BAR_CHART_MARGIN, TOOLTIP_OFFSET, X_AXIS_PADDING, Y_AXIS_LABEL_OFFSET } from "./constants";
import BarChartOverlay from "./Overlay";
import PercentilesGroup from "../components/Percentile/Group";

const defaultFormatValue = (value: number) =>
  value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });

export type Datum = {
  [key: string]: number;
};

export type BarChartBaseProps = {
  data: Datum[];
  items: string[];
  colors: string[];
  inactiveColors?: string[];
  xKey: string;
  tooltipHeader?: string;
  formatXAxisLabel: (value: StringLike) => string;
  formatValue?: (value: number) => string;
  formatTooltipValue?: (data: Datum) => string;
  aspectRatio?: number;
  showItemsInTooltip?: boolean;
  percentiles?: Percentile[];
  leftNumTicks?: number;
  leftAxisLabel?: string;
  showGridRows?: boolean;
  tooltipReactToScroll?: boolean;
  fixedHeight?: number;
  minBarHeight?: number;
};

type BarChartProps = BarChartBaseProps & {
  colorScale: ScaleOrdinal<string, string>;
  inactiveColorScale: ScaleOrdinal<string, string>;
};

type TooltipData = {
  data: Datum;
  hoveredIndex: number;
};

const tooltipStyles = { ...defaultStyles, ...tooltipContainerStyles };

const formatYAxisLabel = (value: NumberLike) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(value.valueOf());
};

const BarChart = ({
  data,
  xKey,
  items,
  formatXAxisLabel,
  tooltipHeader = "Total:",
  aspectRatio,
  showItemsInTooltip = true,
  percentiles,
  leftNumTicks = 6,
  colorScale,
  inactiveColorScale,
  leftAxisLabel,
  formatValue = defaultFormatValue,
  formatTooltipValue,
  showGridRows = true,
  tooltipReactToScroll = false,
  fixedHeight,
  minBarHeight = 0,
}: BarChartProps) => {
  const {
    containerRef: svgContainerRef,
    width: parentWidth,
    height: parentHeight,
  } = useContainerSize();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: tooltipReactToScroll,
  });

  const width = parentWidth;

  const dynamicHeight = aspectRatio ? parentWidth * aspectRatio : parentHeight;
  const height = fixedHeight || dynamicHeight;

  const xMax = width - BAR_CHART_MARGIN.left - BAR_CHART_MARGIN.right;
  const yMax = height - BAR_CHART_MARGIN.top - BAR_CHART_MARGIN.bottom;

  const xDomain = useMemo(() => data.map((d) => d[xKey]), [data, xKey]);

  const xScale = useMemo(
    () =>
      scaleBand({
        domain: xDomain,
        range: [0, xMax],
        padding: X_AXIS_PADDING,
      }),
    [xDomain, xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [
          0,
          Math.max(
            ...data.map((d: Datum) => {
              let total = 0;
              for (const item of items) {
                total += d[item];
              }
              return total;
            })
          ),
        ],
        range: [yMax, 0],
        nice: true,
      }),
    [data, items, yMax]
  );

  const hoveredIndex = tooltipData?.hoveredIndex ?? null;

  const handleMouseMove = useCallback(
    (barIndex: number, coordinates: Point | null) => {
      const tooltipData = { ...data[barIndex] };
      delete tooltipData[xKey];

      showTooltip({
        tooltipData: { data: tooltipData, hoveredIndex: barIndex },
        tooltipTop: coordinates?.y,
        tooltipLeft: coordinates?.x,
      });
    },
    [data, showTooltip, xKey]
  );

  const defaultFormatTooltipValue = useCallback(
    (dataToFormat: Datum) =>
      formatValue(Object.values(dataToFormat).reduce((a: number, b) => a + b, 0)),

    [formatValue]
  );

  const shouldRender = xMax >= 0 && yMax >= 0;

  return (
    <Box ref={svgContainerRef} fullWidth grow="1">
      {shouldRender && (
        <svg ref={containerRef} width="100%" height={height} className={styles.barChart}>
          <Group top={BAR_CHART_MARGIN.top} left={BAR_CHART_MARGIN.left}>
            {showGridRows && (
              <GridRows
                scale={yScale}
                width={xMax}
                strokeDasharray="1,0"
                stroke="var(--bar-chart-grid-color)"
              />
            )}
            <AxisLeft
              numTicks={leftNumTicks}
              scale={yScale}
              hideTicks
              stroke="var(--bar-chart-axis-color)"
              tickComponent={(tickProps) => <AxisLabel {...tickProps} x={tickProps.x - 5} />}
              label={leftAxisLabel}
              labelOffset={Y_AXIS_LABEL_OFFSET}
              labelClassName={styles.axisLabel}
              tickFormat={formatYAxisLabel}
            />
            <AxisBottom
              scale={xScale}
              top={yMax}
              numTicks={5}
              hideTicks
              tickFormat={formatXAxisLabel}
              tickComponent={AxisLabel}
              stroke="var(--bar-chart-axis-color)"
              tickValues={hoveredIndex !== null ? [data?.[hoveredIndex]?.[xKey]] : undefined}
            />

            <BarChartOverlay
              keys={xDomain}
              xScale={xScale}
              padding={X_AXIS_PADDING}
              yMax={yMax}
              onMouseMove={handleMouseMove}
              onMouseLeave={hideTooltip}
            />

            <BarStack
              data={data}
              keys={items}
              x={(d) => d[xKey]}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barItems) =>
                barItems.map((barItem) =>
                  barItem.bars.map((bar) => {
                    const shouldBeHighlighted = !tooltipOpen || hoveredIndex === bar.index;
                    const shouldShowMinHeight = bar.height > 0 && bar.height < minBarHeight;
                    return (
                      <Fragment key={`bar-item-${barItem.index}-${bar.index}`}>
                        <Bar
                          x={bar.x}
                          y={shouldShowMinHeight ? bar.y - 1 - minBarHeight : bar.y - 1}
                          height={shouldShowMinHeight ? minBarHeight : bar.height}
                          width={bar.width}
                          fill={
                            shouldBeHighlighted
                              ? bar.color
                              : inactiveColorScale(barItem.key) || "var(--bar-color-secondary)"
                          }
                          style={{ pointerEvents: "none" }}
                        />

                        {barItem.index > 0 && (
                          // FYI: this is needed to create the gap between items
                          <Bar
                            x={bar.x}
                            y={bar.y + bar.height - 1}
                            height={1}
                            width={bar.width}
                            fill={
                              shouldBeHighlighted
                                ? "var(--bar-chart-item-gap-color)"
                                : "var(--bar-color-secondary)"
                            }
                          />
                        )}
                      </Fragment>
                    );
                  })
                )
              }
            </BarStack>
            {percentiles && (
              <PercentilesGroup percentiles={percentiles} xMax={xMax} yScale={yScale} />
            )}
          </Group>
        </svg>
      )}

      {tooltipOpen && tooltipData?.data && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          offsetLeft={TOOLTIP_OFFSET.left}
          offsetTop={TOOLTIP_OFFSET.top}
          style={tooltipStyles}
        >
          <ChartTooltip>
            <ChartTooltipTotalHeader
              title={tooltipHeader}
              count={
                formatTooltipValue
                  ? formatTooltipValue(tooltipData.data)
                  : defaultFormatTooltipValue(tooltipData.data)
              }
            />
            {showItemsInTooltip && (
              <>
                <ChartTooltipDivider />
                {[...Object.entries(tooltipData.data)]
                  .reverse()
                  .filter(([, value]) => !!value)
                  .map(([name, value]) => (
                    <ChartTooltipItemValue
                      key={name}
                      color={colorScale(name)}
                      label={name}
                      value={formatValue(value)}
                    />
                  ))}
              </>
            )}
          </ChartTooltip>
        </TooltipInPortal>
      )}
    </Box>
  );
};

BarChart.displayName = "DS.Charts.BarChart.Chart";

export default memo(BarChart);
