import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { getStacksBackUrl } from "views/Stack/helpers";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import ViewHeader from "components/ViewHeader";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import Box from "ds/components/Box";
import WebhookDetailsResponse from "components/WebhookDeliveries/Details/Response";
import { useHideOnScrollContext } from "components/HideOnScroll/HideOnScrollContext";

import { StackSettingsContextData } from "../../Context";
import { GET_STACK_WEBHOOK_DELIVERY_DETAILS } from "./gql";
import StackSettingsLeaveFeedbackButton from "../../components/LeaveFeedbackButton";

const StackSettingsIntegrationsWebhookDelivery = () => {
  const { webhookId, deliveryId } = useParams<{
    webhookId: string;
    deliveryId: string;
  }>();

  const { onError } = useTypedContext(FlashContext);
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const { setScrollableElRef } = useHideOnScrollContext();

  const { loading, data, error } = useQuery(GET_STACK_WEBHOOK_DELIVERY_DETAILS, {
    onError,
    variables: {
      webhookId: webhookId!,
      deliveryId: deliveryId!,
      stackId: stackSettings.id,
    },
    fetchPolicy: "no-cache",
    skip: !webhookId || !deliveryId,
  });

  useTitle(`Delivery · ${deliveryId}`);

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stackSettings.name,
        link: `/stack/${stackSettings.id}`,
      },
      {
        title: "Stack settings",
        link: `/stack/${stackSettings.id}/settings/integrations/webhooks`,
      },
      {
        title: "Recent deliveries",
        link: `/stack/${stackSettings.id}/settings/integrations/webhook/${webhookId}`,
      },
      {
        title: `Delivery ${deliveryId}`,
      },
    ],
    [stackSettings.id, stackSettings.name, webhookId, deliveryId]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return <NotFoundPage />;
  }

  const item = data.stack.integrations?.webhook?.deliveryDetails;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Delivery {deliveryId}</ViewHeaderTitle>
        </ViewHeaderWrapper>
        <ViewHeaderWrapper>
          <StackSettingsLeaveFeedbackButton />
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab to="../request" label="Request" />
          <Tab to="../response" label="Response" />
        </ViewHeaderNavigation>
      </ViewHeader>
      <Box ref={setScrollableElRef} padding="0 x-large" fullWidth direction="column">
        <Routes>
          <Route index element={<Navigate to="request" replace />} />
          <Route
            path="request"
            element={
              <WebhookDetailsResponse headers={item?.requestHeaders} body={item?.requestBody} />
            }
          />
          <Route
            path="response"
            element={
              <WebhookDetailsResponse
                headers={item?.responseHeaders}
                body={item?.responseBody}
                error={item?.responseError}
              />
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default StackSettingsIntegrationsWebhookDelivery;
