import { Line, LinePath } from "@visx/shape";
import { Group } from "@visx/group";

import { CONNECTION_BORDER_RADIUS, CONNECTION_SPACE } from "./constants";
import { Connection } from "./types";
import styles from "./styles.module.css";

type TreeChartConnectionProps = Connection;

const TreeChartConnection = ({ id, start, end }: TreeChartConnectionProps) => {
  return start.y === end.y ? (
    <Line
      className={styles.connection}
      key={id}
      from={start}
      to={end}
      stroke="var(--semantic-color-border-divider)"
      strokeWidth="1"
    />
  ) : (
    <Group key={id}>
      <LinePath
        className={styles.connection}
        d={`M ${start.x} ${start.y} L ${end.x - CONNECTION_BORDER_RADIUS * 2 - CONNECTION_SPACE} ${start.y} C ${end.x - CONNECTION_BORDER_RADIUS * 2 - CONNECTION_SPACE} ${start.y}, ${end.x - CONNECTION_BORDER_RADIUS - CONNECTION_SPACE} ${start.y}, ${end.x - CONNECTION_BORDER_RADIUS - CONNECTION_SPACE} ${start.y + CONNECTION_BORDER_RADIUS} L ${end.x - CONNECTION_BORDER_RADIUS - CONNECTION_SPACE} ${end.y - CONNECTION_BORDER_RADIUS} C ${end.x - CONNECTION_BORDER_RADIUS - CONNECTION_SPACE} ${end.y - CONNECTION_BORDER_RADIUS}, ${end.x - CONNECTION_BORDER_RADIUS - CONNECTION_SPACE} ${end.y}, ${end.x - CONNECTION_SPACE} ${end.y} L ${end.x} ${end.y}`}
        stroke="var(--semantic-color-border-divider)"
        strokeWidth="1"
      />
    </Group>
  );
};

export default TreeChartConnection;
