import TypographyHeading from "../Typography/Heading";

type DrawerHeaderTitleProps = {
  title: string;
};

const DrawerHeaderTitle = ({ title }: DrawerHeaderTitleProps) => {
  return (
    <TypographyHeading variant="p-t4" slot="title">
      {title}
    </TypographyHeading>
  );
};

DrawerHeaderTitle.displayName = "DS.DrawerNew.HeaderTitle";

export default DrawerHeaderTitle;
