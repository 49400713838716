import { useCallback } from "react";

import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";

import { ModuleSettingsContext } from "../Context";

const ModuleSettingsDetailsViewForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);

  const onOpenFullDescription = useCallback(
    () => showFullDescription({ description: module.description }),
    [module.description]
  );
  return (
    <>
      <FormFieldViewText label="Name" value={module.id} />
      <FormFieldViewText label="Provider" value={module.terraformProvider} />
      <FormFieldViewText label="Space" value={module.spaceDetails.name} />
      <FormField label="Labels">
        <TagsList tags={module.labels} alwaysExpanded withEmptyPlaceholder withTopMargin={false} />
      </FormField>
      <DescriptionDetails
        description={module.description}
        onOpenFullDescription={onOpenFullDescription}
      />
    </>
  );
};

export default ModuleSettingsDetailsViewForm;
