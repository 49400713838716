import Button from "ds/components/Button";
import { LogoDatadogColored } from "components/icons/generated";
import Tile from "ds/components/Tile";
import { getDocsUrl } from "utils/getDocsUrl";

const IntegrationsDataDog = () => {
  return (
    <Tile
      title="Datadog"
      icon={LogoDatadogColored}
      indicator={
        <Button
          variant="secondary"
          href={getDocsUrl("/integrations/observability/datadog.html")}
          rel="noopener noreferrer"
          target="_blank"
        >
          Go to docs
        </Button>
      }
      description="Spacelift can send data to Datadog to help you monitor your infrastructure and Spacelift
      stacks using Datadog's excellent monitoring and analytics tools."
    />
  );
};

export default IntegrationsDataDog;
