import { useId, useMemo } from "react";

import { useToggle } from "hooks/useToggle";
import ProgressBar from "ds/components/ProgressBar";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import Box from "ds/components/Box";

import GetStartedChecklistFeatureTile from "./FeatureTile";
import LaunchPadGetStartedChecklistSkeleton from "./Skeleton";
import { LaunchPadFeatureMetadata, LaunchPadsFeatureProgress } from "../../types";

type LaunchPadChecklistLayoutProps<T extends string> = {
  onboardingProgressInitialLoading: boolean;
  features: LaunchPadFeatureMetadata<T>[];
  progressByFeature: LaunchPadsFeatureProgress<T>;
};

const LaunchPadChecklistLayout = <T extends string>({
  onboardingProgressInitialLoading,
  features,
  progressByFeature,
}: LaunchPadChecklistLayoutProps<T>) => {
  const [isCollapsed, handleToggle] = useToggle(false);

  const completedFeaturesCount = useMemo(
    () => features.filter(({ feature }) => progressByFeature[feature].isCompleted).length,
    [features, progressByFeature]
  );

  const titleId = useId();

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader justify="between" padding="x-large" align="center">
        <Box grow="1" align="center" gap="medium">
          <CollapsiblePanelToggleTrigger ariaLevel={2} ariaLabelledby={titleId} />
          <CollapsiblePanelTitle id={titleId} variant="p-t5">
            Get started checklist
          </CollapsiblePanelTitle>
        </Box>
        {!onboardingProgressInitialLoading && (
          <ProgressBar
            ariaLabel="Number of completed features"
            max={features.length}
            current={completedFeaturesCount}
          />
        )}
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="0 x-large x-large x-large" gap="large">
        {onboardingProgressInitialLoading ? (
          <LaunchPadGetStartedChecklistSkeleton features={features} />
        ) : (
          features.map(({ feature, title, url, icon, analyticsTitle }) => (
            <GetStartedChecklistFeatureTile
              key={feature}
              title={title}
              to={url}
              icon={icon}
              isCompleted={progressByFeature[feature].isCompleted}
              analyticsTitle={analyticsTitle}
            />
          ))
        )}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadChecklistLayout;
