import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WebhooksIntegrationInput } from "types/generated";

import { UPDATE_WEBHOOK_INTEGRATION } from "./gql";

type UseUpdateWebhookProps = {
  refetchQueries?: string[];
};

type HandleUpdateWebhook = {
  webhookId: string;
  entityId: string; //* Stack or Module ID
  input: WebhooksIntegrationInput;
  successCallback?: () => void;
};

const useUpdateWebhook = ({ refetchQueries }: UseUpdateWebhookProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updateWebhook, { loading }] = useMutation(UPDATE_WEBHOOK_INTEGRATION, {
    refetchQueries,
    onError,
  });

  const handleUpdateWebhook = useCallback(
    ({ webhookId, entityId, input, successCallback }: HandleUpdateWebhook) => {
      updateWebhook({
        variables: {
          id: webhookId,
          entityId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.webhooksIntegrationUpdate?.id) {
            reportSuccess({
              message: `Webhook integration was successfully updated`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, updateWebhook]
  );

  return {
    updateWebhook: handleUpdateWebhook,
    loading,
  };
};

export default useUpdateWebhook;
