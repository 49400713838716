import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { FilterItem } from "components/Filters/types";
import { getLabelFilterName } from "components/Filters/helpers";
import { FiltersContext } from "components/Filters";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPage } from "hooks/useAnalytics";

import { checkLabelFilter } from "../helpers";
import FilterSectionContent from "./FilterSectionContent";

type FilterSectionProps = {
  analyticsPage?: AnalyticsPage;
  filterItem: FilterItem;
  isLastLabelsFilter: boolean;
};

const FilterSection = ({ filterItem, isLastLabelsFilter, analyticsPage }: FilterSectionProps) => {
  const {
    activeFilters,
    setGenericFilter,
    reMountLabelGroups,
    toggleNewLabelGroup,
    isNewLabelGroupOpen,
    setOpenSections,
    shouldExpandSections,
    disabledFilters,
  } = useTypedContext(FiltersContext);

  const activeFilter = useMemo(
    () => activeFilters.get(filterItem.key),
    [activeFilters, filterItem.key]
  );

  const [isOpen, setIsOpen] = useState(false);

  const hasChosenOptions = activeFilters.has(filterItem.key);
  const isLabelFilter = checkLabelFilter(filterItem.filterName);

  const filterName =
    (isLabelFilter && getLabelFilterName(filterItem.key, isLastLabelsFilter)) || filterItem.key;

  const toggleSectionOpen = useCallback(() => {
    const newValue = !isOpen;
    setIsOpen(newValue);
  }, [isOpen]);

  const addAnotherLabelFilter = useCallback(() => {
    const labelCounter = Number(filterItem.key.replace("label", "")) + 1;
    const newKey = "label" + labelCounter.toString();

    setGenericFilter({
      ...filterItem,
      key: newKey,
    });

    setIsOpen(false);
    toggleNewLabelGroup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItem]);

  useEffect(() => {
    setOpenSections(filterItem.filterName, isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (typeof shouldExpandSections === "boolean") {
      setIsOpen(shouldExpandSections);
    }
  }, [shouldExpandSections]);

  // auto open new created labels group
  useEffect(() => {
    if (isLabelFilter && isLastLabelsFilter && isNewLabelGroupOpen) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reMountLabelGroups]);

  // avoid re-rendering on the Context change
  return useMemo(() => {
    return (
      <FilterSectionContent
        isOpen={isOpen}
        isActive={Boolean(hasChosenOptions && activeFilter)}
        key={isLabelFilter && reMountLabelGroups ? 1 : 0}
        activeFilter={activeFilter}
        isLabelFilter={isLabelFilter}
        filterName={filterName}
        filterItem={filterItem}
        toggleSectionOpen={toggleSectionOpen}
        isLastLabelsFilter={isLastLabelsFilter}
        addAnotherLabelFilter={addAnotherLabelFilter}
        analyticsPage={analyticsPage}
        disabled={disabledFilters?.includes(filterItem.key)}
      />
    );
  }, [
    activeFilter,
    addAnotherLabelFilter,
    analyticsPage,
    filterItem,
    filterName,
    hasChosenOptions,
    isLabelFilter,
    isLastLabelsFilter,
    isOpen,
    reMountLabelGroups,
    toggleSectionOpen,
    disabledFilters,
  ]);
};

export default memo(FilterSection);
