import Typography from "../Typography";

type DrawerHeaderTitleProps = {
  title: string;
};

const DrawerHeaderTitle = ({ title }: DrawerHeaderTitleProps) => {
  return (
    <Typography tag="h4" variant="p-t4">
      {title}
    </Typography>
  );
};

DrawerHeaderTitle.displayName = "DS.Drawer.HeaderTitle";

export default DrawerHeaderTitle;
