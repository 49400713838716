import useTypedContext from "hooks/useTypedContext";
import { SelfHostedLicenseContext } from "views/SelfHosted/LicenseProvider/Context";
import { pluralize } from "shared/Pluralize";
import { LicenseExpirationStatus } from "views/SelfHosted/LicenseProvider/types";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import { AccountContext } from "views/AccountWrapper";
import Feedback, { FeedbackProps } from "ds/components/Feedback";

import { parseLicenseDate } from "./utils";

const CONTACT_US_EMAIL = "cs@spacelift.io";

const getLicenseExpirationStatusProps = (
  endDate?: string,
  remainingDays?: number,
  licenseExpirationStatus?: LicenseExpirationStatus
): { title: string; variant: FeedbackProps["variant"] } | null => {
  if (!endDate || remainingDays === undefined) {
    return null;
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.EXPIRED) {
    return {
      variant: "danger",
      title: `Your license expired on ${endDate}`,
    };
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.LAST_DAY) {
    return {
      variant: "danger",
      title: "Your license expires today",
    };
  }
  if (
    licenseExpirationStatus === LicenseExpirationStatus.IN_2_WEEKS ||
    licenseExpirationStatus === LicenseExpirationStatus.IN_2_MONTHS
  ) {
    return {
      variant: "warning",
      title: `Your license will expire in ${remainingDays} ${pluralize("day", remainingDays)} (${endDate})`,
    };
  }

  return null;
};

type AccountDetailsDrawerSelfHostedLicenseWarningBannerProps = {
  type: FeedbackProps["type"];
  show2MonthsWarning?: boolean;
  storageKey?: string;
};

const SelfHostedLicenseWarning = ({
  type,
  show2MonthsWarning,
  storageKey,
}: AccountDetailsDrawerSelfHostedLicenseWarningBannerProps) => {
  const { licenseExpirationStatus, remainingDays, debugInfo } =
    useTypedContext(SelfHostedLicenseContext);
  const validityEndDate = debugInfo?.selfHostedLicence?.validity.end || undefined;

  const { viewer } = useTypedContext(AccountContext);

  const properties = getLicenseExpirationStatusProps(
    parseLicenseDate(validityEndDate),
    remainingDays,
    licenseExpirationStatus
  );

  if (!properties) {
    return null;
  }

  if (!show2MonthsWarning && licenseExpirationStatus === LicenseExpirationStatus.IN_2_MONTHS) {
    return null;
  }

  const allowDismiss = !!storageKey && licenseExpirationStatus !== LicenseExpirationStatus.EXPIRED;

  return (
    <Feedback
      type={type}
      title={properties.title}
      variant={properties.variant}
      storageKey={allowDismiss ? storageKey : undefined}
      withPermanentCloseConfirmation={allowDismiss}
    >
      Please contact us to renew your contract.
      <FeedbackActions>
        {viewer.admin && (
          <Button href={`mailto:${CONTACT_US_EMAIL}`} variant="contrast" size="small">
            Contact us
          </Button>
        )}
      </FeedbackActions>
    </Feedback>
  );
};

export default SelfHostedLicenseWarning;
