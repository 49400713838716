import { useMemo } from "react";

import { SideNavigationItemType, NavigationConfigType } from "types/Navigation";
import { VCS_INTEGRATIONS_PATH } from "views/Account/VCS/constants";
import {
  CloudArrows,
  CodeBrowser,
  DataFlow,
  GridTilesMasonry,
  Pickaxe,
  Package,
  Play,
  Policy,
  Puzzle,
  Space,
  Stack,
  Webhook,
  GitBranch,
} from "components/icons/generated";

type UseNavigationConfigProps = {
  isAdmin: boolean;
};

const useNavigationConfig = ({ isAdmin }: UseNavigationConfigProps): NavigationConfigType => {
  return useMemo<NavigationConfigType>(
    () => ({
      ...(isAdmin
        ? {
            [SideNavigationItemType.Dashboard]: {
              title: "Dashboard",
              icon: GridTilesMasonry,
              to: "/dashboard",
              rule: ["/dashboard"],
            },
          }
        : {}),
      [SideNavigationItemType.Stacks]: {
        title: "Stacks",
        icon: Stack,
        to: "/stacks",
        rule: ["/stack", "/new/stack"],
        id: () => "stacks-navigation-item",
      },
      [SideNavigationItemType.Blueprints]: {
        title: "Blueprints",
        icon: CodeBrowser,
        to: "/blueprints",
        rule: ["/blueprint"],
      },
      [SideNavigationItemType.Modules]: {
        icon: Package,
        title: "Terraform registry",
        to: "/modules",
        rule: ["/module", "/provider", "/gpg-key", "/new/module", "/shared-modules"],
      },
      [SideNavigationItemType.Runs]: {
        title: "Runs",
        icon: Play,
        to: "/runs",
        rule: ["/runs"],
      },
      [SideNavigationItemType.Spaces]: {
        title: "Spaces",
        icon: Space,
        to: "/spaces",
        rule: ["/spaces"],
      },
      [SideNavigationItemType.Resources]: {
        title: "Resources",
        icon: DataFlow,
        to: "/resources",
        id: (hasAnsibleStacks?: boolean) =>
          hasAnsibleStacks ? "resources-with-configuration-management" : undefined,
        rule: ["/resources"],
      },
      [SideNavigationItemType.Policies]: {
        title: "Policies",
        icon: Policy,
        to: "/policies",
        rule: ["/policy", "/new/policy", "/policies"],
      },
      [SideNavigationItemType.Contexts]: {
        title: "Contexts",
        icon: Puzzle,
        to: "/contexts",
        rule: ["/context", "/new/context"],
      },
      [SideNavigationItemType.WorkerPools]: {
        title: "Worker pools",
        icon: Pickaxe,
        to: "/worker-pools",
        rule: ["/worker-pool"],
      },
      [SideNavigationItemType.Webhooks]: {
        title: "Webhooks",
        icon: Webhook,
        to: "/webhooks",
        rule: ["/webhook"],
      },
      [SideNavigationItemType.VCSManagement]: {
        title: "Source code",
        icon: GitBranch,
        to: VCS_INTEGRATIONS_PATH,
        rule: ["/vcs-agent-pool", "/new/vcs-agent-pool", "/vcs/"],
      },
      [SideNavigationItemType.CloudIntegrations]: {
        title: "Cloud integrations",
        icon: CloudArrows,
        to: "/cloud-integrations",
        rule: ["/cloud-integrations"],
      },
    }),
    [isAdmin]
  );
};

export default useNavigationConfig;
