import { ReactNode } from "react";
import { ListBoxItem } from "react-aria-components";

import styles from "./styles.module.css";
import ComboBoxHighlightText from "./HighlightText";

type ComboBoxItemProps = {
  id: string;
  label: string;
  suffix?: ReactNode;
};

const ComboBoxItem = ({ id, label, suffix }: ComboBoxItemProps) => {
  return (
    <ListBoxItem className={styles.listBoxItem} id={id} textValue={label}>
      <ComboBoxHighlightText text={label} />
      {suffix}
    </ListBoxItem>
  );
};

ComboBoxItem.displayName = "DS.ComboBox.Item";

export default ComboBoxItem;
