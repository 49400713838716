import cx from "classnames";
import { forwardRef } from "react";
import { FocusScope } from "react-aria";

import { Cross, Expand, SparksFilled, ThumbDown, ThumbUp } from "components/icons/generated";
import Markdown from "components/markdown/Markdown";
import Box from "ds/components/Box";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import Typography from "ds/components/Typography";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import { useToggle } from "hooks/useToggle";

import { LoaderAiSummary } from "../LoaderAiSummary";
import BoxHeader from "./BoxHeader";
import SaturnheadBubble from "./SaturnheadBubble";
import styles from "./styles.module.css";

type RunSummarizationBoxProps = {
  loading?: boolean;
  content?: string;
  date?: number;
  type: "run" | "phase";
};

const EMPTY_SUMMARY = "Looks like we couldn't generate a response for this run.";

const RunSummarizationBox = forwardRef<HTMLDivElement, RunSummarizationBoxProps>(
  function RunSummarizationBox({ content, date, loading, type }, externalRef) {
    const [fullScreen, toggleFullScreen] = useToggle(false);
    const [feedbackComplete, setFeedbackComplete] = useToggle(false);

    const onFeedbackClick = () => {
      setFeedbackComplete(true);
    };

    useEscapeKeypress(fullScreen, toggleFullScreen);

    if (loading) {
      return (
        <Box
          className={styles.runSummary}
          direction="column"
          align="center"
          justify="center"
          gap="large"
          style={{ minHeight: "20rem" }}
          fullWidth
        >
          <LoaderAiSummary />
        </Box>
      );
    }

    return (
      <FocusScope contain={fullScreen}>
        <Box
          className={cx(styles.runSummary, { [styles.fullScreen]: fullScreen })}
          fullWidth
          direction="column"
          gap="large"
        >
          <Box className={styles.summaryControls} gap="medium">
            {fullScreen ? (
              <ButtonIconNew
                icon={Cross}
                variant="secondary"
                onPress={() => toggleFullScreen(false)}
                size="small"
                aria-expanded={fullScreen}
              >
                Exit full screen mode
              </ButtonIconNew>
            ) : (
              <>
                <ButtonIconNew
                  icon={Expand}
                  variant="secondary"
                  onPress={() => toggleFullScreen(true)}
                  size="small"
                  aria-expanded={fullScreen}
                >
                  Full screen mode
                </ButtonIconNew>
              </>
            )}
          </Box>

          <BoxHeader title="AI summary" icon={SparksFilled} date={date} />

          <Box className={styles.bodyWrapper} ref={externalRef} scrollable fullWidth gap="large">
            <SaturnheadBubble />
            {/* tabIndex required for accessibility purposes. Scrollable elements should be keyboard accessible. */}
            <Box className={styles.bodyContent} scrollable fullWidth tabIndex={0}>
              <Markdown markup={content ?? EMPTY_SUMMARY} className={styles.summaryText} />
            </Box>
          </Box>
          <Box className={styles.feedbackFooter} gap="large" align="center">
            {feedbackComplete ? (
              <Typography variant="p-body2" tag="span">
                Thank you for your feedback!
              </Typography>
            ) : (
              <>
                <Typography variant="p-body2" tag="span">
                  Was this response useful?
                </Typography>
                <ButtonIconNew
                  variant="ghost"
                  icon={ThumbUp}
                  onPress={onFeedbackClick}
                  analyticsTitle="Summary Feedback"
                  analyticsProps={{ feedback: "yes", type }}
                >
                  Yes
                </ButtonIconNew>
                <ButtonIconNew
                  variant="ghost"
                  icon={ThumbDown}
                  onPress={onFeedbackClick}
                  analyticsTitle="Summary Feedback"
                  analyticsProps={{ feedback: "no", type }}
                >
                  No
                </ButtonIconNew>
              </>
            )}
          </Box>
        </Box>
      </FocusScope>
    );
  }
);

export default RunSummarizationBox;
