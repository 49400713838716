import { useCallback, useMemo } from "react";

import { SEARCH_STACK_SUGGESTIONS_DICTIONARY, StackSuggestions } from "constants/stack";
import { getCommmitDigitsCount } from "utils/commit";
import { generateRepoUrlText } from "utils/urls";
import { Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { ApplyFiltersContext } from "components/Filters";

import { STACKS_COLUMN_ID } from "../constants";
import usePredefinedFilters, { PredefinedFilterType } from "../usePredefinedFilters";
import useStackListAnalytics from "../useStackListAnalytics";

const ADD_TO_FILTERS = "Add to filters";

export const STACK_COLUMNS_WITH_ACTIONS = [
  STACKS_COLUMN_ID.STATE,
  STACKS_COLUMN_ID.SPACE,
  STACKS_COLUMN_ID.REPOSITORY,
  STACKS_COLUMN_ID.PROJECT_ROOT,
  STACKS_COLUMN_ID.BRANCH,
  STACKS_COLUMN_ID.COMMIT,
] as const;

export type StackColumnWithActions = (typeof STACK_COLUMNS_WITH_ACTIONS)[number];

type StackColumnActionsMeta = {
  title: string;
  action: () => void;
};

const useCellActionsConfig = (
  stack: Stack
): Record<StackColumnWithActions, StackColumnActionsMeta[] | undefined> => {
  const { applySpaceFilter, applyFilter } = useTypedContext(ApplyFiltersContext);
  const { predefinedFilterTab } = usePredefinedFilters();
  const trackAnalytics = useStackListAnalytics();

  const trackFilterClickedAnalytics = useCallback(
    (type: string) => {
      trackAnalytics("Add to filters clicked", { type });
    },
    [trackAnalytics]
  );

  const cellActionsConfig = useMemo(() => {
    const commitValue = stack.trackedCommit?.hash.slice(0, getCommmitDigitsCount(stack.provider));
    const isFilterStateTabUnactivated =
      !predefinedFilterTab || predefinedFilterTab === PredefinedFilterType.AllStacks;
    return {
      [STACKS_COLUMN_ID.STATE]: isFilterStateTabUnactivated
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(
                  StackSuggestions.State,
                  SEARCH_STACK_SUGGESTIONS_DICTIONARY
                )(stack.state);
                trackFilterClickedAnalytics(StackSuggestions.State);
              },
            },
          ]
        : undefined,
      [STACKS_COLUMN_ID.SPACE]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applySpaceFilter(
              StackSuggestions.Space,
              SEARCH_STACK_SUGGESTIONS_DICTIONARY
            )(stack.spaceDetails.id);
            trackFilterClickedAnalytics(StackSuggestions.Space);
          },
        },
      ],
      [STACKS_COLUMN_ID.REPOSITORY]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applyFilter(
              StackSuggestions.Repository,
              SEARCH_STACK_SUGGESTIONS_DICTIONARY
            )(
              generateRepoUrlText({
                namespace: stack.namespace,
                repository: stack.repository,
              })
            );
            trackFilterClickedAnalytics(StackSuggestions.Repository);
          },
        },
      ],
      [STACKS_COLUMN_ID.PROJECT_ROOT]: stack.projectRoot
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(
                  StackSuggestions.ProjectRoot,
                  SEARCH_STACK_SUGGESTIONS_DICTIONARY
                )(stack.projectRoot!);
                trackFilterClickedAnalytics(StackSuggestions.ProjectRoot);
              },
            },
          ]
        : undefined,
      [STACKS_COLUMN_ID.BRANCH]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applyFilter(StackSuggestions.Branch, SEARCH_STACK_SUGGESTIONS_DICTIONARY)(stack.branch);
            trackFilterClickedAnalytics(StackSuggestions.Branch);
          },
        },
      ],
      [STACKS_COLUMN_ID.COMMIT]: commitValue
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(
                  StackSuggestions.Commit,
                  SEARCH_STACK_SUGGESTIONS_DICTIONARY
                )(commitValue);
                trackFilterClickedAnalytics(StackSuggestions.Commit);
              },
            },
          ]
        : undefined,
    };
  }, [
    applySpaceFilter,
    applyFilter,
    stack.branch,
    stack.namespace,
    stack.projectRoot,
    stack.provider,
    stack.repository,
    stack.spaceDetails.id,
    stack.state,
    stack.trackedCommit?.hash,
    trackFilterClickedAnalytics,
    predefinedFilterTab,
  ]);

  return cellActionsConfig;
};

export default useCellActionsConfig;
