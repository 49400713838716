import { useForm, FormProvider, Controller } from "react-hook-form";
import { useEffect } from "react";
import { NetworkStatus } from "@apollo/client";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FormToggleField from "ds/components/Form/ToggleField";
import MissingDataBanner from "components/MissingDataBanner";
import FormLoading from "components/form/components/loading";
import { isSelfHostedDistribution } from "utils/distribution";
import Select from "ds/components/Select";
import Box from "ds/components/Box";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import { getDocsUrl } from "utils/getDocsUrl";
import useTypedFlags from "hooks/useTypedFlags";
import StackEnableSensitiveOutputUploadFormField from "components/Forms/Stack/EnableSensitiveOutputUploadFormField";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { useFormValidations } from "hooks";

import NewStackFooter from "../Footer";
import { StackFormContext } from "../context";
import { StackBehaviourFormFields, StackCreationVendor, StackCreationWizardStep } from "../types";
import {
  validateRequired,
  getSwitchTooggleAnalyticsProps,
  getTooltipAnalyticsProps,
} from "../utils";
import Documentation from "./Documentation";
import { useWorkerPoolOptions } from "./useWorkerPoolOptions";
import useErrorHandlerNewStack from "../useErrorHandlerNewStack";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";

const isSelfHosted = isSelfHostedDistribution();

const getWorkerPoolInitialData = (workerPool: string) => {
  if (isSelfHosted && workerPool === WORKER_POOL_SHARED_VALUE) {
    return "";
  }

  return workerPool;
};

const NewStackBehaviour = () => {
  const { showLeakingSensitiveOutputsThroughDependencies } = useTypedFlags();

  const { currentStep, updateStepData, formData, setInternalFormData, internalData } =
    useTypedContext(StackFormContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const stepData = formData[StackCreationWizardStep.Behaviour];

  const { workerPoolsOptions, loading, hasData, error, refetch, networkStatus } =
    useWorkerPoolOptions(formData[StackCreationWizardStep.Details].space);

  useErrorHandlerNewStack(error);

  const initialWorkerPool = getWorkerPoolInitialData(stepData.workerPool);

  const builderForm = useForm<StackBehaviourFormFields>({
    defaultValues: {
      ...stepData,
      workerPool: initialWorkerPool,
    },
    mode: "onChange",
  });

  const { control, setValue, watch } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const newStepData = watch();

  const processStepData = () => {
    const workerPoolOption = workerPoolsOptions.find(
      ({ value }) => value === newStepData.workerPool
    );
    setInternalFormData({ ...internalData, workerPoolLabel: workerPoolOption?.label });

    return updateStepData(currentStep, newStepData);
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  const selectedWorkerPool = watch("workerPool");
  useEffect(() => {
    if (!selectedWorkerPool) {
      if (isSelfHosted && workerPoolsOptions?.length) {
        setValue("workerPool", workerPoolsOptions[0].value, { shouldValidate: true });
      }
    } else if (hasData || (!loading && !hasData)) {
      // validate worker pool shared via link
      const isNotAvailable = !workerPoolsOptions.find(({ value }) => {
        return value === selectedWorkerPool;
      });
      if (isNotAvailable) {
        setValue("workerPool", "");
      }
    }
  }, [workerPoolsOptions, setValue, selectedWorkerPool, hasData, loading]);

  const handleWorkerPoolChange = (workerPool: string) => {
    setValue("workerPool", workerPool, { shouldValidate: true });

    if (workerPool === WORKER_POOL_SHARED_VALUE) {
      setValue("autoretry", false);
    }
  };

  const isPulumiVendor =
    formData[StackCreationWizardStep.Vendor]?.vendor === StackCreationVendor.Pulumi;

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <FormLoading />;
  }

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Define behavior {!isPulumiVendor && !isSelfHosted && "(optional)"}
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Define additional stack settings
        </Typography>
        <Box margin="x-large 0 large 0">
          <FormProvider {...builderForm}>
            <Box direction="column" gap="large">
              <Controller
                name="workerPool"
                rules={{
                  required: "Worker pool is required",
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    label="Worker pool"
                    tooltipInfoVariant="modal"
                    {...getTooltipAnalyticsProps("Behavior", "Worker pool", {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Worker pool</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            By using a private worker pool you make sure that you have full control
                            over your infrastructure changes, and can even access resources that
                            would otherwise not be reachable from the public internet.
                          </Typography>
                          <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/worker-pools")} />
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {({ ariaInputProps }) => (
                      <>
                        <Select
                          disabled={workerPoolsOptions.length === 1}
                          value={field.value}
                          options={workerPoolsOptions}
                          onChange={handleWorkerPoolChange}
                          error={!!fieldState.error?.message}
                          placeholder="Select worker pool"
                          ariaInputProps={ariaInputProps}
                          ref={field.ref}
                        />
                        {!hasData && (
                          <Box direction="column" margin="large 0 0 0">
                            <MissingDataBanner
                              text="Couldn't load your private worker pools, please refresh or come back later"
                              refreshHandler={refetch}
                              refreshLoading={loading && networkStatus === NetworkStatus.refetch}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </FormField>
                )}
              />

              <Controller
                name="runnerImage"
                control={control}
                rules={isPulumiVendor ? { validate: validateRequired("Runner image") } : undefined}
                render={({ field, fieldState }) => (
                  <FormField
                    label="Runner image"
                    error={fieldState.error?.message}
                    isOptional={!isPulumiVendor}
                    noMargin
                    tooltipInfoVariant="modal"
                    {...getTooltipAnalyticsProps("Behavior", "Runner image", {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Runner image</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Every Spacelift job is executed in a separate Docker container, setting
                            a custom runner image provides a convenient way to prepare the exact
                            runtime environment your infra-as-code flow is designed to use.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl("/concepts/stack/stack-settings#runner-image")}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {({ ariaInputProps }) => (
                      <Input
                        error={!!fieldState.error}
                        type="text"
                        value={field.value}
                        placeholder="Runner image"
                        onChange={field.onChange}
                        {...ariaInputProps}
                      />
                    )}
                  </FormField>
                )}
              />
              <Controller
                name="administrative"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Administrative"
                    description="Stack will receive a runtime environment variable giving administrative access to other stacks within the same account"
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Administrative", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Administrative stack</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Administrative stacks can create, update and destroy Spacelift
                            resources.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl("/concepts/stack/stack-settings#administrative")}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="githubActionDeploy"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Allow run promotion"
                    description="Proposed runs can be promoted (deployed) using the Spacelift API or GitHub Actions"
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Allow run promotion", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Allow run promotion</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Indicates whether proposed runs can be promoted (deployed) using the
                            Spacelift API or GitHub Actions.
                          </Typography>
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="autodeploy"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Autodeploy"
                    description="Changes to the stack will be applied automatically"
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Autodeploy", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Autodeploy</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Consider setting it to true when you always do a code review before
                            merging to the tracked branch, and/or want to rely on plan policies.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl("/concepts/stack/stack-settings#autodeploy")}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="autoretry"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    disabled={selectedWorkerPool === WORKER_POOL_SHARED_VALUE}
                    onChange={field.onChange}
                    checked={field.value}
                    title="Autoretry"
                    description="Tracked runs will automatically retry invalidated proposed runs"
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Autoretry", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Autoretry</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Indicates whether tracked runs will automatically retry invalidated
                            proposed runs.
                          </Typography>

                          <Typography tag="p" variant="p-body3">
                            When set to true, any proposed runs on branches will be retried as soon
                            as they are invalidated by a tracked run.
                          </Typography>

                          <Typography tag="p" variant="p-body3">
                            Autoretry is only available on stacks using private workers.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl("/concepts/stack/stack-settings#autoretry")}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="localPreviewEnabled"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Enable local preview"
                    description="Run local previews using spacectl."
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Enable local preview", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Local preview</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            When set to true, proposed runs can be created based on user-uploaded
                            local workspaces. This way you can preview how your code changes will
                            execute without creating a commit.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl(
                              "/concepts/stack/stack-settings#enable-local-preview"
                            )}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="enableWellKnownSecretMasking"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Enable secret masking"
                    description="Indicates whether secret patterns will be automatically redacted from logs."
                    {...getSwitchTooggleAnalyticsProps(
                      "Behavior",
                      "enableWellKnownSecretMasking",
                      true,
                      {
                        version: analyticsVersion,
                      }
                    )}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Secret masking</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Indicates whether secret patterns will be automatically redacted from
                            logs. For full list of the supported patterns refer to the
                            documentation.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl(
                              "/concepts/stack/stack-settings#enable-well-known-secret-masking"
                            )}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="protectFromDeletion"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Protect from deletion (recommended)"
                    description="Manage deletion protection"
                    {...getSwitchTooggleAnalyticsProps("Behavior", "Protect from deletion", true, {
                      version: analyticsVersion,
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Protect from deletion</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Deletion protection helps protect your stacks from accidental deletion.
                            When enabled, any attempts to delete your stack will fail.
                          </Typography>
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              {showLeakingSensitiveOutputsThroughDependencies && (
                <StackEnableSensitiveOutputUploadFormField<StackBehaviourFormFields>
                  name="enableSensitiveOutputUpload"
                  {...getSwitchTooggleAnalyticsProps(
                    "Behavior",
                    "Transfer sensitive outputs across dependencies",
                    true,
                    {
                      version: analyticsVersion,
                    }
                  )}
                />
              )}
            </Box>
          </FormProvider>
        </Box>
      </FullScreenModalBody>
      <NewStackFooter
        isDataChanged={isDataChanged}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/concepts/stack/creating-a-stack#define-behavior")}
        documentationTitle="Define behaviour"
        documentationBody={<Documentation />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewStackBehaviour;
