import { useCallback } from "react";

import Button from "../Button";
import { useIsDismissable } from "../Modal/useIsDismissable";

export type ConfirmationModalConfirmButtonProps = {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  variant?: "dangerPrimary" | "primary";
};

const ConfirmationModalConfirmButton = ({
  onClick,
  label,
  isLoading,
  isDisabled,
  variant = "dangerPrimary",
}: ConfirmationModalConfirmButtonProps) => {
  const isDismissable = useIsDismissable();
  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <Button
      variant={variant}
      onClick={handleClick}
      loading={isLoading}
      disabled={!isDismissable || isDisabled}
    >
      {label}
    </Button>
  );
};

ConfirmationModalConfirmButton.displayName = "DS.ConfirmationModal.ConfirmButton";

export default ConfirmationModalConfirmButton;
