import cx from "classnames";
import { useCallback } from "react";

import Box from "ds/components/Box";
import { ChevronRight } from "components/icons/generated";
import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";
import { useToggle } from "hooks/useToggle";

import styles from "./styles.module.css";
import { GpgKeysListSectionProps } from "./types";

function GpgKeysListSection({ title, count, children }: GpgKeysListSectionProps) {
  const [isCollapsed, toggle] = useToggle(false);
  const handleToggle = useCallback(() => toggle(), [toggle]);

  return (
    <Box
      direction="column"
      className={cx(styles.root, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      <BaseAction onClick={handleToggle} className={styles.button}>
        <Icon src={ChevronRight} className={styles.chevron} />
        <Typography variant="p-t6" tag="span">
          {title}
        </Typography>
        <Counter count={count} />
      </BaseAction>

      <Box className={styles.collapsible} grid gridTemplate="1fr" aria-hidden={isCollapsed}>
        <div role="table" aria-label={title} className={styles.collapsibleContent}>
          {children}
        </div>
      </Box>
    </Box>
  );
}

export default GpgKeysListSection;
