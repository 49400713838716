/* eslint react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as d3 from "d3";

import { getKeyValue } from "../helpers";

// getRectPosition calculates the [x,y] offset of a rectangle in a grid.
export const getRectPosition = (i, rectWidth, rectHeight, lineWidth) => {
  const lineRects = Math.floor(lineWidth / rectWidth);
  const line = Math.floor(i / lineRects);
  const x = (i % lineRects) * rectWidth;
  const y = line * rectHeight;
  return [x, y];
};

// groupBy creates a mapping {x -> [element | element.key == x]}.
export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    const keyValue = getKeyValue(key, x);
    (rv[keyValue] = rv[keyValue] || []).push(x);
    return rv;
  }, {});
};

// getIdText creates an html id tag value based on the entity's ID.
export const getIdText = function (s) {
  return `entity-${s.replace(/[^a-zA-Z0-9]+/g, "-")}`;
};

// zoomIntoSubtree filters the entities to those being in the rootId subtree.
// Also returns the parent node id of the zoomed in node, as well as the id of the root of all entities.
export const zoomIntoSubtree = (entities, rootId) => {
  if (entities.length === 0) return [];

  const entitiesById = {};
  let rootParentId;
  for (const entity of entities) {
    entitiesById[entity.id] = entity;
    if (entity.id === rootId) {
      rootParentId = entity.parent;
    }
  }

  const treeNodes = [];

  for (const entity of entities) {
    // Don't include entities which have no parents as that would
    // break the entire resource chart screen as we can't stratify them.
    if (entity.parent !== "" && !entitiesById[entity.parent]) {
      continue;
    }
    treeNodes.push({ id: entity.id, name: entity.name, parent: entity.parent });
  }

  const stratified = d3
    .stratify()
    .id((d) => d.id)
    .parentId((d) => d.parent)(treeNodes);

  if (rootId === "") {
    return [entities, "", stratified.id];
  }

  const subtree = findSubtree(stratified, rootId);
  const outEntities = subtree.descendants().map((d) => {
    const id = d.data.id;
    return entitiesById[id];
  });

  const rootEntityParent = JSON.parse(JSON.stringify(entitiesById[rootParentId]));
  rootEntityParent.parent = "";
  outEntities.push(rootEntityParent);

  return [outEntities, rootEntityParent.id, stratified.id];
};

// findSubtree finds the subtree rooted in id in the stratified tree node.
function findSubtree(node, id) {
  if (node.data.id === id) {
    return node;
  }
  if (node.children) {
    for (const child of node.children) {
      const subtree = findSubtree(child, id);
      if (subtree) {
        return subtree;
      }
    }
  }
  return null;
}

// getVisibleTreeNodes filters the tree nodes to only those which are visible.
// only tree nodes which have an entity in their subtree are visible.
export const getVisibleTreeNodes = (allEntities, visibleEntities) => {
  const visibleGroupedByParent = groupBy(visibleEntities, "parent");

  if (allEntities.length === 0) return {};
  if (visibleEntities.length === 0) return {};

  const entitiesById = new Set(allEntities.map((e) => e.id));
  const treeNodes = [];
  for (const entity of allEntities) {
    if (entity.parent !== "" && !entitiesById.has(entity.parent)) {
      continue;
    }

    treeNodes.push({ id: entity.id, name: entity.name, parent: entity.parent });
  }

  const stratified = d3
    .stratify()
    .id((d) => d.id)
    .parentId((d) => d.parent)(treeNodes);

  const visibleParents = {};
  getVisibleParents(visibleGroupedByParent, visibleParents, stratified);

  return visibleParents;
};

// getVisibleParents walks the tree and fills visibleParents with nodes which are visible.
function getVisibleParents(visibleGroupedByParent, visibleParents, node) {
  let selfVisible = false;
  if (node.data.id in visibleGroupedByParent) {
    selfVisible = true;
  }

  let anyChildVisible = false;
  if (node.children) {
    for (const child of node.children) {
      anyChildVisible =
        getVisibleParents(visibleGroupedByParent, visibleParents, child) || anyChildVisible;
    }
  }
  if (anyChildVisible) {
    selfVisible = true;
  }

  if (selfVisible) {
    visibleParents[node.data.id] = true;
  }
  return selfVisible;
}
