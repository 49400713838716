import { gql } from "@apollo/client";

export const GET_PHASE_SUMMARIZATION = gql`
  mutation GetPhaseSummarization($stack: ID!, $run: ID!, $stateVersion: Int!) {
    summarizeRunState(stack: $stack, run: $run, stateVersion: $stateVersion) {
      summary
    }
  }
`;

export const GET_RUN_SUMMARIZATION = gql`
  mutation GetRunSummarization($stack: ID!, $run: ID!) {
    summarizeRun(stack: $stack, run: $run) {
      summary
    }
  }
`;
