import { useEffect, useRef } from "react";

type UseFocusOnEnterOptions = {
  shouldFocusOnEnter?: boolean;
};
const useFocusOnEnter = ({ shouldFocusOnEnter = true }: UseFocusOnEnterOptions = {}) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldFocusOnEnter) {
      return;
    }
    const focusableElements = bodyRef.current?.querySelectorAll<HTMLElement>(
      'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
    );
    focusableElements?.[0]?.focus();
  }, [shouldFocusOnEnter]);

  return bodyRef;
};

export default useFocusOnEnter;
