import cx from "classnames";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type PopoverActionBarProps = {
  children: React.ReactNode;
  className?: string;
};

const PopoverActionBar = ({ children, className }: PopoverActionBarProps) => {
  return (
    <Box
      direction="row"
      justify="center"
      className={cx(styles.controls, className)}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Box>
  );
};

PopoverActionBar.displayName = "DS.PopoverActionBar";

export default PopoverActionBar;
