import { forwardRef } from "react";
import type { HeadingProps as AriaHeadingProps } from "react-aria-components";
import { HeadingContext, useContextProps } from "react-aria-components";

import Typography, { TypographyProps } from ".";

type HeadingProps = AriaHeadingProps & Omit<TypographyProps, "tag">;
type TagType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const TypographyHeading = forwardRef(function Heading(
  props: HeadingProps,
  ref: React.ForwardedRef<HTMLHeadingElement>
) {
  [props, ref] = useContextProps(props, ref, HeadingContext);

  const { children, level = 3, className, ...domProps } = props;
  const tag = `h${level}` as TagType;

  return (
    <Typography {...domProps} tag={tag} ref={ref} className={className}>
      {children}
    </Typography>
  );
});

TypographyHeading.displayName = "DS.Typography.Heading";

export default TypographyHeading;
