import InfiniteLoader from "react-window-infinite-loader";

import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import { isSaasDistribution } from "utils/distribution";
import { WorkerPool } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import WorkerPoolVirtualizedListItem from "./ListItem/Virtualized";
import WorkerPoolsEmpty from "./Empty";
import WorkerPoolsFiltersLayout from "./FiltersLayout";
import WorkerPoolsPageLayout from "./PageLayout";
import useSearchWorkerPools from "./useSearchWorkerPools";
import { ITEMS_LIMIT } from "./constants";
import { showWorkerPoolFormDrawer } from "./CreateWorkerPool";
import PublicWorkerPoolListItem from "./ListItem/PublicWorkerPoolListItem";

const isSaas = isSaasDistribution();

const WorkerPools = () => {
  const {
    publicWorkerPool,
    entities,
    isPageEmpty,
    isPageLoading,
    notFound,
    error,
    stopPolling,
    loadMoreItems,
    hasNextPage,
    isSearchActive,
  } = useSearchWorkerPools();

  const handleCreateWorkerPool = () => {
    showWorkerPoolFormDrawer();
  };

  const handleEditWorkerPool = (workerPool: WorkerPool) => {
    showWorkerPoolFormDrawer({
      id: workerPool.id,
      workerPool,
      refetchQuery: "SearchWorkerPools",
    });
  };

  const isItemLoaded = (value: number) => !hasNextPage || value < entities.length;

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return (
      <WorkerPoolsPageLayout>
        <PageLayoutSkeleton />
      </WorkerPoolsPageLayout>
    );
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <WorkerPoolsPageLayout onCreate={handleCreateWorkerPool}>
      <WorkerPoolsFiltersLayout />

      {isSaas && publicWorkerPool && (
        <PublicWorkerPoolListItem publicWorkerPool={publicWorkerPool} />
      )}

      {isPageEmpty && !isSearchActive && <WorkerPoolsEmpty />}
      {isPageEmpty && isSearchActive && (
        <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} announce />
      )}

      {!isPageEmpty && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? entities.length + ITEMS_LIMIT : entities.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={entities.length}
              itemProps={{
                items: entities,
                onEdit: handleEditWorkerPool,
              }}
              virtualizedItem={WorkerPoolVirtualizedListItem}
              itemKey={(index) => entities[index].id}
              onItemsRendered={onItemsRendered}
            />
          )}
        </InfiniteLoader>
      )}
    </WorkerPoolsPageLayout>
  );
};

export default WorkerPools;
