import { IconComponent } from "types/Icon";

export enum BadgeVariant {
  green = "green",
  blue = "blue",
  purple = "purple",
  fuchsia = "fuchsia",
  yellow = "yellow",
  orange = "orange",
  red = "red",
  turquoise = "turquoise",
  gray = "gray",
  white = "white",
  transparent = "transparent",
}

export type BadgeVariants = `${BadgeVariant}`;

type BadgeType = "strong" | "weak";

export type BadgeProps = {
  className?: string;
  text?: string;
  startIcon?: IconComponent;
  endIcon?: IconComponent;
  iconOnly?: boolean;
  textEllipsis?: boolean;
  type: BadgeType;
  variant: BadgeVariants;
};
