import { ReactNode, useCallback } from "react";
import cx from "classnames";

import Typography, { TypographyProps } from "ds/components/Typography";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box, { BoxProps } from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";

import styles from "./styles.module.css";

type MetricCardProps = Omit<BoxProps, "children" | "onClick"> & {
  className?: string;
  noBorder?: boolean;
  selected?: boolean;
  onClick?: () => void;
  containerClassName?: string;
} & (
    | {
        title: string;
        value: string | number | ReactNode;
        infoTooltip?: string;
        titleIcon?: ReactNode;
        titleColor?: TypographyProps["color"];
        action?: ReactNode;
        children?: never;
      }
    | {
        titleIcon?: never;
        infoTooltip?: never;
        titleColor?: never;
        action?: never;
        title?: never;
        value?: never;
        children: ReactNode;
      }
  );

const MetricCard = ({
  children,
  title,
  infoTooltip,
  titleColor = "secondary",
  value,
  className,
  noBorder,
  titleIcon,
  action,
  selected,
  onClick,
  containerClassName,
  ...boxProps
}: MetricCardProps) => {
  const handleKeyboardPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (["Enter"].includes(e.key)) {
        e.preventDefault();
        onClick?.();
      }
    },
    [onClick]
  );

  return (
    <Box className={containerClassName} {...boxProps}>
      <TileWrapper
        onClick={onClick}
        onKeyDown={handleKeyboardPress}
        role={onClick ? "button" : undefined}
        selected={selected}
        tabIndex={onClick ? 0 : -1}
        className={cx(!!onClick && styles.clickable, className)}
        noBorder={noBorder}
      >
        <TileContent gap="medium">
          {children ? (
            children
          ) : (
            <>
              <Box justify="between" align="start">
                <Box gap="medium" direction="column">
                  <Box gap="medium" align="center">
                    {titleIcon}
                    <Typography variant="p-t7" color={titleColor} tag="span">
                      {title}
                    </Typography>
                    {infoTooltip && <TooltipInfo>{infoTooltip}</TooltipInfo>}
                  </Box>
                  <Typography variant="p-t2" tag="span">
                    {value}
                  </Typography>
                </Box>
                {action}
              </Box>
            </>
          )}
        </TileContent>
      </TileWrapper>
    </Box>
  );
};

export default MetricCard;
