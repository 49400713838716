import Toggle from "ds/components/Toggle";

type BulkActionsSelectAllCheckboxProps = {
  allSelected?: boolean;
  onSelectAll?: () => void;
  onResetAll?: () => void;
};

const BulkActionsSelectAllCheckbox = ({
  allSelected,
  onSelectAll,
  onResetAll,
}: BulkActionsSelectAllCheckboxProps) => {
  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll?.();
    } else {
      onResetAll?.();
    }
  };

  return (
    <Toggle
      variant="checkbox"
      id="select_all_items"
      isSelected={allSelected}
      onChange={handleSelectAllToggle}
      tooltip={(allSelected) => (allSelected ? "Unselect all" : "Select all")}
      aria-label={allSelected ? "Unselect all items" : "Select all items"}
    />
  );
};

export default BulkActionsSelectAllCheckbox;
