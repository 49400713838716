import { ApolloClient, ApolloProvider } from "@apollo/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import NiceModal from "@ebay/nice-modal-react";
import { ReactNode } from "react";

import FlashMessages from "components/FlashMessages";
import { FlashContextProvider } from "components/FlashMessages/FlashContext";
import AriaRouterProvider from "components/AriaRouterProvider";
import SelfHostedInstanceProvider from "views/SelfHosted/InstanceProvider";
import Theme from "views/Theme";
import SystemMessage from "components/SystemMessage";
import ButtonNew from "ds/components/Button/New";
import { ErrorCircleColored } from "components/icons/generated";

import Routes from "./Routes";

type AppProps = {
  client: ApolloClient<unknown>;
};

type AppProvidersProps = AppProps & {
  children: ReactNode;
};

const AppProviders = ({ client, children }: AppProvidersProps) => (
  <AriaRouterProvider>
    <FlashContextProvider>
      <FlashMessages />
      <ApolloProvider client={client}>
        <CookiesProvider>
          <SelfHostedInstanceProvider>
            {/*
              FYI: we should use 2 NiceModal.Providers to provide the context to the whole app -
              one for the AccountWrapper and one for the rest of the app (pages on which users are not
              logged in) 
            */}
            <NiceModal.Provider>
              <Theme>{children}</Theme>
            </NiceModal.Provider>
          </SelfHostedInstanceProvider>
        </CookiesProvider>
      </ApolloProvider>
    </FlashContextProvider>
  </AriaRouterProvider>
);

const App = ({ client }: AppProps) => {
  const router = createBrowserRouter(
    [
      {
        path: "/*",
        errorElement: (
          <SystemMessage
            title="Something went wrong"
            message="Please try to reload the page"
            icon={ErrorCircleColored}
          >
            <ButtonNew variant="primary" onPress={() => window.location.reload()} fullWidth>
              Reload
            </ButtonNew>
          </SystemMessage>
        ),

        element: (
          <AppProviders client={client}>
            <Outlet />
          </AppProviders>
        ),
        children: [
          {
            path: "*",
            element: <Routes />,
          },
        ],
      },
    ],
    {
      future: {
        v7_relativeSplatPath: true,
      },
    }
  );

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: false,
      }}
    />
  );
};

export default App;
