import LinkNew, { type LinkProps } from "ds/components/LinkNew";

const TextLink = ({ children, ...restProps }: LinkProps) => {
  return (
    <LinkNew {...restProps} variant="secondary" target="_blank" rel="noopener noreferrer">
      {children}
    </LinkNew>
  );
};

export default TextLink;
