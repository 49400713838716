import cx from "classnames";

import styles from "./styles.module.css";
import Box, { BoxProps } from "../Box";

type ModalContentProps = BoxProps;

const ModalContent = ({
  children,
  className,
  padding = "x-large",
  direction = "column",
  ...restProps
}: ModalContentProps) => {
  return (
    <Box
      direction={direction}
      padding={padding}
      className={cx(styles.content, className)}
      fullWidth
      {...restProps}
    >
      {children}
    </Box>
  );
};

ModalContent.displayName = "DS.Modal.Content";

export default ModalContent;
