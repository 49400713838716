import { ReactComponent as LogoSymbol } from "ds/components/SpaceliftLogo/logo-symbol.svg";

import styles from "./styles.module.css";

const SaturnheadBubble = () => {
  return (
    <div className={styles.saturnheadBubbleBackground}>
      <LogoSymbol className={styles.logoSymbol} viewBox="0 0 32 26" />
    </div>
  );
};

export default SaturnheadBubble;
