import cx from "classnames";
import { useContext } from "react";
import { ComboBoxStateContext } from "react-aria-components";

import Box from "../Box";
import AriaInput from "../Input/AriaInput";
import ComboBoxClearButton from "./ClearButton";
import ComboBoxOpenButton from "./OpenButton";
import styles from "./styles.module.css";
import LoadingIndicator from "../LoadingIndicator";

export type ComboBoxInputProps = {
  id: string;
  placeholder?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
};

const ComboBoxInput = ({ id, placeholder, isDisabled, isError, isLoading }: ComboBoxInputProps) => {
  const state = useContext(ComboBoxStateContext);

  return (
    <Box className={styles.inputWrapper} fullWidth>
      <AriaInput
        id={id}
        className={cx(styles.input, {
          [styles.withClear]: state?.inputValue !== "",
          [styles.withLoading]: isLoading,
        })}
        disabled={isDisabled}
        error={isError}
        placeholder={placeholder}
      />
      <Box className={styles.buttonWrapper} gap="small" align="center">
        {isLoading && <LoadingIndicator loading />}
        <ComboBoxClearButton isDisabled={isDisabled} />
        <ComboBoxOpenButton isDisabled={isDisabled} />
      </Box>
    </Box>
  );
};

ComboBoxInput.displayName = "DS.ComboBox.Input";

export default ComboBoxInput;
