import { useMemo } from "react";

import { PolicyType } from "types/generated";

import { POLICY_TYPE_LABELS } from "../constants";

export type PolicyTypeOptions = {
  value: PolicyType;
  label: string;
};

export const usePolicyTypeOptions = (availablePolicyTypes: PolicyType[]) => {
  return useMemo(() => {
    const policyOptions = Object.entries(POLICY_TYPE_LABELS).map(([key, value]) => ({
      value: key as PolicyType,
      label: value,
    }));

    return policyOptions.filter(({ value }) => availablePolicyTypes.includes(value));
  }, [availablePolicyTypes]);
};
