import { ChangeEvent, ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import cx from "classnames";

import styles from "./styles.module.css";

type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> &
  TextareaAutosizeProps & {
    id?: string;
    error?: boolean;
    color?: "default" | "inversed";
  };

const Textarea = forwardRef(function Textarea(
  props: TextareaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const { className, color = "default", disabled, onChange, error, ...restProps } = props;

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!disabled) {
      onChange?.(e);
    }
  };

  return (
    <TextareaAutosize
      ref={ref}
      onChange={handleOnChange}
      className={cx(styles.textarea, styles[color], { [styles.error]: error }, className)}
      cols={30}
      minRows={5}
      maxRows={5}
      disabled={disabled}
      {...restProps}
    />
  );
});

Textarea.displayName = "DS.Textarea";

export default Textarea;
