import React, { AriaRole } from "react";
import cx from "classnames";
import useLocalStorage from "@rehooks/local-storage";

import useTypedContext from "hooks/useTypedContext";
import ListContentWrapper from "components/ListContentWrapper";

import styles from "./styles.module.css";
import { FILTER_EXPANDED_STORAGE_KEY } from "../constants";
import { FiltersContext } from "..";
import { createStorageKey } from "../helpers";

type FiltersContentWrapperProps = {
  children: React.ReactNode;
  role?: AriaRole;
  titleId?: string;
  rowCount?: number;
};

const FiltersContentWrapper = ({
  children,
  role,
  titleId,
  rowCount,
}: FiltersContentWrapperProps) => {
  const { filterType, filtersRevealedOnHover } = useTypedContext(FiltersContext);
  const [filtersExpanded] = useLocalStorage(
    createStorageKey(filterType, FILTER_EXPANDED_STORAGE_KEY),
    true
  );

  return (
    <ListContentWrapper
      role={role}
      titleId={titleId}
      rowCount={rowCount}
      className={cx(styles.contentWrapper, {
        [styles.expanded]: filtersExpanded,
        [styles.revealedOnHover]: filtersRevealedOnHover,
      })}
    >
      {children}
    </ListContentWrapper>
  );
};

export default FiltersContentWrapper;
