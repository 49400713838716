import { useCallback, useMemo, useState, useEffect, useRef } from "react";

import EmptyState from "ds/components/EmptyState";
import { SearchQueryOrderDirection, Space } from "types/generated";
import { convertToTreeStructure, isNodeVisible, search, updateTreeRendering } from "utils/tree";
import { Space as SpaceIcon } from "components/icons/generated";
import Box from "ds/components/Box";

import SpacesTreeGridListSortHeader from "./SortHeader";
import { FilterItemKeys } from "../constants";
import styles from "./styles.module.css";
import SpacesTreeGridListRow from "./Row";
import { getSortPredicate } from "../utils";

type SpacesTreeGridListProps = {
  spaces: Space[];
  sortBy: FilterItemKeys;
  sortDirection: SearchQueryOrderDirection;
  onSortChange: (option: FilterItemKeys) => void;
  isRootAdmin: boolean;
  activeId?: string;
  searchQuery?: string;
};
const SpacesTreeGridList = ({
  spaces,
  isRootAdmin,
  sortBy,
  sortDirection,
  onSortChange,
  activeId,
  searchQuery,
}: SpacesTreeGridListProps) => {
  const selectedRef = useRef<HTMLDivElement>(null);
  const prevSearchQueryRef = useRef(searchQuery);

  const { flatList } = useMemo(
    () =>
      convertToTreeStructure<Space>(
        spaces,
        "id",
        "parentSpace",
        "name",
        getSortPredicate(sortBy, sortDirection)
      ),
    [sortBy, sortDirection, spaces]
  );

  const allKeysSet = useMemo(() => {
    const allKeys = flatList.map((item) => item.id);
    return new Set(allKeys);
  }, [flatList]);

  const [expandedKeys, setExpandedKeys] = useState(allKeysSet);
  const [renderedKeys, setRenderedKeys] = useState(allKeysSet);

  const visibleNodes = useMemo(
    () => flatList.filter((node) => isNodeVisible(node, renderedKeys, expandedKeys)),
    [expandedKeys, renderedKeys, flatList]
  );

  useEffect(() => {
    selectedRef?.current?.scrollIntoView({ block: "center", inline: "nearest" });
  }, []);

  useEffect(() => {
    const queryChanged = searchQuery !== prevSearchQueryRef.current;
    prevSearchQueryRef.current = searchQuery;

    if (!searchQuery) {
      setRenderedKeys(allKeysSet);

      if (queryChanged) {
        setExpandedKeys(allKeysSet);
      }

      return;
    }

    const matchedPaths = search(flatList, searchQuery, ["name", "id"]);

    const { expandedNodes, renderedNodes } = updateTreeRendering(flatList, matchedPaths);

    setRenderedKeys(renderedNodes);

    if (queryChanged) {
      setExpandedKeys(expandedNodes);
    }
  }, [allKeysSet, flatList, searchQuery]);

  const toggleKey = useCallback(
    (key: string) => () => {
      setExpandedKeys((prev) => {
        const newKeys = new Set(prev);
        if (newKeys.has(key)) {
          newKeys.delete(key);
        } else {
          newKeys.add(key);
        }
        return newKeys;
      });
    },
    [setExpandedKeys]
  );

  return (
    <Box direction="column" grow="1" role="treegrid" aria-label="Spaces">
      {visibleNodes.length > 0 && (
        <SpacesTreeGridListSortHeader
          sortOption={sortBy}
          sortDirection={sortDirection}
          onSortChange={onSortChange}
        />
      )}

      <Box direction="column" grow="1" className={styles.listWrapper} role="rowgroup" fullWidth>
        {visibleNodes.map((node) => (
          <SpacesTreeGridListRow
            key={node.id}
            node={node}
            isActive={activeId === node.id}
            isExpanded={expandedKeys.has(node.id)}
            isRootAdmin={isRootAdmin}
            onToggle={toggleKey(node.id)}
            searchQuery={searchQuery}
            innerRef={activeId === node.id ? selectedRef : undefined}
          />
        ))}

        {visibleNodes.length === 0 && (
          <EmptyState
            title="No results"
            icon={SpaceIcon}
            caption="Try different search."
            announce
          />
        )}
      </Box>
    </Box>
  );
};

export default SpacesTreeGridList;
