import { ReactNode } from "react";

import Box, { BoxProps } from "../Box";

type TooltipModalBodyProps = {
  children: ReactNode;
  align?: BoxProps["align"];
};

const TooltipModalBody = ({ children, align }: TooltipModalBodyProps) => {
  return (
    <Box gap="medium" direction="column" align={align} id="tooltip-modal-body">
      {children}
    </Box>
  );
};

TooltipModalBody.displayName = "DS.TooltipModal.Body";

export default TooltipModalBody;
