import { useMutation } from "@apollo/client";
import { writeStorage } from "@rehooks/local-storage";
import cx from "classnames";
import { datadogRum } from "@datadog/browser-rum";

import { FiltersContext } from "components/Filters";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { DEFAULT_VIEW_ID } from "components/Filters/constants";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { SAVE_VIEW, UPDATE_VIEW } from "../gql";
import { useSortOptions } from "../useSortOptions";
import { showFiltersSavedViewsUpdateConfirmationModal } from "./ConfirmationModal";
import { getFiltersFromSavedData, getSavedViewData } from "../helpers";
import styles from "./styles.module.css";

type FiltersSavedViewsUpdateProps = {
  isMarked: boolean;
};

const FiltersSavedViewsUpdate = ({ isMarked }: FiltersSavedViewsUpdateProps) => {
  const [updateView] = useMutation(UPDATE_VIEW);
  const [saveView] = useMutation(SAVE_VIEW, { refetchQueries: ["SavedView", "SavedViewsList"] });
  const { activeFilters, currentView, setCurrentView, filterType, defaultEmptyFilterView } =
    useTypedContext(FiltersContext);

  const sortOptions = useSortOptions();
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const handleUpdate = () => {
    const notSavedDefaultView = currentView?.id === DEFAULT_VIEW_ID;

    const { data, error } = getSavedViewData(activeFilters, sortOptions);

    if (error) {
      return onError(error);
    }

    if (notSavedDefaultView) {
      saveView({
        variables: {
          input: {
            data,
            type: filterType,
            name: currentView?.name,
            isPublic: currentView?.isPublic,
          },
        },
      })
        .then(({ data: { savedFilterCreate } }) => {
          const { order, filters, sortOption, sortDirection, search } = getFiltersFromSavedData(
            savedFilterCreate.data,
            defaultEmptyFilterView
          );
          writeStorage(getDefaultViewStorageKey(filterType), savedFilterCreate.id);
          setCurrentView({
            name: savedFilterCreate.name,
            id: savedFilterCreate.id,
            isPublic: savedFilterCreate.isPublic,
            order,
            filters,
            isMine: true,
            sortOption,
            sortDirection,
            search,
          });
          reportSuccess({ message: "View was successfully updated" });
        })
        .catch(onError);
    } else {
      updateView({
        variables: {
          id: currentView?.id,
          data,
          name: currentView?.name,
          isPublic: currentView?.isPublic,
        },
      })
        .then(({ data: { savedFilterUpdate } }) => {
          const { order, filters, sortOption, sortDirection, search } = getFiltersFromSavedData(
            savedFilterUpdate.data,
            defaultEmptyFilterView
          );

          if (currentView) {
            setCurrentView({
              ...currentView,
              order,
              filters,
              sortOption,
              sortDirection,
              search,
            });
          }

          reportSuccess({ message: "View was successfully updated" });
        })
        .catch(onError);
    }
  };

  const handleClick = () => {
    datadogRum.addAction("[Saved Views] Click Update");

    showFiltersSavedViewsUpdateConfirmationModal({
      onConfirm: handleUpdate,
      name: currentView?.name,
    });
  };

  return (
    <DropdownMenuItem onAction={handleClick}>
      <span className={cx({ [styles.isMarked]: isMarked })}>Update</span>
    </DropdownMenuItem>
  );
};

export default FiltersSavedViewsUpdate;
