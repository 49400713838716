import { useEffect } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { Routes as ReactRouterRoutes, Route, useLocation, useNavigate } from "react-router-dom";

import Account from "views/Account/Account";
import ApiKeyToken from "views/Account/ApiKeyToken";
import ProductSignUpWrapper from "views/ProductSignUp/ProductSignUpWrapper";
import Login from "views/Login";
import Logout from "views/Logout";
import SSOLogin from "views/SSOLogin";
import UnauthorizedPage from "components/error/UnauthorizedPage";
import AccountWrapper from "views/AccountWrapper";
import CLILogin from "views/CLI/CLILogin";
import CLIAuthSuccess from "views/CLI/CLIAuthSuccess";
import CLIAuthFailure from "views/CLI/CLIAuthFailure";
import BreadcrumbsContextProvider from "components/Breadcrumbs/Context";
import LoginTerraform from "views/LoginTerraform";
import SubscriptionWrapper from "views/Account/SubscriptionWrapper";
import ThirdPartyLibsWrapper from "views/ThirdPartyLibsWrapper";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import MFA from "views/MFA";
import EnableMFA from "views/EnableMFA";
import RedirectPage from "views/Redirect";
import { pageUserEvent } from "shared/Analytics/server-mutation/page";
import SpacesProvider from "views/Account/SpacesProvider";
import LoginLimitExceededPage from "components/error/LoginLimitExceeded";
import SelfHostedLogout from "views/SelfHosted/Logout";
import SelfHostedAdminLogin from "views/SelfHosted/AdminLogin";
import SelfHostedCreateAccount from "views/SelfHosted/CreateAccount";
import SelfHostedInstanceProviderGate from "views/SelfHosted/InstanceProvider/Gate";
import SelfHostedFeatureFlagsProvider from "views/SelfHosted/FeatureFlagsProvider";
import SelfHostedLicenseProvider from "views/SelfHosted/LicenseProvider";

const _hsq = (window._hsq = window._hsq || []);
const isSelfHosted = isSelfHostedDistribution();

const isSaas = isSaasDistribution();

const Routes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // TODO: check how it affects the SH
    if (isSaas) {
      trackPageView();
    }
  }, [location]);

  function trackPageView() {
    _hsq.push(["setPath", window.location.pathname]);
    _hsq.push(["trackPageView"]);
    pageUserEvent();
  }

  // Remove Trailing Slash from URLs
  useEffect(() => {
    if (location.pathname !== "/" && /\/$/.test(location.pathname) && location.search === "") {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ReactRouterRoutes>
      <Route path="/apikeytoken" element={<ApiKeyToken />} />

      <Route
        path="/login"
        element={
          <SelfHostedInstanceProviderGate>
            <Login />
          </SelfHostedInstanceProviderGate>
        }
      />

      <Route path="/logout" element={<Logout />} />
      <Route path="/sso/login" element={<SSOLogin />} />

      <Route path="/unauthorized" element={<UnauthorizedPage />} />

      <Route path="/login-limit-exceeded" element={<LoginLimitExceededPage />} />

      <Route path="/cli_login" element={<CLILogin />} />

      <Route path="/auth_success" element={<CLIAuthSuccess />} />

      <Route path="/auth_failure" element={<CLIAuthFailure />} />

      <Route path="/redirect" element={<RedirectPage />} />

      {isSelfHosted && <Route path="/see-you-later" element={<SelfHostedLogout />} />}
      {isSelfHosted && <Route path="/admin-login" element={<SelfHostedAdminLogin />} />}
      {isSelfHosted && <Route path="/create-account" element={<SelfHostedCreateAccount />} />}

      <Route path="/enable_mfa" element={<EnableMFA />} />
      <Route path="/mfa" element={<MFA />} />
      <Route
        path="/terraform-login"
        element={
          <AccountWrapper>
            <LoginTerraform />
          </AccountWrapper>
        }
      />
      <Route
        path="*"
        element={
          <SelfHostedInstanceProviderGate>
            <SelfHostedLicenseProvider>
              <SelfHostedFeatureFlagsProvider>
                <AccountWrapper>
                  <ProductSignUpWrapper>
                    <SubscriptionWrapper>
                      <SpacesProvider>
                        <ThirdPartyLibsWrapper>
                          <BreadcrumbsContextProvider>
                            <NiceModal.Provider>
                              <Account />
                            </NiceModal.Provider>
                          </BreadcrumbsContextProvider>
                        </ThirdPartyLibsWrapper>
                      </SpacesProvider>
                    </SubscriptionWrapper>
                  </ProductSignUpWrapper>
                </AccountWrapper>
              </SelfHostedFeatureFlagsProvider>
            </SelfHostedLicenseProvider>
          </SelfHostedInstanceProviderGate>
        }
      />
    </ReactRouterRoutes>
  );
};

export default Routes;
