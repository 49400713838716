import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";

const WorkerPoolQueuedRunsNoResults = () => {
  return (
    <EmptyState
      icon={EmptystateSearchNoResultsColored}
      title="No results"
      caption="Don’t give up! Check the spelling or try something less specific."
      announce
    />
  );
};

export default WorkerPoolQueuedRunsNoResults;
