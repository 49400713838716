import BadgeNext from "ds/components/BadgeNext";

import { StackStateBadgeProps } from "./types";
import { STACK_STATES } from "./constants";

const StackStateBadge = ({ state, endIcon }: StackStateBadgeProps) => {
  if (!STACK_STATES[state]) {
    return null;
  }

  return (
    <BadgeNext
      textEllipsis
      type="strong"
      endIcon={endIcon}
      startIcon={STACK_STATES[state].icon}
      variant={STACK_STATES[state].variant}
      text={STACK_STATES[state].text}
    />
  );
};

export default StackStateBadge;
