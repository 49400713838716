import Banner from "ds/components/Banner";
import SystemMessage from "components/SystemMessage";

const CLIAuthSuccess = () => {
  return (
    <SystemMessage title="Spacelift CLI" message="You may close this window.">
      <Banner variant="success" title="The authentication flow has completed successfully." />
    </SystemMessage>
  );
};

export default CLIAuthSuccess;
