import camelCase from "lodash-es/camelCase";
import cx from "classnames";
import { Link } from "react-router-dom";

import { capitalizeFirstLetter } from "utils/strings";
import {
  CalendarArrow,
  CheckList,
  Circle,
  Clock,
  Cross,
  Edit,
  Eye,
  EyeArrowCircle,
  ExclamationMarkCircle,
  ExclamationMarkTriangle,
  Gear,
  Hourglass,
  Cross as CrossNew,
  InfoCircle,
  File,
  Flash,
  FlagRacing,
  Play,
  Policy,
  Skip,
  StopCircle,
  Tick,
  ThumbUp,
  ThumbDown,
  Trash,
} from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

export const STATE_ICONS: Record<string, typeof Play> = {
  APPLYING: Play,
  BUSY: Clock,
  CONFIRMED: ThumbUp,
  CANCELED: CrossNew,
  SKIPPED: Skip,
  DESTROYING: Trash,
  DISCARDED: ThumbDown,
  DRAINED: Cross,
  DRAINING: Clock,
  FAILED: ExclamationMarkCircle,
  FINISHED: Tick,
  IDLE: Tick,
  INITIALIZING: Gear,
  "NO CHANGES": Circle,
  NONE: Circle,
  PERFORMING: Flash,
  PLANNING: Edit,
  PREPARING: CheckList,
  PREPARING_APPLY: CheckList,
  QUEUED: Hourglass,
  STOPPED: StopCircle,
  UNCONFIRMED: Eye,
  COMMENT: File,
  WARNING: ExclamationMarkTriangle,
  INFO: InfoCircle,
  POLICY: Policy,
  APPROVE: ThumbUp,
  REJECT: ThumbDown,
  REPLAN_REQUESTED: CalendarArrow,
  PREPARING_REPLAN: CheckList,
  READY: FlagRacing,
  PENDING_REVIEW: EyeArrowCircle,
};

export const prepareName = (type: string) => {
  switch (type) {
    case "PREPARING_APPLY":
    case "PREPARING_REPLAN":
      return "PREPARING";
    case "REPLAN_REQUESTED":
      return "REPLAN REQUESTED";
    case "PENDING_REVIEW":
      return "PENDING REVIEW";
    default:
      return type;
  }
};

type StateProps = {
  text?: string;
  type?: string;
  full?: boolean;
  link?: string;
  compact?: boolean;
  className?: string;
  isModuleVersion?: boolean;
};

const State = ({ text, type, full, link, compact, className, isModuleVersion }: StateProps) => {
  if (!type || type === null) {
    return null;
  }

  const elementClass = cx(
    styles.state,
    {
      [styles[camelCase(type)]]: !isModuleVersion,
      [styles[`moduleVersion${capitalizeFirstLetter(camelCase(type))}`]]: isModuleVersion,
      [styles.rectangular]: text,
      [styles.compact]: compact,
      [styles.link]: !!link && !text && !full,
    },
    className
  );

  const details = (
    <>
      {STATE_ICONS[type] && <Icon src={STATE_ICONS[type]} />}
      {full && <span className={styles.text}>{prepareName(type)}</span>}
      {text && <span className={styles.version}>{text}</span>}
    </>
  );

  if (link) {
    return (
      <Link to={link} className={elementClass}>
        {details}
      </Link>
    );
  }

  return <span className={elementClass}>{details}</span>;
};

export default State;
