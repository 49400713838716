import { Group } from "@visx/group";
import { useCallback, useMemo, useState } from "react";
import { ScaleLinear } from "d3";

import { Percentile } from "../../types";
import PercentileLine from "./Line";
import {
  PERCENTILE_BADGE_GAP,
  PERCENTILE_BADGE_HEIGHT,
  PERCENTILE_BADGE_STACKED_OFFSET,
} from "./constants";
import styles from "./styles.module.css";

type PercentileGroupProps = {
  percentiles: Percentile[];
  xMax: number;
  yScale: ScaleLinear<number, number>;
};

const PercentileGroup = ({ percentiles, xMax, yScale }: PercentileGroupProps) => {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);
  const percentilesByValue = useMemo(() => {
    const byValue: Map<number, Percentile[]> = new Map();
    percentiles?.forEach((percentile) => {
      let percentiles = byValue.get(percentile.value);
      if (!percentiles) {
        percentiles = [];
      }
      percentiles.push(percentile);
      byValue.set(percentile.value, percentiles);
    });
    return byValue;
  }, [percentiles]);

  const handleMouseEnter = useCallback(
    (value: number) => () => {
      setHoveredValue(value);
    },
    []
  );

  const handleMouseLeave = useCallback(() => {
    setHoveredValue(null);
  }, []);

  return Array.from(percentilesByValue.entries()).map(([value, percentilesInGroup]) => (
    <Group
      key={value}
      className={styles.group}
      onMouseEnter={handleMouseEnter(value)}
      onMouseLeave={handleMouseLeave}
    >
      {percentilesInGroup.map((percentile, index) => {
        const order = percentilesInGroup.length - 1 - index;

        return (
          <PercentileLine
            key={`percentile-${value}-${index}`}
            xMax={xMax}
            y={yScale(percentile.value)}
            rectY={yScale(percentile.value)}
            name={`p${percentile.percentile * 100}`}
            lineColor={percentile.lineColor}
            backgroundColor={percentile.backgroundColor}
            badgeClassName={styles.badge}
            badgeStyle={
              hoveredValue === value
                ? {
                    transform: `translateY(${order * (PERCENTILE_BADGE_GAP + PERCENTILE_BADGE_HEIGHT)}px)`,
                  }
                : {
                    transform: `translateY(${order * PERCENTILE_BADGE_STACKED_OFFSET}px)`,
                  }
            }
          />
        );
      })}
    </Group>
  ));
};

PercentileGroup.displayName = "DS.Charts.PercentileGroup";

export default PercentileGroup;
