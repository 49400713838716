import { useCallback, useState } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import ModalSimple from "ds/components/ModalSimple";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalCancelButton from "ds/components/Modal/CancelButton";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useLockStack from "shared/Stack/useLockStack";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import ButtonNew from "ds/components/Button/New";

type StackLockNoteModalProps = {
  stackId: string;
  analyticsPage?: AnalyticsPage;
};

const StackLockNoteModal = create(function StackLockNoteModal({
  stackId,
  analyticsPage,
}: StackLockNoteModalProps) {
  const modal = useModal();

  const [notes, setNotes] = useState("");

  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [stackLock, { loading }] = useLockStack({
    refetchQueries: ["GetStack"],
  });

  const handleLock = useCallback(
    (note: string | null) => {
      stackLock({ id: stackId, note: note }, (data) => {
        if (data?.stackLock) {
          reportSuccess({ message: "Stack successfully locked" });
          trackSegmentAnalyticsEvent?.("Stack locked");
          modal.hide();
        }
      });
    },
    [modal, reportSuccess, stackId, stackLock, trackSegmentAnalyticsEvent]
  );

  return (
    <ModalSimple size="large">
      <ModalHeader title="Lock stack" />

      <ModalContent>
        <FormField label="Note" isOptional id="lockNote">
          {({ ariaInputProps }) => (
            <Textarea
              name="lockNote"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              minRows={3}
              maxRows={10}
              autoFocus
              placeholder="Enter the note here..."
              {...ariaInputProps}
            />
          )}
        </FormField>
      </ModalContent>
      <ModalFooter>
        <ModalCancelButton onClick={modal.hide} />
        <ButtonNew
          onPress={() => handleLock(notes)}
          variant="primary"
          loading={loading}
          disabled={loading}
        >
          Lock stack
        </ButtonNew>
      </ModalFooter>
    </ModalSimple>
  );
});

export const showStackLockNoteModal = (props: StackLockNoteModalProps) =>
  show(StackLockNoteModal, props);
