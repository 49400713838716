import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import Tag from "ds/components/Tag";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import { ContextFormFields } from "../types";
import NewContextSummaryPanel from "./Panel";
import { ContextCreationWizardStep } from "../constants";

const NewContextSummaryDetails = () => {
  const { getValues } = useFormContext<ContextFormFields>();
  const formValues = getValues();

  return (
    <NewContextSummaryPanel name="Details" step={ContextCreationWizardStep.Details}>
      <Box direction="column">
        <FormSummaryKeyValueElement name="Name">
          {formValues.contextName}
        </FormSummaryKeyValueElement>
        <FormSummaryKeyValueElement name="Space">{formValues.space}</FormSummaryKeyValueElement>
        {formValues.description && (
          <FormSummaryKeyValueElement name="Description" align="center">
            <ListEntitiesItemDescription noPadding description={formValues.description} />
          </FormSummaryKeyValueElement>
        )}
        <FormSummaryKeyValueElement name="Labels">
          {formValues.labels.length && (
            <Box gap="medium" justify="end" wrap>
              {formValues.labels.map(({ value }, i) => (
                <Tag tag={value} key={i} />
              ))}
            </Box>
          )}
        </FormSummaryKeyValueElement>
      </Box>
    </NewContextSummaryPanel>
  );
};

export default NewContextSummaryDetails;
