import { useCallback, useEffect, useRef } from "react";
import { localPoint } from "@visx/event";
import { Pie } from "@visx/shape";
import { Point } from "@visx/point";
import { Group } from "@visx/group";

import { Datum } from "./types";
import { CHART_THICKNESS } from "./constants";
import styles from "./styles.module.css";

type PieChartOverlayProps = {
  data: Datum[];
  radius: number;
  thickness: number;
  onMouseMove: (arcId: string, coordinates: Point | null) => void;
  onMouseLeave: () => void;
  onMouseLeaveCallback?: () => void;
  forcedHoveredId?: string;
  minValue: number;
};

const MOUSE_LEAVE_TIMEOUT = 200;

const PieChartOverlay = ({
  data,
  radius,
  onMouseMove,
  onMouseLeave,
  onMouseLeaveCallback,
  forcedHoveredId,
  minValue,
}: PieChartOverlayProps) => {
  const mouseLeaveTimeoutId = useRef<number>();

  useEffect(() => {
    // clear on component unmount and hide immediatelly tooltip when component has forced hovered index from outside
    return () => {
      onMouseLeave();
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, [forcedHoveredId, onMouseLeave]);

  const handleMouseLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      onMouseLeave();
      onMouseLeaveCallback?.();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave, onMouseLeaveCallback]);

  const createMouseMoveHandler = useCallback(
    (arcId: string) => (event: React.MouseEvent<SVGRectElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);

      const eventSvgCoords = localPoint(event);

      onMouseMove(arcId, eventSvgCoords);
    },
    [onMouseMove]
  );

  return (
    <Pie
      data={data}
      pieValue={(d) => (d.value > minValue ? d.value : minValue)}
      // Create bigger mask than chart part to remove edges visible during rendering
      outerRadius={radius + 1}
      innerRadius={radius - CHART_THICKNESS - 1}
      padAngle={0.04}
    >
      {(pie) =>
        pie.arcs.map((arc, index) => {
          const arcPath = pie.path(arc);
          return (
            arcPath && (
              <Group key={index}>
                <path
                  d={arcPath}
                  fill="transparent"
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={createMouseMoveHandler(arc.data.id)}
                  className={styles.overlay}
                />
              </Group>
            )
          );
        })
      }
    </Pie>
  );
};

PieChartOverlay.displayName = "DS.Charts.PieChart.Overlay";

export default PieChartOverlay;
