import { ReactNode } from "react";

import { usePhaseSummary } from "components/RunSummarization/hooks/usePhaseSummary";
import RunSummarizationBox from "components/RunSummarization/RunSummarizationBox";
import RunSummarizationTrigger from "components/RunSummarization/RunSummarizationTrigger";
import Box from "ds/components/Box";
import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";
import useTypedFlags from "hooks/useTypedFlags";
import { RunState } from "types/generated";

import RunLogs from "../RunLogs";
import RunPhaseLogsHooks from "./Hooks";
import styles from "./styles.module.css";

type RunPhaseLogsProps = {
  until: number | null;
  stateVersion: number | null;
  state: RunState;
  beforeHooks?: string[] | undefined;
  afterHooks?: string[] | undefined;
  children?: ReactNode;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const containersNotSupported = () => {
  // is safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return true;
  // firefox before version 133.0
  const version = navigator.userAgent.match(/firefox\/(\d+)/i)?.[1];
  return version && parseInt(version) < 133;
};

const RunPhaseLogs = ({
  beforeHooks,
  afterHooks,
  children,
  isModuleRun,
  ...rest
}: RunPhaseLogsProps) => {
  const { runSummarizationAi } = useTypedFlags();
  const {
    acceptedTermsAndConditionsAI,
    canTriggerSummary,
    date,
    loading,
    showSummary,
    showTrigger,
    summary,
    summaryContainerRef,
    trigger,
  } = usePhaseSummary(rest.stateVersion ?? 0);

  const logs = (
    <Box fullWidth direction="column" gap="large">
      <Box
        className={styles.logsContainer}
        direction={containersNotSupported() ? "column" : "row"}
        gap="x-large"
        fullWidth
      >
        <RunLogs isModuleRun={isModuleRun} {...rest} />

        {runSummarizationAi && showSummary && (
          <RunSummarizationBox
            type="phase"
            ref={summaryContainerRef}
            loading={loading}
            content={summary}
            date={date}
          />
        )}
      </Box>
    </Box>
  );

  const disableAiTrigger = !canTriggerSummary || !acceptedTermsAndConditionsAI;

  const generateTooltipMessage = () => {
    if (!acceptedTermsAndConditionsAI) {
      return "An account admin must accept the terms and conditions before using AI summary";
    }

    return (
      <span>
        This will provide a summary of the <b>current phase</b>. It is <b>faster</b> to execute than
        the full run summary and provides information on the phase execution errors.
      </span>
    );
  };

  const summarizationTrigger = (
    <>
      {runSummarizationAi && showTrigger && (
        <RunSummarizationTrigger
          onPress={trigger}
          disabled={disableAiTrigger}
          tooltipText={generateTooltipMessage()}
        >
          AI summary
        </RunSummarizationTrigger>
      )}
    </>
  );

  if (!beforeHooks?.length && !afterHooks?.length) {
    return (
      <>
        {logs}
        <Box gap="medium" align="start">
          {children}

          {summarizationTrigger}
        </Box>
      </>
    );
  }

  return (
    <Tree padding="0 0 0 medium">
      <RunPhaseLogsHooks type="before" hooks={beforeHooks} />

      <TreeBranch gap="medium" align="start">
        <TreeBranchLink position="top">{logs}</TreeBranchLink>

        <Box gap="medium" align="start">
          {children}

          {summarizationTrigger}
        </Box>
      </TreeBranch>

      <RunPhaseLogsHooks type="after" hooks={afterHooks} />
    </Tree>
  );
};

export default RunPhaseLogs;
