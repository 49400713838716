import { useForm, FormProvider, Controller } from "react-hook-form";
import { NetworkStatus, useQuery } from "@apollo/client";
import isEqual from "lodash-es/isEqual";
import { useCallback, useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import FormLoading from "components/form/components/loading";
import Tile from "ds/components/Tile";
import IconTile from "ds/components/IconTile";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { useFormValidations } from "hooks";

import { StackCreationVendor, StackCreationWizardStep, StackVendorFormFields } from "../types";
import { StackFormContext } from "../context";
import NewStackFooter from "../Footer";
import { INITIAL_FORM_DATA } from "../constants";
import NewStackVendorTerraform from "./Terraform";
import { NEW_STACK_RESOURCES } from "./gql";
import { vendorConfig } from "./constants";
import { NewStackResources } from "./types";
import NewStackVendorPulumi from "./Pulumi";
import NewStackVendorCloudFormation from "./CloudFormation";
import NewStackVendorKubernetes from "./Kubernetes";
import NewStackVendorAnsible from "./Ansible";
import NewStackVendorTerragrunt from "./Terragrunt";
import TileCheckboxGrid from "../TileCheckboxGrid";
import NewStackVendorDocumentation from "./Documentation";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import useErrorHandlerNewStack from "../useErrorHandlerNewStack";

const INITIAL_VENDOR_DATA = INITIAL_FORM_DATA[StackCreationWizardStep.Vendor];

const NewStackVendor = () => {
  const { onError } = useTypedContext(FlashContext);

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { currentStep, updateStepData, formData, asyncValidationLoading } =
    useTypedContext(StackFormContext);
  const stepData = formData[StackCreationWizardStep.Vendor];

  const builderForm = useForm<StackVendorFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const { control, setValue, getValues, watch } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const { data, loading, error, refetch, networkStatus } = useQuery<NewStackResources>(
    NEW_STACK_RESOURCES,
    {
      onError,
      onCompleted(data) {
        if (data?.terraformVersions) {
          const formValues = getValues();

          if (!formValues.terraform.version.value) {
            setValue("terraform.version.value", data.terraformVersions[0]);
          }

          if (!formValues.kubernetes.kubectlVersion) {
            setValue("kubernetes.kubectlVersion", data.kubectlVersions[0]);
          }

          if (!formValues.terragrunt.workflowVersion.value) {
            setValue("terragrunt.workflowVersion.value", data.terraformVersions[0]);
          }

          if (!formValues.terragrunt.terragruntVersion.value) {
            setValue("terragrunt.terragruntVersion.value", data.terragruntVersions[0]);
          }
        }
      },
    }
  );

  useErrorHandlerNewStack(error);

  const resetVendorValues = (vendor: StackCreationVendor) => {
    switch (vendor) {
      case StackCreationVendor.Terraform: {
        return setValue("terraform", {
          ...INITIAL_VENDOR_DATA.terraform,
          version: {
            ...INITIAL_VENDOR_DATA.terraform.version,
            value: data?.terraformVersions[0] || "",
          },
        });
      }

      case StackCreationVendor.Kubernetes: {
        return setValue("kubernetes", {
          ...INITIAL_VENDOR_DATA.kubernetes,
          kubectlVersion: data?.kubectlVersions[0] || "",
        });
      }

      case StackCreationVendor.Terragrunt: {
        return setValue("terragrunt", {
          ...INITIAL_VENDOR_DATA.terragrunt,
          workflowVersion: {
            ...INITIAL_VENDOR_DATA.terragrunt.workflowVersion,
            value: data?.terraformVersions[0] || "",
          },
          terragruntVersion: {
            ...INITIAL_VENDOR_DATA.terragrunt.terragruntVersion,
            value: data?.terragruntVersions[0] || "",
          },
        });
      }

      case StackCreationVendor.Ansible: {
        return setValue("ansible", INITIAL_VENDOR_DATA.ansible);
      }

      case StackCreationVendor.CloudFormation: {
        return setValue("cloudFormation", INITIAL_VENDOR_DATA.cloudFormation);
      }

      case StackCreationVendor.Pulumi: {
        return setValue("pulumi", INITIAL_VENDOR_DATA.pulumi);
      }
    }
  };

  const newStepData = watch();

  const localFormData = useMemo(
    () => ({ ...formData, [currentStep]: newStepData }),
    [formData, currentStep, newStepData]
  );

  const processStepData = () => {
    return updateStepData(currentStep, newStepData);
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  const validateForm = useCallback(async () => {
    return runFormValidations();
  }, [runFormValidations]);

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <FormLoading />;
  }

  const reloadLoading = loading && networkStatus === NetworkStatus.refetch;

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Choose vendor
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Choose your infrastructure tool and configure its options
        </Typography>
        <FormProvider {...builderForm}>
          <Controller
            name="vendor"
            control={control}
            render={({ field }) => (
              <Box direction="column" gap="x-large" margin="x-large 0 large 0">
                <TileCheckboxGrid>
                  {vendorConfig.map(({ name, logos, type }) => (
                    <Tile
                      key={type}
                      selected={field.value === type}
                      title={name}
                      icon={
                        <Box gap="small">
                          {logos.map((logo, index) => (
                            <IconTile
                              key={index}
                              icon={logo}
                              variant={field.value === type ? "primary" : "secondary"}
                            />
                          ))}
                        </Box>
                      }
                      onClick={() => {
                        if (type !== field.value) {
                          field.onChange(type);
                          resetVendorValues(type);
                          trackSegmentEvent("Choose vendor", { type });
                          runFormValidations();
                        }
                      }}
                      indicator="radio"
                    />
                  ))}
                </TileCheckboxGrid>
                {field.value === StackCreationVendor.Terraform && (
                  <NewStackVendorTerraform
                    reloadVersionsData={refetch}
                    terraformVersions={data?.terraformVersions}
                    openTofuVersions={data?.openTofuVersions}
                    reloadLoading={reloadLoading}
                  />
                )}
                {field.value === StackCreationVendor.Pulumi && <NewStackVendorPulumi />}
                {field.value === StackCreationVendor.CloudFormation && (
                  <NewStackVendorCloudFormation />
                )}
                {field.value === StackCreationVendor.Kubernetes && (
                  <NewStackVendorKubernetes
                    reloadVersionsData={refetch}
                    kubectlVersions={data?.kubectlVersions}
                    reloadLoading={reloadLoading}
                  />
                )}
                {field.value === StackCreationVendor.Ansible && <NewStackVendorAnsible />}
                {field.value === StackCreationVendor.Terragrunt && (
                  <NewStackVendorTerragrunt
                    reloadVersionsData={refetch}
                    terraformVersions={data?.terraformVersions}
                    openTofuVersions={data?.openTofuVersions}
                    terragruntVersions={data?.terragruntVersions}
                    reloadLoading={reloadLoading}
                  />
                )}
              </Box>
            )}
          />
        </FormProvider>
      </FullScreenModalBody>

      <NewStackVendorDocumentation vendor={newStepData.vendor}>
        {({ body, link }) => (
          <NewStackFooter
            isDataChanged={isDataChanged}
            loading={asyncValidationLoading}
            processStepData={processStepData}
            documentationLink={link}
            documentationTitle="Choose vendor"
            documentationBody={body}
            analyticsDocsLocation={newStepData.vendor}
            // TODO: consider different approach
            localFormData={localFormData}
            runFormValidations={validateForm}
          />
        )}
      </NewStackVendorDocumentation>
    </>
  );
};

export default NewStackVendor;
