import { TickRendererProps } from "@visx/axis";

import Typography from "ds/components/Typography";

const AxisLabel = ({ formattedValue, ...tickProps }: TickRendererProps) => (
  <text {...tickProps} fill="currentColor" textAnchor="middle">
    <Typography tag="tspan" variant="p-body4" color="secondary">
      {formattedValue}
    </Typography>
  </text>
);

AxisLabel.displayName = "DS.Charts.AxisLabel";

export default AxisLabel;
