import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import isEqual from "lodash-es/isEqual";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_UI_CONFIG, UPDATE_UI_CONFIG } from "./gql";

const useUIConfig = <T extends object>(
  configKey: string,
  initialConfig: T,
  validateConfig?: (config: T) => T
) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const [updateUIConfig] = useMutation(UPDATE_UI_CONFIG, {
    refetchQueries: ["GetUIConfig"],
    onCompleted: () => reportSuccess({ message: "UI configuration was successfuly saved" }),
    onError: onError,
  });

  const client = useApolloClient();

  const { data, loading } = useQuery<{ uiConfigGet: string }>(GET_UI_CONFIG, {
    onCompleted: (data) => {
      // It allows as to validate config and return correct one
      if (validateConfig && data?.uiConfigGet) {
        try {
          const fullConfig = JSON.parse(data?.uiConfigGet);

          const currentConfig = fullConfig?.[configKey];

          if (!currentConfig) {
            return;
          }

          const refreshedConfig = validateConfig(currentConfig);

          if (!isEqual(refreshedConfig, currentConfig)) {
            const uiConfigGet = JSON.stringify({
              ...fullConfig,
              [configKey]: refreshedConfig,
            });

            client.writeQuery({
              query: GET_UI_CONFIG,
              data: {
                uiConfigGet,
              },
            });
          }
        } catch {
          // malformed config
          reportError({ message: "Couldn't load UI config" });
          return;
        }
      }
    },
  });

  const fullConfig = data?.uiConfigGet && JSON.parse(data?.uiConfigGet);
  const config: T = fullConfig?.[configKey] || initialConfig;

  const updateConfig = useCallback(
    (value: T) => {
      try {
        const config = JSON.stringify({
          ...fullConfig,
          [configKey]: value,
        });

        client.writeQuery({
          query: GET_UI_CONFIG,
          data: {
            uiConfigGet: config,
          },
        });

        updateUIConfig({
          variables: {
            input: config,
          },
        });
      } catch {
        reportError({ message: "Couldn't save UI config" });
        return;
      }
    },

    [updateUIConfig, fullConfig, configKey, client, reportError]
  );

  return {
    noData: !loading && !data,
    config,
    updateConfig,
    configLoading: loading && !data,
  };
};

export default useUIConfig;
