import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type FullScreenModalBodyProps = {
  noFooter?: boolean;
  noMaxWidth?: boolean;
} & BoxProps;

const FullScreenModalBody = ({
  noFooter,
  children,
  direction = "column",
  className,
  padding = "x-large large",
  grow = "1",
  noMaxWidth = false,
  ...restProps
}: FullScreenModalBodyProps) => {
  return (
    <Box
      direction="column"
      grow="1"
      className={cx(styles.bodyWrapper, { [styles.noFooter]: noFooter }, className)}
      fullWidth
    >
      <Box
        direction={direction}
        className={cx(styles.body, { [styles.noMaxWidth]: noMaxWidth })}
        padding={padding}
        grow={grow}
        {...restProps}
      >
        {children}
      </Box>
    </Box>
  );
};

FullScreenModalBody.displayName = "DS.FullScreenModal.Body";

export default FullScreenModalBody;
