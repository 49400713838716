import { VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import { VCS_PROVIDERS_ICONS } from "constants/provider_icons";

import MetaInfoListItem from "./Item";

type MetaInfoProviderProps = {
  provider: VcsProvider;
};

const MetaInfoProvider = ({ provider }: MetaInfoProviderProps) => {
  return (
    <MetaInfoListItem icon={VCS_PROVIDERS_ICONS[provider]}>
      {HUMANIZE_PROVIDER[provider]}
    </MetaInfoListItem>
  );
};

export default MetaInfoProvider;
