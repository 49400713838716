import { Controller, useFormContext } from "react-hook-form";

import ComboBoxItem from "ds/components/ComboBox/Item";
import ComboBox from "ds/components/ComboBox";

type NewModuleAttachCloudFormIntegrationFieldProps = {
  options: Array<{ label: string; value: string }>;
};

const NewModuleAttachCloudFormIntegrationField = ({
  options,
}: NewModuleAttachCloudFormIntegrationFieldProps) => {
  const { control } = useFormContext<{ integrationId: string }>();
  return (
    <Controller
      name="integrationId"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <ComboBox
          label="Attach integration"
          value={field.value}
          items={options}
          onChange={field.onChange}
        >
          {(item) => <ComboBoxItem id={item.value} label={item.label} />}
        </ComboBox>
      )}
    />
  );
};

export default NewModuleAttachCloudFormIntegrationField;
