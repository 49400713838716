import { ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Box from "ds/components/Box";

import { UserManagementActivationStatus } from "../../types";
import useGetGroup from "./useGetGroup";
import IdpGroupMappingDetailsDrawerContent from "./Content";
import { UPDATE_GROUP_DRAWER_TEST_ID } from "./constants";

type IdpGroupMappingDetailsDrawerProps = {
  currentGroup?: ManagedUserGroup;
  activationStatus: UserManagementActivationStatus;
};

const IdpGroupMappingDetailsDrawer = createDrawer(
  ({ currentGroup, activationStatus }: IdpGroupMappingDetailsDrawerProps) => {
    const { group, loading, error } = useGetGroup({
      id: currentGroup?.id,
    });

    if (error) {
      return null;
    }

    return (
      <DrawerSimple dataTestId={UPDATE_GROUP_DRAWER_TEST_ID}>
        {loading && (
          <Box grow="1" justify="center" align="center">
            <LoadingIndicator size="x-large" loading />
          </Box>
        )}
        {group && !loading && (
          <IdpGroupMappingDetailsDrawerContent group={group} activationStatus={activationStatus} />
        )}
      </DrawerSimple>
    );
  }
);

export const showIdpGroupMappingDetailsDrawer = createDrawerTrigger(IdpGroupMappingDetailsDrawer);
