import { useMemo } from "react";
import partition from "lodash-es/partition";

import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import useTitle from "hooks/useTitle";

import { ContextContext } from "../Context";
import ContextUsedByAttached from "./Attached";

const ContextUsedBy = () => {
  const { context } = useTypedContext(ContextContext);

  useTitle(`Used by · ${context.name}`);

  const { attachedStacks } = context;

  const [autoAttached, manuallyAttached] = useMemo(
    () => partition(attachedStacks, "isAutoattached"),
    [attachedStacks]
  );

  return (
    <>
      <PageInfo title="Used by" />
      <Box padding="0 0 large 0">
        <ContextUsedByAttached items={manuallyAttached} name="Manually attached" />
      </Box>
      <Box>
        <ContextUsedByAttached items={autoAttached} name="Auto-attached" hasAutoAttach />
      </Box>
    </>
  );
};

export default ContextUsedBy;
