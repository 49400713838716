import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";

import ViewCustomizationContextProvider from "components/ViewCustomization/Context";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchModulesOutput } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import ListEntitiesNew from "components/ListEntitiesNew";
import { uniqByKey } from "utils/uniq";
import useURLParams from "hooks/useURLParams";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { SavedFilterView } from "components/Filters/types";
import useBulkActionsSelection from "components/BulkActions/useBulkActionsSelection";

import { SEARCH_MODULES } from "./gql";
import ModulesPageLayout from "./components/PageLayout";
import ModulesEmptyState from "./components/EmptyState";
import ModuleVirtualizedListItem from "./components/ListItem/Virtualized";
import FiltersLayout from "./components/FiltersLayout";
import {
  initialSortDirection,
  initialSortOption,
  INITIAL_MODULE_LIST_VIEW_ITEMS,
  POLL_INTERVAL,
  MODULES_LIMIT,
  MODULE_LIST_VIEW_ITEMS_SETTINGS_KEY,
} from "./constants";
import { getAllItemsForSelectAll } from "./helpers";
import ModulesBulkActions from "./components/BulkActions";

const Modules = () => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    allSelected,
    selectedSet,
    syncAllSelectedItems,
    toggleItem,
    unselectItem,
    onBulkSelectAll,
    onBulkResetAll,
    syncSelectedItemsWithVisibleOnes,
    onBulkContinueWith,
  } = useBulkActionsSelection();

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { onError } = useTypedContext(FlashContext);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreModules,
    refetch,
    networkStatus,
    previousData,
  } = useQuery<{
    searchModules: SearchModulesOutput;
  }>(SEARCH_MODULES, {
    variables: {
      input: {
        first: MODULES_LIMIT,
        after: null,
        fullTextSearch: searchInput,
        predicates,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    nextFetchPolicy: "cache-first",
  });

  const initialLoading = loading && networkStatus === NetworkStatus.loading;

  const memoizedModules = useMemo(
    () =>
      data?.searchModules?.edges.map((edge) => edge.node) ||
      previousData?.searchModules?.edges.map((edge) => edge.node) ||
      [],
    [data?.searchModules?.edges, previousData?.searchModules?.edges]
  );

  const memoizedModulesMap = useMemo(
    () => new Map(memoizedModules.map((edge) => [edge.id, edge])),
    [memoizedModules]
  );

  const modulesQueryRefetch = async () => {
    try {
      await refetch();
    } catch (e) {
      onError(e);
    }
  };

  // mark selected new loaded modules if allSelected is true
  useEffect(() => {
    if (allSelected) {
      syncAllSelectedItems(getAllItemsForSelectAll(memoizedModules));
    }
  }, [allSelected, memoizedModules, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list (filter out the ones that are not visible, for example in result of filters changing)
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(memoizedModulesMap);
    }
  }, [memoizedModulesMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  const handleBulkSelectAll = useCallback(() => {
    onBulkSelectAll(getAllItemsForSelectAll(memoizedModules));
  }, [memoizedModules, onBulkSelectAll]);

  const isItemLoaded = (value: number) => value < memoizedModules.length;

  const loadMoreItems = async () => {
    try {
      if (data?.searchModules?.pageInfo.endCursor && data?.searchModules?.pageInfo.hasNextPage) {
        await fetchMoreModules({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchModules.edges.length > 0) {
              return {
                ...fetchMoreResult,
                searchModules: {
                  ...fetchMoreResult.searchModules,
                  edges: uniqByKey(
                    [...(prev.searchModules.edges || []), ...fetchMoreResult.searchModules.edges],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: MODULES_LIMIT,
              after: data.searchModules.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (initialLoading && !data?.searchModules) {
    return (
      <ModulesPageLayout>
        <PageLayoutSkeleton />
      </ModulesPageLayout>
    );
  }

  if (!loading && !data?.searchModules) {
    return <NotFoundPage />;
  }

  const handleBulkActionsFinish = async () => {
    await modulesQueryRefetch();
  };

  return (
    <ModulesPageLayout>
      <ViewCustomizationContextProvider
        localStorageKey={MODULE_LIST_VIEW_ITEMS_SETTINGS_KEY}
        initialItems={INITIAL_MODULE_LIST_VIEW_ITEMS}
      >
        <FiltersLayout
          predicates={predicates}
          allSelected={allSelected}
          onSelectAll={handleBulkSelectAll}
          onResetAll={onBulkResetAll}
          hasItems={memoizedModules.length > 0}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
        >
          {data && !memoizedModules.length && (
            <ModulesEmptyState didPerformSearch={!!searchInput || predicates.length > 0} />
          )}

          {data && memoizedModules.length > 0 && (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={memoizedModules.length + MODULES_LIMIT}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered }) => (
                <ListEntitiesNew
                  itemCount={memoizedModules.length}
                  itemProps={{
                    items: memoizedModules,
                    onCheckItem: toggleItem,
                    selectedSet,
                  }}
                  virtualizedItem={ModuleVirtualizedListItem}
                  itemKey={(index) => memoizedModules[index].id}
                  onItemsRendered={onItemsRendered}
                  outerContainerRef={virtualizedListContainerRef}
                />
              )}
            </InfiniteLoader>
          )}

          <ModulesBulkActions
            virtualizedListContainerRef={virtualizedListContainerRef}
            selectedSet={selectedSet}
            modulesMap={memoizedModulesMap}
            onBulkResetAll={onBulkResetAll}
            onFinish={handleBulkActionsFinish}
            onItemDismiss={unselectItem}
            onBulkContinueWith={onBulkContinueWith}
          />
        </FiltersLayout>
      </ViewCustomizationContextProvider>
    </ModulesPageLayout>
  );
};

export default Modules;
