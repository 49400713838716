import EmptyState from "ds/components/EmptyState";
import {
  EmptystateWebhooksColored,
  EmptystateSearchNoResultsColored,
} from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import DocumentationButton from "components/DocumentationButton";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import { getDocsUrl } from "utils/getDocsUrl";

import { SpacesContext } from "../SpacesProvider";

type WebhooksEmptyProps = {
  hasNoResults?: boolean;
};

const WebhooksEmpty = ({ hasNoResults }: WebhooksEmptyProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  return (
    <>
      {hasNoResults && (
        <EmptyState
          title="No results"
          caption="Try different filters."
          icon={EmptystateSearchNoResultsColored}
          announce
        />
      )}

      {!hasNoResults && (
        <EmptyState
          title="You don’t have any webhooks yet"
          caption="Spacelift can be configured to send webhook notifications for various events to an HTTP
          endpoint of your choice."
          icon={EmptystateWebhooksColored}
        >
          <Box gap="medium">
            <DocumentationButton to={getDocsUrl("/integrations/webhooks")} label="Documentation" />
            {hasEntityCreateAccess && (
              <Button variant="primary" to="/new/webhook">
                Create webhook
              </Button>
            )}
          </Box>
        </EmptyState>
      )}
    </>
  );
};

export default WebhooksEmpty;
