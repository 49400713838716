import { ReactNode } from "react";

import Box from "../Box";
import Typography, { TypographyProps } from "../Typography";
import styles from "./styles.module.css";

type PageInfoHeaderProps = {
  title: ReactNode;
  children?: ReactNode;
  titleTag?: TypographyProps["tag"];
  caption?: string;
  captionTag?: TypographyProps["tag"];
};

const PageInfoHeader = ({
  title,
  children,
  titleTag = "h2",
  caption,
  captionTag = "p",
}: PageInfoHeaderProps) => (
  <Box justify="between" align="center" className={styles.header}>
    <Box direction="column">
      <Typography tag={titleTag} variant="p-t5">
        {title}
      </Typography>
      {caption && (
        <Typography color="secondary" tag={captionTag} variant="p-body3">
          {caption}
        </Typography>
      )}
    </Box>
    <Box align="center" className={styles.actions}>
      {children}
    </Box>
  </Box>
);

PageInfoHeader.displayName = "DS.PageInfoHeader";

export default PageInfoHeader;
