import { useState } from "react";
import useLocalStorage from "@rehooks/local-storage";
import { Navigate } from "react-router-dom";

import SystemMessage from "components/SystemMessage";
import Button from "ds/components/Button";
import useMFA from "hooks/useMFA";
import useTitle from "hooks/useTitle";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { MFA_USING_KEY_COMPATIBILITY_MESSAGE } from "constants/mfa";
import { getAuthStatusRedirectUrl } from "utils/auth";

const MFA = () => {
  useTitle("MFA");
  const [error, setError] = useState<string | null>(null);

  const {
    handleAuthenticate,
    isLoading,
    shouldUseMFA,
    isUserVerifyingPlatformAuthenticatorAvailable,
  } = useMFA();
  const [redirectTo, , deleteRedirectTo] = useLocalStorage("redirectTo");
  const [nextUrl, setNextUrl] = useState<string | undefined>();

  const redirectRoute = redirectTo || "/";

  const handleAuthenticateMFA = async () => {
    const { success, message, status } = await handleAuthenticate();

    if (success) {
      setError(null);
      deleteRedirectTo();
      setNextUrl(redirectRoute);
    } else {
      const authRedirectUrl = getAuthStatusRedirectUrl(status);

      if (authRedirectUrl) {
        setNextUrl(authRedirectUrl);
      } else {
        setError(message);
      }
    }
  };

  if (nextUrl) {
    return <Navigate to={nextUrl} replace />;
  }

  if (!shouldUseMFA) {
    return <Navigate to={redirectRoute} replace />;
  }

  return (
    <SystemMessage
      title="Verify your identity"
      message="When you are ready to authenticate press the button below"
    >
      <Box direction="column" gap="x-large" fullWidth>
        {isUserVerifyingPlatformAuthenticatorAvailable === false && (
          <Banner variant="info" fullWidth>
            {MFA_USING_KEY_COMPATIBILITY_MESSAGE}
          </Banner>
        )}

        {error && (
          <Banner variant="danger" fullWidth>
            {error}
          </Banner>
        )}
        <Button variant="primary" fullWidth onClick={handleAuthenticateMFA} loading={isLoading}>
          {`${!error ? "Use" : "Retry"} security key`}
        </Button>
      </Box>
    </SystemMessage>
  );
};

export default MFA;
