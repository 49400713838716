import Counter from "ds/components/Counter";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { withTestId } from "utils/withTestId";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import styles from "./styles.module.css";
import { ReplanRequestChangeType } from "./types";
import ReplanRequestChange from "./Change";

type ReplanRequestChangesProps = {
  title: string;
  changes: ReplanRequestChangeType[];
  dataTestId?: string;
};

function ReplanRequestChanges({ title, changes, dataTestId }: ReplanRequestChangesProps) {
  const [isCollapsed, toggle] = useToggle(true);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} withTransition onToggle={toggle}>
      <CollapsiblePanelHeader
        align="center"
        gap="medium"
        padding="small large"
        className={styles.header}
      >
        <CollapsiblePanelToggleTrigger
          ariaLabel={`${title}. ${changes.length} elements.`}
          ariaLevel={4}
        />

        <CollapsiblePanelTitle aria-hidden variant="p-t7">
          {title}
        </CollapsiblePanelTitle>

        <Counter count={changes.length} />
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent {...withTestId(dataTestId)}>
        {changes.map((change) => (
          <ReplanRequestChange key={change.id} change={change} />
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
}

export default ReplanRequestChanges;
