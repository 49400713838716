import { useMemo } from "react";

import Box, { BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";

import { ReactComponent as LogoAiSummary } from "./saturnHead.svg";
import styles from "./styles.module.css";

type LoaderAiSummaryProps = {
  logoSize?: number;
} & Omit<BoxProps, "children">;

const aiSentences = [
  "Preparing for liftoff...",
  "Warming up the AI engines...",
  "Scanning the galaxy for insights...",
  "AI thrusters are firing up...",
  "Initiating data countdown...",
  "AI systems are aligning...",
  "Engaging AI boosters...",
  "Setting coordinates for AI exploration...",
  "AI systems calibrated and ready!",
  "Charting new AI frontiers...",
  "Launching into the data cosmos.",
  "AI navigation systems online.",
  "AI exploration mission is a GO!",
  "AI data incoming in 3... 2... 1...",
];

export const LoaderAiSummary = ({ logoSize = 130, ...boxProps }: LoaderAiSummaryProps) => {
  const randomSentence = useMemo(
    () => aiSentences[Math.floor(Math.random() * aiSentences.length)],
    []
  );

  return (
    <Box {...boxProps} className={styles.wrapper} justify="center">
      <div className={styles.typingContainer}>
        <Typography variant="p-body3" tag="span" align="center" color="tertiary">
          {randomSentence}
        </Typography>
      </div>
      <LogoAiSummary width={logoSize} />
    </Box>
  );
};
