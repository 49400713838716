import { ChangeEvent, ReactNode, useId, useMemo } from "react";

import CardWrapper from "components/CardWrapper";
import formStyles from "components/FormDefault/styles.module.css";
import MissingDataBanner from "components/MissingDataBanner";
import Box from "ds/components/Box";
import ComboBox from "ds/components/ComboBox";
import ComboBoxItem from "ds/components/ComboBox/Item";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import SegmentedControl from "ds/components/SegmentedControl";
import TooltipInfo from "ds/components/TooltipInfo";
import Typography from "ds/components/Typography";

import { VERSION_INPUT_DICTIONARY } from "./constants";
import { VERSION_INPUT_TYPE, VersionInputOption } from "./types";

type FormFieldVersionInputProps = {
  type: VERSION_INPUT_TYPE;
  version?: string | null;
  supportedVersions?: string[];
  onChange(version: VersionInputOption): void;
  errorMessage?: string;
  title: string;
  tooltipInfo: ReactNode;
  reloadVersionsData: () => void;
  reloadLoading: boolean;
};

const FormFieldVersionInput = ({
  title,
  type,
  version,
  supportedVersions,
  onChange,
  errorMessage,
  tooltipInfo,
  reloadVersionsData,
  reloadLoading,
}: FormFieldVersionInputProps) => {
  const defaultPreciseVersion =
    supportedVersions && supportedVersions.length ? supportedVersions[0] : "";
  const inputId = useId();

  const supportedVersionOptions = useMemo(
    () =>
      supportedVersions?.map((value) => ({
        value,
        label: value,
      })),
    [supportedVersions]
  );

  const handleTypeChange = (type: VERSION_INPUT_TYPE) => {
    if (type === VERSION_INPUT_TYPE.SPECIFIC) {
      onChange({ value: defaultPreciseVersion, type });
    } else if (type === VERSION_INPUT_TYPE.RANGE) {
      onChange({ value: version, type });
    }
  };

  const handleVersionChange = (value: string | null) => onChange({ value, type });

  const handleVersionRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const range = event.target.value;
    onChange({
      value: range,
      type: VERSION_INPUT_TYPE.RANGE,
    });
  };

  return (
    <CardWrapper variant="filled" direction="column">
      <Box gap="small" align="center" margin="0 0 x-large">
        <Typography tag="label" htmlFor={inputId} variant="p-t5">
          {title}
        </Typography>
        <TooltipInfo variant="modal">{tooltipInfo}</TooltipInfo>
      </Box>

      {supportedVersionOptions === undefined && (
        <MissingDataBanner refreshLoading={reloadLoading} refreshHandler={reloadVersionsData} />
      )}

      {supportedVersionOptions !== undefined && (
        <Box direction="column" gap="large">
          <SegmentedControl
            value={type}
            onChange={handleTypeChange}
            options={[
              {
                value: VERSION_INPUT_TYPE.SPECIFIC,
                label: VERSION_INPUT_DICTIONARY[VERSION_INPUT_TYPE.SPECIFIC],
              },
              {
                value: VERSION_INPUT_TYPE.RANGE,
                label: VERSION_INPUT_DICTIONARY[VERSION_INPUT_TYPE.RANGE],
              },
            ]}
          />
          {type === VERSION_INPUT_TYPE.SPECIFIC && (
            <ComboBox
              id={inputId}
              error={errorMessage}
              items={supportedVersionOptions}
              onChange={handleVersionChange}
              value={version}
            >
              {(item) => <ComboBoxItem id={item.value} label={item.label} />}
            </ComboBox>
          )}

          {type === VERSION_INPUT_TYPE.RANGE && (
            <FormField id={inputId} error={errorMessage} helperText={"Use SemVer format"} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  error={!!errorMessage}
                  autoFocus
                  className={formStyles.input}
                  type="text"
                  value={version || ""}
                  placeholder="Set range"
                  onChange={handleVersionRangeChange}
                  aria-description="Use SemVer format with range"
                  {...ariaInputProps}
                />
              )}
            </FormField>
          )}
        </Box>
      )}
    </CardWrapper>
  );
};

export default FormFieldVersionInput;
