import { ReactNode, isValidElement } from "react";

import { IconComponent } from "types/Icon";

import Box from "../Box";
import TileTitle from "./Title";
import TileIndicator from "./Indicator";
import IconTile from "../IconTile";
import TileDescription from "./Description";
import TileWrapper from "./Wrapper";
import TileContent from "./Content";

type TileProps = {
  title: string | ReactNode;
  description?: string | ReactNode;
  icon?: IconComponent | ReactNode;
  selected?: boolean;
  disabled?: boolean;
  indicator?: "radio" | ReactNode;
  onClick?: () => void;
  children?: ReactNode;
};

const Tile = ({
  title,
  icon,
  selected,
  disabled,
  description,
  indicator,
  onClick,
  children,
}: TileProps) => {
  const iconComponent =
    isValidElement(icon) || icon === null ? (
      icon
    ) : (
      <IconTile
        icon={icon as IconComponent}
        variant={selected && !disabled ? "primary" : "secondary"}
      />
    );

  const indicatorComponent = indicator && (
    <TileIndicator indicator={indicator} selected={selected} disabled={disabled} />
  );

  const titleComponent = typeof title === "string" ? <TileTitle>{title}</TileTitle> : title;

  const handleOnClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <TileWrapper
      selected={selected}
      disabled={disabled}
      onClick={onClick ? handleOnClick : undefined}
    >
      <TileContent gap="small">
        {icon && (
          <Box direction="column" gap="medium">
            <Box justify="between" align="start" fullWidth>
              {iconComponent}
              {indicatorComponent}
            </Box>
            {titleComponent}
          </Box>
        )}

        {!icon && (
          <Box justify="between" gap="medium" align="center" fullWidth>
            {titleComponent}
            {indicatorComponent}
          </Box>
        )}

        {!!description && typeof description === "string" ? (
          <TileDescription>{description}</TileDescription>
        ) : (
          description
        )}

        {children}
      </TileContent>
    </TileWrapper>
  );
};

Tile.displayName = "DS.Tile";

export default Tile;
