import Tag from "ds/components/Tag";
import Box from "ds/components/Box";
import KeyValue from "components/KeyValue";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import { ModuleDetailsFormFields } from "../types";

type NewModuleSummaryDetailsProps = {
  data: ModuleDetailsFormFields;
};

const NewModuleSummaryDetails = ({ data }: NewModuleSummaryDetailsProps) => {
  return (
    <>
      <KeyValue name="Name">{data.name}</KeyValue>
      {data.description && (
        <KeyValue name="Description" align="center">
          <ListEntitiesItemDescription noPadding description={data.description} />
        </KeyValue>
      )}
      <KeyValue name="Provider">{data.terraformProvider}</KeyValue>

      <KeyValue name="Labels">
        {data.labels.length && (
          <Box gap="medium" justify="end" wrap>
            {data.labels.map(({ value }, i) => (
              <Tag tag={value} key={i} />
            ))}
          </Box>
        )}
      </KeyValue>
    </>
  );
};

export default NewModuleSummaryDetails;
