import {
  EmptystateSearchNoResultsColored,
  EmptystateNotificationsColored,
} from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type NotificationsEmptyProps = {
  hasNoResults?: boolean;
};

const NotificationsEmpty = ({ hasNoResults }: NotificationsEmptyProps) => {
  return (
    <Box align="center" justify="center" grow="1" className={styles.emptyContainer}>
      {hasNoResults && (
        <EmptyState
          title="No results"
          caption="Check the date range or try different filters."
          icon={EmptystateSearchNoResultsColored}
          announce
        />
      )}

      {!hasNoResults && (
        <EmptyState
          title="No notifications yet"
          caption="You currently don't have any notifications. We'll inform you if any appear."
          icon={EmptystateNotificationsColored}
        />
      )}
    </Box>
  );
};

export default NotificationsEmpty;
