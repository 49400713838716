import { ReactNode } from "react";

import { IconComponent } from "types/Icon";

import Feedback, { FeedbackProps } from "../Feedback";
import { FeedbackVariant } from "../Feedback/types";

// ts-prune-ignore-next
export type CalloutProps = {
  children?: ReactNode;
  className?: string;
  closeCallback?: FeedbackProps["closeCallback"];
  icon?: IconComponent;
  permanentCloseConfirmationTooltipInfo?: ReactNode;
  storageKey?: string;
  title?: ReactNode;
  variant: FeedbackVariant;
  withPermanentCloseConfirmation?: boolean;
};

const Callout = ({
  children,
  className,
  closeCallback,
  icon,
  permanentCloseConfirmationTooltipInfo,
  storageKey,
  title,
  variant,
  withPermanentCloseConfirmation,
}: CalloutProps) => {
  return (
    <Feedback
      variant={variant}
      type="callout"
      title={title}
      storageKey={storageKey}
      className={className}
      closeCallback={closeCallback}
      withPermanentCloseConfirmation={withPermanentCloseConfirmation}
      permanentCloseConfirmationTooltipInfo={permanentCloseConfirmationTooltipInfo}
      icon={icon}
    >
      {children}
    </Feedback>
  );
};

Callout.displayName = "DS.Callout";

export default Callout;
