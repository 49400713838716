import ButtonNew from "ds/components/Button/New";

import useConnectSlackIntegration from "./useConnectSlackIntegration";

const SettingsIntegrationsSlackConnectButton = () => {
  const { connectSlackIntegration, loading } = useConnectSlackIntegration();

  return (
    <ButtonNew variant="primary" onPress={connectSlackIntegration} loading={loading}>
      Connect
    </ButtonNew>
  );
};

export default SettingsIntegrationsSlackConnectButton;
