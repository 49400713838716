import { ChangeEvent, FunctionComponent, useMemo, useState } from "react";

import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import FormField from "ds/components/Form/Field";
import Button from "ds/components/Button";
import FormToggleField from "ds/components/Form/ToggleField";
import { AwsIntegration, AzureIntegration } from "types/generated";
import Banner from "ds/components/Banner";
import MissingDataBanner from "components/MissingDataBanner";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import Input from "ds/components/Input";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import ComboBox from "ds/components/ComboBox";
import ComboBoxItem from "ds/components/ComboBox/Item";

import NewStackAttachCloudAWSIntegrationWarning from "./AWSIntegrationWarning";
import { StackCreationCloud } from "../../types";
import TileCheckboxGrid from "../../TileCheckboxGrid";
import useAttachCloud from "../hooks/useAttachCloud";

type NewStackAttachCloudFormProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: StackCreationCloud;
  }>;
  attachableAzureIntegrations?: AzureIntegration[];
  attachableAwsIntegrations?: AwsIntegration[];
  cloud?: StackCreationCloud;
  setCloud: (value?: StackCreationCloud) => void;
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
};

const NewStackAttachCloudForm = ({
  cloudConfig,
  attachableAzureIntegrations,
  attachableAwsIntegrations,
  cloud,
  setCloud,
  hasData,
  refetch,
  refetching,
}: NewStackAttachCloudFormProps) => {
  const [integrationId, setIntegrationId] = useState<string | null>(null);
  const [azureSubscriptionId, setAzureSubscriptionId] = useState("");
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);

  useObserveForWarning(
    !!integrationId && (read || write),
    <>
      You have not attached the <strong>selected cloud</strong>. Do you want to continue without
      attaching it?
    </>
  );

  const availableIntegrations = useMemo(() => {
    if (cloud === StackCreationCloud.AWS) {
      return attachableAwsIntegrations
        ? attachableAwsIntegrations.map((value) => ({ value: value.id, label: value.name }))
        : [];
    }

    if (cloud === StackCreationCloud.Azure) {
      return attachableAzureIntegrations
        ? attachableAzureIntegrations.map((value) => ({ value: value.id, label: value.name }))
        : [];
    }

    return [];
  }, [attachableAwsIntegrations, attachableAzureIntegrations, cloud]);

  const { attach, attachLoading, isIntegrationAwsWarningVisible, hideIntegrationAwsWarning } =
    useAttachCloud();

  const handleIntegrationTypeChange = (cloud: StackCreationCloud) => {
    setIntegrationId("");
    setAzureSubscriptionId("");
    hideIntegrationAwsWarning();
    setCloud(cloud);
    setWrite(false);
    setRead(false);
  };

  const handleIntegrationChange = (integration: string | null) => {
    setIntegrationId(integration);

    if (cloud === StackCreationCloud.Azure) {
      const subscriptionId = attachableAzureIntegrations?.find(
        ({ id }) => id === integration
      )?.defaultSubscriptionId;

      if (subscriptionId) {
        setAzureSubscriptionId(subscriptionId);
      }
    }

    hideIntegrationAwsWarning();
  };

  const handleWriteChange = (isSelected: boolean) => {
    setWrite(isSelected);
    hideIntegrationAwsWarning();
  };

  const handleReadChange = (isSelected: boolean) => {
    setRead(isSelected);
    hideIntegrationAwsWarning();
  };

  const handleAzureSubscriptionIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAzureSubscriptionId(e.target.value);
  };

  const azureSubscriptionIdIsMissing =
    cloud === StackCreationCloud.Azure && !azureSubscriptionId.trim();

  const isAttachDisabled = !integrationId || (!read && !write);

  return (
    <>
      <Banner variant="info">
        You can only attach integrations from the current space and parent spaces that you inherit
        from.
      </Banner>
      {!hasData && (
        <Box margin="large 0 0 0" direction="column">
          <MissingDataBanner
            text="Couldn't load cloud integrations. Please try to refresh or come back later."
            refreshHandler={refetch}
            refreshLoading={refetching}
          />
        </Box>
      )}
      <Box direction="column" gap="x-large" margin="x-large 0 large 0">
        <TileCheckboxGrid>
          {cloudConfig.map(({ name, logo, type }) =>
            cloudConfig.length === 1 ? (
              <TileWrapper
                key={type}
                selected={cloud === type}
                onClick={() => handleIntegrationTypeChange(type)}
              >
                <TileContent direction="row" align="center">
                  <IconTile icon={logo} variant={cloud === type ? "primary" : "secondary"} />
                  <TileTitle>{name}</TileTitle>
                </TileContent>
              </TileWrapper>
            ) : (
              <Tile
                key={type}
                selected={cloud === type}
                title={name}
                icon={logo}
                onClick={() => handleIntegrationTypeChange(type)}
              />
            )
          )}
        </TileCheckboxGrid>
      </Box>
      {cloud && (
        <>
          <CardWrapper variant="filled" gap="large" direction="column" margin="large 0">
            <ComboBox
              label="Attach integration"
              value={integrationId}
              items={availableIntegrations}
              onChange={handleIntegrationChange}
            >
              {(item) => <ComboBoxItem id={item.value} label={item.label} />}
            </ComboBox>

            {cloud === StackCreationCloud.Azure && (
              <FormField noMargin label="Subscription ID">
                {({ ariaInputProps }) => (
                  <Input
                    value={azureSubscriptionId}
                    onChange={handleAzureSubscriptionIdChange}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}

            <FormToggleField
              variant="switch"
              onChange={handleReadChange}
              checked={read}
              title="Read"
              description="Integration will be used during read phases of runs (for example, plans)."
            />

            <FormToggleField
              variant="switch"
              onChange={handleWriteChange}
              checked={write}
              title="Write"
              description="Integration will be used during write phases of runs (for example, applies)"
            />
            {write && !read && (
              <Banner variant="warning" title="Read permission not selected">
                Selecting only write permission will make the runs fail in the planning phase.
              </Banner>
            )}
            <Box justify="end">
              <Button
                variant="contrast"
                loading={attachLoading}
                onClick={() => {
                  if (integrationId) {
                    return attach(cloud, {
                      integrationId: integrationId,
                      read,
                      write,
                      azureSubscriptionId,
                    });
                  }
                }}
                disabled={isAttachDisabled || attachLoading || azureSubscriptionIdIsMissing}
              >
                Attach
              </Button>
            </Box>
          </CardWrapper>
          {isIntegrationAwsWarningVisible && integrationId && (
            <NewStackAttachCloudAWSIntegrationWarning
              read={read}
              write={write}
              integrationId={integrationId}
            />
          )}
        </>
      )}
    </>
  );
};

export default NewStackAttachCloudForm;
