import { memo, useCallback, useMemo } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { Point } from "@visx/point";

import useContainerSize from "hooks/useContainerSize";
import Box from "ds/components/Box";

import styles from "./styles.module.css";
import ChartTooltip from "../components/Tooltip";
import { tooltipContainerStyles } from "../components/Tooltip/helpers";
import { CHART_THICKNESS, EMPTY_DATA, TOOLTIP_OFFSET } from "./constants";
import { Datum, PieChartBaseProps } from "./types";
import PieChartOverlay from "./Overlay";

type PieChartProps = PieChartBaseProps;

type TooltipData = {
  data: Datum;
  hoveredId: string;
};

const tooltipStyles = { ...defaultStyles, ...tooltipContainerStyles };

const PieChart = ({
  data,
  renderTooltip,
  centralInfo,
  tooltipReactToScroll,
  forcedHoveredId,
  hideTooltipCallback,
  showTooltipCallback,
  disableTooltip,
}: PieChartProps) => {
  const { containerRef: svgContainerRef, width: parentWidth } = useContainerSize();

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: tooltipReactToScroll,
  });

  const radius = parentWidth / 2;
  const centerY = radius;
  const centerX = radius;

  const handleMouseMove = useCallback(
    (arcId: string, coordinates: Point | null) => {
      const foundData = data.find(({ id }) => id === arcId);

      if (foundData) {
        const tooltipData = { ...foundData };

        showTooltip({
          tooltipData: { data: tooltipData, hoveredId: arcId },
          tooltipTop: coordinates?.y,
          tooltipLeft: coordinates?.x,
        });
        showTooltipCallback?.(tooltipData);
      }
    },
    [data, showTooltip, showTooltipCallback]
  );

  const filteredData = useMemo(() => {
    return data?.filter((item) => !!item.value) || [];
  }, [data]);

  const minValue = useMemo(() => {
    return filteredData.reduce((acc, next) => acc + next.value, 0) / 100;
  }, [filteredData]);

  const isEmpty = filteredData.length === 0;

  return (
    <Box ref={svgContainerRef} fullWidth grow="1">
      {centralInfo && (
        <div className={styles.centralInfoContainer}>
          <div className={styles.centralInfo} style={{ top: centerY, left: centerX }}>
            {centralInfo}
          </div>
        </div>
      )}

      <svg ref={containerRef} width={parentWidth} height={parentWidth}>
        <Group top={centerY} left={centerX}>
          <Pie
            data={isEmpty ? EMPTY_DATA : filteredData}
            pieValue={(d) => (d.value > minValue ? d.value : minValue)}
            outerRadius={radius}
            innerRadius={radius - CHART_THICKNESS}
            padAngle={0.04}
          >
            {(pie) =>
              pie.arcs.map((arc, index) => {
                const arcPath = pie.path(arc);
                const hoveredArcId = forcedHoveredId || tooltipData?.data.id;
                const arcColor =
                  hoveredArcId === arc.data.id ? arc.data.activeColor : arc.data.inactiveColor;
                const arcColorNoId = arc.data.color || "var(--colors-gray-100)";

                return (
                  arcPath && (
                    <Group key={index}>
                      <path
                        d={arcPath}
                        fill={hoveredArcId === undefined ? arcColorNoId : arcColor}
                      />
                    </Group>
                  )
                );
              })
            }
          </Pie>
          {!isEmpty && (
            <PieChartOverlay
              radius={radius}
              minValue={minValue}
              thickness={CHART_THICKNESS}
              data={filteredData}
              onMouseMove={handleMouseMove}
              onMouseLeave={hideTooltip}
              onMouseLeaveCallback={hideTooltipCallback}
              forcedHoveredId={forcedHoveredId}
            />
          )}
        </Group>
      </svg>

      {tooltipOpen && tooltipData?.data && !disableTooltip && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          offsetLeft={TOOLTIP_OFFSET.left}
          offsetTop={TOOLTIP_OFFSET.top}
          style={tooltipStyles}
        >
          <ChartTooltip>{renderTooltip?.(tooltipData?.data)}</ChartTooltip>
        </TooltipInPortal>
      )}
    </Box>
  );
};

PieChart.displayName = "DS.Charts.PieChart.Chart";

export default memo(PieChart);
