import { useEffect, useMemo } from "react";
import { useForm, SubmitHandler, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Space } from "types/generated";
import FormFieldTags from "components/FormFields/Tags";
import SpaceSelect from "components/SpaceSelect";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageSpaces } from "hooks/useAnalytics/pages/spaces";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import Toggle from "ds/components/Toggle";

import { SpacesViewContext } from "../Context";
import styles from "./styles.module.css";
import { SPACE_CREATE, SPACE_UPDATE } from "../gql";
import { getDefaultValues } from "./helpers";
import { showDeleteConfirmation } from "../DeleteConfirmation";
import { SpacesViewActionsContext } from "../ActionsContext";
import { SpacesPageLayoutContext } from "../PageLayout";

type SpaceDrawerFormFields = {
  name: string;
  description?: string;
  inheritEntities: boolean;
  parentSpace: string;
  labels: Record<"value", string>[];
};

type SpaceDrawerBuilderProps = {
  drawerResetFormTrigger: number;
  handleCloseDrawer: () => void;
};

const SpaceDrawerBuilder = ({
  drawerResetFormTrigger,
  handleCloseDrawer,
}: SpaceDrawerBuilderProps) => {
  const { layoutMode } = useTypedContext(SpacesPageLayoutContext);
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageSpaces.Spaces,
  });
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { currentSpace, drawerMode } = useTypedContext(SpacesViewContext);
  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const isEditMode = drawerMode === "edit";
  const isCreateMode = drawerMode === "create";

  const builderForm = useForm<SpaceDrawerFormFields>({
    defaultValues: getDefaultValues(isEditMode, currentSpace),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [spaceCreate, { loading: isSpaceCreateLoading }] = useMutation<{ spaceCreate: Space }>(
    SPACE_CREATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );
  const [spaceUpdate, { loading: isSpaceUpdateLoading }] = useMutation<{ spaceUpdate: Space }>(
    SPACE_UPDATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["GetSpaces"],
    }
  );

  const hiddenIds = useMemo(() => {
    if (!isCreateMode && currentSpace?.id) {
      return [currentSpace.id];
    }

    return [];
  }, [currentSpace?.id, isCreateMode]);

  const handleCancel = () => {
    reset();
    onCloseDrawer();
  };

  const afterSubmit = () => {
    reset();
    onCloseDrawer();
  };

  const onSubmit: SubmitHandler<SpaceDrawerFormFields> = (data) => {
    if (isEditMode && currentSpace?.id) {
      spaceUpdate({
        variables: {
          spaceId: currentSpace.id,
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceUpdate?.name) {
            reportSuccess({ message: `Space "${data.spaceUpdate.name}" is successfully updated` });
            afterSubmit();
          }
        })
        .catch(onError);
    } else {
      spaceCreate({
        variables: {
          input: {
            name: data.name,
            description: data.description,
            inheritEntities: data.inheritEntities,
            parentSpace: data.parentSpace,
            labels: data.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.spaceCreate?.name) {
            reportSuccess({ message: `Space "${data.spaceCreate.name}" is successfully created` });
            afterSubmit();

            const baseAnalyticsProps = {
              id: data.spaceCreate.id,
              parentSpace: data.spaceCreate.parentSpace ?? undefined,
              inheritEntities: data.spaceCreate.inheritEntities,
              labels: data.spaceCreate.labels,
            };

            if (currentSpace) {
              trackSegmentEvent("Child Space Added", {
                ...baseAnalyticsProps,
                view: layoutMode ?? undefined,
              });
            } else {
              trackSegmentEvent("Space Created", baseAnalyticsProps);
            }
          }
        })
        .catch(onError);
    }
  };

  const handleSpaceDeleteConfirmation = () => {
    if (!currentSpace?.id) {
      return;
    }

    showDeleteConfirmation({ name: currentSpace.name, id: currentSpace.id }).then(afterSubmit);
  };

  useEffect(() => {
    reset(getDefaultValues(isEditMode, currentSpace));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace, isEditMode]);

  useEffect(() => {
    if (drawerResetFormTrigger > 0) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerResetFormTrigger]);

  const isPrimaryActionLoading = isEditMode ? isSpaceUpdateLoading : isSpaceCreateLoading;

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isCreateMode ? "Create a new space" : "Space details"} />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody noPadding>
        <div className={styles.viewList}>
          {isEditMode && currentSpace?.id && (
            <FormFieldViewText label="ID" value={currentSpace.id} withCopy />
          )}

          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter space name here..."
                error={!!errors?.name}
                {...register("name", { required: "Name field is required." })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter space description here..."
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldTags label="Labels" tagName="label" name="labels" />
        </div>

        <div className={styles.viewList}>
          <Typography tag="h5" variant="p-t5" className={styles.title}>
            Inherit entities
          </Typography>

          <Typography tag="p" variant="p-body2" className={styles.info}>
            Should read access and attachable entities be inherited from the parent space.
          </Typography>

          <Controller
            name="inheritEntities"
            control={control}
            render={({ field }) => (
              <Toggle
                variant="checkbox"
                id={field.name}
                onChange={field.onChange}
                isSelected={field.value}
                aria-label={field.value ? "Disable inheritance" : "Enable inheritance"}
              >
                Inheritance enabled
              </Toggle>
            )}
          />
        </div>

        <div className={styles.viewList}>
          <Typography tag="h5" variant="p-t5" className={styles.title}>
            Parent
          </Typography>

          <Typography tag="p" variant="p-body2" className={styles.info}>
            Choose the parent space for this space.
          </Typography>

          <Controller
            name="parentSpace"
            control={control}
            rules={{ required: "Parent space field is required." }}
            render={({ field, fieldState }) => (
              <FormField error={fieldState.error?.message} noMargin>
                {({ ariaInputProps }) => (
                  <SpaceSelect
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error?.message}
                    hiddenIds={hiddenIds}
                    disabled={isCreateMode && !!currentSpace}
                    ariaProps={ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />
        </div>
      </DrawerBody>
      <DrawerFooter>
        {isEditMode && (
          <Button
            className={styles.deleteSpaceLink}
            variant="dangerSecondary"
            onClick={handleSpaceDeleteConfirmation}
          >
            Delete space
          </Button>
        )}
        <DrawerFooterActions>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || (isEditMode && !isDirty) || isPrimaryActionLoading}
            loading={isPrimaryActionLoading}
          >
            {isEditMode ? "Save" : "Create"}
          </Button>
        </DrawerFooterActions>
      </DrawerFooter>
    </FormProvider>
  );
};

export default SpaceDrawerBuilder;
