import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import setLocation from "shared/setLocation";
import { BillingTierFeature, Maybe, SamlSettings } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import SettingItem from "components/SettingItem";
import SettingItemLink from "components/SettingItem/SettingItemLink";
import SettingItemContent from "components/SettingItem/SettingItemContent";
import SettingItemActions from "components/SettingItem/SettingItemActions";
import Button from "components/button/Button";
import useAnalytics from "hooks/useAnalytics";
import useTierFeature from "views/Account/hooks/useTierFeature";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import TierInfo from "components/TierInfo";
import Box from "ds/components/Box";

import IdentityProviderConfiguration from "./IdentityProviderConfiguration";
import SSOSetupForm from "./SSOSetupForm";
import ViewConfiguration from "./ViewConfiguration";
import { DELETE_SAML } from "./gql";
import styles from "./styles.module.css";

type SAMLPaneProps = {
  integration: Maybe<SamlSettings>;
  disabled: boolean;
};

const SETUP_PATH = "saml";

const SAMLPane = ({ disabled, integration }: SAMLPaneProps) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const navigate = useNavigate();
  const location = useLocation();

  const isSamlActive = useTierFeature(BillingTierFeature.CustomSsoSaml);

  const isDisabled = disabled || !isSamlActive;
  const isSetupMode = location.pathname.includes(SETUP_PATH) && !isDisabled;

  const [deleteSaml, { loading: isDeleting }] = useMutation(DELETE_SAML, {
    refetchQueries: ["GetSSOSettings"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSAML,
    defaultCallbackTrackProperties: {
      method: "SAML",
    },
  });

  const onDisable = () => {
    trackSegmentAnalyticsEvent("Confirm disable click");
    deleteSaml()
      .then(() => reportSuccess({ message: "SAML successfully disabled" }))
      .catch(onError);
  };

  const handleSetUp = () => {
    trackSegmentAnalyticsEvent("SAML Set Up Click");

    navigate(isSetupMode ? "../" : `../${SETUP_PATH}`);
  };

  const toggleDeleteConfirmation = () => {
    trackSegmentAnalyticsEvent(deleteConfirmation ? "Cancel disable click" : "Disable Click");
    setDeleteConfirmation((state) => !state);
  };

  const handleActivate = () => {
    trackSegmentAnalyticsEvent("Activate Click");
    setLocation("/saml/start");
  };

  return (
    <SettingItem>
      <SettingItemLink
        className={styles.topPanel}
        to={`../${SETUP_PATH}`}
        title="SAML Settings"
        description=""
        disabled={isDisabled}
      >
        <SettingItemActions>
          {!integration && !isSetupMode && isSamlActive && (
            <Tooltip
              on={(props) => (
                <span {...props}>
                  <Button disabled={isDisabled} type="button" onClick={handleSetUp} full pill>
                    Set up
                  </Button>
                </span>
              )}
              active={isDisabled}
            >
              OIDC integration already present, can't set up SAML
            </Tooltip>
          )}
          {!deleteConfirmation && integration?.adminLogin === null && isSamlActive && (
            <Button type="button" disabled={isDeleting} onClick={handleActivate} pill>
              Activate
            </Button>
          )}
          {integration && !deleteConfirmation && (
            <Button
              type="button"
              danger
              loading={isDeleting}
              onClick={toggleDeleteConfirmation}
              pill
            >
              Disable
            </Button>
          )}
          {integration && deleteConfirmation && (
            <>
              <Button type="button" loading={isDeleting} onClick={onDisable} pill full danger>
                Confirm
              </Button>

              <Button type="button" loading={isDeleting} onClick={toggleDeleteConfirmation} pill>
                Cancel
              </Button>
            </>
          )}
        </SettingItemActions>
      </SettingItemLink>

      {!isSamlActive && (
        <Box padding="0 0 x-large 0" gap="x-large" direction="column">
          {integration ? (
            <TierInfo
              type="banner"
              variant="danger"
              feature={BillingTierFeature.CustomSsoSaml}
              title="SAML SSO 2.0 is not supported by your plan"
            >
              You can still use or disable the integration, but to make changes, please upgrade to
              the <strong>Enterprise</strong> plan.
            </TierInfo>
          ) : (
            <TierInfo
              variant="promo"
              type="banner"
              feature={BillingTierFeature.CustomSsoSaml}
              title="Upgrade plan to use SAML SSO 2.0"
            >
              Add extra layer of security to your account by enabling SAML SSO 2.0
            </TierInfo>
          )}
        </Box>
      )}
      <IdentityProviderConfiguration />

      <Routes>
        {!isDisabled && (
          <Route
            path={SETUP_PATH}
            element={
              <SettingItemContent title={`SAML Settings · ${accountName}`}>
                {!!integration && (
                  <ViewConfiguration integration={integration} onFormClose={handleSetUp} />
                )}
                {!integration && isSamlActive && <SSOSetupForm onCancel={handleSetUp} />}
              </SettingItemContent>
            }
          />
        )}
      </Routes>
    </SettingItem>
  );
};

export default SAMLPane;
