import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect, useId } from "react";

import { User } from "components/icons/generated";
import Box from "ds/components/Box";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import MetricCard from "components/MetricCard";
import Icon from "ds/components/Icon";
import Button from "ds/components/Button";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import { useToggle } from "hooks/useToggle";
import Tile from "ds/components/Tile";
import Timestamp from "components/time/Timestamp";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import MissingDataBanner from "components/MissingDataBanner";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { GET_USERS_ACTIVITY } from "./gql";
import DashboardOverviewColumnUserActivitySkeleton from "./Skeleton";
import { GetUsersActivity } from "./types";
import { getLastLogins } from "./getLastLogins";
import { OverviewStaticWidget } from "../../types";
import { DashboardContext } from "../../context";
import usePollInterval from "../../usePollInterval";

const DashboardOverviewColumnUserActivity = () => {
  const pollInterval = usePollInterval();
  const { updateTabOverviewColumnConfig, config } = useTypedContext(DashboardContext);

  const [isCollapsed, handleToggle] = useToggle(true);

  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetUsersActivity>(GET_USERS_ACTIVITY, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.usage?.logins;

  const usersCount = showErrorBanner ? 0 : data?.usage?.logins?.length || 0;

  const lastLogins = getLastLogins(data?.usage?.logins);

  const hasLastLogins = !showErrorBanner && !!lastLogins?.length;

  const onHide = () => {
    if (config["overview"].overviewColumn) {
      updateTabOverviewColumnConfig(
        config["overview"].overviewColumn.map((item) =>
          item.value === OverviewStaticWidget.UserActivity ? { ...item, hidden: true } : item
        )
      );
    }
  };

  const titleId = useId();

  return (
    <TileWrapper padding="x-large x-large 0 x-large" grow="0" noBorder>
      <TileContent direction="column" gap="large">
        <Box justify="between" align="center" grow="1">
          <Box align="center" gap="large">
            <TileTitle variant="p-t5">Users active in the last 28 days</TileTitle>
          </Box>
          <DropdownMenuEllipsis tooltip="Settings" buttonVariant="ghost">
            <DropdownMenuItem onAction={onHide}>Hide</DropdownMenuItem>
            <DropdownMenuItem href="/settings/users">Go to user management</DropdownMenuItem>
          </DropdownMenuEllipsis>
        </Box>

        {!showErrorBanner && isLoading ? (
          <DashboardOverviewColumnUserActivitySkeleton />
        ) : (
          <Box direction="column" padding={hasLastLogins ? "0" : "0 0 x-large 0"}>
            {showErrorBanner && (
              <Box margin="0 0 x-large 0" fullWidth>
                <MissingDataBanner
                  text="Couldn’t load user data. Please try to refresh or come back later. "
                  refreshHandler={refetch}
                  refreshLoading={loading}
                  fullWidth
                />
              </Box>
            )}
            <MetricCard
              noBorder
              titleIcon={<Icon src={User} />}
              action={
                <Button
                  size="medium"
                  variant="primary"
                  analyticsTitle="Users Widget - Invite Users Clicked"
                  analyticsPage={AnalyticsPageDashboard.Dashboard}
                  to="/settings/users"
                >
                  Invite user
                </Button>
              }
              titleColor="primary"
              title="Users"
              value={usersCount}
              className={styles.purpleWeakBackground}
            />
            {hasLastLogins && (
              <CollapsiblePanel
                variant="section"
                onToggle={handleToggle}
                isCollapsed={isCollapsed}
                withTransition
                noBorder
              >
                <CollapsiblePanelHeader padding="x-large 0" align="center" gap="medium">
                  <CollapsiblePanelToggleTrigger
                    analyticsPage={AnalyticsPageDashboard.Dashboard}
                    analyticsTitle="Users Widget - Last logins Clicked"
                    ariaLevel={3}
                    ariaLabelledby={titleId}
                  />
                  <CollapsiblePanelTitle id={titleId} variant="p-t6">
                    Last logins
                  </CollapsiblePanelTitle>
                </CollapsiblePanelHeader>
                <CollapsiblePanelContent gap="medium" padding="0 0 x-large 0">
                  {lastLogins.map((item) => (
                    <Tile
                      key={item.username}
                      title={item.username}
                      description={
                        <TextEllipsis tooltip={<Timestamp timestamp={item.timestamp} />}>
                          {(props) => (
                            <Typography {...props} color="secondary" tag="span" variant="p-body3">
                              {item.timestamp && <Timestamp timestamp={item.timestamp} />}
                            </Typography>
                          )}
                        </TextEllipsis>
                      }
                    />
                  ))}
                </CollapsiblePanelContent>
              </CollapsiblePanel>
            )}
          </Box>
        )}
      </TileContent>
    </TileWrapper>
  );
};

export default DashboardOverviewColumnUserActivity;
