import Feedback, { type FeedbackProps } from "../Feedback";

export type BannerProps = Omit<FeedbackProps, "type">;

const Banner = ({ children, ...restProps }: BannerProps) => {
  return (
    <Feedback type="banner" {...restProps}>
      {children}
    </Feedback>
  );
};

Banner.displayName = "DS.Banner";

export default Banner;
