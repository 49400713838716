import { Controller, useForm } from "react-hook-form";
import { create, show, useModal } from "@ebay/nice-modal-react";

import Typography from "ds/components/Typography";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import { fileToBase64 } from "utils/file";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import ConfirmationModal from "ds/components/ConfirmationModal";

import useResetWorkerPool from "../WorkerPools/useResetWorkerPool";

type ResetWorkerPoolFormFields = {
  certificateFile: File | null;
};

type WorkerPoolResetConfirmationProps = {
  name: string;
  id: string;
  viewPageMode?: boolean;
};

const WorkerPoolResetConfirmation = create(function WorkerPoolResetConfirmation({
  name,
  id,
  viewPageMode,
}: WorkerPoolResetConfirmationProps) {
  const { onError } = useTypedContext(FlashContext);

  const { resetWorkerPool, loading } = useResetWorkerPool(viewPageMode);

  const resetWorkerPoolForm = useForm<ResetWorkerPoolFormFields>({
    defaultValues: {
      certificateFile: null,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = resetWorkerPoolForm;

  const modal = useModal();

  const handleOnReset = async (data: ResetWorkerPoolFormFields) => {
    if (!data.certificateFile) {
      return;
    }

    try {
      const base64 = await fileToBase64(data.certificateFile);

      if (base64) {
        resetWorkerPool(id, base64.toString().split(",")[1]).then(() => modal.hide());
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <ConfirmationModal
      title="Reset worker pool"
      confirmationButtonLabel="Reset"
      onConfirm={handleSubmit(handleOnReset)}
      isLoading={loading || isSubmitting}
      isDismissable={!loading && !isSubmitting}
      isConfirmationDisabled={loading || isSubmitting || !isValid}
    >
      <Box direction="column" gap="large">
        <Banner variant="info" fullWidth>
          Resetting this worker pool means causes that existing workers will no longer be able to
          connect to Spacelift and will need to be restarted with the new configuration.
        </Banner>

        <Controller
          name="certificateFile"
          control={control}
          rules={{ required: "Certificate field is required." }}
          render={({ field }) => (
            <DragDropFileUpload
              ariaLabel="Upload certificate .csr file"
              caption="Drag and drop the .csr file here"
              onChange={field.onChange}
              file={field.value}
            />
          )}
        />
        <Typography variant="p-body2" tag="p">
          Are you sure you want to reset your{" "}
          <Typography tag="span" variant="p-t5">
            {name}
          </Typography>{" "}
          worker pool?
        </Typography>
      </Box>
    </ConfirmationModal>
  );
});

export const showResetConfirmation = (props: WorkerPoolResetConfirmationProps) =>
  show(WorkerPoolResetConfirmation, props);
