import cx from "classnames";

import { Spinner } from "components/Spinner";
import formStyles from "components/FormDefault/styles.module.css";
import Icon from "ds/components/Icon";

const FormLoading = () => (
  <section className={formStyles.form}>
    <section className={cx(formStyles.body, formStyles.center)}>
      <Icon src={Spinner} size="x-large" />
    </section>
  </section>
);

export default FormLoading;
