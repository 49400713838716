import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Policy } from "types/generated";
import { showLoginPolicyEditDetailsDrawer } from "views/Account/LoginPolicyEditDetailsDrawer";

import LoginPoliciesListItem from "./Item";

type LoginPoliciesListVirtualizedItemProps = {
  loginPolicies: Policy[];
  takenPolicyNames: string[];
};

const LoginPoliciesListVirtualizedItem = (
  props: ListChildComponentProps<LoginPoliciesListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.loginPolicies[index];

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  const handleEditDetails = (item: Policy) => {
    showLoginPolicyEditDetailsDrawer({
      id: item.id,
      policyId: item.id,
      name: item.name,
      labels: item.labels,
      body: item.body,
      takenPolicyNames: data.takenPolicyNames,
    });
  };

  return (
    <div style={style}>
      <LoginPoliciesListItem
        key={item.id}
        item={item}
        setRowHeight={handleSetRowHeight}
        editDetailsHandler={() => handleEditDetails(item)}
      />
    </div>
  );
};

export default memo(LoginPoliciesListVirtualizedItem, areEqual);
