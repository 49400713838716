import cx from "classnames";
import { ReactNode } from "react";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type DropdownListWrapperProps = {
  children: ReactNode;
  className?: string;
} & BoxProps;

const DropdownListWrapper = ({ children, className, ...restProps }: DropdownListWrapperProps) => {
  return (
    <Box direction="column" className={cx(styles.dropdownList, className)} {...restProps}>
      {children}
    </Box>
  );
};

DropdownListWrapper.displayName = "DS.Dropdown.List.New.Wrapper";

export default DropdownListWrapper;
