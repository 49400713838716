import { ReactNode, useCallback, KeyboardEvent } from "react";
import cx from "classnames";

import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import { TreeNode } from "utils/tree";

import { BaseTreeListItemProps } from "./types";
import { TreeListContext } from "./Context";
import styles from "./styles.module.css";

type TreeNodeProps<T extends object> = {
  node: TreeNode<T>;
  renderItem: (option: BaseTreeListItemProps<T>) => ReactNode;
  isRoot?: boolean;
};

// eslint-disable-next-line spacelift/display-name
const FullTreeBranchLink = ({ children }: { children: ReactNode }) => (
  <TreeBranchLink className={styles.branchLink} full>
    {children}
  </TreeBranchLink>
);

const TreeListNode = <T extends object>({ node, renderItem, isRoot }: TreeNodeProps<T>) => {
  const {
    expandedKeys,
    renderedKeys,
    disabledKeys,
    toggleKey,
    selectedKey,
    searchQuery,
    onChange,
    onFocus,
    onKeyDown,
    selectedRef,
    focusedKey,
  } = useTypedContext(TreeListContext);
  const isExpanded = expandedKeys.has(node.id);
  const isRendered = renderedKeys.has(node.id);
  const isDisabled = disabledKeys?.includes(node.id);
  const isSelected = node.id === selectedKey;

  const handleToggle = useCallback(() => {
    toggleKey(node.id);
  }, [node.id, toggleKey]);

  const handleClick = useCallback(() => {
    onChange?.(node.id);
  }, [node.id, onChange]);

  const handleFocus = useCallback(() => {
    onFocus(node.id);
  }, [node.id, onFocus]);

  const handleBlur = useCallback(() => {
    onFocus("");
  }, [onFocus]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      onKeyDown(e, node.id);
    },
    [node.id, onKeyDown]
  );

  const hasChildren = node.children.some((child) => renderedKeys.has(child.id));

  const NodeWrapper = isRoot ? Box : TreeBranch;

  const OptionWrapper = isRoot ? Box : FullTreeBranchLink;

  if (!isRendered) {
    return null;
  }

  return (
    <NodeWrapper direction="column">
      <OptionWrapper className={cx(styles.branchLink, isRoot && styles.root)}>
        {renderItem({
          item: node.item,
          id: node.id,
          name: node.name,
          selected: isSelected,
          isExpanded: hasChildren ? isExpanded : undefined,
          isDisabled,
          searchQuery,
          innerRef: isSelected ? selectedRef : undefined,
          hasFocus: node.id === focusedKey,
          onToggle: hasChildren ? handleToggle : undefined,
          onClick: handleClick,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
        })}
      </OptionWrapper>
      {hasChildren && isExpanded && (
        <Tree fullWidth={false} className={styles.childTree} role="group">
          {node.children.map((child) => (
            <TreeListNode key={child.id} node={child} renderItem={renderItem} />
          ))}
        </Tree>
      )}
    </NodeWrapper>
  );
};

TreeListNode.displayName = "DS.TreeList.Node";

export default TreeListNode;
