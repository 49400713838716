import React from "react";
import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type TabPaneProps = {
  isActive: boolean;
  children?: React.ReactNode;
  id: string;
} & BoxProps;

const TabPanel = ({ className, isActive, children, id, ...restProps }: TabPaneProps) => {
  return (
    <Box
      className={cx({ [styles.hidden]: !isActive }, className)}
      role="tabpanel"
      hidden={!isActive}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      direction="column"
      {...restProps}
    >
      {isActive && <>{children}</>}
    </Box>
  );
};

TabPanel.displayName = "DS.TabPanel";

export default TabPanel;
