/* eslint react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import { addHours, addDays, startOfDay } from "date-fns";

import FlashContext from "components/FlashMessages/FlashContext";
import InputText from "components/input/InputText";

import styles from "./styles.module.css";

const ranges = {
  "Last 1 Hour": [moment(addHours(new Date(), -1)), moment(new Date())],
  "Last 4 Hours": [moment(addHours(new Date(), -4)), moment(new Date())],
  "Last 24 Hours": [moment(addDays(new Date(), -1)), moment(new Date())],
  "Last 7 Days": [moment(startOfDay(addDays(new Date(), -7))), moment(new Date())],
};

/**
 *
 * @deprecated Do nothing - waiting for the Runs redesign
 */
const ResourcesWrapperDatepicker = ({ dateRange, handleDateRangeChange }) => {
  const { reportError } = useContext(FlashContext);

  return (
    <div className={styles.resourcesRunDatePicker}>
      <div className="resources__label">Date Range:</div>
      <DateTimeRangeContainer
        start={dateRange.startDate}
        end={dateRange.endDate}
        startLabel={dateRange.label}
        ranges={ranges}
        local={{
          format: "DD-MM-YYYY HH:mm",
          sundayFirst: false,
        }}
        noMobileMode={true}
        leftMode={true}
        maxDate={moment(new Date())}
        applyCallback={(startDate, endDate) => {
          if (endDate.diff(startDate, "days") > 8) {
            reportError({ message: "Range is too large, max is 8 days" });
            handleDateRangeChange(dateRange.startDate, dateRange.endDate, dateRange.label);
            return;
          }

          let label = `${startDate.format("DD-MM-YYYY HH:mm")} - ${endDate.format(
            "DD-MM-YYYY HH:mm"
          )}`;
          for (const name of Object.keys(ranges)) {
            if (ranges[name][0].isSame(startDate) && ranges[name][1].isSame(endDate)) {
              label = name;
              break;
            }
          }
          handleDateRangeChange(startDate, endDate, label);
        }}
        style={{
          fromDot: { backgroundColor: "rgb(100, 0, 34)" },
          toDot: { backgroundColor: "rgb(0, 135, 255)" },
          fromDate: { backgroundColor: "var(--color-info-main)" },
          toDate: { backgroundColor: "var(--color-info-main)" },
          betweenDates: {
            backgroundColor: "var(--color-info-bg)",
            color: "var(--color-info-main)",
          },
          hoverCell: {
            backgroundColor: "var(--color-info-bg-2)",
            color: "var(--semantic-color-background-primary)",
          },
          customRangeButtons: {
            backgroundColor: "var(--semantic-color-background-primary)",
            border: "1px solid var(--color-primary-main)",
            color: "var(--color-primary-main)",
          },
          customRangeSelected: {
            backgroundColor: "var(--color-primary-main)",
          },
        }}
      >
        <InputText
          id="formControlsTextB"
          placeholder="Enter text"
          readOnly
          type="text"
          value={dateRange.label}
          style={{ cursor: "pointer" }}
        />
      </DateTimeRangeContainer>
    </div>
  );
};

export default ResourcesWrapperDatepicker;
