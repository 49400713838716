import Autocomplete from "ds/components/Autocomplete";

import styles from "./styles.module.css";
import DropdownHeader from ".";

type DropdownHeaderSearchProps = {
  className?: string;
  title?: string;
  subtitle?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchLoading?: boolean;
  searchPlaceholder?: string;
};

const DropdownHeaderSearch = ({
  title,
  subtitle,
  searchValue,
  setSearchValue,
  searchLoading,
  searchPlaceholder,
}: DropdownHeaderSearchProps) => {
  return (
    <DropdownHeader title={title} subtitle={subtitle}>
      <Autocomplete
        placeholder={searchPlaceholder || "Search"}
        query={searchValue}
        onChange={setSearchValue}
        className={styles.autocomplete}
        loading={searchLoading}
      />
    </DropdownHeader>
  );
};

DropdownHeaderSearch.displayName = "DS.Dropdown.Header.Search";

export default DropdownHeaderSearch;
