import cx from "classnames";

import Box from "ds/components/Box";
import SegmentedControl, { SegmentedControlProps } from "ds/components/SegmentedControl";

import styles from "./styles.module.css";

type DropdownFooterProps<T extends string> = SegmentedControlProps<T>;

const DropdownFooterSwitch = <T extends string>({
  className,
  onChange,
  value,
  options,
}: DropdownFooterProps<T>) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      fullWidth
      className={cx(styles.footer, className)}
    >
      <SegmentedControl onChange={onChange} value={value} options={options} />
    </Box>
  );
};

DropdownFooterSwitch.displayName = "DS.Dropdown.Footer.Switch";

export default DropdownFooterSwitch;
