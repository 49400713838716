import React, { AriaRole } from "react";
import cx from "classnames";

import TextEllipsis from "ds/components/TextEllipsis";
import BaseAction from "ds/components/BaseAction";
import { IconComponent } from "types/Icon";
import Tooltip from "ds/components/Tooltip";
import Icon from "ds/components/Icon";
import { TooltipProps } from "ds/components/Tooltip/types";
import Typography from "ds/components/Typography";
import BaseActionButton from "ds/components/BaseAction/Button";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";

type DefaultMetaInfoListItemProps = {
  // external link
  href?: string;
  // router link
  to?: string;
  className?: string;
  target?: string;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  children?: React.ReactNode;
  type?: string;
  fullWidth?: boolean;
  role?: AriaRole;
} & AnalyticsCommonProps;

type ApplyFilterProps =
  | {
      linkText: string;
      applyFilter?: (value: string) => void;
    }
  | {
      linkText?: never;
      applyFilter?: () => void;
    }
  | {
      linkText?: string;
      applyFilter?: never;
    };

type IconTooltipProps =
  | {
      icon: IconComponent;
      tooltip: React.ReactNode;
    }
  | {
      icon: IconComponent;
      tooltip?: never;
    }
  | {
      icon?: never;
      tooltip?: never;
    };

type MetaInfoListItemProps = DefaultMetaInfoListItemProps & IconTooltipProps & ApplyFilterProps;

const MetaInfoListItem = (props: MetaInfoListItemProps) => {
  const {
    icon,
    to,
    href,
    linkText,
    tooltip,
    children,
    target,
    tooltipPlacement,
    tooltipWidthMode,
    className,
    applyFilter,
    type = "",
    fullWidth,
    analyticsPage,
    analyticsProps,
    analyticsTitle,
    role,
  } = props;

  const isLink = linkText && (to || href);
  const iconOnly = !children && !linkText && icon;

  return (
    <Typography
      role={role}
      tag="span"
      variant="p-body2"
      className={cx(
        styles.metaInfoItem,
        {
          [styles.link]: isLink || applyFilter,
          [styles.iconOnly]: iconOnly,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
    >
      {icon && (
        <Tooltip
          on={(props) => <Icon {...props} src={icon} className={styles.icon} aria-hidden />}
          placement={tooltipPlacement}
          widthMode={tooltipWidthMode}
          active={!!tooltip}
        >
          {tooltip}
        </Tooltip>
      )}

      {applyFilter && (
        <DropdownMenu
          triggerComponent={
            <TextEllipsis tooltip={linkText || children} tooltipWidthMode="maxWidthSm">
              {(props) => (
                <BaseActionButton
                  {...props}
                  className={cx(props.className, styles.link, styles.linkDropdown)}
                >
                  {linkText || children}
                </BaseActionButton>
              )}
            </TextEllipsis>
          }
        >
          {isLink && (
            <DropdownMenuItem href={to || href} target={target}>
              {`Go to ${type}`.trim()}
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onAction={() => {
              if (typeof linkText === "undefined") {
                applyFilter();
              } else {
                applyFilter(linkText);
              }
            }}
          >
            Add to filters
          </DropdownMenuItem>
        </DropdownMenu>
      )}

      {isLink && !applyFilter && (
        <TextEllipsis tooltip={linkText} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <BaseAction
              analyticsPage={analyticsPage}
              analyticsProps={analyticsProps}
              analyticsTitle={analyticsTitle}
              to={to}
              href={href}
              className={styles.link}
              target={target}
            >
              <span {...props}>{linkText}</span>
            </BaseAction>
          )}
        </TextEllipsis>
      )}

      {!(to || href || applyFilter) && (
        <TextEllipsis tooltip={children} tooltipWidthMode="maxWidthSm">
          {(props) => <span {...props}>{children}</span>}
        </TextEllipsis>
      )}
    </Typography>
  );
};

export default MetaInfoListItem;
