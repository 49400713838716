import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";
import Typography from "../Typography";

type RadioProps = {
  id: string;
  checked?: boolean;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  "aria-labelledby"?: string;
};

const Radio = (props: RadioProps) => {
  const { id, checked, name, value, className, disabled, children, onChange } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!disabled) {
      onChange?.(e);
    }
  };

  return (
    <label
      className={cx(
        styles.radioWrapper,
        {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
        },
        className
      )}
      htmlFor={id}
    >
      <input
        id={id}
        type="radio"
        className={styles.input}
        value={value}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        aria-labelledby={props["aria-labelledby"]}
      />
      <div className={styles.radio}>
        <div className={styles.dot} />
      </div>

      {typeof children === "string" ? (
        <Typography tag="span" variant="p-body2" color={disabled ? "disabled" : "primary"}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </label>
  );
};

Radio.displayName = "DS.Radio";

export default Radio;
