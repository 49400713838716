import { differenceInDays, fromUnixTime } from "date-fns";
import { isBefore } from "date-fns/esm";

import { DebugInfo } from "types/generated";
import { getNowDateTime } from "utils/date";

import { LicenseExpirationStatus } from "./types";

/**
 * TODO: refactor it, the `undefined` result has a few meanings here,
 * it can be `LicenseExpirationStatus.VALID` status,
 * it also means the date is invalid or a issue with date-fns
 */
export const getLicenseRemainingDaysFromDebugInfo = (
  debugInfo: DebugInfo | undefined
): number | undefined => {
  const validityEndDate = debugInfo?.selfHostedLicence?.validity.end;
  if (!validityEndDate) {
    return undefined;
  }
  try {
    const expirationDate = fromUnixTime(debugInfo?.selfHostedLicence?.validity?.end ?? 0);
    const now = getNowDateTime();

    const beforeTheExpirationDay = isBefore(now, expirationDate);
    const remainingDays = differenceInDays(expirationDate, now);

    if (remainingDays === 0 && !beforeTheExpirationDay) {
      return -1;
    }

    return Number.isNaN(remainingDays) ? undefined : remainingDays;
  } catch {
    return undefined;
  }
};

export const getLicenseExpirationStatus = (remainingDays?: number): LicenseExpirationStatus => {
  if (remainingDays === undefined) {
    return LicenseExpirationStatus.VALID;
  }
  if (remainingDays < 0) {
    return LicenseExpirationStatus.EXPIRED;
  }
  if (remainingDays === 0) {
    return LicenseExpirationStatus.LAST_DAY;
  }
  if (remainingDays <= 14) {
    return LicenseExpirationStatus.IN_2_WEEKS;
  }
  if (remainingDays <= 60) {
    return LicenseExpirationStatus.IN_2_MONTHS;
  }
  return LicenseExpirationStatus.VALID;
};
