import { Draggable, Droppable } from "react-beautiful-dnd";
import { ReactNode } from "react";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import DraggableItem from "ds/components/DraggableItem";
import Textarea from "ds/components/Textarea";
import { HookType } from "utils/hooks";
import CardWrapper from "components/CardWrapper";
import FormField from "ds/components/Form/Field";
import ButtonNew from "ds/components/Button/New";

import { Command } from "../types";
import BeforeAfterCommandsEmptyState from "../EmptyState";
import Item from "./Item";
import { useCommandsInput } from "./useCommandsInput";

type CommandsProps = {
  type: HookType;
  title: ReactNode;
  commands: Command[];
  onAddCommands: (type: HookType, lines: string[]) => void;
  onRemoveCommand: (type: HookType, ids: string) => void;
  onEditCommand: (type: HookType, id: string, text: string) => void;
  isDragActive: boolean;
  readOnly?: boolean;
  onChangeCallback?: (value: string) => void;
};

const BeforeAfterCommandsList = ({
  type,
  title,
  commands,
  onAddCommands,
  onRemoveCommand,
  onEditCommand,
  readOnly,
  isDragActive,
  onChangeCallback,
}: CommandsProps) => {
  const { handleInputKeyDown, handleInputChange, handleAddCommand, input } = useCommandsInput(
    (lines) => onAddCommands(type, lines),
    onChangeCallback
  );

  return (
    <CardWrapper variant="filled" direction="column">
      {typeof title === "string" ? (
        <Typography tag="p" variant="p-t7" color="secondary" transform="uppercase">
          {title}
        </Typography>
      ) : (
        title
      )}
      <Droppable droppableId={type}>
        {(droppableProvided) => (
          <Box
            direction="column"
            ref={droppableProvided.innerRef}
            margin="medium 0 0 0"
            {...droppableProvided.droppableProps}
          >
            {commands.map((command, index) => (
              <Draggable
                draggableId={command.id}
                index={index}
                key={command.id}
                isDragDisabled={readOnly}
              >
                {(provided, snapshot) => (
                  <DraggableItem
                    ref={provided.innerRef}
                    size="small"
                    margin="0 0 medium 0"
                    dragging={snapshot.isDragging}
                    dropping={snapshot.isDropAnimating}
                    disabled={readOnly}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Item
                      text={command.text}
                      readOnly={readOnly}
                      onRemoveCommand={onRemoveCommand}
                      onEditCommand={onEditCommand}
                      type={type}
                      id={command.id}
                    />
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {!!commands.length && droppableProvided.placeholder}
            <BeforeAfterCommandsEmptyState
              isDragActive={isDragActive}
              type={type}
              hidden={!!commands.length}
            />
          </Box>
        )}
      </Droppable>
      {!readOnly && (
        <>
          <Box gap="medium" margin="0 0 small 0" align="start">
            <FormField
              fullWidth
              label="Add a command"
              helperText="Press Enter to add a new command, or Shift + Enter to add a new line"
            >
              {({ ariaInputProps }) => (
                <Box gap="medium" fullWidth>
                  <Textarea
                    {...ariaInputProps}
                    placeholder="Type your command, e.g. ls -la"
                    value={input}
                    minRows={1}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                  />

                  <ButtonNew variant="contrast" disabled={!input} onPress={handleAddCommand}>
                    Add
                  </ButtonNew>
                </Box>
              )}
            </FormField>
          </Box>
        </>
      )}
    </CardWrapper>
  );
};

export default BeforeAfterCommandsList;
