import { ReactNode } from "react";

import Box from "../Box";
import styles from "./styles.module.css";

type DrawerFooterActionsProps = {
  children: ReactNode;
};

const DrawerFooterActions = ({ children }: DrawerFooterActionsProps) => {
  return (
    <Box direction="row" className={styles.actions}>
      {children}
    </Box>
  );
};

DrawerFooterActions.displayName = "DS.Drawer.FooterActions";

export default DrawerFooterActions;
