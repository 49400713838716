import { Draggable, Droppable } from "react-beautiful-dnd";
import { useMemo } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import DraggableItemHandle from "ds/components/DraggableItem/Handle";
import DraggableItem from "ds/components/DraggableItem";
import { Column } from "components/DragDropList";
import CardWrapper from "components/CardWrapper";
import DropEmptyState from "components/DropEmptyState";
import { ArrowBoxColored } from "components/icons/generated";
import useTypedFlags from "hooks/useTypedFlags";
import Toggle from "ds/components/Toggle";

import { Widget } from "../types";
import styles from "./styles.module.css";
import { getAllDashboardWidgets } from "../helpers";

type DashboardManageDrawerItemsProps = {
  type: string;
  title?: string;
  items: Column<Widget>;
  isDragActive: boolean;
  onVisibilityChange: (id: Widget, hidden: boolean) => void;
};

const DashboardManageDrawerItems = ({
  type,
  title,
  items,
  isDragActive,
  onVisibilityChange,
}: DashboardManageDrawerItemsProps) => {
  const { numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend } =
    useTypedFlags();

  const allWidgets = useMemo(
    () =>
      getAllDashboardWidgets({
        numberOfDeploymentsWidgetFrontend,
        approvalPolicyEvaluationsWidgetFrontend,
      }),
    [numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend]
  );

  return (
    <CardWrapper variant="filled" direction="column">
      {title && (
        <Typography tag="h5" variant="p-t7" color="secondary" transform="uppercase">
          {title}
        </Typography>
      )}
      <Droppable droppableId={type}>
        {(droppableProvided) => (
          <Box
            direction="column"
            ref={droppableProvided.innerRef}
            margin="medium 0 0 0"
            {...droppableProvided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable draggableId={item.value} index={index} key={item.value}>
                {(provided, snapshot) => (
                  <DraggableItem
                    className={styles.draggable}
                    ref={provided.innerRef}
                    size="small"
                    margin="0 0 medium 0"
                    dragging={snapshot.isDragging}
                    dropping={snapshot.isDropAnimating}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DraggableItemHandle grow="1" align="center">
                      <Box align="center" gap="medium" grow="1" justify="between">
                        <Typography tag="span" variant="p-body2">
                          {allWidgets[item.value]?.title}
                        </Typography>
                        <Toggle
                          variant="switch"
                          isSelected={!item.hidden}
                          onChange={(isSelected) => onVisibilityChange(item.value, !isSelected)}
                        />
                      </Box>
                    </DraggableItemHandle>
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {items.length !== 0 && droppableProvided.placeholder}
            {items.length === 0 && (
              <DropEmptyState
                isDragActive={isDragActive}
                padding="large"
                icon={ArrowBoxColored}
                caption="Drag & Drop a widget from other column"
                dropCaption="You can drop the widget here"
              />
            )}
          </Box>
        )}
      </Droppable>
    </CardWrapper>
  );
};

export default DashboardManageDrawerItems;
