import { useQuery } from "@apollo/client";

import Box from "ds/components/Box";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";
import { isSelfHostedDistribution } from "utils/distribution";

import IntegrationsLayout from "./PageLayout";
import IntegrationsSlack from "./Slack";
import IntegrationsMSTeams from "./MSTeams";
import IntegrationsDataDog from "./DataDog";
import { GET_ACCOUNT_INTEGRATIONS } from "./gql";

const isSelfHosted = isSelfHostedDistribution();

const SettingsIntegrations = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery(GET_ACCOUNT_INTEGRATIONS, {
    variables: { isSelfHosted },
    onError,
  });

  useTitle(`Organization Settings · Integrations · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.slackIntegration) {
    return <PageLoading />;
  }

  return (
    <IntegrationsLayout>
      <Box direction="column" gap="large">
        <IntegrationsSlack integration={data?.slackIntegration} appConfig={data?.slackAppConfig} />
        <IntegrationsMSTeams />
        <IntegrationsDataDog />
      </Box>
    </IntegrationsLayout>
  );
};

export default SettingsIntegrations;
