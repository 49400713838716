import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import cx from "classnames";

import { DotsVertical } from "components/icons/generated";

import Box, { BoxProps } from "../Box";
import Icon from "../Icon";
import Typography, { TypographyProps } from "../Typography";
import styles from "./styles.module.css";
import Tooltip from "../Tooltip";

type DraggableItemHandleProps = BoxProps &
  Partial<Pick<TypographyProps, "variant">> & {
    dragHandleProps?: DraggableProvidedDragHandleProps | null;
    dragging?: boolean;
  };

function DraggableItemHandle({
  children,
  variant = "p-body3",
  dragHandleProps,
  dragging,
  ...rest
}: DraggableItemHandleProps) {
  return (
    <Box align="center" gap="medium" {...rest}>
      <Tooltip
        active={!!dragHandleProps && !dragging}
        on={(tooltipProps) => (
          <Box
            {...dragHandleProps}
            {...tooltipProps}
            aria-label="Drag and drop"
            className={cx(!!dragHandleProps && styles.handle)}
            justify="center"
            align="center"
          >
            <Icon src={DotsVertical} className={styles.handleIcon} size="default" />
          </Box>
        )}
      >
        Drag and drop
      </Tooltip>
      {typeof children === "string" ? (
        <Typography tag="span" variant={variant}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
}

DraggableItemHandle.displayName = "DS.DraggableItem.Handle";

export default DraggableItemHandle;
