import { ReactNode, useCallback } from "react";

import Typography from "ds/components/Typography";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import LinkNew from "ds/components/LinkNew";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import styles from "./styles.module.css";
import { showLaunchPadFAQDrawer } from "./Drawer";

type LaunchPadFAQItemProps = {
  title: string;
  children: ReactNode;
  learnMoreContent?: ReactNode;
  learnMoreTitle?: string;
};

const LaunchPadFAQItem = ({
  title,
  children,
  learnMoreContent,
  learnMoreTitle,
}: LaunchPadFAQItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
  });

  const [isCollapsed, onToggle] = useToggle(true);

  const handleToggle = useCallback(() => {
    if (isCollapsed) {
      trackSegmentAnalyticsEvent("FAQ Question Click", { title });
    }

    onToggle();
  }, [isCollapsed, onToggle, title, trackSegmentAnalyticsEvent]);

  const showDrawer = () => {
    if (!learnMoreContent || !learnMoreTitle) {
      return;
    }

    showLaunchPadFAQDrawer({
      title: learnMoreTitle,
      content: learnMoreContent,
    });
  };

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={handleToggle} withTransition>
      <CollapsiblePanelHeader padding="large" gap="small" align="center">
        <CollapsiblePanelToggleTrigger ariaLabel={title} ariaLevel={3} />
        <CollapsiblePanelTitle aria-hidden>{title}</CollapsiblePanelTitle>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent className={styles.faqItemContent}>
        <Typography tag="p" variant="p-body3">
          {children}{" "}
          {learnMoreContent && learnMoreTitle && (
            <LinkNew size="small" onPress={showDrawer}>
              Learn More
            </LinkNew>
          )}
        </Typography>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadFAQItem;
