import { ReactNode } from "react";
import cx from "classnames";
import { MenuItem, MenuItemProps } from "react-aria-components";

import Tooltip from "ds/components/Tooltip";
import { TooltipProps } from "ds/components/Tooltip/types";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { Spinner } from "components/Spinner";
import useAriaHrefProps from "components/AriaRouterProvider/useAriaHrefProps";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";
import useAnalyticsAction from "./useAnalyticsAction";

export type DropdownMenuItemProps = MenuItemProps &
  AnalyticsCommonProps & {
    active?: boolean;
    danger?: boolean;
    loading?: boolean;
    tooltipPlacement?: TooltipProps["placement"];
    tooltipWidthMode?: TooltipProps["widthMode"];
    tooltipDelay?: TooltipProps["delay"];
    isTooltipActive?: boolean;
    tooltip?: ReactNode;
  };

const DropdownMenuItem = ({
  children,
  danger,
  loading,
  isDisabled,
  className,
  active,
  onAction,
  tooltip,
  isTooltipActive = true,
  tooltipPlacement,
  tooltipWidthMode,
  tooltipDelay,
  analyticsPage,
  analyticsTitle,
  analyticsProps,
  href,
  ...restProps
}: DropdownMenuItemProps) => {
  const classNames = cx(
    styles.item,
    {
      [styles.danger]: danger,
      [styles.active]: active,
    },
    className
  );
  const handleAction = useAnalyticsAction({
    analyticsPage,
    analyticsTitle,
    analyticsProps,
    onAction,
  });
  const hrefProps = useAriaHrefProps(href);

  if (tooltip && isTooltipActive) {
    return (
      <Tooltip
        delay={tooltipDelay}
        placement={tooltipPlacement}
        widthMode={tooltipWidthMode}
        on={(tooltipProps) => (
          <MenuItem
            className={classNames}
            isDisabled={isDisabled}
            onAction={handleAction}
            {...restProps}
            {...tooltipProps}
            {...hrefProps}
          >
            {children}
          </MenuItem>
        )}
      >
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <MenuItem
      className={classNames}
      isDisabled={isDisabled}
      onAction={handleAction}
      {...restProps}
      {...hrefProps}
    >
      <>
        {loading ? (
          <>
            <Icon src={Spinner} size="stretch" className={styles.spinner} />
            {typeof children === "string" && (
              <span className={styles.loadingText}>{children} is loading</span>
            )}
          </>
        ) : (
          children
        )}
      </>
    </MenuItem>
  );
};

DropdownMenuItem.displayName = "DS.DropdownMenu.Item";

export default DropdownMenuItem;
