import CollapsiblePanel from "components/CollapsiblePanel";
import Box from "ds/components/Box";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import DraggableItem from "ds/components/DraggableItem";
import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import styles from "./styles.module.css";

type NewStackSummaryHookProps = {
  name: string;
  after?: Array<{ text: string }>;
  before?: Array<{ text: string }>;
};

const NewStackSummaryHook = ({ name, after, before }: NewStackSummaryHookProps) => {
  const [isCollapsed, toggle] = useToggle(false);

  const count = (after?.length || 0) + (before?.length || 0);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle}>
      <CollapsiblePanelHeader padding="medium large">
        <Box grow="1" align="center" gap="medium">
          <CollapsiblePanelToggleTrigger ariaLevel={2} ariaLabel={`${name}. ${count} elements.`} />
          <CollapsiblePanelTitle aria-hidden>
            <Box gap="medium" align="center">
              {name}
              <Counter count={count} />
            </Box>
          </CollapsiblePanelTitle>
        </Box>
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent padding="medium large large" gap="large">
        {!!before?.length && (
          <Box direction="column" padding="large" gap="medium" className={styles.hooksWrapper}>
            <Typography tag="p" variant="p-t7" color="secondary">
              BEFORE
            </Typography>
            <Box direction="column" margin="medium 0 0 0">
              {before?.map(({ text }, index) => (
                <DraggableItem key={index} size="small" margin="0 0 medium 0" disabled>
                  <Typography tag="p" variant="p-body2">
                    {text}
                  </Typography>
                </DraggableItem>
              ))}
            </Box>
          </Box>
        )}
        {!!after?.length && (
          <Box direction="column" padding="large" gap="medium" className={styles.hooksWrapper}>
            <Typography tag="p" variant="p-t7" color="secondary">
              AFTER
            </Typography>
            <Box direction="column" margin="medium 0 0 0">
              {after?.map(({ text }, index) => (
                <DraggableItem key={index} size="small" margin="0 0 medium 0" disabled>
                  <Typography tag="p" variant="p-body2">
                    {text}
                  </Typography>
                </DraggableItem>
              ))}
            </Box>
          </Box>
        )}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default NewStackSummaryHook;
