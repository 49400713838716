import useLocalStorage from "@rehooks/local-storage";

import TileContent from "ds/components/Tile/Content";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import { Cross } from "components/icons/generated";
import AnnouncementTile, { AnnouncementTileBackgroundType } from "components/AnnouncementTile";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import styles from "./styles.module.css";
import { AnnouncementProps } from "./types";
import { isExternalLink } from "./helpers";

const DashboardOverviewColumnAnnouncement = ({
  bannerId,
  content,
  dismissible = true,
  preTitle,
  primaryLink,
  primaryLinkText,
  secondaryLink,
  secondaryLinkText,
  title,
}: AnnouncementProps) => {
  const [closedOnboardingAnnouncements, setClosedOnboardingAnnouncements] = useLocalStorage<
    string[]
  >("dashboardWidgetAnnouncementClosed");

  if (closedOnboardingAnnouncements?.includes(bannerId)) {
    return null;
  }

  const isExternalPrimaryLink = isExternalLink(primaryLink ?? "");
  const isExternalSecondaryLink = isExternalLink(secondaryLink ?? "");

  return (
    <AnnouncementTile backgroundType={AnnouncementTileBackgroundType.Clouds}>
      <TileContent direction="column" gap="large">
        <Box direction="column">
          {dismissible && (
            <Box justify="end">
              <ButtonIconNew
                variant="ghostInversed"
                onPress={() =>
                  setClosedOnboardingAnnouncements(
                    closedOnboardingAnnouncements
                      ? [...closedOnboardingAnnouncements, bannerId]
                      : [bannerId]
                  )
                }
                icon={Cross}
              >
                Dismiss
              </ButtonIconNew>
            </Box>
          )}
          {preTitle && (
            <Typography className={styles.announcementGreyColor} tag="p" variant="p-t7">
              {preTitle}
            </Typography>
          )}
          <Typography className={styles.announcementLightContent} tag="p" variant="p-t4">
            {title}
          </Typography>
        </Box>

        <Typography className={styles.announcementLightContent} tag="p" variant="p-body3">
          {content}
        </Typography>
        <Box direction="row" gap="medium" fullWidth>
          {primaryLink && (
            <Button
              className={styles.linkButton}
              variant="primary"
              to={!isExternalPrimaryLink ? primaryLink : undefined}
              href={isExternalPrimaryLink ? primaryLink : undefined}
              target={isExternalPrimaryLink ? "_blank" : undefined}
              rel={isExternalPrimaryLink ? "noopener noreferrer" : undefined}
            >
              {primaryLinkText ?? ""}
            </Button>
          )}
          {secondaryLink && (
            <Button
              className={styles.linkButton}
              variant="secondary"
              to={!isExternalSecondaryLink ? secondaryLink : undefined}
              href={isExternalSecondaryLink ? secondaryLink : undefined}
              target={isExternalSecondaryLink ? "_blank" : undefined}
              rel={isExternalSecondaryLink ? "noopener noreferrer" : undefined}
            >
              {secondaryLinkText ?? ""}
            </Button>
          )}
        </Box>
      </TileContent>
    </AnnouncementTile>
  );
};

export default DashboardOverviewColumnAnnouncement;
