import { RefObject, useId } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import { Space } from "types/generated";
import { TreeNode } from "utils/tree";
import Box from "ds/components/Box";
import { ChevronRight, Lock } from "components/icons/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import Tooltip from "ds/components/Tooltip";
import Icon from "ds/components/Icon";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import SearchHighlight from "components/SearchHighlight";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import DropdownMenuSection from "ds/components/DropdownMenu/Section";
import DropdownMenuSectionHeader from "ds/components/DropdownMenu/Section/Header";
import DropdownMenuDivider from "ds/components/DropdownMenu/Divider";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import { COLUMN_GAP, COLUMN_ORDER, LEVEL_MARGIN, SPACE_TREE_LIST_ROW_TEST_ID } from "./constants";
import { createNavigationLink, isReadonlySpace } from "../../utils";
import { SpacesViewActionsContext } from "../../ActionsContext";

type SpacesTreeGridListRowProps = {
  node: TreeNode<Space>;
  isActive: boolean;
  isExpanded: boolean;
  isRootAdmin: boolean;
  searchQuery?: string;
  onToggle: () => void;
  innerRef?: RefObject<HTMLDivElement>;
};

const SpacesTreeGridListRow = ({
  isActive,
  isExpanded,
  isRootAdmin,
  searchQuery,
  onToggle,
  node,
  innerRef,
}: SpacesTreeGridListRowProps) => {
  const id = useId();
  const { onFilter, onDelete, onSelect, onCreate } = useTypedContext(SpacesViewActionsContext);

  const rowLevel = node.path.length;

  const getRowMargin = (isExpandable: boolean) => {
    const level = rowLevel - 1;
    const expandable = isExpandable ? "0px" : LEVEL_MARGIN;
    const res = `calc(${LEVEL_MARGIN} * ${level} + ${expandable})`;
    return res;
  };

  const handleSelect = () => {
    onSelect(node.id);
  };

  const handleCreate = () => {
    onCreate(node.id);
    if (!isExpanded) {
      onToggle();
    }
  };

  const handleDelete = () => {
    onDelete(node.id);
  };

  const canBeDeleted = !isReadonlySpace(node.id) && node.children?.length === 0;

  return (
    <ListEntitiesItem
      id={id}
      key={node.id}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
      data-testid={SPACE_TREE_LIST_ROW_TEST_ID}
      ref={innerRef}
      role="row"
      aria-level={rowLevel}
      aria-expanded={isExpanded}
    >
      <Box
        gap="medium"
        align="center"
        style={{ marginLeft: getRowMargin(node.children && node.children.length > 0) }}
        role="rowheader"
      >
        {node.children && node.children.length > 0 && (
          <Box shrink="0">
            <ButtonIconNew
              icon={ChevronRight}
              iconRotate={isExpanded ? "90" : undefined}
              aria-expanded={isExpanded}
              aria-controls={id}
              onPress={onToggle}
              variant="ghost"
            >
              {isExpanded ? "Collapse" : "Expand"}
            </ButtonIconNew>
          </Box>
        )}
        <ListEntitiesItemLink
          title={node.name}
          to={createNavigationLink(`/spaces/${node.id}`)}
          searchQuery={searchQuery}
        />
        {isReadonlySpace(node.id) && isRootAdmin && (
          <Tooltip mode="parent" on={(tooltipProps) => <Icon {...tooltipProps} src={Lock} />}>
            This space is not editable.
          </Tooltip>
        )}

        {!isRootAdmin && <SpaceAccessBadge accessLevel={node.item.accessLevel} />}
      </Box>
      <Box direction="row" align="center" shrink="0" role="gridcell">
        <TextEllipsis tooltip={node.id}>
          {(props) => (
            <Typography {...props} variant="p-body2" tag="span">
              {searchQuery ? (
                <SearchHighlight fullText={node.id} searchQuery={searchQuery} />
              ) : (
                node.id
              )}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
      <Box direction="row" align="center" shrink="0" role="gridcell">
        <Typography tag="span" variant="p-body2">
          {node.item.inheritEntities ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box direction="row" align="center" shrink="0" role="gridcell">
        <DropdownMenuEllipsis tooltip="Space actions">
          <DropdownMenuItem onAction={handleCreate}>Add child space</DropdownMenuItem>
          <DropdownMenuItem onAction={handleSelect}>
            {isReadonlySpace(node.id) ? "Details" : "Edit"}
          </DropdownMenuItem>

          <CopyFieldDropdownMenuItem title="Copy ID" value={node.id} />

          {canBeDeleted && (
            <DropdownMenuItem onAction={handleDelete} danger>
              Delete
            </DropdownMenuItem>
          )}
          <DropdownMenuDivider />
          <DropdownMenuSection>
            <DropdownMenuSectionHeader>Filters</DropdownMenuSectionHeader>
            <DropdownMenuItem onAction={() => onFilter(node.id, "/stacks")}>
              View Stacks
            </DropdownMenuItem>
            <DropdownMenuItem onAction={() => onFilter(node.id, "/blueprints")}>
              View Blueprints
            </DropdownMenuItem>
            <DropdownMenuItem onAction={() => onFilter(node.id, "/webhooks")}>
              View Webhooks
            </DropdownMenuItem>
          </DropdownMenuSection>
        </DropdownMenuEllipsis>
      </Box>
    </ListEntitiesItem>
  );
};

export default SpacesTreeGridListRow;
