import { Clock } from "components/icons/generated";
import Timestamp from "components/time/Timestamp";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";

import styles from "../styles.module.css";

type ConfigTimestampProps = {
  createdAt: number;
};

const ConfigTimestamp = ({ createdAt }: ConfigTimestampProps) => (
  <Box className={styles.variableTimestamp} align="center" gap="small" margin="0 0 0 large">
    <Icon src={Clock} />
    <Timestamp timestamp={createdAt} />
  </Box>
);

export default ConfigTimestamp;
