import { ReactNode, useId } from "react";
import cx from "classnames";

import BaseActionButton from "ds/components/BaseAction/Button";

import Box, { BoxProps } from "../../Box";
import styles from "./styles.module.css";
import { TileContext } from "../Context";

type TileWrapperButtonProps = {
  children: ReactNode;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  noBorder?: boolean;
} & BoxProps;

const TileWrapperButton = ({
  children,
  selected,
  disabled,
  onClick,
  className,
  noBorder,
  highlighted,
  ...boxProps
}: TileWrapperButtonProps) => {
  const titleId = useId();
  const descriptionId = `${titleId}-description`;

  return (
    <TileContext.Provider value={{ titleId, descriptionId }}>
      <Box grow="1" shrink="1" {...boxProps}>
        <BaseActionButton
          onPress={onClick}
          className={cx(
            styles.tileWrapper,
            styles.tileWrapperButton,
            selected && styles.selected,
            highlighted && styles.highlighted,
            disabled && styles.disabled,
            onClick && styles.clickable,
            noBorder && styles.noBorder,
            className
          )}
          aria-labelledby={titleId}
          aria-describedby={descriptionId}
        >
          {children}
        </BaseActionButton>
      </Box>
    </TileContext.Provider>
  );
};

TileWrapperButton.displayName = "DS.Tile.Wrapper.Button";

export default TileWrapperButton;
