import cx from "classnames";
import { datadogRum } from "@datadog/browser-rum";

import Box from "ds/components/Box";
import { FiltersContext } from "components/Filters";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { Spinner } from "components/Spinner";
import { useDefaultView } from "components/Filters/useDefaultView";
import { DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME } from "components/Filters/constants";
import { FiltersApplyView } from "components/Filters/types";
import TextEllipsis from "ds/components/TextEllipsis";
import { withEnterKeyPress } from "utils/browser";
import { AccountContext } from "views/AccountWrapper";
import Icon from "ds/components/Icon";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import useChangeViewPublicVisibility from "./useChangeViewPublicVisibility";

type FiltersSavedViewsListItemDefaultProps = {
  applyView: FiltersApplyView;
};

const FiltersSavedViewsListItemDefault = ({ applyView }: FiltersSavedViewsListItemDefaultProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const { currentView, filtersOrder, filterType, defaultEmptyFilterView } =
    useTypedContext(FiltersContext);

  const defaultView = useDefaultView(filtersOrder, filterType, defaultEmptyFilterView);

  const changeViewPublicVisibility = useChangeViewPublicVisibility(defaultView.data);

  const resetDefaultView = () => {
    datadogRum.addAction("[Saved Views] Click Reset to spacelift default");

    defaultView.removeFromStorage();
    applyView("", DEFAULT_VIEW_NAME, DEFAULT_VIEW_ID, false, true);
  };

  const handleApply = () => {
    datadogRum.addAction("[Saved Views] Click default View");

    applyView(
      defaultView.rawData,
      defaultView.data?.name || DEFAULT_VIEW_NAME,
      defaultView.data?.id || DEFAULT_VIEW_ID,
      false,
      true
    );
  };

  return (
    <>
      <li className={styles.listItem}>
        <Box
          onClick={handleApply}
          onKeyDown={withEnterKeyPress(handleApply)}
          className={cx(styles.listButton, {
            [styles.active]: currentView?.id === defaultView.data?.id || !currentView?.id,
          })}
          role="button"
          tabIndex={0}
          fullWidth
        >
          <Box direction="column" className={styles.listItemContent} fullWidth>
            <Box align="center" gap="small">
              <TextEllipsis tooltip={defaultView.data?.name} tooltipWidthMode="maxWidthSm">
                {(props) => (
                  <Typography {...props} tag="span" variant="p-body2">
                    {defaultView.loading ? <Icon src={Spinner} /> : defaultView.data?.name}
                  </Typography>
                )}
              </TextEllipsis>
              {defaultView.data?.id && defaultView.data?.id !== DEFAULT_VIEW_ID && (
                <DropdownMenuEllipsis tooltip="Saved view actions" className={styles.dropdown}>
                  <CopyFieldDropdownMenuItem title="Copy ID" value={defaultView.data.id} />
                  <DropdownMenuItem onAction={resetDefaultView}>
                    Reset to Spacelift default view
                  </DropdownMenuItem>
                  {defaultView.data.isMine && viewer.admin && (
                    <DropdownMenuItem onAction={changeViewPublicVisibility}>
                      {defaultView.data.isPublic ? "Make it private" : "Share within the account"}
                    </DropdownMenuItem>
                  )}
                </DropdownMenuEllipsis>
              )}
            </Box>

            <MetaInfoList className={styles.metaList}>
              <MetaInfoListItem className={styles.metaListItem}>
                Your personal default view
              </MetaInfoListItem>
            </MetaInfoList>
          </Box>
        </Box>
      </li>
      <li className={styles.divider}></li>
    </>
  );
};

export default FiltersSavedViewsListItemDefault;
