import { ConfigElement, Maybe } from "types/generated";

import styles from "../styles.module.css";

type EnvironmentVariableProps = {
  name: string;
  value: Maybe<string>;
  writeOnly: ConfigElement["writeOnly"];
};

const EnvironmentVariable = ({ name, value, writeOnly }: EnvironmentVariableProps) => {
  return (
    <>
      <div className={styles.variableName}>{name}</div>
      <div className={styles.variableValue}>
        {writeOnly && "*****"}
        {!writeOnly && (value || "<empty>")}
      </div>
    </>
  );
};

export default EnvironmentVariable;
