import { ReactNode } from "react";

import Box from "ds/components/Box";

type FormFieldRowWrapperProps = {
  children?: ReactNode;
};

/**
 *  Use to add suffix and prefix to the input field. Provides consistent spacing
 */
const FormFieldRowWrapper = ({ children }: FormFieldRowWrapperProps) => (
  <Box gap="medium">{children}</Box>
);

FormFieldRowWrapper.displayName = "DS.Form.Field.RowWrapper";

export default FormFieldRowWrapper;
