import { forwardRef, ReactNode } from "react";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { TypographyColors } from "types/Colors";
import { getSpacingCSS } from "utils/css";
import { SpacingShorthand } from "types/Spacing";
import { TextAlign } from "types/TextAlign";

import styles from "./styles.module.css";

type TypographyTransforms = "uppercase" | "lowercase" | "capitalize" | "uppercase-first";

/** TODO:
 * "disabled" variants are unused in the current design version,
 * feel free to uncomment them in case getting related tokens
 */
export type TypographyProps = {
  variant?:
    | "p-t1"
    | "p-t2"
    | "p-t3"
    | "p-t4"
    | "p-t5"
    | "p-t6"
    | "p-t7"
    | "p-t8"
    | "p-body1"
    | "p-body2"
    | "p-body3"
    | "p-body4"
    // | "s-t1"
    // | "s-t2"
    // | "s-t3"
    // | "s-t4"
    // | "s-t5"
    | "s-t6"
    | "s-t7"
    // | "s-t8"
    // | "s-body1"
    | "s-body2"
    | "s-body3";
  // | "s-body4";
  tag:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "span"
    | "div"
    | "label"
    | "code"
    | "tspan"
    | "kbd"
    | "pre"
    | "mark";
  children: ReactNode;
  transform?: TypographyTransforms;
  htmlFor?: string;
  align?: TextAlign;
  color?: TypographyColors;
  margin?: SpacingShorthand;
  wordBreak?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const Typography = forwardRef(function Typography(
  {
    variant,
    tag,
    transform,
    className,
    children,
    align,
    color,
    margin,
    style,
    wordBreak,
    ...restProps
  }: TypographyProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const props = {
    ...restProps,
    ref,
    className: cx(
      styles.typography,
      variant && styles[camelCase(variant)],
      transform && styles[transform],
      align && styles[camelCase(`textAlign-${align}`)],
      color && styles[camelCase(`textColor-${color}`)],
      wordBreak && styles.wordBreak,
      className
    ),
    style: {
      ...style,
      ...getSpacingCSS("margin", margin),
    },
  };

  const Tag = tag as string;

  return <Tag {...props}>{children}</Tag>;
});

Typography.displayName = "DS.Typography";

export default Typography;
