import { RefAttributes, forwardRef, useCallback } from "react";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";
import { PressEvent } from "react-aria";
import { Button as AriaButton, ButtonProps as AriaButtonProps } from "react-aria-components";

import Icon from "ds/components/Icon";
import { Spinner } from "components/Spinner";
import useAnalytics from "hooks/useAnalytics";
import { FLEX_ALIGN_PREFIX, FLEX_JUSTIFY_PREFIX } from "constants/style";

import styles from "./styles.module.css";
import { BaseActionCommonProps } from "./types";

export type BaseActionButtonProps = RefAttributes<HTMLButtonElement> &
  BaseActionCommonProps &
  Omit<AriaButtonProps, "isDisabled"> & {
    loading?: boolean;
  };

const BaseActionButton = forwardRef(function BaseActionButton(
  props: BaseActionButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    fullWidth,
    loading,
    children,
    className,
    analyticsPage,
    analyticsTitle,
    analyticsProps,
    onPress,
    justify = "center",
    align = "center",
    type = "button",
    disabled,
    ...restProps
  } = props;

  const actionClassName = cx(
    styles.baseAction,
    styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
    styles[camelCase(`${FLEX_JUSTIFY_PREFIX}-${justify}`)],
    { [styles.fullWidth]: fullWidth },
    className
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const handlePress = useCallback(
    (e: PressEvent) => {
      onPress?.(e);

      if (analyticsTitle) {
        trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
      }
    },
    [analyticsProps, analyticsTitle, onPress, trackSegmentAnalyticsEvent]
  );

  return (
    <AriaButton
      ref={ref}
      className={actionClassName}
      onPress={handlePress}
      type={type}
      isDisabled={disabled || loading}
      {...restProps}
    >
      {loading && <Icon src={Spinner} className={styles.spinner} size="stretch" />}
      {!loading ? <>{children}</> : <span className={styles.hiddenText}>{children}</span>}
    </AriaButton>
  );
});

BaseActionButton.displayName = "DS.BaseAction.Button";

export default BaseActionButton;
