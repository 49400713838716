import { useLocation } from "react-router-dom";

import { Collapse, Cross, Expand, Gear } from "components/icons/generated";
import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";

import { FiltersContext } from "..";
import { showSettingModal } from "../SettingsModal";
import styles from "./styles.module.css";

const EXPAND_ALL_CLICKED_EVENT = "Filter - Expand All Clicked";
const COLLAPSE_ALL_CLICKED_EVENT = "Filter - Collapse All Clicked";
const GLOBAL_CLEAR_ALL_CLICKED_EVENT = "Filter - Global Clear All Clicked";

type SidebarActionsProps = {
  analyticsPage?: AnalyticsPage;
};

const SidebarActions = ({ analyticsPage }: SidebarActionsProps) => {
  const location = useLocation();
  const {
    hasOpenSections,
    activeFiltersByFilterName,
    resetAllFilters,
    setShouldExpandSections,
    filtersDictionary,
    filtersOrder,
    setFiltersOrder,
  } = useTypedContext(FiltersContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: {
      location: location.pathname,
    },
  });
  const isAnalyticsEnabled = !!trackSegmentAnalyticsEvent;

  const showSettingsModal = () =>
    showSettingModal({
      dictionary: filtersDictionary,
      activeFilters: activeFiltersByFilterName,
      filtersOrder,
      setFiltersOrder,
    });

  const handleResetAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(GLOBAL_CLEAR_ALL_CLICKED_EVENT);
    }
    resetAllFilters();
  };

  const handleCollapseAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(COLLAPSE_ALL_CLICKED_EVENT);
    }
    setShouldExpandSections(false);
  };

  const handleExpandAll = () => {
    if (isAnalyticsEnabled) {
      trackSegmentAnalyticsEvent(EXPAND_ALL_CLICKED_EVENT);
    }
    setShouldExpandSections(true);
  };

  return (
    <Box direction="row" align="center" justify="between" className={styles.config}>
      {!hasOpenSections && (
        <ButtonNew
          variant="ghost"
          size="small"
          startIcon={Expand}
          className={styles.collapseAll}
          onPress={handleExpandAll}
        >
          Expand all
        </ButtonNew>
      )}
      {hasOpenSections && (
        <ButtonNew
          variant="ghost"
          size="small"
          startIcon={Collapse}
          className={styles.collapseAll}
          onPress={handleCollapseAll}
        >
          Collapse all
        </ButtonNew>
      )}
      {activeFiltersByFilterName.size > 0 && (
        <ButtonNew
          variant="ghost"
          size="small"
          startIcon={Cross}
          className={styles.resetAllFilters}
          onPress={handleResetAll}
        >
          Clear all
        </ButtonNew>
      )}
      <ButtonIconNew icon={Gear} onPress={showSettingsModal} variant="ghost">
        Manage filters
      </ButtonIconNew>
    </Box>
  );
};

export default SidebarActions;
