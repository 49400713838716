import cx from "classnames";

import EmptyState, { EmptyStateProps } from "ds/components/EmptyState";

import styles from "./styles.module.css";

const DropdownEmpty = ({ className, ...restProps }: EmptyStateProps) => (
  <EmptyState className={cx(styles.wrapper, className)} {...restProps} />
);

DropdownEmpty.displayName = "DS.Dropdown.Empty";

export default DropdownEmpty;
