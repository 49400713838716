import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";

import Typography from "../../Typography";
import { TileContext } from "../Context";

type TileDescriptionProps = {
  children: ReactNode;
};

const TileDescription = ({ children }: TileDescriptionProps) => {
  const { descriptionId } = useTypedContext(TileContext);

  return (
    <Typography id={descriptionId} variant="p-body3" tag="p" color="secondary">
      {children}
    </Typography>
  );
};

TileDescription.displayName = "DS.Tile.Description";

export default TileDescription;
