import cx from "classnames";
import { forwardRef, RefObject } from "react";

import { prepareName } from "components/state/State";

import styles from "./styles.module.css";
import BaseAction from "../BaseAction";
import { BadgeProps } from "./types";
import Icon from "../Icon";

const Badge = forwardRef(function Badge(
  {
    state,
    className,
    text,
    link,
    onClick,
    children,
    size = "medium",
    variant = "status",
    startIcon,
    iconOnly,
    ...rest
  }: BadgeProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const name = prepareName(state);

  const classNames = cx(
    styles.badge,
    styles[state.toLocaleLowerCase()],
    styles[size],
    {
      [styles.role]: variant === "role",
      [styles.status]: variant === "status",
      [styles.ansibleStatus]: variant === "ansibleStatus",
      [styles.link]: !!link || onClick,
      [styles.iconOnly]: iconOnly,
    },
    className
  );

  const content = (
    <>
      {startIcon && <Icon src={startIcon} className={styles.startIcon} />}
      {!iconOnly && <span>{text || name}</span>}
    </>
  );

  if (!onClick && !link) {
    return (
      <div ref={ref as RefObject<HTMLDivElement>} className={classNames} {...rest}>
        {children || content}
      </div>
    );
  }

  return (
    <BaseAction ref={ref} to={link} onClick={onClick} className={classNames} {...rest}>
      {children || content}
    </BaseAction>
  );
});

Badge.displayName = "DS.Badge";

export default Badge;
