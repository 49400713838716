import { NetworkStatus, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useEffect, useId, useMemo } from "react";

import MetricCard from "components/MetricCard";
import Box from "ds/components/Box";
import Link from "ds/components/Link";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanel from "components/CollapsiblePanel";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import Counter from "ds/components/Counter";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsEmptyStateStacks from "../EmptyStateStacks";
import { GET_STACKS_SIZES } from "./gql";
import { GetStacksSizes } from "./types";
import styles from "./styles.module.css";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

const LIMIT = 3;

const formatK = (value: number) => {
  if (value < 1000) {
    return value;
  }

  return `${Math.round(value / 1000)}K`;
};

const DashboardWidgetsStackSize = () => {
  const pollInterval = usePollInterval();
  const [isCollapsed, handleToggle] = useToggle(true);

  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetStacksSizes>(GET_STACKS_SIZES, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metrics;

  const largestStacks = useMemo(
    () => [...(data?.metrics?.largestStacks || [])],
    [data?.metrics?.largestStacks]
  );
  const hasItems = !!largestStacks.length;
  const maxSize = showErrorBanner
    ? 0
    : (data?.metrics?.maxStackSizeByResourceCount?.[0]?.value ?? 0);
  const avgSize = showErrorBanner
    ? 0
    : (data?.metrics?.avgStackSizeByResourceCount?.[0]?.value ?? 0);

  const titleId = useId();

  return (
    <Box direction="column" className={styles.wrapper} fullWidth>
      {showErrorBanner && (
        <Box margin="0 0 x-large 0" direction="column" fullWidth>
          <MissingDataBanner
            text="Couldn’t load stacks size data. Please try to refresh or come back later. "
            refreshHandler={refetch}
            refreshLoading={loading}
          />
        </Box>
      )}
      <Box gap="x-large" fullWidth>
        <MetricCard
          grow="1"
          titleColor="primary"
          title="Largest stack size"
          infoTooltip="Stack size is based on resources number"
          value={isLoading ? <Skeleton count={1} height={28} width={40} /> : maxSize}
        />
        <MetricCard
          grow="1"
          titleColor="primary"
          title="Average stack size"
          value={isLoading ? <Skeleton count={1} height={28} width={40} /> : avgSize}
        />
      </Box>

      {!showErrorBanner && (
        <>
          {isLoading ? (
            <Box padding="x-large 0 small 0" margin="small 0 0 0">
              <Skeleton count={1} height={23} width={100} />
            </Box>
          ) : (
            <Box direction="column">
              <CollapsiblePanel
                variant="section"
                onToggle={handleToggle}
                isCollapsed={isCollapsed}
                withTransition
                noBorder
              >
                <CollapsiblePanelHeader align="center" gap="medium" padding="x-large 0 0 0">
                  <CollapsiblePanelToggleTrigger ariaLevel={3} ariaLabelledby={titleId} />
                  <CollapsiblePanelTitle id={titleId} variant="p-t6">
                    Top 3 largest stacks
                  </CollapsiblePanelTitle>
                </CollapsiblePanelHeader>
                <CollapsiblePanelContent gap="medium" padding="large 0 medium 0">
                  {!hasItems && <DashboardWidgetsEmptyStateStacks widget={Widget.StacksSize} />}
                  {hasItems &&
                    // TODO: [dashboard] limit it on backend side
                    largestStacks
                      .filter((item) => item.resourcesCount !== 0)
                      .sort((a, b) => b.resourcesCount - a.resourcesCount)
                      .slice(0, LIMIT)
                      .map(({ stackTile, resourcesCount }, i) => {
                        const roundedValue = formatK(resourcesCount);
                        const isRounded = roundedValue !== resourcesCount;

                        return (
                          <TileWrapper key={i}>
                            <TileContent>
                              <Box justify="between" align="center" gap="medium">
                                <TextEllipsis tooltip={stackTile.name}>
                                  {(props) => (
                                    <Link
                                      analyticsTitle="Stack Size Widget - Stack Clicked"
                                      analyticsPage={AnalyticsPageDashboard.Dashboard}
                                      listLink
                                      to={`/stack/${stackTile.slug}`}
                                      variant="secondary"
                                    >
                                      <Typography {...props} tag="span" variant="p-body2">
                                        {stackTile.name}
                                      </Typography>
                                    </Link>
                                  )}
                                </TextEllipsis>

                                <Tooltip
                                  active={isRounded}
                                  on={(props) => <Counter {...props} count={roundedValue} />}
                                >
                                  {resourcesCount}
                                </Tooltip>
                              </Box>
                            </TileContent>
                          </TileWrapper>
                        );
                      })}
                </CollapsiblePanelContent>
              </CollapsiblePanel>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DashboardWidgetsStackSize;
