import { useModal } from "@ebay/nice-modal-react";
import { ReactNode } from "react";

import Modal from "../Modal";
import { ModalProps } from "../Modal/types";

type ModalSimpleProps = {
  children: ReactNode;
  onClose?: () => void;
} & Pick<ModalProps, "isDismissable" | "withAnimation" | "role" | "size">;

/**
 * Simple reusable variation of the Modal component, mainly to prevent passing the same props over and over again.
 */
const ModalSimple = ({ children, onClose, ...restProps }: ModalSimpleProps) => {
  const modal = useModal();

  const handleClose = () => {
    onClose?.();
    modal.hide();
    modal.resolve();
  };

  return (
    <Modal isOpen={modal.visible} onClose={handleClose} onExit={modal.remove} {...restProps}>
      {children}
    </Modal>
  );
};

ModalSimple.displayName = "DS.ModalSimple";

export default ModalSimple;
