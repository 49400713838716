import { MouseEvent, ReactNode, Ref } from "react";
import cx from "classnames";

import BaseAction, { type BaseActionProps } from "ds/components/BaseAction";
import Tooltip from "ds/components/Tooltip";
import { TooltipProps, TooltipReferenceProps } from "ds/components/Tooltip/types";

import styles from "./styles.module.css";

export type DropdownSectionItemProps = BaseActionProps & {
  active?: boolean;
  danger?: boolean;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  innerRef?: Ref<HTMLElement>;
  // TODO: check which items require preventing default behavior and move the logic there
  preventDefaultOnClick?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  tooltipDelay?: TooltipProps["delay"];
  isTooltipActive?: boolean;
  tooltip?: ReactNode;
};

const DropdownSectionItem = ({
  children,
  danger,
  disabled,
  className,
  innerRef,
  active,
  onClick,
  preventDefaultOnClick = true,
  tooltip,
  isTooltipActive = true,
  tooltipPlacement,
  tooltipWidthMode,
  tooltipDelay,
  ...restProps
}: DropdownSectionItemProps) => {
  const handleOnClick = (e: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
    if (preventDefaultOnClick) {
      e.preventDefault();
    }
    onClick?.(e);
  };

  const component = (tooltipProps?: TooltipReferenceProps) => (
    <BaseAction
      fullWidth
      role="menuitem"
      ref={innerRef}
      className={cx(
        styles.item,
        {
          [styles.danger]: danger,
          [styles.disabled]: disabled,
          [styles.active]: active,
        },
        className
      )}
      disabled={disabled}
      {...(onClick && { onClick: handleOnClick })}
      {...restProps}
      {...tooltipProps}
    >
      {children}
    </BaseAction>
  );

  if (tooltip && isTooltipActive) {
    return (
      <Tooltip
        delay={tooltipDelay}
        placement={tooltipPlacement}
        widthMode={tooltipWidthMode}
        on={component}
      >
        {tooltip}
      </Tooltip>
    );
  }

  return component();
};

DropdownSectionItem.displayName = "DS.Dropdown.SectionItem";

export default DropdownSectionItem;
