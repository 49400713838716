import { VCS_PROVIDERS_COLORED_ICONS, VCS_PROVIDERS_ICONS } from "constants/provider_icons";
import Icon from "ds/components/Icon";
import IconTile from "ds/components/IconTile";
import { VcsIntegration } from "types/generated";

type VCSProviderIconProps = {
  provider: VcsIntegration["provider"];
  tile?: boolean;
  isDefault?: boolean;
};

const VCSProviderIcon = ({ provider, tile, isDefault }: VCSProviderIconProps) => {
  return (
    (tile && (
      <IconTile
        icon={VCS_PROVIDERS_COLORED_ICONS[provider]}
        variant={isDefault ? "primary" : "secondary"}
      />
    )) || <Icon src={VCS_PROVIDERS_ICONS[provider]} size={tile ? "large" : "default"} />
  );
};

export default VCSProviderIcon;
