import { ReactNode, useCallback, useId } from "react";
import cx from "classnames";

import Box, { BoxProps } from "../../Box";
import styles from "./styles.module.css";
import { TileContext } from "../Context";

type TileWrapperProps = {
  children: ReactNode;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  /**
   * @deprecated: use TileWrapperButton instead
   */
  onClick?: () => void;
  className?: string;
  noBorder?: boolean;
} & BoxProps;

const TileWrapper = ({
  children,
  selected,
  disabled,
  onClick,
  className,
  noBorder,
  highlighted,
  ...boxProps
}: TileWrapperProps) => {
  const titleId = useId();
  const descriptionId = `${titleId}-description`;

  const handleKeyboardPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (["Enter"].includes(e.key)) {
        e.preventDefault();
        onClick?.();
      }
    },
    [onClick]
  );

  return (
    <TileContext.Provider value={{ titleId, descriptionId }}>
      <Box
        grow="1"
        shrink="1"
        tabIndex={onClick ? 0 : -1}
        onKeyDown={handleKeyboardPress}
        className={cx(
          styles.tileWrapper,
          selected && styles.selected,
          highlighted && styles.highlighted,
          disabled && styles.disabled,
          onClick && styles.clickable,
          noBorder && styles.noBorder,
          className
        )}
        onClick={onClick}
        {...boxProps}
      >
        {children}
      </Box>
    </TileContext.Provider>
  );
};

TileWrapper.displayName = "DS.Tile.Wrapper";

export default TileWrapper;
