import JsonView, { SymbolsElement } from "@uiw/react-json-view";

import { ChevronDownSmall } from "components/icons/generated";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import styles from "./styles.module.css";

type RenderProps = SymbolsElement & {
  "data-expanded"?: string;
};

const isExpanded = (props: RenderProps) => {
  // @uiw/react-json-view is not too accessible, the data-expanded attribute is buggy
  return props.style?.transform === "rotate(0deg)";
};

const JsonViewArrow = () => (
  <JsonView.Arrow
    render={(props: RenderProps) => (
      <ButtonIconNew
        onPress={(e) => e.target.parentElement?.click()}
        className={styles.arrowButton}
        data-expanded={isExpanded(props)}
        aria-expanded={isExpanded(props)}
        variant="ghost"
        icon={ChevronDownSmall}
        iconRotate={isExpanded(props) ? undefined : "270"}
      >
        {isExpanded(props) ? "Collapse section" : "Expand section"}
      </ButtonIconNew>
    )}
  />
);

export default JsonViewArrow;
