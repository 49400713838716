import { RefObject, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { create, show, useModal } from "@ebay/nice-modal-react";

import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalHeader from "ds/components/Modal/Header";
import Box from "ds/components/Box";
import { reorderSingleDNDList } from "utils/dnd";
import Typography from "ds/components/Typography";
import ModalSimple from "ds/components/ModalSimple";
import ModalCancelButton from "ds/components/Modal/CancelButton";
import Button from "ds/components/Button";
import Toggle from "ds/components/Toggle";

import { ActiveFiltersMap, FilterItemSettings, FiltersDictionary } from "../types";
import FilterSettingsItem from "./Item";
import styles from "./styles.module.css";

type FilterSettingsModalProps = {
  dictionary?: FiltersDictionary;
  activeFilters: ActiveFiltersMap;
  setFiltersOrder: (filters: FilterItemSettings[]) => void;
  filtersOrder: FilterItemSettings[];
};

const SettingModal = create(function SettingModal({
  dictionary,
  activeFilters,
  setFiltersOrder,
  filtersOrder,
}: FilterSettingsModalProps) {
  const modal = useModal();

  // we want to set order based on savedView or filters saved locally in Filters component or localStorage value
  const [localFiltersOrder, setLocalFiltersOrder] = useState(filtersOrder);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedFilters = reorderSingleDNDList(
      localFiltersOrder,
      result.source.index,
      result.destination.index
    );

    setLocalFiltersOrder(reorderedFilters);
  };

  const handleVisibilityChange = (index: number) => () => {
    const localStateOrderDuplicate = [...localFiltersOrder];
    const item = {
      ...localStateOrderDuplicate[index],
      visible: !localStateOrderDuplicate[index].visible,
    };

    localStateOrderDuplicate[index] = item;

    setLocalFiltersOrder(localStateOrderDuplicate);
  };

  const handleSelectAll = () => {
    if (allItemsChecked) {
      setLocalFiltersOrder(
        localFiltersOrder.map((filter) => ({ ...filter, visible: activeFilters.has(filter.name) }))
      );
    } else {
      setLocalFiltersOrder(localFiltersOrder.map((filter) => ({ ...filter, visible: true })));
    }
  };

  const allItemsChecked = useMemo(
    () => localFiltersOrder.every(({ visible }) => visible),
    [localFiltersOrder]
  );

  const handleApplySettings = () => {
    setFiltersOrder(localFiltersOrder);
    modal.hide();
  };

  if (!filtersOrder) return null;

  return (
    <ModalSimple size="x-large">
      <ModalHeader title="Manage filters" />
      <ModalContent>
        <Typography className={styles.infoText} variant="p-body2" tag="p">
          By managing filters, you can select those that you want to be displayed on the filter list
          and deselect them. In addition, you can set the order in which filters are displayed.
        </Typography>

        <Box direction="row" align="center" className={styles.actionsBar}>
          <Toggle
            variant="checkbox"
            id="select_all_filters"
            onChange={handleSelectAll}
            isSelected={allItemsChecked}
            aria-label={allItemsChecked ? "Unselect all" : "Select all"}
          >
            Select all
          </Toggle>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="FiltersSettings">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="column"
                className={styles.list}
              >
                {localFiltersOrder.map((filter, i) => (
                  <Draggable key={filter.name} draggableId={filter.name} index={i}>
                    {(provided, snapshot) => (
                      <FilterSettingsItem
                        key={filter.name}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        innerRef={provided.innerRef as unknown as RefObject<HTMLDivElement>}
                        name={dictionary?.[filter.name] || filter.name}
                        visible={localFiltersOrder ? localFiltersOrder[i].visible : true}
                        onVisibilityChange={handleVisibilityChange(i)}
                        hasChosenOptions={activeFilters.has(filter.name)}
                        isDragging={snapshot.isDragging}
                      />
                    )}
                  </Draggable>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </ModalContent>
      <ModalFooter>
        <ModalCancelButton />
        <Button onClick={handleApplySettings} variant="primary">
          Apply
        </Button>
      </ModalFooter>
    </ModalSimple>
  );
});

export const showSettingModal = (props: FilterSettingsModalProps) => show(SettingModal, props);
