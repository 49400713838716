import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { withEnterKeyPress } from "utils/browser";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import TreeChartGroupNodeRectMiddleGroup from "./RectMiddleGroup";
import { Group } from "./types";
import styles from "./styles.module.css";
import {
  RECT_ITEMS_PER_ROW,
  RECT_ITEM_SIZE,
  RECT_ITEM_SPACE,
  RECT_ITEM_BORDER_RADIUS,
  TEXT_GAP,
  TEXT_HEIGHT,
  MOUSE_LEAVE_TIMEOUT,
} from "./contstants";

type TreeChartGroupNodeRectsProps = {
  items: Group[];
  createMouseEnterHandler: (
    tooltipValue: ReactNode
  ) => (event: React.MouseEvent<SVGTextElement | SVGRectElement>) => void;
  onMouseLeave: () => void;
  activeId?: string;
  analyticsPage?: AnalyticsPage;
};

const TreeChartGroupNodeRects = ({
  items,
  createMouseEnterHandler,
  onMouseLeave,
  activeId,
  analyticsPage,
}: TreeChartGroupNodeRectsProps) => {
  const navigate = useNavigate();
  const mouseLeaveTimeoutId = useRef<number>();
  const [activeRectId, setActiveRectId] = useState<string | undefined>();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    setActiveRectId(activeId);
  }, [activeId]);

  const handleMouseRectLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      setActiveRectId(activeId || undefined);
      onMouseLeave();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave, activeId]);

  const createMouseEnterRectHandler = useCallback(
    (id: string, tooltipValue: ReactNode) => (event: React.MouseEvent<SVGRectElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);
      setActiveRectId(id);
      createMouseEnterHandler(tooltipValue)(event);
    },
    [createMouseEnterHandler]
  );

  return (
    <>
      {items.map(
        ({ status, name, link, id, parent, tooltip, hasMiddleGroup, linkEventTitle }, i) => {
          const x = (i % RECT_ITEMS_PER_ROW) * (RECT_ITEM_SIZE + RECT_ITEM_SPACE);
          const y =
            Math.floor(i / RECT_ITEMS_PER_ROW) * (RECT_ITEM_SIZE + RECT_ITEM_SPACE) +
            TEXT_HEIGHT +
            TEXT_GAP;

          const newUrlParams = new URLSearchParams(location.search);
          if (link?.queryKey && link?.queryValue) {
            newUrlParams.set(link?.queryKey, link?.queryValue);
          }

          const linkAction = link
            ? () => {
                navigate(`${link?.path}?${newUrlParams}`, { replace: true });
                if (linkEventTitle) {
                  trackSegmentAnalyticsEvent(linkEventTitle);
                }
              }
            : undefined;

          return (
            <Fragment key={id}>
              <rect
                className={cx(
                  styles.groupRect,
                  status && styles[status.toLowerCase()],
                  activeRectId && id !== activeRectId && styles.unactive
                )}
                y={y}
                x={x}
                ry={RECT_ITEM_BORDER_RADIUS}
                rx={RECT_ITEM_BORDER_RADIUS}
                width={RECT_ITEM_SIZE}
                height={RECT_ITEM_SIZE}
                onMouseLeave={handleMouseRectLeave}
                onMouseEnter={createMouseEnterRectHandler(id, tooltip || name)}
                role="button"
                aria-label={linkAction ? `Open ${name}` : name}
                tabIndex={linkAction ? 0 : -1}
                data-tree-chart-element-interactive
                onClick={linkAction}
                onKeyDown={withEnterKeyPress<SVGGElement>(() => linkAction?.())}
              />
              {parent && items && hasMiddleGroup && (
                <TreeChartGroupNodeRectMiddleGroup
                  x={x}
                  y={y}
                  prevTaskHasTheSameParent={items[i - 1]?.parent === parent}
                  nextTaskHasTheSameParent={items[i + 1]?.parent === parent}
                />
              )}
            </Fragment>
          );
        }
      )}
    </>
  );
};

export default TreeChartGroupNodeRects;
