import { AriaRole, ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ListContentWrapperProps = {
  children: ReactNode;
  className?: string;
  role?: AriaRole;
  titleId?: string;
  rowCount?: number;
};

const ListContentWrapper = ({
  children,
  className,
  role,
  titleId,
  rowCount,
}: ListContentWrapperProps) => (
  <Box
    aria-rowcount={rowCount}
    role={role}
    aria-labelledby={titleId}
    className={cx(styles.content, className)}
    fullWidth
    direction="column"
  >
    {children}
  </Box>
);

export default ListContentWrapper;
