import { InfoCircle } from "components/icons/generated";
import Timestamp from "components/Timestamp";
import Box from "ds/components/Box";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

type BoxHeaderProps = {
  icon: IconComponent;
  title: string;
  date?: number;
};

const BoxHeader = ({ icon: src, title, date }: BoxHeaderProps) => {
  return (
    <Box gap="medium" align="center">
      <Icon src={src} noShrink color="promo" />
      <Typography variant="p-t7" tag="h4" color="primary">
        {title}
      </Typography>
      <ButtonIconNew icon={InfoCircle} variant="ghost">
        The contents of this section were generated by an AI model and may contain inaccurate
        information.
      </ButtonIconNew>
      {date && <Timestamp color="secondary" timestamp={date} />}
    </Box>
  );
};

export default BoxHeader;
