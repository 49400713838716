import ReactFlow, { Controls } from "reactflow";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { FocusScope } from "react-aria";

import ButtonIcon from "ds/components/ButtonIcon";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { Cross } from "components/icons/generated";
import PageWrapper from "components/PageWrapper";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import Icon from "ds/components/Icon";
import useTitle from "hooks/useTitle";
import CustomReactFlowBackground from "ds/components/CustomReactFlow/Background";

import { StackContext } from "../Context";
import { GET_STACK_DEPENDENCIES_GRAPH } from "./gql";
import StackDependencyNode from "./StackDependencyNode";
import { ReactComponent as FlowDot } from "./assets/flowDot.svg";
import { makeDependenciesGraphNodes } from "./helpers";
import styles from "./styles.module.css";
import StackDependenciesGraphEdge from "./Edge";
import { getStacksBackUrl } from "../helpers";

const nodeTypes = { stackDependency: StackDependencyNode };
const edgeTypes = { stackDependency: StackDependenciesGraphEdge };

const StackDependenciesGraph = () => {
  const { stack, stackUrl } = useTypedContext(StackContext);
  useTitle(`Dependencies graph · ${stack.name}`);

  const { data, loading } = useQuery<{ stack: Pick<Stack, "id" | "dependenciesFullGraph"> }>(
    GET_STACK_DEPENDENCIES_GRAPH,
    {
      variables: {
        stackId: stack.id,
      },
    }
  );

  const [nodesList, edges] = useMemo(() => {
    const dependencies = data?.stack.dependenciesFullGraph;

    if (!dependencies || !dependencies.length) {
      return [[], []];
    }

    return makeDependenciesGraphNodes(dependencies);
  }, [data?.stack.dependenciesFullGraph]);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  if (loading && !data?.stack.dependenciesFullGraph) {
    return (
      <PageWrapper>
        <PageLayoutSkeleton />
      </PageWrapper>
    );
  }

  return (
    <FocusScope contain>
      <PageWrapper>
        <Box direction="column" className={styles.graphWrapper}>
          <Box direction="row" align="center" justify="between" className={styles.header}>
            <Typography tag="h1" variant="p-t4">
              Dependencies graph
            </Typography>

            <ButtonIcon icon={Cross} to={`${stackUrl}/dependencies`} variant="ghost">
              Close
            </ButtonIcon>
          </Box>

          <ReactFlow
            nodes={nodesList}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            edgesUpdatable={false}
            nodesDraggable={false}
            maxZoom={4}
            minZoom={0.1}
            attributionPosition="bottom-left"
            fitView
            zoomOnPinch
          >
            <Icon size="large" src={FlowDot} />
            <Controls showInteractive={false} />
            <CustomReactFlowBackground />
          </ReactFlow>
        </Box>
      </PageWrapper>
    </FocusScope>
  );
};

export default StackDependenciesGraph;
