import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";

import Tooltip from "../Tooltip";
import styles from "./styles.module.css";
import ButtonNew, { ButtonProps } from "../Button/New";
import { SplitControlContext } from "./context";

type SplitControlButtonProps = Omit<ButtonProps, "variant" | "size"> & { tooltip?: string };

const SplitControlButton = ({ tooltip, ...resetProps }: SplitControlButtonProps) => {
  const { variant, size } = useTypedContext(SplitControlContext);

  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <ButtonNew
          {...tooltipProps}
          {...resetProps}
          variant={variant}
          size={size}
          className={cx(tooltipProps.className, resetProps.className, styles.splitControlButton)}
        />
      )}
      placement="top"
    >
      {tooltip}
    </Tooltip>
  );
};

SplitControlButton.displayName = "DS.SplitControl.Button";

export default SplitControlButton;
