import { useModal } from "@ebay/nice-modal-react";

import Modal from "../Modal";
import ModalHeader from "../Modal/Header";
import ModalContent from "../Modal/Content";
import ModalFooter from "../Modal/Footer";
import ModalCancelButton from "../Modal/CancelButton";
import ConfirmationModalConfirmButton from "./ConfirmButton";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal = ({
  title = "Please confirm",
  confirmationButtonLabel = "Confirm",
  confirmationButtonVariant,
  children,
  onConfirm,
  onClose,
  isLoading,
  isDismissable,
  isConfirmationDisabled,
  size,
  shouldIgnoreOutsideClick,
  contentWrapperClassName,
}: ConfirmationModalProps) => {
  const modal = useModal();

  const handleClose = () => {
    onClose?.();
    modal.hide();
    modal.resolve();
  };

  return (
    <Modal
      shouldIgnoreOutsideClick={shouldIgnoreOutsideClick}
      size={size}
      role="alertdialog"
      isOpen={modal.visible}
      onClose={handleClose}
      onExit={modal.remove}
      isDismissable={isDismissable}
    >
      <ModalHeader title={title} />
      <ModalContent className={contentWrapperClassName}>{children}</ModalContent>
      <ModalFooter>
        <ModalCancelButton />
        <ConfirmationModalConfirmButton
          label={confirmationButtonLabel}
          variant={confirmationButtonVariant}
          onClick={onConfirm}
          isLoading={isLoading}
          isDisabled={isConfirmationDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.displayName = "DS.ConfirmationModal";

export default ConfirmationModal;
