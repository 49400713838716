import { forwardRef } from "react";
import cx from "classnames";

import usePrevious from "hooks/usePrevious";

import styles from "./styles.module.css";
import Box, { BoxProps } from "../Box";

type DraggableItemProps = BoxProps & {
  size?: "small" | "medium";
  disabled?: boolean;
  dragging?: boolean;
  dropping?: boolean;
  noBorder?: boolean;
};

const DraggableItem = forwardRef(function DraggableItem(
  props: DraggableItemProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    className,
    size = "medium",
    disabled,
    dragging,
    dropping,
    children,
    noBorder,
    ...rest
  } = props;

  const wasDropping = usePrevious(dropping);

  return (
    <Box
      ref={ref}
      className={cx(
        styles.wrapper,
        styles[size],
        {
          [styles.disabled]: disabled,
          [styles.dropping]: wasDropping === true && dropping === false,
          [styles.dragging]: dragging,
          [styles.noBorder]: noBorder,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Box>
  );
});

DraggableItem.displayName = "DS.DraggableItem";

export default DraggableItem;
