import { autoUpdate, getOverflowAncestors, ReferenceType } from "@floating-ui/react";

/** floating-ui autoUpdate doesn't consider reference scrolling ancestors resize events. This can happen when
 * an element is added to a scrollable list (for example load more). This caused the tooltip to be misplaced.
 *
 * The function here adds the ResizeObserver to any scrolling ancestor of the reference element and triggers the tooltip update.
 *
 * If you want to remove this function check the tooltip on the select all element for tracked runs. Selecting shows the bulk actions that adds a tombstone scrolling element causing the container resize.
 */
const patchedAutoUpdate = (reference: ReferenceType, floating: HTMLElement, update: () => void) => {
  if (!(reference instanceof HTMLElement)) {
    return autoUpdate(reference, floating, update);
  }

  const scrollingAncestors = getOverflowAncestors(reference).filter(
    (element) => element instanceof HTMLElement
  );

  const observer = new ResizeObserver(() => {
    update();
  });

  scrollingAncestors.forEach((element) => {
    observer.observe(element);
  });

  const cleanup = autoUpdate(reference, floating, update);

  return () => {
    cleanup();
    scrollingAncestors.forEach((element) => {
      observer.unobserve(element);
    });
  };
};

export default patchedAutoUpdate;
