import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import { EmptystateCodeFileColored, EmptystateVariablesColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import Typography from "ds/components/Typography";

import { ContextFormFields } from "../types";
import NewContextSummaryPanel from "./Panel";
import { ContextCreationWizardStep } from "../constants";
import NewContextVariable from "../ContextVariable";
import NewContextMountedFile from "../ContextMountedFile";

const NewContextSummaryEnvironment = () => {
  const { getValues } = useFormContext<ContextFormFields>();
  const formValues = getValues();
  const attachedMountedFiles = formValues.attachedMountedFiles;
  const attachedVariables = formValues.attachedVariables;
  const count = attachedMountedFiles.length + attachedVariables.length;

  return (
    <>
      <NewContextSummaryPanel
        name="Environment"
        step={ContextCreationWizardStep.Environment}
        count={count}
      >
        <Box direction="column" gap="x-large">
          <Box direction="column" gap="medium">
            <Box grow="1" align="center" gap="medium">
              <Typography tag="span" variant="p-t6">
                Variables
              </Typography>
              <Counter count={attachedVariables.length} size="medium" />
            </Box>
            <Box direction="column" gap="medium">
              {attachedVariables.length > 0 ? (
                attachedVariables.map((item, index) => (
                  <NewContextVariable item={item} key={index} index={index} />
                ))
              ) : (
                <EmptyState
                  padding="large"
                  icon={EmptystateVariablesColored}
                  title="Variables not added"
                  caption="Environment variables are represented as a key-value mapping available to all processes running in a given environment."
                />
              )}
            </Box>
          </Box>
          <Box direction="column" gap="medium">
            <Box grow="1" align="center" gap="medium">
              <Typography tag="span" variant="p-t6">
                Files
              </Typography>
              <Counter count={attachedMountedFiles.length} />
            </Box>
            <Box direction="column" gap="medium">
              {attachedMountedFiles.length > 0 ? (
                attachedMountedFiles.map((item, index) => (
                  <NewContextMountedFile item={item} key={index} index={index} />
                ))
              ) : (
                <EmptyState
                  padding="large"
                  icon={EmptystateCodeFileColored}
                  title="Mounted files not added"
                  caption="Use mounted files to access a file in multiple configurations."
                />
              )}
            </Box>
          </Box>
        </Box>
      </NewContextSummaryPanel>
    </>
  );
};

export default NewContextSummaryEnvironment;
