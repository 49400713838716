import Tag from "ds/components/Tag";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import KeyValue from "components/KeyValue";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import { StackDetailsFormFields } from "../types";
import { StackFormContext } from "../context";

type NewStackSummaryDetailsProps = {
  data: StackDetailsFormFields;
};

const NewStackSummaryDetails = ({ data }: NewStackSummaryDetailsProps) => {
  const { internalData } = useTypedContext(StackFormContext);

  return (
    <>
      <KeyValue name="Name">{data.name}</KeyValue>
      <KeyValue name="Space">{data.space ? internalData.spaceLabel : undefined}</KeyValue>
      {data.description && (
        <KeyValue name="Description" align="center">
          <ListEntitiesItemDescription noPadding description={data.description} />
        </KeyValue>
      )}

      <KeyValue name="Labels">
        {data.labels.length && (
          <Box gap="medium" justify="end" wrap>
            {data.labels.map(({ value }, i) => (
              <Tag tag={value} key={i} />
            ))}
          </Box>
        )}
      </KeyValue>
    </>
  );
};

export default NewStackSummaryDetails;
