import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemDescriptionCellProps = CellComponentProps;

const StackListItemDescriptionCell = ({ stack }: StackListItemDescriptionCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  const handleShowFullDescription = () => {
    trackAnalytics("Show full description clicked");
  };

  if (!stack.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        lines={1}
        hasChevronLink
        description={stack.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default StackListItemDescriptionCell;
