import { memo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import TableCell from "components/Table/Cell";
import { TableContext } from "components/Table/Context";

import { STACKS_COLUMN_ID } from "../constants";
import styles from "./styles.module.css";
import StackListItemFavoriteCell from "./FavoriteCell";
import StackListItemStateCell from "./StateCell";
import StackListItemDescriptionCell from "./DescriptionCell";
import StackListItemSpaceCell from "./SpaceCell";
import StackListItemBlueprintCell from "./BlueprintCell";
import StackListItemVendorCell from "./VendorCell";
import StackListItemDeltaCell from "./DeltaCell";
import StackListItemRepositoryCell from "./RepositoryCell";
import StackListItemProjectRootCell from "./ProjectRootCell";
import StackListItemBranchCell from "./BranchCell";
import StackListItemUpdatedAtCell from "./UpdatedAtCell";
import StackListItemLabelsCell from "./LabelsCell";
import StackListItemActionsDropdownCell from "./DropdownActionsCell";
import StackListItemNameCell from "./NameCell";
import StackListItemCommitCell from "./CommitCell";
import StackListItemIssuesCell from "./IssuesCell";
import { STACK_COLUMNS_WITH_ACTIONS, StackColumnWithActions } from "./useCellActionsConfig";
import StackListItemSettingsCell from "./SettingsCell";
import StackListTableDropdownCell from "./TableDropdownCell";

const CELLS = {
  [STACKS_COLUMN_ID.FAVORITE]: StackListItemFavoriteCell,
  [STACKS_COLUMN_ID.STATE]: StackListItemStateCell,
  [STACKS_COLUMN_ID.DESCRIPTION]: StackListItemDescriptionCell,
  [STACKS_COLUMN_ID.NAME]: StackListItemNameCell,
  [STACKS_COLUMN_ID.SPACE]: StackListItemSpaceCell,
  [STACKS_COLUMN_ID.BLUEPRINT]: StackListItemBlueprintCell,
  [STACKS_COLUMN_ID.VENDOR]: StackListItemVendorCell,
  [STACKS_COLUMN_ID.DELTA_COUNTS]: StackListItemDeltaCell,
  [STACKS_COLUMN_ID.REPOSITORY]: StackListItemRepositoryCell,
  [STACKS_COLUMN_ID.PROJECT_ROOT]: StackListItemProjectRootCell,
  [STACKS_COLUMN_ID.BRANCH]: StackListItemBranchCell,
  [STACKS_COLUMN_ID.COMMIT]: StackListItemCommitCell,
  [STACKS_COLUMN_ID.UPDATED_AT]: StackListItemUpdatedAtCell,
  [STACKS_COLUMN_ID.LABELS]: StackListItemLabelsCell,
  [STACKS_COLUMN_ID.DROPDOWN]: StackListItemActionsDropdownCell,
  [STACKS_COLUMN_ID.ISSUES]: StackListItemIssuesCell,
  [STACKS_COLUMN_ID.SETTINGS]: StackListItemSettingsCell,
};

type StackListItemProps = {
  stack: Stack;
};

const StackListItem = (props: StackListItemProps) => {
  const { stack } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  const [isTriggerDropdownVisible, setTriggerDropdownVisibility] = useState(false);

  if (!stack.spaceDetails) {
    return null;
  }

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as STACKS_COLUMN_ID];
        const hasActions = STACK_COLUMNS_WITH_ACTIONS.includes(id as StackColumnWithActions);

        const uniqueKey = `${id}-${stack.id}`;

        const childrenComponent = (
          <Component stack={stack} setTriggerDropdownVisibility={setTriggerDropdownVisibility} />
        );

        const className = isTriggerDropdownVisible ? styles.activeCell : undefined;

        return hasActions ? (
          <StackListTableDropdownCell
            key={uniqueKey}
            id={id as StackColumnWithActions}
            stack={stack}
            className={className}
          >
            {childrenComponent}
          </StackListTableDropdownCell>
        ) : (
          <TableCell key={uniqueKey} id={id} className={className}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(StackListItem);
