import { ReactNode } from "react";

import Typography from "../Typography";

type ConfirmationModalMessageProps = {
  children: ReactNode;
};

export const ConfirmationModalMessage = ({ children }: ConfirmationModalMessageProps) => {
  return (
    <Typography tag="p" variant="p-body2">
      {children}
    </Typography>
  );
};

ConfirmationModalMessage.displayName = "DS.ConfirmationModal.Message";
