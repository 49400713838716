import Box from "ds/components/Box";
import { EmptystateLinkColored, EmptystateMagnetColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import LoadingIndicator from "ds/components/LoadingIndicator";
import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import MissingDataBanner from "components/MissingDataBanner";

import NewModuleAttachedPolicy from "../AttachPolicies/AttachedPolicy";
import { useAttachedPolicies } from "../AttachPolicies/hooks/useAttachedPolicies";
import { ModuleCreationWizardStep } from "../types";
import NewModuleSummaryPanel from "./Panel";

type NewModuleSummaryPoliciesProps = {
  title: string;
  goToStepDisabled?: boolean;
};

const NewModuleSummaryPolicies = ({ title, goToStepDisabled }: NewModuleSummaryPoliciesProps) => {
  const {
    allPolicies,
    attachedPolicies,
    autoAttachedPolicies,
    loading,
    refetch,
    refetching,
    hasData,
  } = useAttachedPolicies();

  return (
    <NewModuleSummaryPanel
      name={title}
      count={allPolicies.length}
      step={ModuleCreationWizardStep.Policies}
      goToStepDisabled={goToStepDisabled}
    >
      {!loading && hasData && (
        <Box direction="column" gap="x-large">
          <Box direction="column" gap="medium">
            <Box grow="1" align="center" gap="medium">
              <Typography tag="h4" variant="p-t6">
                Manually attached
              </Typography>
              <Counter count={attachedPolicies.length} size="medium" />
            </Box>
            <Box direction="column" gap="medium">
              {attachedPolicies.length > 0 ? (
                attachedPolicies.map((item, index) => (
                  <NewModuleAttachedPolicy item={item} key={index} headingLevel={5} />
                ))
              ) : (
                <EmptyState
                  padding="large"
                  icon={EmptystateLinkColored}
                  title="No policies are attached to this module"
                  caption="Use policies list to attach a policy to module."
                />
              )}
            </Box>
          </Box>
          <Box direction="column" gap="medium">
            <Box grow="1" align="center" gap="medium">
              <Typography tag="h4" variant="p-t6">
                Auto-attached
              </Typography>
              <Counter count={autoAttachedPolicies.length} />
            </Box>
            <Box direction="column" gap="medium">
              {autoAttachedPolicies.length > 0 ? (
                autoAttachedPolicies.map((item, index) => (
                  <NewModuleAttachedPolicy item={item} key={index} headingLevel={5} />
                ))
              ) : (
                <EmptyState
                  padding="large"
                  icon={EmptystateMagnetColored}
                  title="No policies are auto-attached to this module"
                  caption="Use auto-attach label to attach policy automatically"
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {allPolicies.length === 0 && loading && (
        <Box padding="large" justify="center">
          <LoadingIndicator size="x-large" loading />
        </Box>
      )}

      {allPolicies.length === 0 && !hasData && (
        <MissingDataBanner
          text="Couldn't load attached policies, please refresh or come back later"
          refreshHandler={refetch}
          refreshLoading={refetching}
        />
      )}
    </NewModuleSummaryPanel>
  );
};

export default NewModuleSummaryPolicies;
