import { ReactNode, useId } from "react";

import Counter from "ds/components/Counter";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import Box from "ds/components/Box";
import { CollapsiblePanelAriaLevel } from "components/CollapsiblePanel/types";

import CollapsibleListTitle from "./Title";

type CollapsibleListHeaderProps = {
  count?: number;
  title: ReactNode;
  action?: ReactNode;
  ariaLevel: CollapsiblePanelAriaLevel;
};

const CollapsibleListHeader = ({ title, count, ariaLevel, action }: CollapsibleListHeaderProps) => {
  const id = useId();

  return (
    <CollapsiblePanelHeader padding="medium large" gap="medium" align="center" justify="between">
      <Box align="center" gap="medium">
        <CollapsiblePanelToggleTrigger ariaLabelledby={id} ariaLevel={ariaLevel} />
        <Box align="center" gap="medium" aria-hidden id={id}>
          {typeof title === "string" ? <CollapsibleListTitle>{title}</CollapsibleListTitle> : title}
          {count !== undefined && <Counter count={count} />}
        </Box>
      </Box>
      {action}
    </CollapsiblePanelHeader>
  );
};

export default CollapsibleListHeader;
