import Link from "ds/components/Link";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

type Props = {
  selectedLength: number;
  onSelectAll?: () => void;
  onResetAll: () => void;
  allSelected?: boolean;
};

const DropdownSelectClearAll = ({
  onSelectAll,
  onResetAll,
  selectedLength = 0,
  allSelected,
}: Props) => {
  return (
    <Box gap="medium">
      <Typography tag="span" variant="p-body3">
        <Typography tag="span" variant="p-t7">
          {selectedLength}
        </Typography>{" "}
        selected
      </Typography>
      {onSelectAll && !allSelected && (
        <Link size="small" onClick={onSelectAll}>
          Select all
        </Link>
      )}
      {onSelectAll && !allSelected && selectedLength > 0 && (
        <Typography tag="span" variant="p-body3">
          or
        </Typography>
      )}
      {selectedLength > 0 && (
        <Link size="small" onClick={onResetAll}>
          Clear all
        </Link>
      )}
    </Box>
  );
};

DropdownSelectClearAll.displayName = "DS.Dropdown.SelectClearAll";
export default DropdownSelectClearAll;
