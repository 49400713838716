import {
  AriaAttributes,
  CSSProperties,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  forwardRef,
  useImperativeHandle,
} from "react";
import { flushSync } from "react-dom";
import { Popover } from "react-aria-components";
import { useResizeObserver } from "@react-aria/utils";

import useTypedContext from "hooks/useTypedContext";
import { Space } from "types/generated";
import SelectAutocomplete from "ds/components/Autocomplete/SelectAutocomplete";
import TreeList from "ds/components/TreeList";
import { useDebounce } from "hooks/useDebounce";
import DropdownListWrapper from "ds/components/Dropdown/List/New/Wrapper";
import { TreeListApi } from "ds/components/TreeList/types";
import { useToggle } from "hooks/useToggle";
import { SpacesContext } from "views/Account/SpacesProvider";

import styles from "./styles.module.css";
import SpaceSelectItem from "./Item";
import { SPACE_SELECT_TEST_ID } from "./constants";
import { SpaceSelectProps } from "./types";

const SpaceSelect = forwardRef(function SpaceSelect(
  props: SpaceSelectProps,
  externalRef: React.ForwardedRef<HTMLInputElement>
) {
  const { value, error, loading, disabled, hiddenIds, disabledIds, onChange, ariaProps } = props;

  const popupId = useId();
  const { manageableSpaces } = useTypedContext(SpacesContext);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const [isDropdownVisible, toggleDropdownVisibility] = useToggle(false);

  const [isAutocompleteDirty, setIsAutocompleteDirty] = useState(false);

  const selectedValueName = useMemo(() => {
    const selectedSpace = manageableSpaces.find((space) => space.id === value);
    return selectedSpace?.name || "";
  }, [manageableSpaces, value]);

  const [searchQuery, setSearchQuery] = useState(selectedValueName);

  const scrollToSelected = useCallback(() => {
    const selectedElement = selectedRef?.current;

    if (!selectedElement) {
      return;
    }

    selectedElement.scrollIntoView({ block: "center", inline: "nearest" });
  }, []);

  useEffect(() => {
    if (!isDropdownVisible) {
      setIsAutocompleteDirty(false);
      setSearchQuery(selectedValueName);
    }

    if (selectedValueName && isDropdownVisible) {
      scrollToSelected();
    }
  }, [isDropdownVisible, scrollToSelected, selectedValueName]);

  const closeSelect = useCallback(() => {
    toggleDropdownVisibility(false);
  }, [toggleDropdownVisibility]);

  const handleOnChange = useCallback(
    (value: string) => {
      onChange(value);
      closeSelect();
    },
    [closeSelect, onChange]
  );

  const handleQueryClear = useCallback(() => {
    setSearchQuery("");
    onChange("");
    inputRef.current?.focus();
  }, [onChange]);

  const handleQueryChange = useCallback(
    (query: string) => {
      setIsAutocompleteDirty(true);
      toggleDropdownVisibility(true);

      setSearchQuery(query);
    },
    [toggleDropdownVisibility]
  );

  const finalSearchQuery = isAutocompleteDirty ? searchQuery : undefined;

  const debouncedFinalSearchQuery = useDebounce(finalSearchQuery);

  const inputAriaProps: AriaAttributes = useMemo(
    () => ({
      role: "combobox",
      "aria-controls": popupId,
      "aria-expanded": isDropdownVisible,
      "aria-haspopup": "tree",
      ...ariaProps,
    }),
    [isDropdownVisible, popupId, ariaProps]
  );

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    externalRef,
    () => inputRef.current,
    [inputRef]
  );

  const [menuWidth, setMenuWidth] = useState<string | null>(null);

  const shouldCloseOnInteractOutside = (element: Element) => {
    return !ref.current?.contains(element);
  };

  const onResize = useCallback(() => {
    if (inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();

      setMenuWidth(inputRect.width + "px");
    }
  }, [inputRef, setMenuWidth]);

  useResizeObserver({
    ref: inputRef,
    onResize: onResize,
  });

  const treeListRef = useRef<TreeListApi>(null);

  useEffect(() => {
    const combobox = ref.current;

    const handler = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        closeSelect();
        return;
      }

      if (e.key === "Escape") {
        e.preventDefault();
        closeSelect();
        return;
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();

        flushSync(() => {
          toggleDropdownVisibility(true);
        });

        treeListRef.current?.focus();
      }
    };

    combobox?.addEventListener("keydown", handler);

    return () => {
      combobox?.removeEventListener("keydown", handler);
    };
  }, [closeSelect, popupId, toggleDropdownVisibility]);

  const vars = { "--input-width": menuWidth } as CSSProperties;

  const finalDisabledIds = useMemo(
    () => disabledIds?.filter((id) => id !== value),
    [disabledIds, value]
  );

  const stopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const onVisibilityToggle = useCallback(() => {
    inputRef.current?.focus();
    toggleDropdownVisibility();
  }, [toggleDropdownVisibility]);

  return (
    <div ref={wrapperRef} data-testid={SPACE_SELECT_TEST_ID}>
      <SelectAutocomplete
        visibilityToggle={onVisibilityToggle}
        onChange={handleQueryChange}
        onQueryClear={handleQueryClear}
        query={searchQuery}
        placeholder="Select space"
        active={isDropdownVisible}
        error={error}
        disabled={disabled}
        loading={loading}
        ariaProps={inputAriaProps}
        ref={ref}
        inputRef={inputRef}
        tabOnlyInput
      />

      <Popover
        triggerRef={ref}
        isOpen={isDropdownVisible}
        onOpenChange={toggleDropdownVisibility}
        style={vars}
        placement="bottom left"
        ref={popoverRef}
        shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
      >
        <DropdownListWrapper
          className={styles.dropdownList}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
        >
          <TreeList<Space>
            ref={treeListRef}
            id={popupId}
            ariaLabel="Spaces"
            data={manageableSpaces}
            idKey="id"
            parentKey="parentSpace"
            nameKey="name"
            selectedKey={value}
            renderItem={SpaceSelectItem}
            onChange={handleOnChange}
            searchQuery={debouncedFinalSearchQuery}
            selectedRef={selectedRef}
            hiddenKeys={hiddenIds}
            disabledKeys={finalDisabledIds}
            maxDepth={14}
            onEscape={closeSelect}
          />
        </DropdownListWrapper>
      </Popover>
    </div>
  );
});

export default SpaceSelect;
