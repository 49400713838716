import { FunctionComponent } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import {
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
  StackIntegrationGcp,
} from "types/generated";
import { Spinner } from "components/Spinner";
import { Trash } from "components/icons/generated";
import IconTile from "ds/components/IconTile";
import KeyValue from "components/KeyValue";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import useDetachCloudIntegration from "shared/CloudIntegration/useDetachCloudIntegration";
import useTypedContext from "hooks/useTypedContext";
import { StackSettingsContextData } from "views/Stack/Settings/Context";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import StackSettingsIntegrationsCloudAttachedItemAWSFields from "./AWSFields";
import StackSettingsIntegrationsCloudAttachedItemAzureFields from "./AzureFields";
import StackSettingsIntegrationsCloudAttachedItemSwitchIcon from "./SwitchIcon";
import StackSettingsIntegrationsCloudAttachedItemGCPFields from "./GCPFields";

type StackSettingsIntegrationsCloudAttachedItemProps = {
  attachedIntegration:
    | StackAwsIntegrationAttachment
    | StackAzureIntegrationAttachment
    | StackIntegrationGcp;
  attachedIntegrationType: CLOUD_INTEGRATIONS;
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  entityId?: string;
  refetchQueries?: string[];
};

const StackSettingsIntegrationsCloudAttachedItem = ({
  attachedIntegration,
  attachedIntegrationType,
  cloudConfig,
  entityId,
  refetchQueries,
}: StackSettingsIntegrationsCloudAttachedItemProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsIntegrations,
  });

  const { detach, detachLoading } = useDetachCloudIntegration({
    refetchQueries,
  });

  const trackSuccessEvent = () => trackSegmentEvent("Detached", { type: attachedIntegrationType });

  const handleDetach = () => {
    switch (attachedIntegrationType) {
      case CLOUD_INTEGRATIONS.AWS:
      case CLOUD_INTEGRATIONS.Azure:
        if ("id" in attachedIntegration) {
          detach(
            { type: attachedIntegrationType, attachmentId: attachedIntegration.id },
            trackSuccessEvent
          );
        }
        break;
      case CLOUD_INTEGRATIONS.GCP:
        if (entityId) {
          detach({ type: attachedIntegrationType, entityId }, trackSuccessEvent);
        }
    }
  };

  const config = cloudConfig.find(({ type }) => type === attachedIntegrationType);

  if (!config) {
    return null;
  }

  const Logo = config.logo;

  return (
    <CardWrapper variant="outlined" direction="column" fullWidth>
      <Box justify="between" align="center" margin="0 0 large 0">
        <Box align="center" gap="medium">
          <IconTile icon={Logo} />
          <Typography tag="p" variant="p-t5">
            {config.name} integration
          </Typography>
        </Box>
        {canManageStack && (
          <ButtonIcon
            disabled={detachLoading}
            icon={detachLoading ? Spinner : Trash}
            onClick={handleDetach}
            variant={detachLoading ? "ghost" : "ghostDanger"}
          >
            Detach
          </ButtonIcon>
        )}
      </Box>
      {attachedIntegrationType === CLOUD_INTEGRATIONS.AWS && (
        <StackSettingsIntegrationsCloudAttachedItemAWSFields
          integration={attachedIntegration as StackAwsIntegrationAttachment}
        />
      )}

      {attachedIntegrationType === CLOUD_INTEGRATIONS.Azure && (
        <StackSettingsIntegrationsCloudAttachedItemAzureFields
          integration={attachedIntegration as StackAzureIntegrationAttachment}
        />
      )}

      {attachedIntegrationType === CLOUD_INTEGRATIONS.GCP && (
        <StackSettingsIntegrationsCloudAttachedItemGCPFields
          integration={attachedIntegration as StackIntegrationGcp}
        />
      )}

      {"read" in attachedIntegration && "write" in attachedIntegration && (
        <>
          <KeyValue align="center" name="Read">
            <StackSettingsIntegrationsCloudAttachedItemSwitchIcon
              checked={attachedIntegration.read}
            />
          </KeyValue>
          <KeyValue align="center" name="Write">
            <StackSettingsIntegrationsCloudAttachedItemSwitchIcon
              checked={attachedIntegration.write}
            />
          </KeyValue>
        </>
      )}
    </CardWrapper>
  );
};

export default StackSettingsIntegrationsCloudAttachedItem;
