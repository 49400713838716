import { ReactNode, useId } from "react";

import useTypedContext from "hooks/useTypedContext";

import Radio from "../../Radio";
import { TileContext } from "../Context";

type TileIndicatorProps = {
  indicator: "radio" | ReactNode;
  selected?: boolean;
  disabled?: boolean;
};

const TileIndicator = ({ indicator, selected, disabled }: TileIndicatorProps) => {
  const id = useId();
  const { titleId } = useTypedContext(TileContext);

  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (indicator) {
    case "radio":
      return <Radio id={id} checked={selected} disabled={disabled} aria-labelledby={titleId} />;
    default:
      return <>{indicator}</>;
  }
};

TileIndicator.displayName = "DS.Tile.Indicator";

export default TileIndicator;
