import dedent from "dedent";
import partition from "lodash-es/partition";
import { useCallback } from "react";

import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";
import { ModuleInput, TerraformWorkflowTool } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import styles from "./styles.module.css";

type ModuleVersionInstructionsDrawerProps = {
  inputs: ModuleInput[] | undefined;
  moduleName: string;
  type: string;
  submodulePath?: string;
  versionNumber: string;
  moduleSource: string;
  workflowTool: TerraformWorkflowTool;
};

const ModuleVersionInstructionsDrawer = createDrawer(
  ({
    inputs,
    moduleName,
    submodulePath,
    type,
    versionNumber,
    moduleSource,
    workflowTool,
  }: ModuleVersionInstructionsDrawerProps) => {
    const renderInput = (item: ModuleInput, longestName: number, optional?: boolean) => {
      const nameLength = item.name.length;
      const paddingCount = longestName - nameLength;
      const prefix = ` `.repeat(paddingCount);

      const typePrefix = ` `.repeat(longestName + 5);

      const itemType = item.type
        .split("\n")
        .map((line, index) => (index === 0 ? line : `${typePrefix}${line}`))
        .join("\n");

      if (optional) return `  # ${item.name} ${prefix}= ${itemType}`;

      return `  ${item.name} ${prefix}= # ${itemType}`;
    };

    const renderInputs = (inputs: ModuleInput[], optional?: boolean) => {
      if (!inputs) return "";

      const longest = inputs.reduce(function (a, b) {
        return a.name.length > b.name.length ? a : b;
      }).name;

      return `${inputs.map((item) => renderInput(item, longest.length, optional)).join("\n")}`;
    };

    const sourceUrl = type === "module" ? moduleSource : `${moduleSource}//${submodulePath}`;

    const [requiredInputs, optionalInputs] = partition(inputs, "required");

    const renderRequiredInputs = () => {
      if (requiredInputs.length === 0) {
        return "";
      }

      return `${`\n`}${`\n`}  # Required inputs ${`\n`}` + renderInputs(requiredInputs);
    };

    const renderOptionalInputs = () => {
      if (optionalInputs.length === 0) {
        return "";
      }

      return `${`\n`}${`\n`}  # Optional inputs ${`\n`}` + renderInputs(optionalInputs, true);
    };

    const moduleExample =
      dedent`
      module "${moduleName}" {
        source  = "${sourceUrl}"
        version = "${versionNumber}"
      ` +
      renderRequiredInputs() +
      renderOptionalInputs() +
      `${`\n`}}`;

    const trackSegmentAnalyticsEvent = useAnalytics();

    const trackInstructionCopyEvent = useCallback(() => {
      trackSegmentAnalyticsEvent("Module Registry - Instructions Copied");
    }, [trackSegmentAnalyticsEvent]);

    const handleCopy = useCopyToClipboard(moduleExample, trackInstructionCopyEvent);

    return (
      <DrawerSimple variant="wide">
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Instructions" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <Typography variant="p-body2" tag="p">
            Copy and paste into your{" "}
            {workflowTool === TerraformWorkflowTool.OpenTofu ? "OpenTofu" : "Terraform"}{" "}
            configuration
          </Typography>
          <Box margin="x-large 0 0 0" surfaceColor="quaternary" className={styles.code}>
            <Box className={styles.codeInner} padding="x-large">
              <Typography variant="p-body3" tag="pre">
                <code>{moduleExample}</code>
              </Typography>
            </Box>

            <ButtonNew
              variant="secondary"
              onPress={handleCopy}
              size="small"
              className={styles.copyToClipboardButton}
            >
              Copy
            </ButtonNew>
          </Box>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showModuleVersionInstructionsDrawer = createDrawerTrigger(
  ModuleVersionInstructionsDrawer
);
