import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";

import Box from "ds/components/Box";
import useTitle from "hooks/useTitle";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import ButtonNew from "ds/components/Button/New";
import { stringIsRequired } from "utils/formValidators";
import SecretInput from "ds/components/SecretInput";
import SystemMessage from "components/SystemMessage";
import SpaceliftLoader from "components/loading/SpaceliftLoader";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import { isSelfHostedDistribution } from "utils/distribution";

import useInstanceProviderGateRules from "../InstanceProvider/useInstanceProviderGateRules";
import { STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN } from "../constants";

const isSelfHosted = isSelfHostedDistribution();

type SelfHostedAdminLoginFormFields = {
  username: string;
  password: string;
};

const SelfHostedAdminLogin = () => {
  useTitle("Admin login");

  const navigate = useNavigate();
  const { reportSuccess, reportError } = useTypedContext(FlashContext);

  const [isLoading, setIsLoading] = useState(false);
  const { isInstanceInfoLoading, shouldCreateAccount, isSelfHostedInstalled } =
    useInstanceProviderGateRules();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SelfHostedAdminLoginFormFields>({
    mode: "onChange",
  });

  const handleLogin = (formData: SelfHostedAdminLoginFormFields) => {
    setIsLoading(true);

    const body = new URLSearchParams();
    body.append("username", formData.username);
    body.append("password", formData.password);

    fetch("/admin_login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
      .then(async (response) => {
        if (!response.ok) {
          const responseMessage = await response.text();

          return reportError({
            title: "Log in to your account",
            message: responseMessage || "Invalid credentials",
          });
        }

        sessionStorage.setItem(STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN, "true");
        reportSuccess({ message: "Successfully logged in to your account" });

        if (isSelfHostedInstalled) {
          navigate("/launchpad", { replace: true });
        } else {
          navigate("/create-account", { replace: true });
        }

        return undefined;
      })
      .catch(() => {
        reportError({ title: "Log in to your account", message: "Something went wrong" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isSelfHosted) {
    return <NotFoundPage />;
  }

  if (isInstanceInfoLoading) {
    return <SpaceliftLoader />;
  }

  if (!isSelfHostedInstalled && shouldCreateAccount) {
    return <Navigate to="/create-account" replace />;
  }

  return (
    <SystemMessage
      title="Log in to your account"
      message="Enter credentials defined during infra deployment process."
    >
      <form onSubmit={handleSubmit(handleLogin)}>
        <Box direction="column" gap="xx-large">
          <Box direction="column" gap="x-large">
            <FormField label="Username" error={errors?.username?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  error={!!errors?.username}
                  {...register("username", {
                    validate: stringIsRequired("Username field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            <FormField label="Password" error={errors?.password?.message} noMargin>
              {({ ariaInputProps }) => (
                <SecretInput
                  error={!!errors?.password}
                  {...register("password", {
                    validate: stringIsRequired("Password field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>

          <ButtonNew type="submit" variant="primary" loading={isLoading} fullWidth>
            Log in
          </ButtonNew>
        </Box>
      </form>
    </SystemMessage>
  );
};

export default SelfHostedAdminLogin;
