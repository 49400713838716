import { getApolloClient } from "apollo/client";

import { TRACK_USER_EVENT } from "./gql";
import { SegmentPropertiesObject } from "./types";
import { getDefaultContext } from "./utils";

export const trackUserEvent = (
  name: string,
  properties?: SegmentPropertiesObject,
  context?: SegmentPropertiesObject
) => {
  const apolloClient = getApolloClient();

  if (!apolloClient) {
    return;
  }

  let strigifiedProperties: string | undefined;
  let stringifiedContext: string;

  try {
    strigifiedProperties = properties ? JSON.stringify(properties) : undefined;
    stringifiedContext = JSON.stringify({ ...getDefaultContext(), ...context });
  } catch {
    // do nothing, this is the best effort approach
    return;
  }

  apolloClient
    .mutate({
      mutation: TRACK_USER_EVENT,
      variables: {
        name,
        properties: strigifiedProperties,
        context: stringifiedContext,
      },
      fetchPolicy: "no-cache",
    })
    .catch(() => {
      // do nothing, this is the best effort approach
    });
};
