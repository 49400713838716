/* eslint react/prop-types: warn */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import capitalize from "lodash-es/capitalize";

import { TerraformWorkflowTool } from "types/generated";
import { VENDOR_CONFIG_TYPENAME, VENDOR_DICTIONARY } from "constants/vendor";
import { getVendorName as getVendorNameByTypename } from "utils/vendor";

export const GROUP_BY_OPTIONS_VALUES = {
  account: ["parent", "provider", "stack", "type"],
  stack: ["parent", "provider", "type"],
};

const FILTER_LABELS = {
  creator: "Created by",
  name: "Name",
  parent: "Parent",
  provider: "Provider",
  stack: "Stack",
  labels: "Stack labels",
  type: "Type",
  updater: "Last updated by",
  driftedBoolean: "Drifted",
  vendorName: "Vendor",
};

const FILTERS_KEYS = {
  account: [
    "creator.id",
    "name",
    "parent",
    "provider",
    "stack",
    "labels",
    "type",
    "updater.id",
    "driftedBoolean",
    "vendorName",
  ],
  stack: ["creator.id", "name", "parent", "provider", "type", "updater.id", "driftedBoolean"],
};

export const omitTypename = (key, value) => {
  return key === "__typename" ? undefined : value;
};

const getVendorName = (stack) => {
  const vendorTypeName = stack?.vendorConfig?.__typename;

  if (!vendorTypeName) {
    return "";
  }

  if (
    vendorTypeName === VENDOR_CONFIG_TYPENAME.TERRAFORM &&
    stack?.vendorConfig?.workflowTool === TerraformWorkflowTool.OpenTofu
  ) {
    return VENDOR_DICTIONARY.OPEN_TOFU;
  }

  return getVendorNameByTypename(vendorTypeName);
};

const decodeJSONStrings = (entity) => {
  if (entity["vendor"]["terraform"]) {
    if (
      entity["vendor"]["terraform"]["values"] &&
      typeof entity["vendor"]["terraform"]["values"] === "string"
    ) {
      entity["vendor"]["terraform"]["values"] = JSON.parse(entity["vendor"]["terraform"]["values"]);
    }
    if (
      entity["vendor"]["terraform"]["value"] &&
      typeof entity["vendor"]["terraform"]["value"] === "string"
    ) {
      entity["vendor"]["terraform"]["value"] = JSON.parse(entity["vendor"]["terraform"]["value"]);
    }
  }
  if (entity["vendor"]["pulumi"]) {
    if (
      entity["vendor"]["pulumi"]["outputs"] &&
      typeof entity["vendor"]["pulumi"]["outputs"] === "string"
    ) {
      entity["vendor"]["pulumi"]["outputs"] = JSON.parse(entity["vendor"]["pulumi"]["outputs"]);
    }
  }
  if (entity["vendor"]["cloudFormation"]) {
    if (
      entity["vendor"]["cloudFormation"]["template"] &&
      typeof entity["vendor"]["cloudFormation"]["template"] === "string"
    ) {
      entity["vendor"]["cloudFormation"]["template"] = JSON.parse(
        entity["vendor"]["cloudFormation"]["template"]
      );
    }
  }
  if (entity["vendor"]["kubernetes"]) {
    if (
      entity["vendor"]["kubernetes"]["data"] &&
      typeof entity["vendor"]["kubernetes"]["data"] === "string"
    ) {
      entity["vendor"]["kubernetes"]["data"] = JSON.parse(entity["vendor"]["kubernetes"]["data"]);
    }
  }
};

export const generateAccountEntities = (data, accountName) => {
  const entities = [];
  if (data.stacks) {
    for (const stack of data.stacks) {
      if (stack && stack.entities) {
        for (const entity of stack.entities) {
          const entityCopy = JSON.parse(JSON.stringify(entity, omitTypename));
          entityCopy.stack = stack.id;
          entityCopy.labels = stack.labels;
          if (entityCopy.parent === "") {
            entityCopy.name = `${stack.id}`;
            entityCopy.parent = "account";
          }
          entityCopy.driftedBoolean = JSON.stringify(!!entity.drifted);
          entityCopy.drifted = entity.drifted;
          entityCopy.vendorName = getVendorName(stack);

          entities.push(entityCopy);
        }
      }
    }
  }
  if (entities.length > 0) {
    entities.push({
      id: "account",
      name: accountName,
      type: "module",
      parent: "",
      vendor: { spacelift: {} },
    });
  }
  return entities;
};

export const generateEntities = (data) => {
  // deep copy entities
  const entities = JSON.parse(JSON.stringify(data.stack.entities, omitTypename));

  entities.forEach((entity) => {
    entity.driftedBoolean = JSON.stringify(!!entity.drifted);
    entity.vendorName = getVendorName(data.stack);
  });

  return entities;
};

export const generateAccountEntity = (data) => {
  const entity = JSON.parse(JSON.stringify(data.stack.entity, omitTypename));

  entity.stack = data.stack.id;
  entity.labels = data.stack.labels;
  if (entity.parent === "") {
    entity.name = `${data.stack.id}`;
    entity.parent = "account";
  }
  entity.driftedBoolean = JSON.stringify(!!entity.drifted);
  entity.vendorName = getVendorName(data.stack);

  decodeJSONStrings(entity);

  return entity;
};

export const generateEntity = (data) => {
  const entity = JSON.parse(JSON.stringify(data.stack.entity, omitTypename));

  entity.driftedBoolean = JSON.stringify(!!entity.drifted);
  entity.vendorName = getVendorName(data.stack);
  decodeJSONStrings(entity);

  return entity;
};

export const generateFilters = (filterField, filterValues) => {
  // filters is a list of pairs (key, possible values)
  // the key may repeat
  const filters = [];
  for (const i in filterField) {
    if (filterValues[i] && filterValues[i].length > 0) {
      filters[i] = [filterField[i], new Set(filterValues[i].map((option) => option.value))];
    } else {
      filters[i] = [filterField[i], new Set()];
    }
  }

  return filters;
};

// getKeyValue finds the value of a property, which can be nested using dots as separators.
// Returns none if it can't find it.
export const getKeyValue = (key, object) => {
  let currentKey = key;

  if (key === "provider") {
    currentKey = `vendor.${Object.keys(object.vendor)[0]}.provider`;
  }

  const parts = currentKey.split(".");

  for (const part of parts) {
    if (object === null) {
      return "none";
    }
    if (!(part in object)) {
      return "none";
    }
    object = object[part];
  }

  return object;
};

function escapeRegex(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const visibilityTest = (possibleValue, actualValue) =>
  new RegExp(`^${escapeRegex(possibleValue)}$`.replace(/\\\*/g, ".*")).test(actualValue);

export const getVisibleEntities = (entities, filters) => {
  // getVisibleEntities gets all the entities which should be rendered to the user as rectangles.
  const output = [];
  entityLoop: for (const entity of entities) {
    if (entity.type === "module" || entity.type === "root" /*special resource for Kubernetes*/) {
      continue;
    }
    for (const i in filters) {
      const [key, possibleValues] = filters[i];

      let found = false;
      if (possibleValues.size === 0) {
        continue;
      }
      const actualValue = getKeyValue(key, entity);
      for (const possibleValue of possibleValues) {
        // This allows us to use percentage signs as wildcards.
        const isValueArray = Array.isArray(actualValue);

        if (
          (!isValueArray && visibilityTest(possibleValue, actualValue)) ||
          (isValueArray && actualValue.some((value) => visibilityTest(possibleValue, value)))
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        continue entityLoop;
      }
    }
    output.push(entity);
  }
  return output;
};

export const translateFilterKey = (key, entity) => {
  if (key === "provider") {
    const vendor = Object.keys(entity.vendor)[0];

    return getKeyValue(`vendor.${vendor}.provider`, entity);
  }

  if (key === "creator" || key === "updater") {
    return entity[key].id;
  }

  return getKeyValue(key, entity);
};

export const generateKeyPossibleValues = (filterField, filters, entities, isAccountWide) => {
  // This calculates the available key-values for each filter in the pipeline.
  // This way the filter pipeline behaves more like a bunch of piped greps.
  // In short, each filter has a view of the world as if only the preceding filters existed.
  const keyPossibleValues = [];
  for (let i = 0; i < filterField.length; i++) {
    keyPossibleValues[i] = {};

    const activeFilters = filters.slice(0, i);

    const currentlyVisibleEntities = getVisibleEntities(entities, activeFilters);

    const possibleKeys = isAccountWide ? FILTERS_KEYS.account : FILTERS_KEYS.stack;

    for (const key of possibleKeys) {
      const values = new Set();

      for (const entity of currentlyVisibleEntities) {
        const entityValue = translateFilterKey(key, entity);
        if (Array.isArray(entityValue)) {
          entityValue.forEach((value) => values.add(value));
        } else {
          values.add(entityValue);
        }
      }

      keyPossibleValues[i][key] = Array.from(values);
    }
  }

  return keyPossibleValues;
};

export const generateGroupByOptions = (isAccountWide) => {
  const groupByOptionsValue = isAccountWide
    ? GROUP_BY_OPTIONS_VALUES.account
    : GROUP_BY_OPTIONS_VALUES.stack;

  return groupByOptionsValue.map((value) => {
    return {
      value,
      label: capitalize(value),
    };
  });
};

export const translateLabel = (value) => {
  if (value === "creator.id" || value === "updater.id") {
    return FILTER_LABELS[value.split(".")[0]];
  }

  if (value.startsWith("vendor") && value !== "vendorName") {
    return FILTER_LABELS.provider;
  }
  return FILTER_LABELS[value];
};
