import { useMemo } from "react";

import { HierarchyFilterItemOption } from "components/Filters/types";
import InfiniteList from "components/InfiniteList";
import Box from "ds/components/Box";

import { makeHierarchyOptions } from "../../helpers";
import styles from "./styles.module.css";
import FilterSectionEmpty from "./FilterSectionEmpty";
import HierarchyFilterOption from "./HierarchyFilterOption";

type HierarchyFilterOptionsListProps = {
  options: HierarchyFilterItemOption[];
  handleSelectValue: (value: string) => void;
  selectedValues: Set<string>;
  searchInput: string;
  titleId: string;
};

const HierarchyFilterOptionsList = ({
  handleSelectValue,
  options,
  selectedValues,
  searchInput,
  titleId,
}: HierarchyFilterOptionsListProps) => {
  const [optionsMap, firstLevelOptions] = useMemo(
    () => makeHierarchyOptions(options, searchInput),
    [options, searchInput]
  );

  return (
    <InfiniteList items={firstLevelOptions}>
      {(items, onScroll) => (
        <Box
          role="group"
          aria-labelledby={titleId}
          direction="column"
          className={styles.valuesList}
          onScroll={onScroll}
        >
          {items.map((item) => (
            <HierarchyFilterOption
              key={item.id}
              item={item}
              optionsMap={optionsMap}
              handleSelectValue={handleSelectValue}
              selectedValues={selectedValues}
            />
          ))}

          {firstLevelOptions.length === 0 && <FilterSectionEmpty />}
        </Box>
      )}
    </InfiniteList>
  );
};

export default HierarchyFilterOptionsList;
