import { useCallback, useMemo } from "react";

import PageInfoHeader from "ds/components/PageInfoHeader";
import { ModuleMetadata, SearchQueryOrderDirection, VersionState } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import { VersionContext } from "../Context";
import { showModuleVersionInstructionsDrawer } from "../components/InstructionsDrawer";
import ModuleVersionSubmodulesItem from "./Item";

const columns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
];

const ModuleVersionSubmodules = () => {
  const { version, module } = useTypedContext(VersionContext);

  const submodules = useMemo(() => version?.metadata?.submodules || [], [version]);

  const canShowInstructions = !version.yanked && version.state === VersionState.Active;

  const handleOpenInstructions = useCallback(
    (submodule: ModuleMetadata) => () => {
      showModuleVersionInstructionsDrawer({
        id: submodule.name,
        moduleName: submodule?.name || "",
        inputs: submodule.inputs,
        versionNumber: version.number,
        type: "submodule",
        workflowTool: module.workflowTool,
        moduleSource: module.moduleSource,
        submodulePath: submodule?.path,
      });
    },
    [module, version]
  );

  const hasSubmodules = submodules.length > 0;

  return (
    <>
      <PageInfoHeader title="Submodules" />
      {!hasSubmodules && (
        <EmptyState
          fullHeight
          icon={EmptystateSearchNoResultsColored}
          caption="This module has no submodules"
        />
      )}
      {hasSubmodules && (
        <SortableTable
          items={submodules}
          columns={columns}
          columnOrder="1fr"
          renderItems={(sorted) =>
            sorted.map((item) => (
              <ModuleVersionSubmodulesItem
                key={item.name}
                canShowInstructions={canShowInstructions}
                item={item}
                handleOpenInstructions={handleOpenInstructions}
              />
            ))
          }
          initialSortBy="name"
          initialDirection={SearchQueryOrderDirection.Desc}
        />
      )}
    </>
  );
};

export default ModuleVersionSubmodules;
