import { useLayoutEffect, useRef } from "react";

/**
 * react-aria omits the `aria-modal` attribute on purpose, since they've detected an issue with
 * safari modals within an iframe. Since we're not using any modals within an iframe we want to
 * include the attribute as it's required by the accessibility auditor.
 *
 * @see auditor report -> https://github.com/adobe/react-spectrum/blob/4dd0993da6421c14a632b7d0a11b17e759eef38c/packages/%40react-aria/dialog/src/useDialog.ts#L66
 * @see react-aria justification -> https://github.com/adobe/react-spectrum/blob/4dd0993da6421c14a632b7d0a11b17e759eef38c/packages/%40react-aria/dialog/src/useDialog.ts#L66
 *
 * Unfortunately, the attributes passed down to the section tag that includes role="dialog" are
 * filtered, so we need to force the attribute using the layout effect.
 */
const useAriaModalAttribute = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useLayoutEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.setAttribute("aria-modal", "true");
    }
  }, []);

  return dialogRef;
};

export default useAriaModalAttribute;
