import { MouseEvent, useCallback, useRef, useState } from "react";

import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import Button from "ds/components/Button";
import DropdownList from "ds/components/Dropdown/List";
import ControlledInfiniteList from "components/InfiniteList/Controlled";
import SelectOption from "ds/components/Select/Option";
import DropdownFooterSelection from "ds/components/Dropdown/Footer/Selection";
import useOutsideClick from "hooks/useOutsideClick";
import DropdownHeaderSearch from "ds/components/Dropdown/Header/Search";
import { EmptystateStacksColored } from "components/icons/generated";
import { SpaceAccessLevel } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownEmpty from "ds/components/Dropdown/Empty";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import useSearchStacks from "./useSearchStacks";
import styles from "./styles.module.css";

type DependenciesDropdownProps = {
  onAddDependencies: (selectedIds: string[], callback: () => void) => void;
  exclude?: string[];
  requiredAccessLevel: SpaceAccessLevel;
};

const DependenciesDropdownV2 = ({
  onAddDependencies,
  exclude,
  requiredAccessLevel,
}: DependenciesDropdownProps) => {
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set());

  const { stacks, handleLoadMore, hasMore, loading, searchInput, setSearchInput } = useSearchStacks(
    requiredAccessLevel,
    exclude
  );

  const handleToggleDropdown = (e: MouseEvent) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  const handleSelectValue = useCallback(
    (stackId: string) => {
      if (selectedValues.has(stackId)) {
        selectedValues.delete(stackId);
      } else {
        selectedValues.add(stackId);
      }

      setSelectedValues(new Set(selectedValues));
    },
    [selectedValues]
  );

  const handleResetAll = () => {
    setSearchInput("");
    setSelectedValues(new Set());
  };

  const handleAddItems = () => {
    onAddDependencies(Array.from(selectedValues), () => {
      handleResetAll();
      closeDropdown();
    });
  };

  const noSearchResults = stacks.length === 0 && searchInput.length > 0;
  const isEmpty = stacks.length === 0 && searchInput.length === 0;

  useOutsideClick(wrapperRef, closeDropdown);

  useEscapeKeypress(isVisible, closeDropdown);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <Button variant="secondary" size="small" onClick={handleToggleDropdown} active={isVisible}>
        Add dependencies
      </Button>

      <DropdownList active={isVisible} position="bottomLeft" className={styles.dropdownList}>
        <DropdownHeaderSearch
          searchPlaceholder="Search by stack name..."
          searchValue={searchInput}
          setSearchValue={setSearchInput}
          searchLoading={loading}
        />

        <ControlledInfiniteList onLoadMore={handleLoadMore} hasMore={hasMore} loading={loading}>
          {(onScroll) => (
            <DropdownSection
              topDivider={false}
              direction="column"
              onScroll={onScroll}
              className={styles.valuesList}
            >
              {noSearchResults && (
                <DropdownEmpty
                  icon={EmptystateStacksColored}
                  caption="There are no matching stacks. Try with different keyword."
                  announce
                />
              )}

              {isEmpty && (
                <EmptyState
                  caption="There are no available stacks."
                  icon={EmptystateStacksColored}
                />
              )}

              {stacks.map((item) => (
                <SelectOption
                  key={item.id}
                  value={item.id}
                  label={item.name}
                  onChange={handleSelectValue}
                  checked={selectedValues.has(item.id)}
                  multiple
                ></SelectOption>
              ))}
            </DropdownSection>
          )}
        </ControlledInfiniteList>

        {!isEmpty && (
          <DropdownFooterSelection
            selectedLength={selectedValues.size}
            onResetAll={handleResetAll}
            onAdd={handleAddItems}
          />
        )}
      </DropdownList>
    </DropdownWrapper>
  );
};

export default DependenciesDropdownV2;
