/**
 * Based on the requirement of showing different levels of license expiration status in the UI.
 */
export enum LicenseExpirationStatus {
  VALID = "valid",
  IN_2_MONTHS = "in_2_months",
  IN_2_WEEKS = "in_2_weeks",
  LAST_DAY = "last_day",
  EXPIRED = "expired",
}
