import cx from "classnames";

import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

type FilterSectionContentHeaderProps = {
  id: string;
  name: string;
};

const FilterSectionContentHeader = ({ name, id }: FilterSectionContentHeaderProps) => {
  return (
    <TextEllipsis tooltip={name}>
      {(ellipsisProps) => (
        <Typography
          {...ellipsisProps}
          aria-hidden
          id={id}
          tag="span"
          variant="p-t6"
          className={cx(styles.text, ellipsisProps.className)}
        >
          {name}
        </Typography>
      )}
    </TextEllipsis>
  );
};

export default FilterSectionContentHeader;
