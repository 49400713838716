import { forwardRef, ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type DropdownWrapperProps = {
  className?: string;
  children?: ReactNode;
};

const DropdownWrapper = forwardRef(function DropdownWrapper(
  props: DropdownWrapperProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { className, children } = props;

  return (
    <div ref={ref} className={cx(styles.dropdown, className)}>
      {children}
    </div>
  );
});

DropdownWrapper.displayName = "DS.Dropdown.Wrapper";

export default DropdownWrapper;
