import { useEffect, useId, useState } from "react";

import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import Tab from "ds/components/Tab";
import TabPanel from "ds/components/TabPanel";
import Typography from "ds/components/Typography";
import PageLoading from "components/loading/PageLoading";
import { FiltersApplyView } from "components/Filters/types";

import SaveFiltersList from "./List";
import styles from "./styles.module.css";
import { SavedFiltersContext } from "./Context";
import { FiltersContext } from "..";

type FiltersSavedViewsProps = {
  applyView: FiltersApplyView;
};

const FiltersSavedViews = ({ applyView }: FiltersSavedViewsProps) => {
  const [currentTab, setCurrentTab] = useState("all");

  const { resetOpenSections } = useTypedContext(FiltersContext);

  const { loading, privateViews, data, publicViews } = useTypedContext(SavedFiltersContext);

  useEffect(() => {
    resetOpenSections();
  }, [resetOpenSections]);

  const titleId = useId();

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <div>
      <div>
        <Box direction="column" align="start" className={styles.wrapper}>
          <Typography id={titleId} tag="label" variant="p-t6" className={styles.label}>
            Saved views
          </Typography>
          <Box gap="medium" direction="row" align="center" role="tablist" aria-labelledby={titleId}>
            <Tab
              onClick={setCurrentTab}
              isActive={currentTab === "all"}
              id="all"
              size="small"
              label="All"
            />
            <Tab
              onClick={setCurrentTab}
              isActive={currentTab === "private"}
              id="private"
              size="small"
              label="Private"
            />
            <Tab
              onClick={setCurrentTab}
              isActive={currentTab === "public"}
              id="public"
              size="small"
              label="Shared"
            />
          </Box>
        </Box>
      </div>

      <TabPanel isActive={currentTab === "all"} id="all">
        <SaveFiltersList
          isDefaultViewAlwaysVisible={true}
          items={data?.savedFilters}
          applyView={applyView}
          placeHolderText="Here you will manage the list of all of available views"
        />
      </TabPanel>
      <TabPanel isActive={currentTab === "private"} id="private">
        <SaveFiltersList
          isDefaultViewAlwaysVisible={true}
          items={privateViews}
          applyView={applyView}
          placeHolderText="Here you will manage the list of your added views"
        />
      </TabPanel>
      <TabPanel isActive={currentTab === "public"} id="public">
        <SaveFiltersList
          items={publicViews}
          applyView={applyView}
          placeHolderText="Your list of saved views will appear here"
        />
      </TabPanel>
    </div>
  );
};

export default FiltersSavedViews;
