import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import CardWrapper from "components/CardWrapper";
import useURLParams from "hooks/useURLParams";
import Banner from "ds/components/Banner";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";
import { ArrowLeft, ArrowRight } from "components/icons/generated";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { ModuleFormContext } from "../context";
import styles from "./styles.module.css";
import NewModuleIntermediateConfirmationStepTile from "./StepTile";
import { config } from "./constants";
import { availableSteps } from "../constants";

const NewModuleIntermediateConfirmation = () => {
  const urlParams = useURLParams();
  const hasCopiedConfiguration = urlParams.size !== 0;

  const { setIsIntermediateStepActive, currentStep, goToNextStep, goToSummary } =
    useTypedContext(ModuleFormContext);

  const onContinue = () => {
    setIsIntermediateStepActive(false);
    goToNextStep();
  };

  const onBack = () => {
    setIsIntermediateStepActive(false);
  };

  const onSkipToSummary = () => {
    setIsIntermediateStepActive(false);
    goToSummary();
  };

  const currentStepIndex = availableSteps.findIndex(
    (availableStep) => availableStep.step === currentStep
  );

  const availableActions = config.filter(({ action: { step } }) => {
    const actionStepIndex = availableSteps.findIndex(
      (availableStep) => availableStep.step === step
    );

    if (actionStepIndex > currentStepIndex) {
      return true;
    }

    return false;
  });

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Module created
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Now you can make it even more powerful by sharing it, attaching cloud, policies or
          contexts in the next steps!
        </Typography>
        <Box direction="column" margin="x-large 0 large 0" gap="large">
          {hasCopiedConfiguration && (
            <Banner variant="warning" title="Configuration copied">
              Additional configuration was copied. You can go to summary to review it.
            </Banner>
          )}
          <CardWrapper variant="filled" direction="column" gap="large">
            <Typography tag="h3" variant="p-t5">
              Choose shortcut or continue
            </Typography>
            {availableActions.map((action, i) => (
              <NewModuleIntermediateConfirmationStepTile
                hasCopiedConfiguration={hasCopiedConfiguration}
                key={i}
                {...action}
              />
            ))}
          </CardWrapper>
        </Box>
      </FullScreenModalBody>
      <FullScreenModalFooter className={styles.footerWrapper} justify="end">
        <Box gap="medium" justify="end">
          <Button
            analyticsTitle="Skip to summary click"
            analyticsProps={{
              location: "Intermediate step",
            }}
            variant="secondary"
            onClick={onSkipToSummary}
          >
            Skip to summary
          </Button>
          <Button
            variant="secondary"
            analyticsPage={AnalyticsPageModule.ModuleNew}
            onClick={onBack}
            analyticsTitle="Back click"
            analyticsProps={{
              location: "Intermediate step",
            }}
            startIcon={({ className }) => <Icon src={ArrowLeft} className={className} />}
          >
            Back
          </Button>
          <Button
            variant="primary"
            analyticsTitle="Continue click on intermediate step"
            analyticsPage={AnalyticsPageModule.ModuleNew}
            onClick={onContinue}
            endIcon={({ className }) => <Icon src={ArrowRight} className={className} />}
          >
            Continue
          </Button>
        </Box>
      </FullScreenModalFooter>
    </>
  );
};

export default NewModuleIntermediateConfirmation;
