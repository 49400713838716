import camelCase from "lodash-es/camelCase";
import { memo, ReactNode } from "react";
import cx from "classnames";

import { Minus, Tick } from "components/icons/generated";
import { FLEX_ALIGN_PREFIX } from "constants/style";
import { FlexAlign } from "types/Flex";
import Tooltip from "ds/components/Tooltip";
import { withTestId } from "utils/withTestId";

import Box from "../Box";
import Icon from "../Icon";
import Typography from "../Typography";
import styles from "./styles.module.css";
import { getDefaultTooltip, getTextColor } from "./utils";

type ToggleContentProps = {
  id?: string;
  color?: "default" | "inversed";
  variant: "checkbox" | "switch";
  className?: string;
  children?: ReactNode;
  align?: FlexAlign;
  isSelected: boolean;
  isDisabled: boolean;
  isIndeterminate: boolean;
  tooltip?: string;
  dataTestId?: string;
  initialMinHeight?: boolean;
};

const ToggleContent = ({
  children,
  dataTestId,
  className,
  color,
  align,
  variant,
  isSelected,
  isDisabled,
  isIndeterminate,
  tooltip,
  initialMinHeight,
}: ToggleContentProps) => {
  const parsedTooltip = tooltip || (!isDisabled && getDefaultTooltip(variant, isSelected));

  return (
    <div
      {...withTestId(dataTestId)}
      className={cx(
        styles.toggle,
        styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
        styles[camelCase(`color-${color}`)],
        {
          [styles.checked]: isSelected || isIndeterminate,
          [styles.disabled]: isDisabled,
          [styles.initialMinHeight]: initialMinHeight,
        },
        className
      )}
    >
      <Tooltip
        active={!!parsedTooltip}
        on={(tooltipProps) => (
          <Box
            {...tooltipProps}
            align="center"
            className={cx(styles.iconWrapper, tooltipProps.className)}
          >
            {variant === "switch" && <span className={styles.switchIcon} />}
            {variant === "checkbox" && (
              <span className={styles.checkboxWrapper}>
                <Icon src={isIndeterminate ? Minus : Tick} className={styles.checkboxIcon} />
              </span>
            )}
          </Box>
        )}
      >
        {parsedTooltip}
      </Tooltip>

      {children && typeof children === "string" ? (
        <Typography
          className={styles.label}
          tag="span"
          variant="p-body2"
          color={getTextColor(isDisabled, color)}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </div>
  );
};

ToggleContent.displayName = "DS.Toggle.Content";

export default memo(ToggleContent);
