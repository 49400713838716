import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import DrawerSimple from "ds/components/DrawerNew/Simple";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import useGetAttachablePolicies from "shared/Policy/useGetAttachablePolicies";
import { PolicyType } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Feedback from "ds/components/Feedback";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import ButtonNew from "ds/components/Button/New";
import useAttachPolicy from "shared/Policy/useAttachPolicy";
import { getDocsUrl } from "utils/getDocsUrl";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Box from "ds/components/Box";
import ComboBoxItem from "ds/components/ComboBox/Item";
import ComboBox from "ds/components/ComboBox";

import { GET_POLICIES_REFETCH_QUERIES } from "./constants";

type AttachPolicyFormFields = {
  policyType: PolicyType;
  policy: string;
};

type PoliciesAttachDrawerProps = {
  alreadyAttachedPolicies: Set<string>;
  entityId: string;
  spaceId: string;
};

const PoliciesAttachDrawer = createDrawer(
  ({ alreadyAttachedPolicies, entityId, spaceId }: PoliciesAttachDrawerProps) => {
    const drawer = useModal();
    const attachPolicyForm = useForm<AttachPolicyFormFields>({
      mode: "onChange",
    });

    const trackSegmentEvent = useAnalytics({
      page: AnalyticsPageStack.StacksPolicies,
    });

    const {
      control,
      formState: { isValid },
      handleSubmit,
      watch,
    } = attachPolicyForm;

    const policyType = watch("policyType");

    const { policiesOptions, policyTypesOptions, loading } = useGetAttachablePolicies({
      spaceId,
      alreadyAttachedPolicies,
    });

    const { attachPolicy, loading: isAttaching } = useAttachPolicy(
      "stack",
      GET_POLICIES_REFETCH_QUERIES
    );

    const attachablePolicyOptions = useMemo(
      () => policiesOptions.filter((policy) => policy.type === policyType),
      [policiesOptions, policyType]
    );

    const handleClose = () => {
      drawer.hide();
    };

    const handleCancelAttaching = () => {
      handleClose();
      trackSegmentEvent("Attach Policy Canceled");
    };

    const handleAttach = (formData: AttachPolicyFormFields) => {
      attachPolicy(formData.policy, entityId, () => {
        trackSegmentEvent("Policy Attached", { type: formData.policyType });
        handleClose();
      });
    };

    const handleFormSubmit = () => {
      handleSubmit(handleAttach)();
    };

    const policyTypesTooltip = (
      <>
        <TooltipModalTitle>Policy type</TooltipModalTitle>
        <TooltipModalBody align="start">
          <Typography tag="p" variant="p-body3">
            Spacelift uses an open-source project called{" "}
            <TextLink href="https://www.openpolicyagent.org/">
              <Typography tag="span" variant="p-body3">
                Open Policy Agent
              </Typography>
            </TextLink>{" "}
            and its rule language,{" "}
            <TextLink href="https://www.openpolicyagent.org/docs/latest/policy-language/">
              <Typography tag="span" variant="p-body3">
                Rego
              </Typography>
            </TextLink>
            , to execute user-defined pieces of code we call Policies at various decision points.
            Policies come in different flavors that we call types, with each type being executed at
            a different decision point.
          </Typography>
          <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy")} />
        </TooltipModalBody>
      </>
    );

    return (
      <DrawerSimple onClose={handleCancelAttaching}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Attach policy" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Box direction="column" gap="x-large">
            <Feedback type="banner" variant="info">
              You can only attach policies from the current space and parent spaces that you inherit
              from.
            </Feedback>

            <Controller
              name="policyType"
              control={control}
              rules={{ required: "Policy type is required." }}
              render={({ field, fieldState }) => (
                <ComboBox
                  label="Policy type"
                  error={fieldState.error?.message}
                  items={policyTypesOptions}
                  value={field.value}
                  onChange={field.onChange}
                  tooltipInfo={policyTypesTooltip}
                >
                  {(item) => <ComboBoxItem id={item.value} label={item.label} />}
                </ComboBox>
              )}
            />

            <Controller
              name="policy"
              control={control}
              rules={{ required: "Policy is required." }}
              render={({ field, fieldState }) => (
                <ComboBox
                  label="Select policy"
                  error={fieldState.error?.message}
                  items={attachablePolicyOptions}
                  isDisabled={!policyType}
                  isLoading={loading}
                  value={field.value}
                  onChange={field.onChange}
                >
                  {(item) => <ComboBoxItem id={item.value} label={item.label} />}
                </ComboBox>
              )}
            />
          </Box>

          <DrawerFooter>
            <DrawerFooterActions>
              <ButtonNew variant="secondary" onPress={handleCancelAttaching} disabled={isAttaching}>
                Cancel
              </ButtonNew>
              <ButtonNew
                variant="primary"
                type="submit"
                onPress={handleFormSubmit}
                loading={isAttaching}
                disabled={!isValid || isAttaching}
              >
                Attach
              </ButtonNew>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showPoliciesAttachDrawer = createDrawerTrigger(PoliciesAttachDrawer);
