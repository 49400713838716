import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import { HooksInput, Context } from "types/generated";
import { PhaseName, adaptCommandsToFields, adaptFieldsToCommands } from "utils/hooks";
import useTitle from "hooks/useTitle";
import useBlockNavigationModal from "hooks/useBlockNavigationModal";

import { UPDATE_CONTEXT } from "../gql";
import { ContextContext } from "../Context";
import ContextHooksItem from "./Item";
import { ContextHooksFormFields } from "./types";

const ContextHooks = () => {
  const { context } = useTypedContext(ContextContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  useTitle(`Hooks · ${context.name}`);

  const [updateConfig] = useMutation<{
    contextUpdateV2: Context;
  }>(UPDATE_CONTEXT, {
    refetchQueries: ["GetContext"],
  });
  const hooks = adaptFieldsToCommands(context.hooks);

  const form = useForm<ContextHooksFormFields>({
    defaultValues: {
      hooks: hooks,
    },
    mode: "onChange",
  });

  const [changedHooks, setChangedHooks] = useState<Set<string>>(new Set());

  useBlockNavigationModal({
    isNavigationBlocked: changedHooks.size > 0,
  });

  const onSave = (callback: () => unknown) => {
    const hooksData = form.getValues("hooks");

    let hooks = {} as HooksInput;

    for (const [phase, commands] of Object.entries(hooksData)) {
      hooks = {
        ...hooks,
        ...adaptCommandsToFields(commands, phase as PhaseName),
      };
    }

    updateConfig({
      variables: {
        id: context.id,
        input: {
          name: context.name,
          description: context.description,
          labels: context.labels,
          hooks,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          callback();
          reportSuccess({
            message: "Hooks were updated",
          });
        }
      })
      .catch(onError);
  };

  return (
    <>
      <PageInfo title="Hooks"></PageInfo>
      <FormProvider {...form}>
        <Box direction="column" padding="large" gap="large">
          {Object.keys(hooks).map((item) => {
            return (
              <ContextHooksItem
                key={item}
                item={item as PhaseName}
                onSave={onSave}
                setChangedHooks={setChangedHooks}
              />
            );
          })}
        </Box>
      </FormProvider>
    </>
  );
};

export default ContextHooks;
