import { useBlocker } from "react-router-dom";
import { useCallback, useEffect } from "react";

import { showBlockNavigationModal } from "./BlockNavigationModal";

type UseBlockNavigationModalProps = {
  isNavigationBlocked: boolean;
  title?: string;
  description?: string;
};

const useBlockNavigationModal = ({
  isNavigationBlocked,
  title,
  description,
}: UseBlockNavigationModalProps) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isNavigationBlocked && currentLocation.pathname !== nextLocation.pathname
  );

  const onNavigationConfirm = useCallback(() => {
    blocker?.proceed?.();
  }, [blocker]);

  useEffect(() => {
    if (blocker.state === "blocked")
      showBlockNavigationModal({ title, description, onConfirm: onNavigationConfirm });
  }, [blocker.state, title, description, onNavigationConfirm]);
};

export default useBlockNavigationModal;
