import { memo, useEffect, useMemo, useRef } from "react";
import { useLazyQuery } from "@apollo/client";

import Filters from "components/Filters";
import useURLParams from "hooks/useURLParams";
import {
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import { getSearchQuery } from "components/SearchInput/helpers";
import FiltersSortHeader from "components/Filters/SortHeader";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import { AnalyticsPageWebhook } from "hooks/useAnalytics/pages/webhooks";

import { SEARCH_WEBHOOKS_SUGGESTIONS } from "../gql";
import {
  COLUMN_GAP,
  FILTERS_ORDER_SETTINGS_KEY,
  initialSortDirection,
  initialSortOption,
} from "../constants";
import { POLL_INTERVAL, COLUMN_ORDER } from "../constants";
import { webhooksSuggestionsDictionary, sortItemOrder } from "./constants";

type WebhooksFiltersLayoutProps = {
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  hasNoResults: boolean;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
};

const WebhooksFiltersLayout = ({
  hasNoResults,
  predicates,
  children,
  currentSavedView,
  setCurrentSavedView,
}: WebhooksFiltersLayoutProps) => {
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [
    loadSearchNamedWebhooksIntegrationsSuggestions,
    { data: filteringData, loading, refetch },
  ] = useLazyQuery<{
    searchNamedWebhooksIntegrationsSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_WEBHOOKS_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchNamedWebhooksIntegrationsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable && field.name !== "slug")
        .map((field) => {
          return {
            value: field.name,
            label: webhooksSuggestionsDictionary[field.name] || field.name,
          };
        })
        .sort((a, b) => sortItemOrder.indexOf(a.value) - sortItemOrder.indexOf(b.value)) || []
    );
  }, [cachedFiltersData]);

  const [filters, webhooksFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        // timestamp is not a filter, it's a date range option
        .filter((field) => field.filterable && field.name !== "timestamp")
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = webhooksSuggestionsDictionary[key] || key;
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadSearchNamedWebhooksIntegrationsSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;

    refetch({
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={webhooksFiltersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersDictionary={webhooksSuggestionsDictionary}
      filtersType="webhooks"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageWebhook.WebhooksList} />

        <FiltersContentWrapper role="table">
          {!hasNoResults && <FiltersSortHeader columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP} />}
          {children}
        </FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(WebhooksFiltersLayout);
