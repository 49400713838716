import { VERSION_INPUT_TYPE, VersionInputOption } from "components/FormFields/VersionInput/types";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";
import { VENDOR_DICTIONARY, VENDOR_KEY } from "constants/vendor";
import {
  Stack,
  StackConfigVendorInput,
  StackConfigVendorTerraform,
  TerraformWorkflowTool,
} from "types/generated";
import { stackValidator } from "utils/formValidators";

import { type StackSettingsTerraformVendorFormFields } from "./Edit";

export const getFormDefaultValues = (
  stackSettings: Stack
): StackSettingsTerraformVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorTerraform;

  return {
    version: {
      type: stackSettings.effectiveTerraformVersion
        ? VERSION_INPUT_TYPE.RANGE
        : VERSION_INPUT_TYPE.SPECIFIC,
      value: config.version || stackSettings.effectiveTerraformVersion || "",
    },
    useSmartSanitization: config.useSmartSanitization,
    workflowTool: config.workflowTool,
    externalStateAccessEnabled: config.externalStateAccessEnabled,
    workspace: config.workspace || "",
  };
};

export const getValidateToolVersion =
  (toolVersions?: string[]) =>
  (version: VersionInputOption, formValues: StackSettingsTerraformVendorFormFields) => {
    if (!toolVersions || !version.value?.trim()) {
      return "Version is required";
    }

    const sanitizationError = stackValidator.validateUseSmartSanitizationField(
      formValues.useSmartSanitization,
      version.value,
      toolVersions,
      formValues.workflowTool
    );

    let rangeError;
    if (formValues.version.type === VERSION_INPUT_TYPE.RANGE) {
      const vendor =
        formValues.workflowTool === TerraformWorkflowTool.OpenTofu
          ? VENDOR_DICTIONARY.OPEN_TOFU
          : VENDOR_DICTIONARY.TERRAFORM;

      rangeError = stackValidator.validateVersionRange(version.value, toolVersions, vendor);
    }

    return sanitizationError || rangeError || true;
  };

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsTerraformVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.TERRAFORM]: {
    ...formData,
    version: formData.version.value || null,
    workspace: formData.workspace || null,
  },
});
