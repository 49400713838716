import { useMutation } from "@apollo/client";
import { useState } from "react";
import { writeStorage } from "@rehooks/local-storage";

import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Input from "ds/components/Input";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import { FiltersContext } from "components/Filters";
import { DEFAULT_VIEW_ID } from "components/Filters/constants";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import PageLoading from "components/loading/PageLoading";

import { SAVE_VIEW, UPDATE_VIEW } from "../gql";
import styles from "./styles.module.css";
import { getFiltersFromSavedData, getSavedViewData } from "../helpers";
import { SavedFiltersContext } from "../Context";

type FiltersSavedViewsUpdateNameFormProps = {
  wrapperId: string;
  closeForm: () => void;
};

const FiltersSavedViewsUpdateNameForm = ({
  wrapperId,
  closeForm,
}: FiltersSavedViewsUpdateNameFormProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { currentView, setCurrentView, filterType, defaultEmptyFilterView } =
    useTypedContext(FiltersContext);
  const [name, setName] = useState(currentView?.name);

  const { loading, privateViews } = useTypedContext(SavedFiltersContext);

  const [updateView] = useMutation<{ savedFilterUpdate: { name: string } }>(UPDATE_VIEW);

  const [saveView] = useMutation(SAVE_VIEW, { refetchQueries: ["SavedView", "SavedViewsList"] });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const notSavedDefaultView = currentView?.id === DEFAULT_VIEW_ID;

    const { data, error } = getSavedViewData(defaultEmptyFilterView.filters, {
      order: defaultEmptyFilterView.order,
      text: null,
      sort: {
        direction: defaultEmptyFilterView.sortDirection,
        option: defaultEmptyFilterView.sortOption,
      },
    });

    if (error) {
      return onError(error);
    }

    if (notSavedDefaultView) {
      saveView({
        variables: {
          input: {
            data,
            type: filterType,
            name: name,
            isPublic: currentView?.isPublic,
          },
        },
      })
        .then(({ data: { savedFilterCreate } }) => {
          const { order, filters, sortOption, sortDirection, search } = getFiltersFromSavedData(
            savedFilterCreate.data,
            defaultEmptyFilterView
          );
          closeForm();
          writeStorage(getDefaultViewStorageKey(filterType), savedFilterCreate.id);
          setCurrentView({
            name: savedFilterCreate.name,
            id: savedFilterCreate.id,
            isPublic: savedFilterCreate.isPublic,
            order,
            filters,
            isMine: true,
            sortOption,
            sortDirection,
            search,
          });
          reportSuccess({ message: "View name was successfully updated" });
        })
        .catch(onError);
    } else {
      updateView({
        variables: {
          id: currentView?.id,
          name: name,
          isPublic: currentView?.isPublic,
        },
      })
        .then(({ data }) => {
          reportSuccess({ message: "View name was successfully updated" });
          closeForm();
          if (currentView) {
            setCurrentView({ ...currentView, name: data?.savedFilterUpdate.name || "" });
          }
        })
        .catch(onError);
    }
  };

  const privateViewWithTheSameName = privateViews?.find((view) => view.name === name);

  const error =
    privateViewWithTheSameName && privateViewWithTheSameName.id !== currentView?.id
      ? "View name already exists"
      : undefined;

  if (loading) {
    return <PageLoading />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box id={wrapperId} direction="column" gap="large" className={styles.container}>
        <FormField label="Name" error={error}>
          {({ ariaInputProps }) => (
            <Input
              onChange={handleNameChange}
              value={name}
              placeholder="Name your view"
              error={!!error}
              autoFocus
              {...ariaInputProps}
            />
          )}
        </FormField>

        <Box justify="end" __deprecatedGap="1rem">
          <Button onClick={closeForm} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={!!error}>
            Update
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default FiltersSavedViewsUpdateNameForm;
