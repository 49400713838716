import {
  EmptystateModulesColored,
  EmptystateSearchNoResultsColored,
} from "components/icons/generated";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";

type ModulesEmptyStateProps = {
  didPerformSearch?: boolean;
};

const ModulesEmptyState = ({ didPerformSearch }: ModulesEmptyStateProps) => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <Box align="center" justify="center" grow="1">
      {didPerformSearch && (
        <EmptyState
          title="No results"
          icon={EmptystateSearchNoResultsColored}
          caption="Don’t give up! Check the spelling or try something less specific."
          announce
        />
      )}
      {!didPerformSearch && viewer.admin && (
        <EmptyState title="You don’t have any modules yet" icon={EmptystateModulesColored}>
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/module-registry.html")}
              label="Documentation"
            />
            <Button variant="primary" to="/new/module">
              Create module
            </Button>
          </Box>
        </EmptyState>
      )}
      {!didPerformSearch && !viewer.admin && (
        <EmptyState
          title="Spacelift is no fun without modules and you don't have access to any yet"
          icon={EmptystateModulesColored}
          caption="Please reach out to one of your friendly account admins to grant you access, or create a
        new module for you to play with."
        />
      )}
    </Box>
  );
};

export default ModulesEmptyState;
