import { EmptystateMagnetColored } from "components/icons/generated";
import CollapsibleList from "components/CollapsibleList";
import EmptyState from "ds/components/EmptyState";

import NewModuleAttachedPolicy from "../AttachedPolicy";
import { AutoAttachedPolicy } from "../types";

type NewModuleAutoAttachedPoliciesProps = {
  items: AutoAttachedPolicy[];
};

const NewModuleAutoAttachedPolicies = ({ items }: NewModuleAutoAttachedPoliciesProps) => {
  return (
    <CollapsibleList
      ariaLevel={3}
      title="Auto-attached"
      count={items.length}
      emptyPlaceholder={
        <EmptyState
          padding="large"
          icon={EmptystateMagnetColored}
          title="No policies are auto-attached to this module"
          caption="Use auto-attach label to attach policy automatically."
        />
      }
      initialIsCollapsed={false}
    >
      {items.map((item) => (
        <NewModuleAttachedPolicy key={item.id} item={item} headingLevel={4} />
      ))}
    </CollapsibleList>
  );
};

export default NewModuleAutoAttachedPolicies;
