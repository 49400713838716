import { ReactNode } from "react";
import { Section } from "react-aria-components";

import styles from "./styles.module.css";

type DropdownMenuSectionProps = {
  children: ReactNode;
};

const DropdownMenuSection = ({ children }: DropdownMenuSectionProps) => {
  return <Section className={styles.section}>{children}</Section>;
};

DropdownMenuSection.displayName = "DS.DropdownMenu.Section";

export default DropdownMenuSection;
