import { gql, TypedDocumentNode } from "@apollo/client";

import {
  Maybe,
  Scalars,
  Stack,
  WebhookDeliveryDetails,
  WebhooksIntegration,
} from "types/generated";

export type GetModuleWebhookDeliveryDetailsArgs = {
  deliveryId: Scalars["String"]["input"];
  webhookId: Scalars["ID"]["input"];
  stackId: Scalars["ID"]["input"];
};

type GetModuleWebhookDeliveryDetailsData = {
  stack: Maybe<{
    id: Stack["id"];
    integrations: Maybe<{
      webhook: Maybe<{
        id: WebhooksIntegration["id"];
        deliveryDetails: Maybe<WebhookDeliveryDetails>;
      }>;
    }>;
  }>;
};

export const GET_STACK_WEBHOOK_DELIVERY_DETAILS: TypedDocumentNode<
  GetModuleWebhookDeliveryDetailsData,
  GetModuleWebhookDeliveryDetailsArgs
> = gql`
  query GetStackWebhookDeliveryDetails($deliveryId: String!, $webhookId: ID!, $stackId: ID!) {
    stack(id: $stackId) {
      id
      integrations {
        webhook(id: $webhookId) {
          id
          deliveryDetails(key: $deliveryId) {
            requestHeaders {
              name
              values
            }
            requestBody
            responseHeaders {
              name
              values
            }
            responseError
            responseBody
          }
        }
      }
    }
  }
`;
