import { ReactNode } from "react";

import Box from "../Box";
import styles from "./styles.module.css";
import { ButtonProps } from "../Button/New";
import { SplitControlContext } from "./context";

type SplitControlWrapperProps = {
  children: ReactNode;
} & Pick<ButtonProps, "variant" | "size">;

const SplitControlWrapper = ({ children, ...restProps }: SplitControlWrapperProps) => {
  return (
    <SplitControlContext.Provider value={restProps}>
      <Box className={styles.splitControlWrapper}>{children}</Box>
    </SplitControlContext.Provider>
  );
};

SplitControlWrapper.displayName = "DS.SplitControl.Wrapper";

export default SplitControlWrapper;
