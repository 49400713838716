import { ReactNode, useCallback } from "react";

import { IconComponent } from "types/Icon";
import Tile from "ds/components/Tile";
import ButtonNew from "ds/components/Button/New";
import Box from "ds/components/Box";
import TileTitle from "ds/components/Tile/Title";
import BadgeNext from "ds/components/BadgeNext";

import { showChangeManagementStrategyConfirmationModal } from "./ConfirmationModal";

type ManagementStrategyItemProps = {
  active: boolean;
  title: string;
  icon: IconComponent;
  children: ReactNode;
  confirmationDescription: ReactNode;
  confirmationCallback: () => void;
};

const ManagementStrategyItem = ({
  active,
  children,
  icon,
  title,
  confirmationDescription,
  confirmationCallback,
}: ManagementStrategyItemProps) => {
  const handleClick = useCallback(() => {
    if (!active) {
      showChangeManagementStrategyConfirmationModal({
        name: title,
        description: confirmationDescription,
        onConfirm: confirmationCallback,
      });
    }
  }, [active, confirmationCallback, confirmationDescription, title]);

  return (
    <Tile
      title={
        <Box direction="row" gap="medium" align="center">
          <TileTitle>{title}</TileTitle>
          {active && <BadgeNext variant="green" type="strong" text="Enabled" />}
        </Box>
      }
      icon={icon}
      description={children}
      indicator={
        active ? null : (
          <ButtonNew variant="primary" onPress={handleClick}>
            Enable
          </ButtonNew>
        )
      }
    />
  );
};

export default ManagementStrategyItem;
