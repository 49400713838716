import { useMemo, useState, ReactNode, AriaRole } from "react";

import { SearchQueryOrderDirection } from "types/generated";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import { SortOption } from "components/Filters/types";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";
import { Spacing } from "types/Spacing";

import styles from "./styles.module.css";
import { sortByField } from "./helpers";

export type SortableTableColumn = SortOption & { static?: boolean; colStart?: string };

export type SortableTableProps<T> = {
  columns: SortableTableColumn[];
  initialSortBy: string;
  initialDirection: SearchQueryOrderDirection;
  items: T[];
  renderItems: (sorted: T[]) => ReactNode;
  columnOrder: string;
  columnGap?: Spacing;
  stickyHeader?: boolean;
  role?: boolean;
  headerCellRole?: AriaRole;
};

const SortableTable = <T,>({
  columnOrder,
  items,
  columns,
  initialSortBy,
  initialDirection,
  renderItems,
  stickyHeader,
  columnGap = "large",
  headerCellRole,
}: SortableTableProps<T>) => {
  const [direction, setDirection] = useState<SearchQueryOrderDirection>(initialDirection);
  const [sortBy, setSortBy] = useState(initialSortBy);

  const sorted = useMemo(() => sortByField(items, sortBy, direction), [items, sortBy, direction]);

  const handleChangeSorting = (field: string) => () => {
    if (field === sortBy) {
      setDirection((prev) =>
        prev === SearchQueryOrderDirection.Asc
          ? SearchQueryOrderDirection.Desc
          : SearchQueryOrderDirection.Asc
      );
    } else {
      setSortBy(field);
    }
  };
  return (
    <>
      {sorted.length > 0 && (
        <FiltersSortHeaderWrapper
          columnOrder={columnOrder}
          columnGap={columnGap}
          className={stickyHeader ? styles.stickyHeader : undefined}
        >
          {columns.map((column) =>
            column.static ? (
              <FiltersSortHeaderStaticColumn
                role={headerCellRole}
                key={column.value}
                gridColStart={column.colStart}
              >
                <Typography tag="span" variant="p-t6">
                  {column.label}
                </Typography>
              </FiltersSortHeaderStaticColumn>
            ) : (
              <FiltersSortHeaderSortableColumn
                wrapperAriaRole={headerCellRole}
                key={column.value}
                option={column}
                onClick={handleChangeSorting}
                querySortOption={sortBy}
                querySortDirection={direction}
                gridColStart={column.colStart}
              />
            )
          )}
        </FiltersSortHeaderWrapper>
      )}
      {renderItems(sorted)}
    </>
  );
};

export default SortableTable;
