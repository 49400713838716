import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { PolicyType } from "types/generated";
import { ChevronDown } from "components/icons/generated";

type PolicyTypeConfig = {
  variant: BadgeVariants;
  text: string;
};

export const POLICY_TYPES: Record<PolicyType, PolicyTypeConfig> = {
  [PolicyType.Access]: { variant: "blue", text: "Access" },
  [PolicyType.Approval]: { variant: "green", text: "Approval" },
  [PolicyType.GitPush]: { variant: "orange", text: "Push" },
  [PolicyType.Initialization]: { variant: "blue", text: "Init" },
  [PolicyType.Login]: { variant: "blue", text: "Login" },
  [PolicyType.Notification]: { variant: "fuchsia", text: "Notification" },
  [PolicyType.Plan]: { variant: "yellow", text: "Plan" },
  [PolicyType.StackAccess]: { variant: "blue", text: "Stack access" },
  [PolicyType.Task]: { variant: "gray", text: "Task" },
  [PolicyType.TaskRun]: { variant: "gray", text: "Task run" },
  [PolicyType.TerraformPlan]: { variant: "yellow", text: "Terraform plan" },
  [PolicyType.Trigger]: { variant: "purple", text: "Trigger" },
};

type PolicyTypeBadgeProps = {
  type: PolicyType;
  className?: string;
  withDropdown?: boolean;
};

const PolicyTypeBadge = ({ type, className, withDropdown }: PolicyTypeBadgeProps) => {
  if (!type || !POLICY_TYPES[type]) {
    return null;
  }

  return (
    <BadgeNext
      variant={POLICY_TYPES[type].variant}
      className={className}
      text={POLICY_TYPES[type].text}
      type="weak"
      endIcon={withDropdown ? ChevronDown : undefined}
    />
  );
};

export default PolicyTypeBadge;
