import BadgeNext from "ds/components/BadgeNext";
import { Space } from "components/icons/generated";

import styles from "./styles.module.css";
import { AttachableProject } from "./types";

const NewContextAttachContextProjectComboBoxItemBadge = (props: AttachableProject) => {
  return (
    <div className={styles.badgeWrapper}>
      <BadgeNext
        variant="gray"
        startIcon={Space}
        text={props.spaceDetails.name}
        textEllipsis
        type="weak"
      />
    </div>
  );
};

export default NewContextAttachContextProjectComboBoxItemBadge;
