import { useCallback } from "react";

import { showSimpleLeaveConfirmation } from "../LeaveConfirmationModal/Simple";
import DrawerSimple, { DrawerSimpleProps } from "./Simple";

type DrawerFormProps = {
  isDirty: boolean;
} & DrawerSimpleProps;

const DrawerForm = ({ children, onClose, isDirty, ...restProps }: DrawerFormProps) => {
  const handleClose = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    onClose?.();
  }, [onClose, isDirty]);

  return (
    <DrawerSimple onClose={handleClose} {...restProps}>
      {children}
    </DrawerSimple>
  );
};

DrawerForm.displayName = "DS.DrawerNew.Form";

export default DrawerForm;
