import ListEntitiesItem from "components/ListEntitiesItem";
import { ModuleMetadata } from "types/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ButtonNew from "ds/components/Button/New";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

type ModuleVersionSubmodulesItemProps = {
  canShowInstructions: boolean;
  item: ModuleMetadata;
  handleOpenInstructions: (submodule: ModuleMetadata) => () => void;
};
const ModuleVersionSubmodulesItem = ({
  canShowInstructions,
  item,
  handleOpenInstructions,
}: ModuleVersionSubmodulesItemProps) => {
  const isActive = useDrawerVisibilityForId(item.name);
  return (
    <ListEntitiesItem key={item.name} justify="between" direction="row" isActive={isActive}>
      <ListEntitiesItemLink
        title={item.name}
        to={`../submodule/${encodeURIComponent(item.name)}`}
      />
      {canShowInstructions && item.inputs && (
        <ButtonNew
          variant="secondary"
          size="small"
          aria-label="Show instructions"
          onPress={handleOpenInstructions(item)}
        >
          Instructions
        </ButtonNew>
      )}
    </ListEntitiesItem>
  );
};

export default ModuleVersionSubmodulesItem;
