import { useForm, FormProvider, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import LabelsInfo from "components/LabelsInfo";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Box from "ds/components/Box";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { getDocsUrl } from "utils/getDocsUrl";
import FormFieldTagsMagicBanner from "components/FormFields/Tags/MagicLabels";
import { useFormValidations } from "hooks";

import { ModuleCreationWizardStep, ModuleDetailsFormFields } from "../types";
import { ModuleFormContext } from "../context";
import NewModuleFooter from "../Footer";
import useAsyncValidation from "./useAsyncValidation";
import Documentation from "./Documentation";
import { getTooltipAnalyticsProps } from "../utils";
import { getDetailsFromRepo, validateNameField } from "./utils";

const NewModuleDetails = () => {
  const [hasUnsubmittedLabelsChanges, setHasUnsubmittedLabelsChanges] = useState(false);
  const {
    asyncValidationLoading,
    currentStep,
    updateStepData,
    formData,
    setInternalFormData,
    internalData,
    createdModuleId,
  } = useTypedContext(ModuleFormContext);

  useObserveForWarning(
    hasUnsubmittedLabelsChanges,
    <>
      You have not saved changes in <b>labels</b> field. Do you want to continue without saving
      them?
    </>
  );

  const stepData = formData[ModuleCreationWizardStep.Details];

  const builderForm = useForm<ModuleDetailsFormFields>({
    defaultValues: {
      ...stepData,
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    formState: { errors },
    setError,
    setValue,
    trigger,
    watch,
    getValues,
  } = builderForm;
  const runFormValidations = useFormValidations(builderForm);

  const newStepData = watch();
  const { validateName } = useAsyncValidation(setError, stepData.name);

  useEffect(() => {
    if (!createdModuleId) {
      const { name, provider } = getDetailsFromRepo(
        formData[ModuleCreationWizardStep.Vcs].repository
      );
      const validationSlug = `terraform-${provider || "default"}-${name}`;

      validateName(validationSlug);
      setValue("name", name);
      setValue("terraformProvider", provider);
      trigger("name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdModuleId]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const provider = getValues("terraformProvider");

      if (value.length > 0) {
        validateName(`terraform-${provider || "default"}-${value}`);
      }

      setValue("name", value);
      trigger("name");
    },
    [setValue, trigger, validateName, getValues]
  );

  const handleProviderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const name = getValues("name");

      if (value.length > 0) {
        validateName(`terraform-${value || "default"}-${name}`);
      }
      trigger("name");
      setValue("terraformProvider", value);
      trigger("terraformProvider");
    },
    [setValue, trigger, validateName, getValues]
  );

  const processStepData = async () => {
    setInternalFormData({ ...internalData });

    return updateStepData(currentStep, newStepData);
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Add module details
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Name your module and add labels for improved organization and filtering.
        </Typography>
        <Box direction="column" margin="x-large 0 large 0">
          <FormProvider {...builderForm}>
            <Controller
              name="name"
              control={control}
              rules={{
                validate: (value) => validateNameField(value),
              }}
              render={({ field, fieldState }) => (
                <FormField
                  label="Name"
                  error={errors?.name?.message}
                  tooltipInfoVariant="modal"
                  {...getTooltipAnalyticsProps("Details", "Name")}
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Name your module</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        The module name will by default be inferred from the repository name if it
                        follows the terraform-provider-name naming convention. However, if the
                        repository doesn't follow this convention, or you want to give it a custom
                        name, you can provide it here.
                      </TooltipModalBody>
                    </>
                  }
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Name your module"
                      error={!!fieldState.error}
                      type="text"
                      value={field.value}
                      onChange={handleNameChange}
                      name="moduleName"
                      {...ariaInputProps}
                      ref={field.ref}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name="terraformProvider"
              control={control}
              render={({ field, fieldState }) => (
                <FormField
                  label="Provider"
                  isOptional
                  error={errors?.terraformProvider?.message}
                  tooltipInfoVariant="modal"
                  {...getTooltipAnalyticsProps("Details", "Terraform provider")}
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Name your module</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        The module provider will by default be inferred from the repository name if
                        it follows the terraform-provider-name naming convention. However, if the
                        repository doesn't follow this convention, or you gave the module a custom
                        name, it will not infer the provider but you can provide the provider name
                        here.
                      </TooltipModalBody>
                    </>
                  }
                >
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Place provider"
                      error={!!fieldState.error}
                      type="text"
                      value={field.value}
                      onChange={handleProviderChange}
                      name="terraformProvider"
                      {...ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />
            <FormFieldTags
              label={
                <Box gap="small" align="center">
                  Labels
                  <Typography tag="span" variant="p-body3" color="secondary">
                    (Optional)
                  </Typography>
                  <LabelsInfo
                    analyticsPage={AnalyticsPageModule.ModuleNew}
                    analyticsTitle="Tooltip click"
                    analyticsProps={{
                      location: "Details",
                      name: "Labels",
                    }}
                  />
                </Box>
              }
              tagName="label"
              name="labels"
              inputSize="regular"
              analyticsPage={AnalyticsPageModule.ModuleNew}
              onInputChange={(value) => setHasUnsubmittedLabelsChanges(!!value)}
            />

            <FormFieldTagsMagicBanner entityType="module" margin="x-large 0 0" />

            <FormField
              label="Description"
              isOptional
              tooltipInfoVariant="modal"
              {...getTooltipAnalyticsProps("Details", "Description")}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Describe your module</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Human-friendly description of this module - supports Markdown. This value is
                    only used to make life easier for you, the user. You can use this space to
                    describe your module, provide useful links, or embed a cat GIF. The only limit
                    is yourself.
                  </TooltipModalBody>
                </>
              }
            >
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter module description here..."
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </FormProvider>
        </Box>
      </FullScreenModalBody>
      <NewModuleFooter
        isDataChanged={isDataChanged}
        loading={asyncValidationLoading}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/vendors/terraform/module-registry")}
        documentationTitle="Module"
        documentationBody={<Documentation />}
        runFormValidations={runFormValidations}
      />
    </>
  );
};

export default NewModuleDetails;
